import actionConstants from '../constants/ActionConstants';

const initialState = {
  error: '',
  isFetching: false,
  success: '',
  updatedSystemInfoList: [],
  isSuccess: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionConstants.ADD_SYSTEM_OR_DEVICE_REQUEST:
      return {
        ...state,
        isSuccess: false,
        isFetching: true,
      };
    case actionConstants.ADD_SYSTEM_OR_DEVICE_SUCCESS:
      return {
        ...state,
        updatedSystemInfoList: action.payload.systemInfo,
        isFetching: false,
        isSuccess: true,
        success: action.message,
        error: '',
      };
    case actionConstants.ADD_SYSTEM_OR_DEVICE_FAILURE:
      return {
        ...state,
        error: action.message,
        isSuccess: false,
        isFetching: false,
        success: '',
      };
    case actionConstants.RESET_ALL_REDUCERS:
      return {
        ...initialState,
      };
    case actionConstants.CLEAR_STATE:
      return {
        ...state,
        isSuccess: false,
        isFetching: false,
      };
    default:
      return state;
  }
};
