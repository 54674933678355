import React from 'react';
import { FormattedMessage } from 'react-intl';

export default {
  finishSettingProfile: {
    userName: {
      target: '#user-name-header',
      nextTarget: '.edit-profile-option',
      style: { left: '20px', top: '53px' },
      toolTipClass: 'tooltip-triangle-top',
      content: (
        <FormattedMessage
          id="Click on your name."
          defaultMessage="Click on your name."
        />
      ),
    },
    editProfile: {
      target: '.edit-profile-option',
      nextTarget: '',
      style: { left: '-250px', top: '5px' },
      toolTipClass: 'tooltip-triangle-right',
      content: (
        <FormattedMessage
          id="Click Edit Profile and fill the blanks."
          defaultMessage="Click Edit Profile and fill the blanks."
        />
      ),
    },
  },

  seeReportLooksLike: {
    reports: {
      target: '.reports-coachmark',
      nextTarget: '.reports-complete-coachmark',
      style: { left: '50px', top: '60px' },
      toolTipClass: 'tooltip-triangle-top',
      content: (
        <FormattedMessage
          id="Click on Reports."
          defaultMessage="Click on Reports."
        />
      ),
    },
    reportComplete: {
      target: '.reports-complete-coachmark',
      nextTarget: '.report-listing-table-coachmark',
      style: { left: '2px', top: '50px' },
      toolTipClass: 'tooltip-triangle-top',
      content: (
        <FormattedMessage
          id="Click Completed."
          defaultMessage="Click Completed."
        />
      ),
    },
    reportList: {
      target: '.report-listing-table-coachmark',
      nextTarget: '',
      style: { left: '580px', top: '130px', width: '380px' },
      toolTipClass: 'tooltip-triangle-bottom',
      content: (
        <FormattedMessage
          id="Completed Reports is where you will find your recently completed inspection reports, work orders and quotes. You can also find our example reports here where you can choose any or all to view."
          defaultMessage="Completed Reports is where you will find your recently completed inspection reports, work orders and quotes. You can also find our example reports here where you can choose any or all to view."
        />
      ),
    },
  },

  inspectionReminders: {
    customer: {
      target: '.customer-coachmark',
      nextTarget: '.import-customer-coachmark1',
      style: { left: '57px', top: '60px' },
      toolTipClass: 'tooltip-triangle-top',
      content: (
        <FormattedMessage
          id="Click Customers."
          defaultMessage="Click Customers."
        />
      ),
    },
    importCustomer: {
      target: '.import-customer-coachmark1',
      nextTarget: '.download-coachmark',
      style: { left: '216px', top: '2px' },
      toolTipClass: '',
      content: (
        <FormattedMessage
          id="Click on Import Customers."
          defaultMessage="Click on Import Customers."
        />
      ),
    },
    downloadCustomer: {
      target: '.download-coachmark',
      nextTarget: '.customer-coachmark1',
      style: { left: '-309px', top: '30px', width: '320px' },
      toolTipClass: 'tooltip-triangle-right',
      content: (
        <span>
          <FormattedMessage
            id="Step 1: Click to download our custom Excel file."
            defaultMessage="Step 1: Click to download our custom Excel file."
          />
          <hr />
          <FormattedMessage
            id="Step 2: Cut and paste the customer data from you file to ours."
            defaultMessage="Step 2: Cut and paste the customer data from you file to ours."
          />
          <hr />
          <FormattedMessage
            id="Step 3: Click to import your customer data."
            defaultMessage="Step 3: Click to import your customer data."
          />
        </span>
      ),
    },

    clickCustomer: {
      target: '.customer-coachmark1',
      nextTarget: '#customer-list-item-coachmark',
      style: { left: '57px', top: '60px' },
      toolTipClass: 'tooltip-triangle-top',
      content: (
        <FormattedMessage
          id="Click Customers."
          defaultMessage="Click Customers."
        />
      ),
    },
    customerItem: {
      target: '#customer-list-item-coachmark',
      nextTarget: '.recurring-inspection-coachmark',
      style: { left: '107px', top: '341px', width: '174px' },
      toolTipClass: 'tooltip-triangle-right',
      content: (
        <FormattedMessage
          id="Click on a customer."
          defaultMessage="Click on a customer."
        />
      ),
    },
    recurring: {
      target: '.recurring-inspection-coachmark',
      nextTarget: '.joblink-coachmark',
      style: { left: '-19px', top: '-225px', width: '200px' },
      toolTipClass: 'tooltip-triangle-bottom',
      content: (
        <div>
          <FormattedMessage
            id="Click on Recurring Inspections to create inspection
            reminders for each of your customers."
            defaultMessage="Click on Recurring Inspections to create
            inspection reminders for each of your customers."
          />
          <hr />
          <FormattedMessage id="OR" defaultMessage="OR" />
          <hr />
          <FormattedMessage
            id="Click on Add Job to create a one time job like a repair."
            defaultMessage="Click on Add Job to create a one time job like a repair."
          />
        </div>
      ),
    },
    joblink: {
      target: '.joblink-coachmark',
      nextTarget: '.job-list-head',
      style: { left: '19px', top: '60px', width: '200px' },
      toolTipClass: 'tooltip-triangle-top',
      content: (
        <FormattedMessage
          id="Click on Joblink to view or schedule created jobs."
          defaultMessage="Click on Joblink to view or schedule created jobs."
        />
      ),
    },
    jobHead: {
      target: '.job-list-head',
      nextTarget: '.job-list-item-coachmark',
      style: { left: '-282px', right: '329px' },
      toolTipClass: 'tooltip-triangle-right',
      content: (
        <FormattedMessage
          id="Recurring Inspections or jobs created for customers will appear here."
          defaultMessage="Recurring Inspections or jobs created for customers will appear here."
        />
      ),
    },
    jobList: {
      target: '.job-list-item-coachmark',
      nextTarget: '.choose-one-coachmark',
      style: { left: '-247px', top: '176px', right: '300px' },
      toolTipClass: 'tooltip-triangle-right',
      content: (
        <FormattedMessage
          id="Click on the job you want to schedule."
          defaultMessage="Click on the job you want to schedule."
        />
      ),
    },
    choose: {
      target: '.choose-one-coachmark',
      nextTarget: '.quarterly-wrap-modal',
      style: { left: '76px', top: '130px', right: '76px' },
      toolTipClass: 'tooltip-triangle-top',
      content: (
        <FormattedMessage
          id="You can select to schedule the job or make a note about the job."
          defaultMessage="You can select to schedule the job or make a note about the job."
        />
      ),
    },
    editJob: {
      target: '.quarterly-wrap-modal',
      nextTarget: '.task-coachmark',
      style: {
        left: '46%',
        top: '6vh',
        width: '249px',
        zIndex: '2000',
      },
      toolTipClass: 'tooltip-triangle-bottom',
      content: (
        <FormattedMessage
          id="Complete this form and Save to assign the job to a tech."
          defaultMessage="Complete this form and Save to assign the job to a tech."
        />
      ),
    },
    jobs: {
      target: '.task-coachmark',
      nextTarget: '.my-jobs-coachmark',
      style: { left: '9px', top: '60px', width: '220px' },
      toolTipClass: 'tooltip-triangle-top',
      content: (
        <FormattedMessage
          id="Techs can schedule their jobs one of the two ways. From the web app, click here on Jobs."
          defaultMessage="Techs can schedule their jobs one of the two ways. From the web app, click here on Jobs."
        />
      ),
    },
    myJobs: {
      target: '.my-jobs-coachmark',
      nextTarget: '.start-job-coachmark',
      style: { left: '1px', top: '50px' },
      toolTipClass: 'tooltip-triangle-top',
      content: (
        <FormattedMessage
          id="Click on My Jobs."
          defaultMessage="Click on My Jobs."
        />
      ),
    },
    startJob: {
      target: '.start-job-coachmark',
      nextTarget: '.team-coachmark',
      style: { left: '-170px', top: '11px' },
      toolTipClass: 'tooltip-triangle-right',
      content: (
        <FormattedMessage
          id="Click here to start a job."
          defaultMessage="Click here to start a job."
        />
      ),
    },

    team: {
      target: '.team-coachmark',
      nextTarget: '.add-team-coachmark',
      style: { left: '57px', top: '60px' },
      toolTipClass: 'tooltip-triangle-top',
      content: (
        <FormattedMessage id="Click on Team." defaultMessage="Click on Team." />
      ),
    },
    addTeam: {
      target: '.add-team-coachmark',
      nextTarget: '.team-basic-detail-coachmark',
      style: { left: '452px', top: '158px' },
      toolTipClass: '',
      content: (
        <FormattedMessage
          id="Click Add Member."
          defaultMessage="Click Add Member."
        />
      ),
    },
    firstSection: {
      target: '.team-basic-detail-coachmark',
      nextTarget: '.team-permission-detail-coachmark',
      style: { left: '664px', top: '97px' },
      toolTipClass: 'tooltip-triangle-bottom',
      content: (
        <div>
          <FormattedMessage
            id="Step 1: Complete this section."
            defaultMessage="Step 1: Complete this section."
          />
          <hr />
          <FormattedMessage
            id={'Step 2: Click the "Show in Joblink" box.'}
            defaultMessage={'Step 2: Click the "Show in Joblink" box.'}
          />
          <hr />
          <FormattedMessage
            id="Step 3: Click Next."
            defaultMessage="Step 3: Click Next."
          />
        </div>
      ),
    },
    permission: {
      target: '.team-permission-detail-coachmark',
      nextTarget: '.save-team-coachmark',
      style: { left: '283px', top: '-12px', width: '400px' },
      toolTipClass: 'tooltip-triangle-bottom',
      content: (
        <FormattedMessage
          id="Click each of the permissions you would like this member to have. Then click Save."
          defaultMessage="Click each of the permissions you would like this member to have. Then click Save."
        />
      ),
    },
    saveTeam: {
      target: '.save-team-coachmark',
      nextTarget: '',
      style: { left: '312px', top: '-7px', width: '400px' },
      toolTipClass: 'tooltip-triangle-right',
      content: (
        <FormattedMessage
          id="Click save, an email will be sent to the email entered above with login credentials."
          defaultMessage="Click save, an email will be sent to the email entered above with login credentials."
        />
      ),
    },
  },

  doOwnThing: {
    onBoarding: {
      target: '.onboarding-coachmark',
      nextTarget: '',
      style: { left: '-82px', top: '55px', width: '200px' },
      toolTipClass: 'tooltip-triangle-top',
      content: (
        <FormattedMessage
          id="Click on this guide to get help in the future."
          defaultMessage="Click on this guide to get help in the future."
        />
      ),
    },
  },
};
