import actionConstants from '../constants/ActionConstants';
import { reportTemplateListApiCall } from '../../api/apiCallReport';
import ActionDispatcher from '../../api/ActionDispatcher';

const getReportTemplateList = (page, limit) =>
  ActionDispatcher(
    reportTemplateListApiCall.bind(null, page, limit),
    actionConstants.GET_REPORT_TEMPLATE_LIST_SUCCESS,
    actionConstants.GET_REPORT_TEMPLATE_LIST_FAILURE,
    actionConstants.GET_REPORT_TEMPLATE_LIST_REQUEST
  );

const clearReportTemplateSuccess = () => (dispatch) => {
  dispatch({
    type: actionConstants.CLEAR_REPORT_TEMPLATE_SUCCESS_STATE,
  });
};

export { getReportTemplateList, clearReportTemplateSuccess };
