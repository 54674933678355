import actionConstants from '../constants/ActionConstants';

const initialState = {
  error: '',
  isfetching: false,
  data: {},
  success: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionConstants.GET_QR_CODE_RESPONSE_REQUEST:
      return {
        ...state,
        isfetching: true,
      };
    case actionConstants.GET_QR_CODE_RESPONSE_SUCCESS:
      return {
        ...state,
        isfetching: false,
        data: action.payload,
        success: action.message,
      };
    case actionConstants.GET_QR_CODE_RESPONSE_FAILURE:
      return {
        ...state,
        isfetching: false,
        error: action.message,
      };
    case actionConstants.CLEAR_QR_CODE_RESPONSE:
      return {
        ...state,
        isfetching: false,
        error: '',
        success: '',
      };
    default:
      return state;
  }
};
