import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { connect, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Button from '../../components/Button';
import JobList from '../../components/JobList';
import endpoints from '../../routes';
import { getJobDetailsAction } from '../../redux/actions/jobLinkActions';
import ToolTip from '../../components/ToolTip';
import DropdownSelect from '../../components_2/DropdownSelect';
import { format, isValid } from 'date-fns'; 
import IconButton from '../../components_2/IconButton';
import { ChevronLeft, ChevronRight, LocationOn, Search } from '@material-ui/icons';
import SearchInput from '../../components_2/SearchInput';
import './JobLink.scss';

const viewsList = [
  {
    label: 'Day',
    value: 'day',
  },
  {
    label: 'Month',
    value: 'month',
  },
];

const eventList = ['All', 'Work Order', 'Quote', 'Inspection'].map(value => ({ value, label: value }));

const ScheduleNav = ({ schedulerData, desktopCalendar }) => {

  const d = schedulerData.startDate ? new Date(schedulerData.startDate.split('-')) : new Date();
  const validDate = isValid(d) ? d : new Date();
  const formatted = format(validDate, 'EEEE, PP');

  const navigate = fn => {
    schedulerData[fn]();
    desktopCalendar.setState({ viewModel: schedulerData })
    desktopCalendar.setCalenderData(schedulerData)
  };

  return (
    <div className="hidden md:flex items-center space-x-2">
      <IconButton
        onClick={() => navigate('prev')}
        icon={<ChevronLeft/>}
      />
      <span className="text-2xl mb-0.5">
        {formatted}
      </span>
      <IconButton
        onClick={() => navigate('next')}
        icon={<ChevronRight/>}
      />
    </div>
  );
};

export const JobLinkHeaderComponent = (props) => {
  const {
    viewType,
    onChangeViewType,
    schedulerData,
    newEvent,
    taskDndSource,
    eventCategory,
    setEventCategory,
    handleClickJobListItem,
    search,
    setSearch,
    handleOnSearch,
    intl,
    setResourceReportCategory,
    resourceReportCategory,
    desktopCalendar,
  } = props;

  const [showMobileSearch, setShowMobileSearch] = useState(false)

  const reportCategories = useSelector(state => {
    if(!state?.reportCategory?.reportData) return [];
    return Object.keys(state.reportCategory.reportData)
      .map(value => ({ value, label: value }));
  });

  const title = (
    <>
      <h1
        className="hh"
        data-tip={intl.formatMessage({
          id: 'Where you perform scheduling and dispatching',
        })}
        data-for="joblink-header-tooltip"
      >
        <FormattedMessage id="JobLink" defaultMessage="JobLink" />
      </h1>
      <ToolTip id="joblink-header-tooltip" place="right" />
    </>
  )

  const autoRoutingButton = (
    <>
      <Button
        color="#B71B1B"
        text="Auto Routing"
        onBtnClick={() => props.history.push(`/${endpoints.autoRouting}`)}
        className="mr-3 ml-auto hidden md:inline-block"
        dataTip="Routes your jobs efficiently"
        dataFor="auto-routing-tooltip"
      />
      <IconButton
        icon={<LocationOn/>}
        onClick={() => props.history.push(`/${endpoints.autoRouting}`)}
        className="mr-2 ml-auto inline-block md:hidden text-gray-500"
      />
    </>
  );

  const searchInput = (
    <SearchInput
      onSearch={() => handleOnSearch(true)}
      onChange={e => {
        if (e === '' || (e.target && e.target.value === '')) {
          setSearch('');
          handleOnSearch(true);
          return;
        }
        setSearch(e.target.value);
      }}
      value={search}
      placeholder="Search Team Member"
      className="w-full"
    />
  )

  return (
    <div className="mb-6">
      <div className="flex justify-between items-center mb-3 sm:mb-12">
        {title}
        {autoRoutingButton}
        <span className="hidden md:inline-block w-72">
          {searchInput}
        </span>
        {viewType == 'day' && (
          <IconButton
            icon={<Search/>}
            className="inline-block md:hidden text-gray-500"
            onClick={() => setShowMobileSearch(!showMobileSearch)}
          />
        )}
      </div>
      {(viewType == 'day' && showMobileSearch) && (
        <div className="mb-3 block md:hidden">
          {searchInput}
        </div>
      )}
      <div className="flex justify-between items-center">
        <DropdownSelect
          multiple={true}
          value={resourceReportCategory}
          options={reportCategories}
          onChange={({target}) => setResourceReportCategory(target.value)}
          placeholder="Division"
          className="mr-2 block md:hidden joblink-mobile-select"
          placement="left"
        />
        <DropdownSelect
          value={viewType}
          options={viewsList}
          onChange={({target}) => onChangeViewType(target.value)}
          className="hidden md:block"
          placement="left"
        />
        {viewType == 'month' && (
          <DropdownSelect
            value={eventCategory}
            options={eventList}
            onChange={({target}) => setEventCategory(target.value)}
            className="hidden md:block"
          />
        )}
        {viewType == 'day' && (
          <>
            <ScheduleNav
              schedulerData={schedulerData}
              desktopCalendar={desktopCalendar}
            />
            <JobList
              schedulerData={schedulerData}
              newEvent={newEvent}
              taskDndSource={taskDndSource}
              handleClickJobListItem={(event) => {
                props.getJobDetailsAction(event.id);
                handleClickJobListItem(event);
              }}
            />
          </>
        )}
      </div>
    </div>
  );
};

JobLinkHeaderComponent.propTypes = {
  onChangeViewType: PropTypes.func,
  viewType: PropTypes.string,
  schedulerData: PropTypes.object,
  newEvent: PropTypes.func,
  taskDndSource: PropTypes.object,
  eventCategory: PropTypes.string,
  setEventCategory: PropTypes.func,
  setResourceReportCategory: PropTypes.func,
  history: PropTypes.object,
  handleClickJobListItem: PropTypes.func,
  getJobDetailsAction: PropTypes.func,
  setSearch: PropTypes.func,
  search: PropTypes.string,
  intl: intlShape.isRequired,
  handleOnSearch: PropTypes.func,
  setResourceReportCategory: PropTypes.func,
  resourceReportCategory: PropTypes.array,
};

export default connect(null, {
  getJobDetailsAction,
})(withRouter(injectIntl(JobLinkHeaderComponent)));
