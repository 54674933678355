import actionConstants from '../constants/ActionConstants';

const initialState = {
  error: '',
  isfetching: false,
  success: '',
  isSuccess: false,
  areCoachmarksVisible: false,
  runCoachMarks: false,
  coachMarksRefs: {},
  currentTarget: null,
  isCoachmarkVisibleFirstTime: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionConstants.SIGN_UP_REQUEST:
      return {
        ...state,
        isfetching: true,
        isSuccess: false,
      };
    case actionConstants.SIGN_UP_SUCCESS:
      return {
        ...state,
        isfetching: false,
        success: action.message,
        isSuccess: true,
      };
    case actionConstants.SIGN_UP_FAILURE:
      return {
        ...state,
        error: action.message,
        isfetching: false,
      };
    case actionConstants.TOGGLE_COACKMARKS_VISIBILITY:
      return {
        ...state,
        areCoachmarksVisible:
          action.payload.areCoachMarksVisible === undefined ||
          action.payload.areCoachMarksVisible === null
            ? state.areCoachmarksVisible
            : action.payload.areCoachMarksVisible,
        coachMarksRefs: {
          ...state.coachMarksRefs,
          ...action.payload.ref,
        },
        currentTarget:
          action.payload.currentTarget ||
          (action.payload.currentTarget === ''
            ? action.payload.currentTarget
            : state.currentTarget),
        isCoachmarkVisibleFirstTime:
          action.payload.isCoachmarkVisibleFirstTime === undefined
            ? state.isCoachmarkVisibleFirstTime
            : !!action.payload.isCoachmarkVisibleFirstTime,
      };

    case actionConstants.RUN_COACHMARKS:
      return {
        ...state,
        runCoachMarks: action.payload.runCoachMark,
      };
    case actionConstants.CLEAR_STATE:
      return {
        ...state,
        isSuccess: false,
        error: '',
        success: '',
      };
    case actionConstants.RESET_ALL_REDUCERS:
      return { ...initialState };
    default:
      return state;
  }
};
