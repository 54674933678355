import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import SearchBar from '../SearchBar';
import { REPORT_CATEGORIES } from '../../utils/Constants';

/**
 * this is used to set initial filter alues set to false
 * @param {string} filtersLabels - report categories labels
 */
const populateInitialValues = (filtersLabels) => {
  const filters = {};
  const values = Object.values(filtersLabels);
  for (let reportIndex = 0; reportIndex < values.length; reportIndex += 1) {
    Object.assign(filters, { [values[reportIndex]]: false });
  }
  return filters;
};
class CompleteReportHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      reportFilters: { ...populateInitialValues(REPORT_CATEGORIES) },
      expandedIndex: -1,
    };
  }

  render() {
    return (
      <div data-testid="taskheader">
        <div
          data-testid="searchbar"
          className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center top-search-field"
        >
          <h1>
            <FormattedMessage
              id={this.props.name || ' '}
              defaultMessage={this.props.name || ' '}
            />
          </h1>
          <SearchBar
            onTextChange={(e) => {
              if (e === '') {
                this.props.handleSearch('');
              } else {
                this.props.setSearchText(e.target.value);
              }
            }}
            searchValue={this.props.searchText}
            isCrossVisible={this.props.isCrossVisible}
            onSearchPress={this.props.handleSearch}
          />
        </div>
      </div>
    );
  }
}
CompleteReportHeader.propTypes = {
  isCrossVisible: PropTypes.bool,
  setDatePeriod: PropTypes.func,
  setFilters: PropTypes.func,
  setReportType: PropTypes.func,
  filter: PropTypes.array,
  handleSearch: PropTypes.func,
  reportType: PropTypes.string,
  name: PropTypes.string,
  setSearchText: PropTypes.func,
  searchText: PropTypes.string,
};

export default CompleteReportHeader;
