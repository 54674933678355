import ActionConstants from '../constants/ActionConstants';

const INITIAL_FETCH_API_STATE = {
  isSuccess: false,
  isError: false,
  message: '',
  code: undefined,
};

const INITIAL_SAVE_EMAIL_API_STATE = {
  isSuccess: false,
  isError: false,
  message: '',
  code: undefined,
};

const INITIAL_DELETE_EMAIL_API_STATE = {
  isSuccess: false,
  isError: false,
  message: '',
  code: undefined,
};

const INITIAL_STATE = {
  fetchApiState: {
    ...INITIAL_FETCH_API_STATE,
  },
  saveEmailApiState: {
    ...INITIAL_SAVE_EMAIL_API_STATE,
  },
  deleteEmailApiState: {
    ...INITIAL_DELETE_EMAIL_API_STATE,
  },
  fetchedEmails: [],
  newEmails: [],
  isFetching: false,
};

const updateField = (state, payload) => {
  const { newEmails } = state;
  newEmails[payload.index].email = payload.value;
  newEmails[payload.index].error = '';
  return newEmails;
};

const removeArray = (state, payload) => {
  const { newEmails } = state;
  newEmails.splice(payload.index, 1);
  return newEmails;
};

const setError = (state, payload) => {
  const { newEmails } = state;
  newEmails[payload.index].error = payload.error;
  return newEmails;
};

const emailListForm = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ActionConstants.EMAIL_LIST_FORM_FETCH_EMAILS_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case ActionConstants.EMAIL_LIST_FORM_FETCH_EMAILS_ERROR:
      return {
        ...state,
        isFetching: false,
        fetchApiState: {
          isError: true,
          isSuccess: false,
          message: action.message,
          code: action.code,
        },
      };
    case ActionConstants.EMAIL_LIST_FORM_DELETE_NEW_SUCCESS:
      return {
        ...state,
        isFetching: false,
        deleteEmailApiState: {
          ...state.deleteEmailApiState,
          isSuccess: true,
          message: action.message,
        },
      };
    case ActionConstants.EMAIL_LIST_FORM_DELETE_NEW_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case ActionConstants.EMAIL_LIST_FORM_DELETE_NEW_ERROR:
      return {
        ...state,
        isFetching: false,
        deleteEmailApiState: {
          isError: true,
          isSuccess: false,
          message: action.message,
          code: action.code,
        },
      };
    case ActionConstants.EMAIL_LIST_FORM_FETCH_EMAILS_SUCCESS:
      if (action.extra.screenName === 'Auto Email') {
        return {
          ...state,
          isFetching: false,
          fetchedEmails: action.payload && action.payload.autoEmail,
          fetchApiState: {
            ...state.apiState,
            isSuccess: true,
          },
        };
      }
      return {
        ...state,
        isFetching: false,
        fetchedEmails: action.payload,
        fetchApiState: {
          ...state.apiState,
          isSuccess: true,
        },
      };
    case ActionConstants.EMAIL_LIST_FORM_SAVE_NEW_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case ActionConstants.EMAIL_LIST_FORM_SAVE_NEW_ERROR:
      return {
        ...state,
        saveEmailApiState: {
          ...state.saveEmailApiState,
          isError: true,
          message: action.message,
          code: action.code,
        },
      };
    case ActionConstants.EMAIL_LIST_FORM_SAVE_NEW_SUCCESS:
      return {
        ...state,
        saveEmailApiState: {
          ...state.saveEmailApiState,
          isSuccess: true,
          message: action.message,
        },
      };
    case ActionConstants.EMAIL_LIST_FORM_ADD_NEW:
      return {
        ...state,
        newEmails: [...state.newEmails, { email: '', error: '' }],
      };
    case ActionConstants.EMAIL_LIST_FORM_REMOVE_NEW:
      return {
        ...state,
        newEmails: removeArray(state, action.payload),
      };
    case ActionConstants.EMAIL_LIST_UPDATE_FIELD:
      return {
        ...state,
        newEmails: updateField(state, action.payload),
      };
    case ActionConstants.EMAIL_LIST_SET_ERROR:
      return {
        ...state,
        newEmails: setError(state, action.payload),
      };
    case ActionConstants.EMAIL_LIST_CLEAR_FETCH_API_STATE:
      return {
        ...state,
        fetchApiState: {
          ...INITIAL_FETCH_API_STATE,
        },
      };
    case ActionConstants.EMAIL_LIST_CLEAR_SAVE_API_STATE:
      return {
        ...state,
        saveEmailApiState: {
          ...INITIAL_SAVE_EMAIL_API_STATE,
        },
      };
    case ActionConstants.EMAIL_LIST_CLEAR_DELETE_API_STATE:
      return {
        ...state,
        deleteEmailApiState: {
          ...INITIAL_DELETE_EMAIL_API_STATE,
        },
      };
    case ActionConstants.EMAIL_LIST_FORM_CLEAR_STATE:
      return {
        ...INITIAL_STATE,
      };
    default:
      return state;
  }
};

export default emailListForm;
