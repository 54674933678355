import React from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Modal from '../../components/Modal';
import IconButton from '../../components/IconButton';
import DeleteConfirmationModal from '../../components/DeleteConfirmationModal';
import { setSubMenuVisibility } from '../../redux/actions/setSelectedCustomer';
import endpoints from '../../routes';
import Button from '../../components/Button';
import { noImage } from '../../utils/Constants';
import { subPermissions } from '../../libs/constants/permissions';
import { checkPermission } from '../../_helpers/commonFunctions';
import { toolTips } from '../../utils/toolTips';
import ToolTip from '../../components/ToolTip';
import CommentModalBody from './CommentModalBody';
import PreviewImageModalBody from './PreviewImageModalBody';
import ImageUploadModalBody from './ImageUploadModalBody';

/**
 * This component shows the layout of Images section under Building Info in Customer section.
 * @param {*} props
 */
export const ImageBuildingInfoLayout = (props) => {
  const {
    /**
     * Condition that checcks wether modal will visible or not.
     */
    isModalVisible,
    /**
     * Function that opens or closes the modal to add up images.
     */
    addImage,
    /**
     * Onchange function
     */
    handleChange,
    selectedImage,
    onSave,
    imageDetails,
    isPreviewImage,
    showPreview,
    imageUrl,
    isCommentModal,
    commentAdder,
    commentDescrip,
    isDeleteModal,
    deleteImage,
    onConfirmDelete,
    validationErrs,
    isFetching,
    showReport,
    isReportImage,
    isOffline,
  } = props;

  const getModalData = (isPreview, isComment, isAdd) => {
    let modalData;
    if (isPreview) {
      modalData = {
        heading: 'Preview',
        body: PreviewImageModalBody,
        close: showPreview,
        class: 'imagemodalwidth modal-dialog-centered',
        Id: 'imagemodalwidth',
      };
    } else if (isComment) {
      modalData = {
        heading: 'Add Comment',
        body: CommentModalBody,
        close: commentAdder,
      };
    } else if (isAdd) {
      modalData = {
        heading: 'Add Image',
        body: ImageUploadModalBody,
        close: addImage,
      };
    }
    return modalData;
  };

  const modalData = getModalData(
    isPreviewImage,
    isCommentModal,
    isModalVisible
  );

  const unDeletedImages =
    imageDetails &&
    imageDetails.length &&
    imageDetails.filter((img) => !img.isDeleted).length;

  return (
    <div>
      <div>
        <div
          className="flex-md-nowrap align-items-center"
          style={isReportImage ? { height: 0 } : {}}
        >
          {(isModalVisible || isPreviewImage || isCommentModal) && (
            <Modal
              modalHeading={modalData.heading}
              modalClass={modalData.class}
              modalId={modalData.Id}
              ModalBodyProps={{
                handleChange,
                selectedImage,
                onSave,
                imageUrl,
                commentDescrip,
                validationErrs,
                handleClickBack: modalData.close,
              }}
              handleClickCross={modalData.close}
              ModalBody={modalData.body}
            />
          )}
          {isDeleteModal && (
            <DeleteConfirmationModal
              handleClickCancel={deleteImage}
              handleClickCross={deleteImage}
              handleClickDelete={onConfirmDelete}
              deleteMessage="Are you sure you want to delete this record?"
            />
          )}
          <h1
            className={`${
              props.reportGridShow && !props.isRenderedInReports
                ? 'head-back'
                : ''
            } ${
              props.isRenderedInReports && 'mr-lt-20-px'
            } heading-image-build-info`}
            style={{ paddingBottom: '15px' }}
          >
            {props.reportGridShow && !props.isRenderedInReports && (
              <Link
                to={endpoints.reportClientDetail}
                onClick={() => props.setSubMenuVisibility(false)}
              >
                <span className="back-arrow sprite-icon" />{' '}
              </Link>
            )}
            {isReportImage ? null : (
              <FormattedMessage id="Image" defaultMessage="Image" />
            )}
          </h1>
          <div className="responsive-header-options">
            <ul className="add-icon-mobile-view hide-in-tab hide-in-mob2">
              <li
                id="add-icon"
                className="icon sprite-icon1"
                onClick={addImage}
              >
                <a href="#"></a>
              </li>
            </ul>
          </div>
          {isReportImage ? null : (
            <div className="add-image-build-info hide-in-tab">
              <Button
                blockButton
                className="btn btn-outline-primary show-reports"
                onBtnClick={showReport}
                text="Reports"
              />
            </div>
          )}
        </div>
        {checkPermission(subPermissions.addCustomer) && !isReportImage && (
          <IconButton text="Add Image" handleOnClick={addImage} />
        )}
        <div className="card" style={{ marginTop: '20px' }}>
          {isReportImage ? null : (
            <div className="card-header">
              <FormattedMessage
                id="Upload Building Image"
                defaultMessage="Upload Building Image"
              />
            </div>
          )}
          <div className={isReportImage ? '' : 'card-body'}>
            {isFetching ? (
              <div className="loader-container">
                <div className="loader"></div>
              </div>
            ) : (
              <div className="building-info-wrap">
                <div
                  className="row"
                  id={
                    ((imageDetails && imageDetails.length === 0) ||
                      (isOffline && !unDeletedImages)) &&
                    'rowDataForImage'
                  }
                >
                  {imageDetails &&
                  imageDetails.length > 0 &&
                  unDeletedImages ? (
                    imageDetails.map((row) =>
                      row.isDeleted ? null : (
                        <div key={row._id} className="col-md-6">
                          <div className="uploading-section">
                            <div
                              className="upload-img-wrap"
                              style={{
                                background: `url(${row.url}) no-repeat center`,
                                backgroundSize: 'contain',
                                border: '1px solid #ced4da',
                              }}
                            >
                              {checkPermission(
                                isReportImage ? subPermissions.editDeleteReports : subPermissions.editDeleteExistingCustomers
                              ) &&
                                !props.isViewing && (
                                  <span
                                    id="test-span2"
                                    className="del-label sprite-icon1 delete-image-build-info material-icons-outlined del-vr-2" style={{ color: '#B72E28' }}
                                    onClick={() =>
                                      deleteImage(row._id, 'image')
                                    }
                                    data-tip={props.intl.formatMessage({
                                      id: toolTips.image.delete,
                                    })}
                                    data-for={`${row._id}${toolTips.image.delete}`}
                                  >
                                    delete_outline
                                  </span>
                                )}
                              <span
                                id="test-span1"
                                style={{ cursor: 'pointer' }}
                                className="maximize-icon sprite-icon"
                                onClick={() => showPreview(row.url)}
                                data-tip={props.intl.formatMessage({
                                  id: toolTips.image.preview,
                                })}
                                data-for={`${row._id}${toolTips.image.preview}`}
                              ></span>
                              <ToolTip
                                id={`${row._id}${toolTips.image.preview}`}
                                place="right"
                              />
                              <ToolTip
                                id={`${row._id}${toolTips.image.delete}`}
                                place="left"
                              />
                            </div>
                          </div>
                          {!row.description ||
                          row.description === 'default desc' ||
                          row.description === ' ' ? (
                            <div className="form-group dis-flex f-dir-column">
                              {checkPermission(
                                isReportImage ? subPermissions.editDeleteReports : subPermissions.editDeleteExistingCustomers
                              ) &&
                                !props.isViewing && (
                                  <div
                                    id="test-div1"
                                    className="form-control"
                                    placeholder="Add Comment"
                                    onClick={() => commentAdder(null, row._id)}
                                  >
                                    <FormattedMessage
                                      id="Add Comment"
                                      defaultMessage="Add comment"
                                    />
                                  </div>
                                )}
                            </div>
                          ) : (
                            <div className="comment-details">
                              <div
                                className="name-wrap"
                                style={{ width: '250px' }}
                              >
                                <div
                                  className="name-text-wrap"
                                  style={{ width: '225px' }}
                                >
                                  <p className="bulding-text">
                                    {row.description}
                                  </p>
                                </div>
                              </div>
                              {checkPermission(
                                isReportImage ? subPermissions.editDeleteReports : subPermissions.editDeleteExistingCustomers
                              ) &&
                                !props.isViewing && (
                                  <div className='row bg-white'>
                                    <div className="col align-items-right">
                                      <span
                                        id="test-span3"
                                        className="edit sprite-icon1
                                          material-icons-outlined edit-vr-2"
                                        onClick={() =>
                                          commentAdder(row.description, row._id)
                                        }
                                      >
                                        create
                                      </span>
                                      <span
                                        id="test-span4"
                                        className="edit sprite-icon1 material-icons-outlined del-vr-2" style={{ color: '#B72E28' }}
                                        onClick={() =>
                                          deleteImage(row._id, 'comment')
                                        }
                                      >
                                        delete_outline
                                      </span>
                                    </div>
                                  </div>
                                )}
                            </div>
                          )}
                        </div>
                      )
                    )
                  ) : (
                    <div className="end-message">{noImage}</div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

ImageBuildingInfoLayout.propTypes = {
  isModalVisible: PropTypes.bool,
  isReplicateImageView: PropTypes.bool,
  addImage: PropTypes.func,
  handleChange: PropTypes.func,
  selectedImage: PropTypes.object,
  onSave: PropTypes.func,
  comment: PropTypes.string,
  imageDetails: PropTypes.array,
  showPreview: PropTypes.func,
  isPreviewImage: PropTypes.bool,
  imageUrl: PropTypes.string,
  isCommentModal: PropTypes.bool,
  commentAdder: PropTypes.func,
  commentDescrip: PropTypes.string,
  isDeleteModal: PropTypes.bool,
  deleteImage: PropTypes.func,
  onConfirmDelete: PropTypes.func,
  validationErrs: PropTypes.object,
  isFetching: PropTypes.bool,
  uploadImageActionFetching: PropTypes.bool,
  imageDetailsFetching: PropTypes.bool,
  setSubMenuVisibility: PropTypes.func,
  reportGridShow: PropTypes.bool,
  showReport: PropTypes.func,
  isReportImage: PropTypes.bool,
  intl: intlShape.isRequired,
  isViewing: PropTypes.bool,
  isOffline: PropTypes.bool,
  isRenderedInReports: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  reportGridShow: state.setSelectedCustomer.reportLogoList,
  reportUpdatedData: state.reportUpdate,
  isOffline: state.offlineMode.isOffline,
});

export default connect(mapStateToProps, { setSubMenuVisibility })(
  injectIntl(ImageBuildingInfoLayout)
);
