import React from 'react';
import PropTypes from 'prop-types';

/**
 * Full page loader
 * @param {object} param0
 */
const Loader = ({ containerClass }) => (
  <div id="overlayLoader" className={containerClass || ''}>
    <div className="page-loader-container">
      <div className="pageLoader"></div>
    </div>
  </div>
);

Loader.propTypes = {
  containerClass: PropTypes.string, // Container class in which loader will be visible
};

export default Loader;
