import actionConstants from '../constants/ActionConstants';

const initialState = {
  toolInfo: null,
  isFetching: false,
  isSuccess: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionConstants.GET_TOOL_REQUEST:
      return {
        ...state,
        isFetching: true,
        isSuccess: false,
      };
    case actionConstants.GET_TOOL_SUCCESS:
      return {
        ...state,
        toolInfo: action.payload,
        isFetching: false,
        isSuccess: true,
      };
    case actionConstants.GET_TOOL_FAILURE:
      return {
        ...state,
        isFetching: false,
        isSuccess: false,
      };
    case actionConstants.GET_TOOL_RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};
