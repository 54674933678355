import React, { useState } from 'react';
import IconButton from '../../components_2/IconButton'
import Button from '../../components_2/Button'
import { Add, Remove, CheckBoxOutlineBlankOutlined, CheckBox } from '@material-ui/icons';
import { FormattedMessage } from 'react-intl';
import { convertFrequency } from '../../utils/Constants';
import moment from 'moment';
import { getDateFormat } from '../../utils/utils';

const Description = ({ label, value }) => (
  <div className="my-2 text-gray-800 text-base">
    <span className="font-medium">
      {label}:&nbsp;
    </span>
    <span>
      {value}
    </span>
  </div>
)

const TaskListRowMobile = ({
  handleStartJob,
  completeJobManually,
  jobsDoneManually,
  isReportNeeded = true,
  country,
  row: {
    _id,
    jobId,
    createdFor,
    reportCategory,
    recurringInspectionDetails,
    startDateTime,
    type,
    price,
  }
}) => {

  const [expanded, setExpanded] = useState(false)

  const frequency = recurringInspectionDetails?.frequency;
  const startJobPayload = {
    jobId: _id,
    createdFor,
    reportCategory,
    type,
    price,
  };
  if (frequency) startJobPayload.frequency = frequency;

  const isJobCompletedManually = jobsDoneManually.includes(_id);

  const topBar = (
    <div className="flex justify-between items-center px-3 py-3 text-lg">
      <div className="font-medium">
        <div className="text-gray-800 line-clamp-1" >
          {createdFor.businessName}
        </div>
        <div className="opacity-80">
          {reportCategory} - {convertFrequency[frequency]}
        </div>
      </div>
      <IconButton
        className="flex-shrink-0 mx-2"
        icon={expanded ? <Remove /> : <Add className="text-red-700" />}
        onClick={() => setExpanded(!expanded)}
      />
    </div>
  );

  const checkbox = (
    <div
      className="inline-flex items-center text-base"
      style={{ color: '#6aa14e' }}
      onClick={() => {
        !isJobCompletedManually && completeJobManually({
          reportCategory,
          createdFor: createdFor._id,
          createdForName: createdFor.businessName,
          reportType: type,
          jobId: _id,
        })
      }}
    >
      <FormattedMessage id="Done" defaultMessage="Done" />
      <span className="ml-1">
        {isJobCompletedManually
          ? <CheckBox/>
          : <CheckBoxOutlineBlankOutlined/>}
      </span>
    </div>
  );
  
  return (
    <div className="even:bg-gray-100">
      {topBar}
      {expanded && (
        <div className="px-3">
          <Description
            label={<FormattedMessage id="Job Id" defaultMessage="Job Id" />}
            value={jobId}
          />
          <Description
            label={<FormattedMessage id="Date" defaultMessage="Date" />}
            value={moment(startDateTime).format(
              getDateFormat()
            )}
          />
          <Description
            label={<FormattedMessage id="Job Type" defaultMessage="Job Type" />}
            value={type}
          />
          <div className="py-4 text-right">
            {isReportNeeded ? (
              <Button
                type="success"
                size="large"
                onClick={() => handleStartJob(startJobPayload)}
              >
                <FormattedMessage id="Start Job" defaultMessage="Start Job" />
              </Button>
            ) : (
              checkbox
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default TaskListRowMobile;