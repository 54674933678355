import actionConstants from '../constants/ActionConstants';
import { addBuildingImageDedtails } from '../../api';
import ActionDispatcher from '../../api/ActionDispatcher';

const addImageDetailsAction = (imageDetails, buldingInfoId) =>
  ActionDispatcher(
    addBuildingImageDedtails.bind(null, imageDetails, buldingInfoId),
    actionConstants.ADD_UPLOADED_IMAGE_DETAILS_SUCCESS,
    actionConstants.ADD_UPLOADED_IMAGE_DETAILS_FAILURE,
    actionConstants.ADD_UPLOADED_IMAGE_DETAILS_REQUEST
  );

export default addImageDetailsAction;
