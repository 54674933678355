export const EVENTS = {
  CUSTOMERS_IMPORT_CUSTOMERS_DOWNLOAD_SPREADSHEET: 'CUSTOMERS_IMPORT_CUSTOMERS_DOWNLOAD_SPREADSHEET',
  CUSTOMERS_IMPORT_CUSTOMERS_SAVE: 'CUSTOMERS_IMPORT_CUSTOMERS_SAVE',
  CUSTOMERS_ADD_CUSTOMER_CLICK: 'CUSTOMERS_ADD_CUSTOMER_CLICK',
  CUSTOMERS_ADD_CUSTOMER_SAVE: 'CUSTOMERS_ADD_CUSTOMER_SAVE',
  TEAM_ADD_MEMBER_CLICK: 'TEAM_ADD_MEMBER_CLICK',
  TEAM_ADD_MEMBER_SAVE: 'TEAM_ADD_MEMBER_SAVE',
  REPORTS_NEW_SEARCH_CUSTOMER: 'REPORTS_NEW_SEARCH_CUSTOMER',
  REPORTS_NEW_SELECT_CUSTOMER: 'REPORTS_NEW_SELECT_CUSTOMER',
  REPORTS_NEW_NEW_REPORT_NEXT: 'REPORTS_NEW_NEW_REPORT_NEXT',
  REPORTS_NEW_CLIENT_DETAIL_NEXT: 'REPORTS_NEW_CLIENT_DETAIL_NEXT',
  REPORTS_NEW_OR_COPY_NEW: 'REPORTS_NEW_OR_COPY_NEW',
  REPORTS_EDIT_START_REPORT_START: 'REPORTS_EDIT_START_REPORT_START',
  REPORTS_EDIT_CANCEL_REPORT: 'REPORTS_EDIT_CANCEL_REPORT',
  REPORTS_EDIT_CANCEL_REPORT_CONFIRMATION_YES: 'REPORTS_EDIT_CANCEL_REPORT_CONFIRMATION_YES',
  REPORTS_EDIT_CANCEL_REPORT_CONFIRMATION_NO: 'REPORTS_EDIT_CANCEL_REPORT_CONFIRMATION_NO',
  REPORTS_EDIT_SUBMIT_REPORT: 'REPORTS_EDIT_SUBMIT_REPORT',
  REPORTS_EDIT_IMPORT_FROM_LEGACY: 'REPORTS_EDIT_IMPORT_FROM_LEGACY',
  REPORTS_EDIT_ADD_DEVICE_SAVE_OR_SAVE_AND_NEW: 'REPORTS_EDIT_ADD_DEVICE_SAVE_OR_SAVE_AND_NEW',
  SIDE_MENU_OPTION: 'SIDE_MENU_OPTION',
  CUSTOMERS_RECURRING_INSPECTION_NEW_RECURRING_INSPECTION_SAVE: 'CUSTOMERS_RECURRING_INSPECTION_NEW_RECURRING_INSPECTION_SAVE',
  CUSTOMERS_DEFICIENCIES_EMAIL_QUOTE_TO_CUSTOMER: 'CUSTOMERS_DEFICIENCIES_EMAIL_QUOTE_TO_CUSTOMER',
  CUSTOMERS_CUSTOMER_PORTAL_SAVE_CLICK: 'CUSTOMERS_CUSTOMER_PORTAL_SAVE_CLICK',
  CUSTOMERS_AUTO_EMAIL_SAVE_CLICK: 'CUSTOMERS_AUTO_EMAIL_SAVE_CLICK',
  TEAM_TIME_CARD_EMPLOYEE_CLICK: 'TEAM_TIME_CARD_EMPLOYEE_CLICK',
  TEAM_INSIDE_GPS_SEARCH_ON_TEAM_MEMBER: 'TEAM_INSIDE_GPS_SEARCH_ON_TEAM_MEMBER',
  TEAM_INSIDE_GPS_CLICK_ON_LAST_30_DAYS: 'TEAM_INSIDE_GPS_CLICK_ON_LAST_30_DAYS',
  JOB_LINK_DAY_DRAG_AND_DROP_A_JOB: 'JOB_LINK_DAY_DRAG_AND_DROP_A_JOB',
  JOB_LINK_DAT_ASSIGN_JOB_SCHEDULE_JOB: 'JOB_LINK_DAT_ASSIGN_JOB_SCHEDULE_JOB',
  JOB_LINK_DAY_ASSIGN_INSPECTION_SAVE: 'JOB_LINK_DAY_ASSIGN_INSPECTION_SAVE',
  JOB_LINK_AUTO_ROUTE_MAP_PREVIEW_CONFIRMATION_NEXT: 'JOB_LINK_AUTO_ROUTE_MAP_PREVIEW_CONFIRMATION_NEXT',
  SERVICE_REQUEST_CLICK_ON_ROW: 'SERVICE_REQUEST_CLICK_ON_ROW',
  INVENTORY_PARTS_EDIT_PRICING_LEVEL_SAVE: 'INVENTORY_PARTS_EDIT_PRICING_LEVEL_SAVE',
  INVENTORY_PARTS_ASSIGN_PART_ADD_PART_SAVE: 'INVENTORY_PARTS_ASSIGN_PART_ADD_PART_SAVE',
  INVENTORY_PARTS_ADD_PART_SAVE: 'INVENTORY_PARTS_ADD_PART_SAVE',
  INVENTORY_PARTS_INDIVIDUAL_PART_SAVE: 'INVENTORY_PARTS_INDIVIDUAL_PART_SAVE',
  INVENTORY_SUPPLIERS_ADD_SUPPLIER_SAVE: 'INVENTORY_SUPPLIERS_ADD_SUPPLIER_SAVE',
  INVENTORY_SUPPLIERS_INDIVIDUAL_SUPPLIER_SAVE: 'INVENTORY_SUPPLIERS_INDIVIDUAL_SUPPLIER_SAVE',
  INVENTORY_TOOLS_ASSIGN_TOOL_ADD_TOOL_SAVE: 'INVENTORY_TOOLS_ASSIGN_TOOL_ADD_TOOL_SAVE',
  INVENTORY_TOOLS_ASSIGN_TOOL_SAVE: 'INVENTORY_TOOLS_ASSIGN_TOOL_SAVE',
  INVENTORY_TOOLS_ADD_TOOL_SAVE: 'INVENTORY_TOOLS_ADD_TOOL_SAVE',
  INVENTORY_TOOLS_INDIVIDUAL_TOOL_SAVE: 'INVENTORY_TOOLS_INDIVIDUAL_TOOL_SAVE',
  INVENTORY_VEHICLE_INVENTORY_ADD_VEHICLE_SAVE_AND_NEW: 'INVENTORY_VEHICLE_INVENTORY_ADD_VEHICLE_SAVE_AND_NEW',
  INVENTORY_VEHICLE_INVENTORY_ADD_VEHICLE_SAVE: 'INVENTORY_VEHICLE_INVENTORY_ADD_VEHICLE_SAVE',
  INVENTORY_VEHICLE_INVENTORY_INDIVIDUAL_VEHICLE_SAVE: 'INVENTORY_VEHICLE_INVENTORY_INDIVIDUAL_VEHICLE_SAVE',
  SETTINGS_LINK_TO_QUICKBOOKS_CLICK: 'SETTINGS_LINK_TO_QUICKBOOKS_CLICK',
  SETTINGS_CHANGE_INVOICE_NUMBER: 'SETTINGS_CHANGE_INVOICE_NUMBER',
  SETTINGS_QR_CODE_CLICK_DOWNLOAD_QR_CODES: 'SETTINGS_QR_CODE_CLICK_DOWNLOAD_QR_CODES',
  SETTINGS_QR_CODE_CLICK_PURCHASE_LABELS: 'SETTINGS_QR_CODE_CLICK_PURCHASE_LABELS',
  SETTINGS_VIDEOS_VIDEO_START_VIDEO: 'SETTINGS_VIDEOS_VIDEO_START_VIDEO',
  TOP_MENU_SWITCH_ENGLISH_SPANISH: 'TOP_MENU_SWITCH_ENGLISH_SPANISH',
  CHAT_GROUP_START_NEW_CHAT: 'CHAT_GROUP_START_NEW_CHAT',
  CHAT_INDIVIDUALS_START_NEW_CHAT: 'CHAT_INDIVIDUALS_START_NEW_CHAT',
  OFFLINE_MODE_SWITCH_ONLINE_OFFLINE: 'OFFLINE_MODE_SWITCH_ONLINE_OFFLINE',
  REPORTS_NEW_COPY_INSPECTION_COPY_REPORT: 'REPORTS_NEW_COPY_INSPECTION_COPY_REPORT',
  REPORTS_EDIT_ADD_CUSTOM_QUESTION: 'REPORTS_EDIT_ADD_CUSTOM_QUESTION',
  REPORTS_EDIT_READ_QR: 'REPORTS_EDIT_READ_QR',
  REPORTS_EDIT_ATTACH_DOCUMENT: 'REPORTS_EDIT_ATTACH_DOCUMENT',
  REPORTS_EDIT_PREVIEW_PDF: 'REPORTS_EDIT_PREVIEW_PDF',
};