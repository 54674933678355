import actionConstants from '../constants/ActionConstants';

const initialState = {
  url: undefined,
  isSuccess: false,
  isfetching: false,
  quickbookDetails: {},
  detailsSuccess: false,
  unLinkSucces: false,
  message: '',
  syncSuccess: '',
  syncError: '',
  syncIsFetching: false,
  error: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionConstants.QUICKBOOK_AUTH_REQUEST:
      return {
        ...state,
        isfetching: true,
      };

    case actionConstants.QUICKBOOK_AUTH_SUCCESS:
      return {
        ...state,
        isfetching: false,
        isSuccess: true,
        url: action.payload,
      };
    case actionConstants.QUICKBOOK_AUTH_FAILURE:
      return {
        ...state,
        isfetching: false,
        error: action.error,
      };
    case actionConstants.GET_QUICKBOOK_DETAILS_REQUEST:
      return {
        ...state,
        isfetching: true,
      };
    case actionConstants.GET_QUICKBOOK_DETAILS_SUCCESS:
      return {
        ...state,
        isfetching: false,
        quickbookDetails: action.payload,
        detailsSuccess: true,
        message: action.message,
      };
    case actionConstants.GET_QUICKBOOK_DETAILS_FAILURE:
      return {
        ...state,
        isfetching: false,
        error: action.error,
      };

    case actionConstants.UNLINK_QUICKBOOK_REQUEST:
      return {
        ...state,
        isfetching: true,
      };
    case actionConstants.UNLINK_QUICKBOOK_SUCCESS:
      return {
        ...state,
        isfetching: false,
        quickbookDetails: action.payload.data,
        unLinkSucces: true,
        message: action.message,
      };
    case actionConstants.UNLINK_QUICKBOOK_FAILURE:
      return {
        ...state,
        isfetching: false,
        error: action.error,
      };
    case actionConstants.SYNC_WITH_QUICKBOOKS_REQUEST:
      return {
        ...state,
        syncIsFetching: true,
      };
    case actionConstants.SYNC_WITH_QUICKBOOKS_SUCCESS:
      return {
        ...state,
        syncSuccess: action.payload.message,
        syncIsFetching: false,
      };
    case actionConstants.SYNC_WITH_QUICKBOOKS_FAILURE:
      return {
        ...state,
        syncError: action.message,
        syncIsFetching: false,
      };
    case actionConstants.CLEAR_QUICKBOOK_AUTH:
      return {
        ...state,
        isfetching: false,
        error: '',
        isSuccess: false,
        syncError: '',
        syncIsFetching: false,
        syncSuccess: '',
      };
    case actionConstants.CLEAR_QUICKBOOK_DETAILS:
      return {
        ...state,
        detailsSuccess: false,
        unLinkSucces: false,
      };
    default:
      return state;
  }
};
