import { createSelector } from 'reselect';

const selectAddReport = (state) => state.addReportCat;

export const selectAddReportData = createSelector(
  selectAddReport,
  (addReport) => addReport.reportCatData
);

export default selectAddReportData;
