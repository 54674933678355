import actionConstants from '../constants/ActionConstants';

const initialState = {
  error: '',
  isfetching: false,
  success: false,
  isEmailSuccess: false,
  updateSuccess: '',
  updateError: '',
  isEmailErrors: '',
  pages: 0,
  searchText: '',
  deficiencyData: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionConstants.UPDATE_DEFICIENCY_REQUEST:
      return {
        ...state,
        isfetching: true,
        updateSuccess: '',
      };
    case actionConstants.UPDATE_DEFICIENCY_SUCCESS:
      return {
        ...state,
        deficiencyData: action.payload,
        isfetching: false,
        updateSuccess: action.message,
      };
    case actionConstants.UPDATE_DEFICIENCY_FAILURE:
      return {
        ...state,
        updateError: action.message,
        isfetching: false,
        updateSuccess: '',
      };
    case actionConstants.GET_DEFICIENCY_DETAILS_SUCCESS:
      return {
        ...state,
        deficiencyData: action.payload,
        isfetching: false,
        success: true,
      };
    case actionConstants.GET_DEFICIENCY_DETAILS_REQUEST:
      return {
        ...state,
        isfetching: true,
      };
    case actionConstants.GET_DEFICIENCY_DETAILS_FAILURE:
      return {
        ...state,
        error: action.message,
        isfetching: false,
      };
    case actionConstants.SEND_EMAIL_SUCCESS:
      return {
        ...state,
        isEmailSuccess: true,
        isfetching: false,
      };
    case actionConstants.SEND_EMAIL_REQUEST:
      return {
        ...state,
        isfetching: true,
      };
    case actionConstants.SEND_EMAIL_FAILURE:
      return {
        ...state,
        isfetching: false,
        isEmailErrors: action.message,
      };
    case actionConstants.CLEAR_DEFICIENCY:
      return {
        ...state,
        error: '',
        success: false,
        updateError: '',
        updateSuccess: '',
        isEmailSuccess: false,
        isfetching: false,
        isEmailErrors: '',
      };
    case actionConstants.DELETE_DEFICIENCY_REQUEST:
      return {
        ...state,
        isfetching: true,
      };
    case actionConstants.DELETE_DEFICIENCY_SUCCESS:
      return {
        ...state,
        isfetching: false,
      };
    default:
      return state;
  }
};
