import actionConstants from '../constants/ActionConstants';
import ActionDispatcher from '../../api/ActionDispatcher';
import downloadReportPdf, {
  deleteReportPdf,
  emailReportPdf,
} from '../../api/downloadReportPdf';

/**
 * Handles report pdf download
 * @param {object} payload
 */
const downloadReportPDF = (payload) =>
  ActionDispatcher(
    downloadReportPdf.bind(null, payload),
    actionConstants.DOWNLOAD_REPORT_PDF_SUCCESS,
    actionConstants.DOWNLOAD_REPORT_PDF_FAILURE,
    actionConstants.DOWNLOAD_REPORT_PDF_REQUEST
  );

const deleteReportPDF = (reportId) =>
  ActionDispatcher(
    deleteReportPdf.bind(null, reportId),
    actionConstants.DELETE_REPORT_PDF_SUCCESS,
    actionConstants.DELETE_REPORT_PDF_FAILURE,
    actionConstants.DELETE_REPORT_PDF_REQUEST
  );

/**
 * Handles emailing report pdf
 * @param {object} payload
 */
const emailReportPDF = (reportId, recipients) =>
  ActionDispatcher(
    emailReportPdf.bind(null, reportId, recipients),
    actionConstants.EMAIL_REPORT_PDF_SUCCESS,
    actionConstants.EMAIL_REPORT_PDF_FAILURE,
    actionConstants.EMAIL_REPORT_PDF_REQUEST
  );

/**
 * Clears reducer state for report pdf download
 */
const clearReportPdf = () => (dispatch) =>
  dispatch({ type: actionConstants.DOWNLOAD_REPORT_PDF_CLEAR });

export { downloadReportPDF, clearReportPdf, deleteReportPDF, emailReportPDF };
