export const SuppressionConstant = {
  Monitoring: 'monitoring',
  Devices: 'device',
  Cylinders: 'cylinders',
  'NFPA Questions': 'nfpaQuestions',
  'Control Panel': 'controlPanel',
  Finish: 'finish',
};
export const SUPPRESSION_TYPE = [
  {
    label: 'Argon',
    value: 'Argon',
  },
  {
    label: 'Co2',
    value: 'Co2',
  },
  {
    label: 'FE 13',
    value: 'FE 13',
  },
  {
    label: 'FE 36',
    value: 'FE 36',
  },
  {
    label: 'FM 200/Sinorix 227',
    value: 'FM 200/Sinorix 227',
  },
  {
    label: 'Halon',
    value: 'Halon',
  },
  {
    label: 'Inergen',
    value: 'Inergen',
  },
  {
    label: 'Novec 1230/Sinorix 1230',
    value: 'Novec 1230/Sinorix 1230',
  },
  {
    label: 'Other',
    value: 'Other',
  },
];

export const SYSTEM_TYPE = [
  {
    label: 'Master Panel',
    value: 'Master Panel',
  },
  {
    label: 'Power Supply',
    value: 'Power Supply',
  },
  {
    label: 'Sub Panel',
    value: 'Sub Panel',
  },
];

export const reportSections = Object.keys(SuppressionConstant)