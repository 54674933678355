import { createSelector } from 'reselect';

const selectAuth = (state) => state.auth;

export const selectUser = createSelector(selectAuth, (auth) => auth.loginData);

export const selectActivePlan = createSelector(
  selectAuth,
  (auth) => auth.loginData.userSubscriptionDetails?.subscriptions[0]?.planValue
);

export const selectCountry = createSelector(
  selectAuth,
  (auth) => auth.loginData.businessAddress.country
);

export const selectAdminEmail = createSelector(
  selectAuth,
  (auth) => auth.loginData.adminEmail
);
