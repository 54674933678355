import actionConstants from '../constants/ActionConstants';

const initialState = {
  workingCategories: [],
  ReportCategory: [],
  isfetching: false,
  error: '',
  isSuccess: false,
  invoiceNumberType: '',
  isSetSuccess: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionConstants.GET_REPORT_CATEGORY_REQUEST:
      return {
        ...state,
        isfetching: true,
      };

    case actionConstants.GET_REPORT_CATEGORY_SUCCESS:
      return {
        ...state,
        isfetching: false,
        ReportCategory: Object.keys(action.payload),
      };
    case actionConstants.GET_REPORT_CATEGORY_FAILURE:
      return {
        ...state,
        error: action.payload.message,
      };
    case actionConstants.GET_WORKING_REPORT_CATEGORY_REQUEST:
      return {
        ...state,
        isfetching: true,
        isSuccess: false,
        error: '',
      };
    case actionConstants.GET_WORKING_REPORT_CATEGORY_SUCCESS:
      return {
        ...state,
        isfetching: false,
        isSuccess: true,
        invoiceNumberType: action.payload.invoiceNumberType,
        workingCategories: action.payload.serviceReportCategories,
      };
    case actionConstants.GET_WORKING_REPORT_CATEGORY_FAILURE:
      return {
        ...state,
        isfetching: false,
        error: action.payload.message,
      };
    case actionConstants.SET_WORKING_REPORT_CATEGORY_REQUEST:
      return {
        ...state,
        isfetching: true,
      };
    case actionConstants.SET_WORKING_REPORT_CATEGORY_SUCCESS:
      return {
        ...state,
        isfetching: false,
        isSetSuccess: true,
        workingCategories: action.payload.serviceReportCategories,
        invoiceNumberType: action.payload.invoiceNumberType,
      };
    case actionConstants.SET_WORKING_REPORT_CATEGORY_FAILURE:
      return {
        ...state,
        isfetching: false,
        error: action.payload.message,
      };
    case actionConstants.CLEAR_SETTINGS:
      return {
        ...state,
        isfetching: false,
        isSuccess: false,
        error: '',
        isSetSuccess: false,
      };
    default:
      return state;
  }
};
