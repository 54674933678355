import { tz } from 'moment-timezone';
import call from './apiRequest';
import apiEndpoints from './apiEndpoints';

/**
 * Api call for adding employee
 * @param {object} data
 */
const AddEmployee = (data) =>
  call({
    method: 'post',
    endpoint: apiEndpoints.customer,
    payload: data,
  });

export default AddEmployee;
