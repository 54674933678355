import actionConstants from '../constants/ActionConstants';

const initialState = {
  error: '',
  isfetchingDropDown: false,
  errorDropdown: '',
  isfetching: false,
  apiMessage: '',
  isSuccess: false,
  vehicleData: [],
  assignedTechnicianList: [],
  totalPages: null,
  totalCount: null,
  currentPage: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionConstants.INVENTORY_ADD_VEHICLE_REQUEST:
      return {
        ...state,
        isfetching: true,
      };
    case actionConstants.INVENTORY_ADD_VEHICLE_SUCCESS:
      return {
        ...state,
        isSuccess: true,
        isfetching: false,
        apiMessage: action.message,
      };
    case actionConstants.INVENTORY_ADD_VEHICLE_FAILURE:
      return {
        ...state,
        error: action.message,
        isfetching: false,
      };
    case actionConstants.INVENTORY_ADD_VEHICLE_RESET:
      return {
        ...initialState,
      };
    case actionConstants.FETCH_ASSIGNED_TECHNICIAN_REQUEST:
      return {
        ...state,
        isfetchingDropDown: true,
      };
    case actionConstants.FETCH_ASSIGNED_TECHNICIAN_FAILURE:
      return {
        ...state,
        isfetchingDropDown: false,
        errorDropdown: action.message,
      };
    case actionConstants.FETCH_ASSIGNED_TECHNICIAN_SUCCESS:
      return {
        ...state,
        isfetchingDropDown: false,
        assignedTechnicianList: [
          ...state.assignedTechnicianList,
          ...action.payload.docs,
        ],
        totalPages: action.payload.pages,
        totalCount: action.payload.total,
        currentPage: action.payload.page,
        apiMessage: action.payload.message,
      };
    case actionConstants.FETCH_ASSIGNED_TECHNICIAN_RESET:
      return {
        ...state,
        error: '',
        errorDropdown: '',
        isfetching: false,
        isfetchingDropDown: false,
        assignedTechnicianList: [],
        totalCount: null,
        totalPages: null,
        currentPage: null,
        apiMessage: '',
      };
    default:
      return state;
  }
};
