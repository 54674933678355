import { connect } from 'react-redux';
import * as chatActions from '../../redux/actions/chat';
import handlePermissionsUpdate, {
  handleSubscriptionsUpdate,
} from '../../redux/actions/permissionsSubscriptionsActions';
import {
  handleReportAnswerUpdated,
  generateRow,
  deleteRowFromJson,
  moveRowInJson,
} from '../../redux/actions/updateAnswer';
import setNotificationCount from '../../redux/actions/notificationCount';
import getGenericAccessPermissions from '../../redux/actions/getAccessPermissions';
import { fetchActiveSubscriptions } from '../../redux/actions/customerDetails';
import getNotificationCount from '../../redux/actions/getNotificationCount';
import ChatEnabler from './ChatProvider';

const mapStateToProps = ({ auth, genericPermissions, updateAnswer }) => ({
  auth,
  genericPermissions,
  updateAnswer,
});

export default connect(mapStateToProps, {
  ...chatActions,
  handlePermissionsUpdate,
  setNotificationCount,
  handleSubscriptionsUpdate,
  handleReportAnswerUpdated,
  getGenericAccessPermissions,
  fetchActiveSubscriptions,
  generateRow,
  deleteRowFromJson,
  moveRowInJson,
  getNotificationCount,
})(ChatEnabler);
