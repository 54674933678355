import moment from 'moment';
import actionConstants from '../constants/ActionConstants';
import getJobList, {
  getJobDetails,
  getTeamListWithFilters,
  assignJob,
  deleteJob,
  unassignJob,
  updateJobApiCall,
} from '../../api/apiCallsJobLink';
import ActionDispatcher from '../../api/ActionDispatcher';
import { getAddressApiWithAddress } from '../../api';

const getJobListAction = (queryParams, page, limit, jobStatus = ['Created']) =>
  ActionDispatcher(
    getJobList.bind(
      null,
      {
        startDate: moment().startOf('day').valueOf(),
        endDate: moment().endOf('month').valueOf(),
        jobStatus,
        ...queryParams,
      },
      page,
      limit,
    ),
    actionConstants.GET_JOB_LINK_SUCCESS,
    actionConstants.GET_JOB_LINK_FAILURE,
    actionConstants.GET_JOB_LINK_REQUEST
  );

export const getMissedJobListAction = (
  queryParams,
  page,
  limit,
  jobStatus = ['Created', 'Assigned']
) => {
  return ActionDispatcher(
    getJobList.bind(
      null,
      {
        startDate: moment().subtract(1, 'months').startOf('month').valueOf(),
        endDate: moment().subtract(1, 'months').endOf('month').valueOf(),
        jobStatus,
        extraParams: { isMissed: true },
        ...queryParams,
      },
      page,
      limit
    ),
    actionConstants.GET_MISSED_JOB_LIST_SUCCESS,
    actionConstants.GET_MISSED_JOB_LIST_FAILURE,
    actionConstants.GET_MISSED_JOB_LIST_REQUEST
  );
};

export const getNextMonthJobListAction = (
  queryParams,
  page,
  limit,
  jobStatus = ['Created']
) =>
  ActionDispatcher(
    getJobList.bind(
      null,
      {
        startDate: moment().add(1, 'month').startOf('month').valueOf(),
        endDate: moment().add(1, 'month').endOf('month').valueOf(),
        jobStatus,
        ...queryParams,
      },
      page,
      limit
    ),
    actionConstants.GET_NEXT_MONTH_JOBS_SUCCESS,
    actionConstants.GET_NEXT_MONTH_JOBS_FAILURE,
    actionConstants.GET_NEXT_MONTH_JOBS_REQUEST
  );

export const clearGetJobListAction = () => (dispatch) =>
  dispatch({
    type: actionConstants.RESET_FETCH_JOB_LIST,
  });

export const getJobDetailsAction = (jobId) =>
  ActionDispatcher(
    getJobDetails.bind(null, jobId),
    actionConstants.GET_JOB_DETAILS_SUCCESS,
    actionConstants.GET_JOB_DETAILS_FAILURE,
    actionConstants.GET_JOB_DETAILS_REQUEST
  );

export const getTeamListWithFiltersAction = (queryParams, page, limit) =>
  ActionDispatcher(
    getTeamListWithFilters.bind(null, queryParams, page, limit),
    actionConstants.GET_TEAM_LIST_WITH_FILTERS_SUCCESS,
    actionConstants.GET_TEAM_LIST_WITH_FILTERS_FAILURE,
    actionConstants.GET_TEAM_LIST_WITH_FILTERS_REQUEST
  );

export const resetTeamListWithFiltersAction = () => (dispatch) =>
  dispatch({
    type: actionConstants.RESET_TEAM_LIST_WITH_FILTERS,
  });

export const assignJobAction = (jobId, body) =>
  ActionDispatcher(
    assignJob.bind(null, jobId, body),
    actionConstants.ASSIGN_JOB_SUCCESS,
    actionConstants.ASSIGN_JOB_FAILURE,
    actionConstants.ASSIGN_JOB_REQUEST
  );

export const unassignJobAction = (jobId) =>
  ActionDispatcher(
    unassignJob.bind(null, jobId),
    actionConstants.UNASSIGN_JOB_SUCCESS,
    actionConstants.UNASSIGN_JOB_FAILURE,
    actionConstants.UNASSIGN_JOB_REQUEST
  );

export const deleteJobAction = (jobId) =>
  ActionDispatcher(
    deleteJob.bind(null, jobId),
    actionConstants.DELETE_JOB_SUCCESS,
    actionConstants.DELETE_JOB_FAILURE,
    actionConstants.DELETE_JOB_REQUEST
  );

export const setCalenderViewData = (calenderView) => (dispatch) => {
  dispatch({
    type: actionConstants.SET_CALENDER_DATA,
    payload: calenderView,
  });
};

export const clearCalenderViewData = () => (dispatch) => {
  dispatch({
    type: actionConstants.CLEAR_CALENDER_DATA,
  });
};

export const getLatLongWithAddress =
  (address, jobName, customerName) => (dispatch) => {
    dispatch({
      type: actionConstants.GET_LAT_LONG_WITH_ADDRESS_REQUEST,
    });
    getAddressApiWithAddress(address)
      .then((response) =>
        dispatch({
          type: actionConstants.GET_LAT_LONG_WITH_ADDRESS_SUCCESS,
          payload: response.body,
          uniqueId: jobName,
          customerName,
        })
      )
      .catch(() =>
        dispatch({
          type: actionConstants.GET_LAT_LONG_WITH_ADDRESS_FAILURE,
          message:
            'It is not an issue from your side. It probably is CORS or Network issue',
        })
      );
  };

export const updateJob = (jobId, jobDetails) =>
  ActionDispatcher(
    updateJobApiCall.bind(null, jobId, jobDetails),
    actionConstants.UPDATE_JOB_SUCCESS,
    actionConstants.UPDATE_JOB_FAILURE,
    actionConstants.UPDATE_JOB_REQUEST
  );

export const selectedOfflineJobsAction = (selectedJobList) => (dispatch) => {
  dispatch({
    type: actionConstants.SET_OFFLINE_SELECTED_JOB_LIST,
    payload: { selectedJobList },
  });
};

export const resetSelectedOfflineJobs = (selectedJobList) => (dispatch) => {
  dispatch({
    type: actionConstants.RESET_OFFLINE_SELECTED_JOB_LIST,
    payload: selectedJobList,
  });
};

export default getJobListAction;
