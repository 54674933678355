import React, { Component } from 'react';
import PropTypes from 'prop-types';
import InfiniteScroll from 'react-infinite-scroll-component';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import Button from '../../../components/Button';
import { getCustomerId } from '../../../_helpers/commonFunctions';
import ProfilePhoto from './ProfilePhoto';
import '../ChatView.scss';

const GROUP_NAME_MAX_LEN = 25;
const LIMIT = 100;
const GROUP_LIMIT = 40;

const PersonRow = ({ firstName, lastName, id, onClick }) => {
  const fullName = `${firstName}${lastName ? ` ${lastName}` : ''}`;
  return (
    <li className="clearfix" onClick={() => onClick(id, fullName)}>
      <ProfilePhoto label={fullName} />
      <div className="about">
        <div className="name">{fullName}</div>
      </div>
    </li>
  );
};

PersonRow.propTypes = {
  firstName: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

const PersonTag = ({ firstName, lastName, id, onClick, isDisabled }) => {
  const fullName = `${firstName}${lastName ? ` ${lastName}` : ''}`;
  return (
    <div
      className={
        isDisabled ? 'search-chip search-chip-disabled' : 'search-chip'
      }
      onClick={isDisabled ? () => {} : () => onClick(id)}
    >
      {fullName}
      {!isDisabled ? <span className="sprite-icon" /> : null}
    </div>
  );
};

PersonTag.propTypes = {
  firstName: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool.isRequired,
};

class ChatRoomDetails extends Component {
  state = {
    chatId: '',
    selectedPeople: new Set(),
    groupName: '',
    prevChatRoomDetails: {},
  };

  constructor(props) {
    super(props);
    this.modal = React.createRef();
  }

  componentDidMount() {
    this.props.getAllPeopleToChat(false, 0, LIMIT);
    this.props.getGroupDetails({ chatId: this.props.activeChatId });
    document.addEventListener('mousedown', this.handleClick);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClick);
  }

  handleClick = (e) => {
    if (this.modal.current.contains(e.target)) {
      return;
    }
    this.props.hideChatModal();
  };

  static getDerivedStateFromProps(props, state) {
    const { chatRoomDetails } = props;
    const { chatId, prevChatRoomDetails } = state;
    const nextState = {};
    if (
      chatRoomDetails.channelId !== chatId ||
      chatRoomDetails !== prevChatRoomDetails
    ) {
      nextState.chatId = chatRoomDetails.channelId;
      nextState.selectedPeople = new Set(chatRoomDetails.members);
      nextState.groupName = chatRoomDetails.groupName;
      nextState.prevChatRoomDetails = chatRoomDetails;
    }
    return nextState;
  }

  handlePersonRowClick = (personId) => {
    this.selectPerson(personId);
  };

  selectPerson = (personId) => {
    if (this.state.selectedPeople.size < GROUP_LIMIT) {
      this.setState((prevState) => ({
        selectedPeople: new Set(prevState.selectedPeople).add(personId),
      }));
    }
  };

  deselectPerson = (personId) => {
    this.setState((prevState) => {
      const selectedPeople = new Set(prevState.selectedPeople);
      selectedPeople.delete(personId);
      return {
        selectedPeople,
      };
    });
  };

  onGroupNameChange = (e) => {
    const value = e.target.value.trimLeft().slice(0, GROUP_NAME_MAX_LEN);
    this.setState({ groupName: value });
  };

  updateGroupChat = () => {
    const { groupName, selectedPeople } = this.state;
    const { updateGroupChat, hideChatModal, chatRoomDetails } = this.props;
    const orignalMemebers = new Set(chatRoomDetails.members);
    const newMembers = [];
    const removedMembers = [];
    const isSelfLeave = false;
    new Set([...selectedPeople, ...orignalMemebers]).forEach((item) => {
      if (selectedPeople.has(item) && orignalMemebers.has(item)) {
        // case: no update needed on server.
        return;
      }
      if (selectedPeople.has(item) && !orignalMemebers.has(item)) {
        // case: new member
        newMembers.push(item);
      }
      if (!selectedPeople.has(item) && orignalMemebers.has(item)) {
        removedMembers.push(item);
      }
    });
    updateGroupChat(
      {
        chatId: chatRoomDetails.channelId,
        newMembers,
        removedMembers,
        groupName,
      },
      isSelfLeave
    );
    hideChatModal();
  };

  loadMore = () => {
    this.props.getAllPeopleToChat(true, this.props.page + 1, LIMIT);
  };

  onExitPress = () => {
    const { groupName } = this.state;
    const { chatRoomDetails, hideChatModal } = this.props;
    const newMembers = [];
    const removedMembers = [];
    const isSelfLeave = true;
    removedMembers.push(getCustomerId());
    this.props.updateGroupChat(
      {
        chatId: chatRoomDetails.channelId,
        newMembers,
        removedMembers,
        groupName,
      },
      isSelfLeave
    );
    hideChatModal();
  };

  render() {
    const {
      hideChatModal,
      allPeople,
      isVisible,
      totalAllPeopleCount,
      intl,
      currentUser,
    } = this.props;
    const { selectedPeople, groupName } = this.state;
    return (
      <div
        className={`modal fade ${isVisible ? 'show' : ''}`}
        id="newchat"
        style={{ display: isVisible ? 'block' : 'none' }}
      >
        <div className="modal-dialog modal-dialog-centered" ref={this.modal}>
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">
                <FormattedMessage
                  id={'Update Group'}
                  defaultMessage={'Update Group'}
                />
              </h4>
              <button
                type="button"
                className="close sprite-icon"
                data-dismiss="modal"
                onClick={hideChatModal}
              ></button>
            </div>
            <div className="modal-body">
              <div className="create-group-chat">
                <div className="create-group-head">
                  <h6>
                    <FormattedMessage
                      id={'Update Group Details'}
                      defaultMessage={'Update Group Details'}
                    />
                  </h6>
                  <Button
                    color="red"
                    onBtnClick={() => this.onExitPress()}
                    cloneButton={true}
                    text="Exit"
                  />
                </div>
                <hr className="seperator" />
                <div className="form-group dis-flex f-dir-row">
                  <input
                    spellCheck="true"
                    type="text"
                    value={groupName || ''}
                    onChange={this.onGroupNameChange}
                    placeholder={intl.formatMessage({ id: 'Enter group name' })}
                    className="form-control"
                    id="usr"
                    name="username"
                  />
                  {groupName && selectedPeople.size !== 0 && (
                    <button onClick={this.updateGroupChat}>
                      <FormattedMessage id={'Save'} defaultMessage={'Save'} />
                    </button>
                  )}
                </div>
                <div className="chat-person-tag-container">
                  {
                    <PersonTag
                      key={getCustomerId()}
                      firstName={currentUser.userName}
                      lastName={''}
                      id={0}
                      isDisabled
                    />
                  }
                  {allPeople.map(
                    (item) =>
                      selectedPeople.has(item._id) && (
                        <PersonTag
                          key={item._id}
                          firstName={item.firstName}
                          lastName={item.lastName}
                          id={item._id}
                          onClick={this.deselectPerson}
                        />
                      )
                  )}
                </div>
              </div>
              <div className="people-list open-new-chat-list">
                <ul className="list">
                  <InfiniteScroll
                    dataLength={allPeople.length}
                    next={this.loadMore}
                    hasMore={totalAllPeopleCount > allPeople.length}
                    height={500}
                  >
                    {allPeople.map(
                      (item) =>
                        !selectedPeople.has(item._id) && (
                          <PersonRow
                            key={item._id}
                            firstName={item.firstName}
                            lastName={item.lastName}
                            id={item._id}
                            onClick={this.handlePersonRowClick}
                          />
                        )
                    )}
                  </InfiniteScroll>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

ChatRoomDetails.propTypes = {
  activeChatId: PropTypes.string.isRequired,
  allPeople: PropTypes.array.isRequired,
  chatRoomDetails: PropTypes.object.isRequired,
  getAllPeopleToChat: PropTypes.func.isRequired,
  getGroupDetails: PropTypes.func.isRequired,
  hideChatModal: PropTypes.func.isRequired,
  updateGroupChat: PropTypes.func.isRequired,
  isVisible: PropTypes.bool.isRequired,
  totalAllPeopleCount: PropTypes.isRequired,
  page: PropTypes.number.isRequired,
  intl: intlShape.isRequired,
  currentUser: PropTypes.isRequired,
};

export default injectIntl(ChatRoomDetails);
