import { getToken } from '../_helpers/commonFunctions';
import call from './apiRequest';
import apiEndpoints from './apiEndpoints';

/**
 * Api call to get report list
 * @param {object} data
 */
export const getReportList = (data) => {
  const reportData = data;
  if (reportData.reportType === 'Incomplete Reports') {
    const IncompleteReport = [
      'Created',
      'Inprogress',
      'Approval Required',
      'Rejected',
      'Deficiency',
    ];
    reportData.status = IncompleteReport;
    delete reportData.reportType;
  }
  return call({
    method: 'get',
    endpoint: apiEndpoints.jobReportList,
    query: reportData,
    authKey: getToken(),
  });
};

/**
 * Api call to delete report
 * @param {string} reportId
 */
export const deleteReport = (reportId) =>
  call({
    method: 'delete',
    endpoint: `${apiEndpoints.jobReportList}/${reportId}`,
    authKey: getToken(),
  });

/**
 * Api call to fetch service due list
 * @param {object} payload
 */
export const fetchServiceDueList = (payload) => {
  if (payload.download) {
    return call({
      method: 'get',
      endpoint: apiEndpoints.serviceDueList,
      authKey: getToken(),
      query: {
        ...payload,
      },
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      responseType: 'blob',
    });
  }
  return call({
    method: 'get',
    endpoint: apiEndpoints.serviceDueList,
    authKey: getToken(),
    query: {
      ...payload,
    },
  });
};

/**
 * Api call to fetch service due for count
 * @param {object} payload
 */
export const fetchServiceDueForCount = (payload) =>
  call({
    method: 'get',
    endpoint: apiEndpoints.serviceDueFor,
    authKey: getToken(),
    query: {
      ...payload,
    },
  });
