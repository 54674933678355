import actionConstants from '../constants/ActionConstants';

const initialState = {
  error: '',
  isfetching: false,
  success: '',
  isSuccess: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionConstants.IMPORT_CUSTOMERS_REQUEST:
    case actionConstants.ADD_CUSTOMER_REQUEST:
      return {
        ...state,
        isfetching: true,
        isSuccess: false,
      };
    case actionConstants.IMPORT_CUSTOMERS_SUCCESS:
    case actionConstants.ADD_CUSTOMER_SUCCESS:
      return {
        ...state,
        isfetching: false,
        success: action.message,
        isSuccess: true,
      };
    case actionConstants.IMPORT_CUSTOMERS_FAILURE:
    case actionConstants.ADD_CUSTOMER_FAILURE:
      return {
        ...state,
        error:
          action.message === 'Conflict'
            ? 'Customer already exists'
            : action.message,
        isfetching: false,
      };
    case actionConstants.CLEAR_STATE:
      return {
        ...state,
        error: '',
        success: '',
        isSuccess: false,
        isfetching: false,
      };
    case actionConstants.RESET_ALL_REDUCERS:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};
