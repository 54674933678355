import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import Modal from '../../Modal';
import './Button.scss';
import ToolTip from '../../ToolTip/index';

export class Button extends Component {
  state = {
    hover: false,
  };

  /**
   *Returns class name for different button types.
   *
   * @memberof Button
   */
  getClassName = () => {
    const { blockButton, cloneButton, disableButton, settingButton } =
      this.props;
    if (cloneButton) {
      return 'clone btn';
    }
    if (blockButton) {
      return 'btn btn_success';
    }
    if (disableButton) {
      return 'btn gry-btn btn_table';
    }
    if (settingButton) {
      return 'btn-setting';
    }
    return 'btn btn_primary';
  };

  render() {
    const { hover, showModal } = this.state;
    const {
      dataFor,
      dataTip,
      place,
      cloneButton,
      disableButton,
      onBtnClick,
      className,
      dataDismiss,
      type,
      isActive,
      isDisabled,
    } = this.props;

    return (
      <Fragment>
        <button
          data-for={dataFor}
          data-tip={dataTip}
          place={place}
          type={type}
          data-dismiss={dataDismiss}
          className={`${className} ${this.getClassName()}`}
          disabled={isDisabled}
          onClick={() => onBtnClick && onBtnClick()}
          onMouseOver={() =>
            !cloneButton && !disableButton && this.setState({ hover: true })
          }
          onMouseOut={() =>
            !cloneButton && !disableButton && this.setState({ hover: false })
          }
          style={
            cloneButton || disableButton
              ? {
                  border: `1px solid ${this.props.color}`, // #282C37
                  color: `${this.props.color}`,
                }
              : hover || isActive
              ? {
                  border: `1.5px solid ${this.props.color}`,
                  color: '#ffffff',
                  background: `${this.props.color}`,
                }
              : {
                  border: `1.5px solid ${this.props.color}`, // #282C37
                  color: `${this.props.color}`,
                }
          }
        >
          {showModal && <Modal />}
          {this.props.text}
        </button>
        <ToolTip id={dataFor} place={place} />
      </Fragment>
    );
  }
}

Button.propTypes = {
  color: PropTypes.string, // color of button
  text: PropTypes.string, // text on button
  blockButton: PropTypes.bool, // is block button
  cloneButton: PropTypes.bool, // is clone button
  disableButton: PropTypes.bool, // is button disabled
  onBtnClick: PropTypes.func, // Function to be called on button click
  className: PropTypes.string, // class name for button
  dataDismiss: PropTypes.string, // dismiss data div class name
  type: PropTypes.string, // button type
  isActive: PropTypes.bool, // is active button type
  settingButton: PropTypes.bool, // is setting button type
  dataFor: PropTypes.string,
  dataTip: PropTypes.string,
  place: PropTypes.string,
  isDisabled: PropTypes.bool,
};

Button.defaultProps = {
  color: '#4DBCE9',
  text: '',
  blockButton: false,
  cloneButton: false,
  disableButton: false,
  settingButton: false,
  type: 'button',
  onBtnClick: () => {},
  isActive: false,
  isDisabled: false,
};

export default Button;
