export const subPermissions = {
  createRecurringInspections: 'Create Recurring Inspections',
  editDeleteRecurringInspections: 'Edit / Delete Recurring Inspections',
  scheduleMyJobs: 'Schedule My Jobs',
  scheduleOthersJobs: 'Schedule Others Jobs',
  fullAdministrativePrivileges: 'Full Administrative Privileges',
  typicalTechPriveleges: 'Typical Tech Priveleges',
  addCustomer: 'Add Customer',
  editDeleteExistingCustomers: 'Edit / Delete Existing Customers',
  addTeamMembers: 'Add Team Members',
  editDeleteExistingTeamMembers: 'Edit / Delete Existing Team Members',
  addInventory: 'Add Inventory',
  editDeleteArchiveInventry: 'Edit / Delete / Archive Inventory',
  addPricesToWorkOrdersAndQuotes: 'Add Prices to Work Orders and Quotes',
  editYourTimeCard: 'Edit Your TimeCard',
  editOthersTimeCard: 'Edit Others TimeCard',
  receiveEmailCopiesOfMyReports: 'Receive Email Copies of my Reports',
  receiveEmailCopiesOfOthersReports: 'Receive Email Copies of Others Reports',
  editDeleteReports: 'Edit / Delete Reports',
  addRemoveAriesFeature: 'Add / Remove Aries Feature',
  viewDashboardFinancialAnalytics: 'View Dashboard Financial Analytics',
  changeOthersWorkingStatus: 'Change Others Working Status',
  changeYourWorkingStatus: 'Change Your Working Status',
  editDeleteSystemOrDevicesOnReport:
    'Edit / Delete Systems or Devices on Reports',
  viewJobsDueMissed: 'View Jobs Due / Missed',
  viewServiceRequests: 'View Service Requests',
  viewDeficiencies: 'View Deficiencies',
  editDeleteDeficiencies: 'Edit / Delete Deficiencies',
  addRemoveCompanyLogo: 'Add / Remove Company Logo',
  manageReports: 'Manage Reports',
  assignPartsToolsVehicles: 'Assign Parts / Tools / Vehicles',
  receiveEmailCopiesOfDeficiency: 'Receive Email Copies of Deficiency',
};

export const permissions = {
  qrCode: 'QR Code',
  recurringInspection: 'Recurring Inspections',
  jobLink: 'Job Scheduling',
  gps: 'GPS',
  customerPortal: 'Customer Portal',
  inventory: 'Inventory',
  quickBooks: 'QuickBooks',
  timeCard: 'TimeCard',
  customReport: 'Custom Report', // TODO
  standard: 'Standard',
  deficiency: 'Deficiency',
};

export const Spark = {
  qrCode: 'QR Code',
  recurringInspection: 'Recurring Inspections',
  jobLink: 'Job Scheduling',
  gps: 'GPS',
  'Customer Portal': 'Spark',
  inventory: 'Inventory',
  quickBooks: 'QuickBooks',
  timeCard: 'TimeCard',
  'Custom Report': 'Spark',
  Standard: 'Spark',
  Deficiency: 'Deficiency',
};

export const Flame = {
  'QR Code': 'Flame',
  'Recurring Inspections': 'Flame',
  'Job Scheduling': 'Flame',
  GPS: 'Flame',
  'Customer Portal': 'Flame',
  inventory: 'Inventory',
  quickBooks: 'QuickBooks',
  timeCard: 'TimeCard',
  'Custom Report': 'Flame',
  Standard: 'Flame',
  Deficiency: 'Flame',
};

export const Blaze = {
  'QR Code': 'Blaze',
  'Recurring Inspections': 'Blaze',
  'Job Scheduling': 'Blaze',
  GPS: 'Blaze',
  'Customer Portal': 'Blaze',
  Inventory: 'Blaze',
  QuickBooks: 'Blaze',
  TimeCard: 'Blaze',
  'Custom Report': 'Blaze',
  Standard: 'Blaze',
  Deficiency: 'Blaze',
};

export const subscriptions = {
  Spark: 'Spark',
  Blaze: 'Blaze',
  Flame: 'Flame',
};

export const subscriptionGrandParents = {
  Spark,
  Blaze,
  Flame,
};

export const allAccessPermissions = [
  'Customer Portal',
  'Custom Report',
  'Deficiency',
  'GPS',
  'Job Scheduling',
  'QR Code',
  'Recurring Inspections',
  'Inventory',
  'QuickBooks',
  'TimeCard',
];

export const allPermissions = {
  qrCode: {
    value: 'QR Code',
    subPermissions: {},
  },
  recurringInspection: {
    value: 'Recurring Inspections',
    subPermissions: {
      createRecurringInspections: 'Create Recurring Inspections',
      editDeleteRecurringInspections: 'Edit / Delete Recurring Inspections',
    },
  },
  jobLink: {
    value: 'Job Scheduling',
    subPermissions: {
      scheduleMyJobs: 'Schedule My Jobs',
      scheduleOthersJobs: 'Schedule Others Jobs',
    },
  },
  gps: {
    value: 'GPS',
    subPermissions: {},
  },
  customerPortal: {
    value: 'Customer Portal',
    subPermissions: {},
  },
  customReport: {
    value: 'Custom Report',
    subPermissions: {},
  },
  inventory: {
    value: 'Inventory',
    subPermissions: {
      addInventory: 'Add Inventory',
      editDeleteArchiveInventry: 'Edit / Delete / Archive Inventory',
      assignPartsToolsVehicles: 'Assign Parts / Tools / Vehicles',
    },
  },
  quickBooks: {
    value: 'QuickBooks',
    subPermissions: {},
  },
  timeCard: {
    value: 'TimeCard',
    subPermissions: {
      editYourTimeCard: 'Edit Your TimeCard',
      editOthersTimeCard: 'Edit Others TimeCard',
      changeOthersWorkingStatus: 'Change Others Working Status',
      changeYourWorkingStatus: 'Change Your Working Status',
    },
  },
  deficiency: {
    value: 'Deficiency',
    subPermissions: {
      viewDeficiencies: 'View Deficiencies',
      editDeleteDeficiencies: 'Edit / Delete Deficiencies',
      receiveEmailCopiesOfDeficiency: 'Receive Email Copies of Deficiency',
    },
  },
  standard: {
    value: 'Standard',
    subPermissions: {
      fullAdministrativePrivileges: 'Full Administrative Privileges',
      typicalTechPriveleges: 'Typical Tech Priveleges',
      addCustomer: 'Add Customer',
      editDeleteExistingCustomers: 'Edit / Delete Existing Customers',
      addTeamMembers: 'Add Team Members',
      editDeleteExistingTeamMembers: 'Edit / Delete Existing Team Members',
      receiveEmailCopiesOfMyReports: 'Receive Email Copies of my Reports',
      receiveEmailCopiesOfOthersReports:
        'Receive Email Copies of Others Reports',
      editDeleteReports: 'Edit / Delete Reports',
      addRemoveAriesFeature: 'Add / Remove Aries Feature',
      viewDashboardFinancialAnalytics: 'View Dashboard Financial Analytics',
      editDeleteSystemOrDevicesOnReport:
        'Edit / Delete Systems or Devices on Reports',
      viewJobsDueMissed: 'View Jobs Due / Missed',
      viewServiceRequests: 'View Service Requests',
      addRemoveCompanyLogo: 'Add / Remove Company Logo',
      manageReports: 'Manage Reports',
      assignPartsToolsVehicles: 'Assign Parts / Tools / Vehicles',
      addPricesToWorkOrdersAndQuotes: 'Add Prices to Work Orders and Quotes',
    },
  },
};

export const subscriptionPermissionsWithParent = {
  'Create Recurring Inspections': 'Recurring Inspections',
  'Edit / Delete Recurring Inspections': 'Recurring Inspections',
  'Schedule My Jobs': 'Job Scheduling',
  'Schedule Others Jobs': 'Job Scheduling',
  'Add Inventory': 'Inventory',
  'Edit / Delete / Archive Inventory': 'Inventory',
  'Assign Parts / Tools / Vehicles': 'Inventory',
  'Edit Your TimeCard': 'TimeCard',
  'Edit Others TimeCard': 'TimeCard',
  'Change Others Working Status': 'TimeCard',
  'Change Your Working Status': 'TimeCard',
  'View Deficiencies': 'Deficiency',
  'Edit / Delete Deficiencies': 'Deficiency',
  'Receive Email Copies of Deficiency': 'Deficiency',
};

export const sparkPermissions = {
  ...allPermissions.standard.subPermissions,
  ...allPermissions.customReport.subPermissions,
  ...allPermissions.customerPortal.subPermissions,
};

export const flamePermissions = {
  ...sparkPermissions,
  ...allPermissions.deficiency.subPermissions,
  ...allPermissions.gps.subPermissions,
  ...allPermissions.jobLink.subPermissions,
  ...allPermissions.qrCode.subPermissions,
  ...allPermissions.recurringInspection.subPermissions,
};

export const blazePermissions = {
  ...flamePermissions,
  ...allPermissions.inventory.subPermissions,
  ...allPermissions.quickBooks.subPermissions,
  ...allPermissions.timeCard.subPermissions,
};

export const planPermissions = {
  Spark: sparkPermissions,
  Blaze: blazePermissions,
  Flame: flamePermissions,
};
