import actionConstants from '../constants/ActionConstants';
import { deleteImage } from '../../api/apiCallsCustomer';
import ActionDispatcher from '../../api/ActionDispatcher';

const deleteImageAction = (imageId, buildingInfoId) =>
  ActionDispatcher(
    deleteImage.bind(null, imageId, buildingInfoId),
    actionConstants.DELETE_IMAGE_SUCCESS,
    actionConstants.DELETE_EMPLOYEE_FAILURE,
    actionConstants.DELETE_EMPLOYEE_REQUEST
  );

export default deleteImageAction;
