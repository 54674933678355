import { getToken } from '../_helpers/commonFunctions';
import call from './apiRequest';
import apiEndpoints from './apiEndpoints';

/**
 * Api call to get number of records in xlsx sheet
 * @param {object} data
 */
const getXLXSCount = (data) =>
  call({
    method: 'post',
    endpoint: apiEndpoints.xlsxCount,
    authKey: getToken(),
    type: 'multipart/form-data',
    payload: data,
  });

export default getXLXSCount;
