/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import { isEmpty } from 'lodash';
import {
  chatSocketHandlers,
  permissionsSubscriptionsSocketHandlers,
  reportEventHandlers,
} from '../../socket';
import { emitGPSLocation } from '../../socket/chatSocketHandlers';
import { findATableWithAId } from '../../views/ReportInspections/reportHelpers';

const LIMIT = 100;
class ChatProvider extends Component {
  componentDidMount() {
    const { login } = this.props.auth;

    if (!login.isFetching && !isEmpty(login.user)) {
      // case: user has been logged in, enable chat.
      this.getLocation();
      this.props.getNotificationCount();
      this.setGPSInterval = setInterval(this.getLocation, 60000);
      this.initChat();
      this.intervalSubscriptionId = setInterval(
        this.markAllUndeliveredMessages,
        20000
      );
    }
  }

  /**
   * clear interval on unmount
   */
  componentWillUnmount = () => {
    clearInterval(this.intervalSubscriptionId);
    clearInterval(this.setGPSInterval);
  };

  /**
   * find all those messages which are undelivered & mark them for resend.
   */
  markAllUndeliveredMessages = () => {
    this.props.markAllUndeliveredMessages();
  };

  componentDidUpdate(prevProps) {
    const { login } = this.props.auth;
    const { login: prevLogin } = prevProps.auth;

    if (login.isFetching) {
      // do not proceed if login state is fetching.
      return;
    }
    if (!isEmpty(login.user) && isEmpty(prevLogin.user)) {
      // case: user has been logged in, enable chat.
      this.getLocation();
      this.setGPSInterval = setInterval(this.getLocation, 60000);
      this.initChat();
    }
    if (isEmpty(login.user) && !isEmpty(prevLogin.user)) {
      // case: user has been logged out, disable chat.
      this.destroyChat();
      clearInterval(this.intervalSubscriptionId);
      clearInterval(this.setGPSInterval);
    }
  }

  getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(this.emitLocation, () => {}, {
        enableHighAccuracy: true,
        timeout: 0,
        maximumAge: 5000,
      });
    }
  };

  emitLocation = (value) => {
    const { user } = this.props.auth.login;
    const payload = {
      customerId: user.role === 'EMPLOYEE' ? user.ownerId : user.customerId,
      employeeId: user.customerId,
      name: user.userName,
      type: 'Point',
      coordinates: `[${value.coords.latitude} ,
              ${value.coords.longitude}]`,
    };
    emitGPSLocation(payload);
  };

  initChat = () => {
    const {
      login: { user },
    } = this.props.auth;
    chatSocketHandlers.init();
    this.props.getPeopleList(); // list of people chatted with
    this.props.getAllPeopleToChat(false, 0, LIMIT); // list of all people available to chat
    chatSocketHandlers.onReceive(this.props.onMessageReceived);
    chatSocketHandlers.onMsgSent(this.props.handleOnMessageSent);
    chatSocketHandlers.onMsgSeen(this.props.handleOnMessageSeen);
    chatSocketHandlers.onMsgDelivered(this.props.handleOnMessageDelivered);
    chatSocketHandlers.onUpdateGroupDetails(this.props.handleGroupUpdate);
    chatSocketHandlers.onConnected(() =>
      chatSocketHandlers.emitAllMsgsAsDelivered({
        userId: user.customerId,
      })
    );
    permissionsSubscriptionsSocketHandlers.init();
    permissionsSubscriptionsSocketHandlers.notificationCount((payload) => {
      this.props.setNotificationCount(JSON.parse(payload));
    });
    permissionsSubscriptionsSocketHandlers.employeePermissionsUpdate(
      this.props.handlePermissionsUpdate
    );
    permissionsSubscriptionsSocketHandlers.subscriptionsUpdate(
      (newSubscriptions) => {
        this.props.handleSubscriptionsUpdate(newSubscriptions);
        if (!this.props.genericPermissions.isFetching)
          this.props.getGenericAccessPermissions();
      }
    );
    permissionsSubscriptionsSocketHandlers.onConnected();
    reportEventHandlers.reportUpdated((payload) => {
      if (payload.answers && payload.answers.tables) {
        this.handleTableRowEvent(payload);
      }
      this.props.handleReportAnswerUpdated(payload);
    });
  };

  handleTableRowEvent = (payload) => {
    const tableIDs = payload.answers.tables
      ? Object.keys(payload.answers.tables)
      : [];
    if (
      tableIDs.length &&
      payload.answers.tables[tableIDs[0]] &&
      payload.answers.tables[tableIDs[0]].event === 'remove'
    ) {
      this.handleRowDecrement(payload);
    } else if (
      tableIDs.length &&
      payload.answers.tables[tableIDs[0]] &&
      payload.answers.tables[tableIDs[0]].event === 'shift'
    ) {
      this.handleShift(payload);
    } else {
      this.handleRowIncrement(payload);
    }
  };

  handleShift = (payload) => {
    const tableIDs = Object.keys(payload.answers.tables);
    const { updateAnswer } = this.props;
    const report = updateAnswer.reportData.find(
      (singleReportData) =>
        singleReportData && singleReportData._id === payload.reportId
    );
    const selectedIndex = updateAnswer.reportData.findIndex(
      (singleReportData) =>
        singleReportData && singleReportData._id === payload.reportId
    );

    if (report && report.templateType && report.templateType !== 'Static') {
      if (tableIDs && tableIDs.length) {
        const json = report.reportJson;
        const { parsedJson } = report;
        const tableID = tableIDs[0];
        if (tableID) {
          const table = findATableWithAId({
            jsonToSearchIn: parsedJson,
            id: tableID,
          });
          if (table && table.length) {
            const { from, to } = payload.answers.tables[tableID];
            this.props.moveRowInJson(
              [...json],
              table[0][0].id,
              from,
              to,
              selectedIndex
            );
          }
        }
      }
    }
  };

  handleRowDecrement = (payload) => {
    const tableIDs = Object.keys(payload.answers.tables);
    const { updateAnswer } = this.props;
    const report = updateAnswer.reportData.find(
      (singleReportData) =>
        singleReportData && singleReportData._id === payload.reportId
    );
    const selectedIndex = updateAnswer.reportData.findIndex(
      (singleReportData) =>
        singleReportData && singleReportData._id === payload.reportId
    );

    if (report && report.templateType && report.templateType !== 'Static') {
      if (tableIDs && tableIDs.length) {
        const json = report.reportJson;
        const { parsedJson } = report;
        const tableID = tableIDs[0];
        if (tableID) {
          const table = findATableWithAId({
            jsonToSearchIn: parsedJson,
            id: tableID,
          });
          if (table && table.length) {
            const tableLength = table[0].filter((row) =>
              Array.isArray(row)
            ).length;
            const minRows = table[0][0].minRows ? table[0].minRows : 1;
            const { index } = payload.answers.tables[tableID];
            this.props.deleteRowFromJson(json, tableID, index, selectedIndex);
            if (tableLength <= minRows) {
              this.props.generateRow(json, tableID, 1, selectedIndex, true);
            }
          }
        }
      }
    }
  };

  handleRowIncrement = (payload) => {
    const tableIDs = Object.keys(payload.answers.tables);
    const { updateAnswer } = this.props;
    const report = updateAnswer.reportData.find(
      (singleReportData) =>
        singleReportData && singleReportData._id === payload.reportId
    );
    const selectedIndex = updateAnswer.reportData.findIndex(
      (singleReportData) =>
        singleReportData && singleReportData._id === payload.reportId
    );

    if (report) {
      if (tableIDs && tableIDs.length) {
        const { reportJson: json, parsedJson } = report;
        const tableID = tableIDs[0];
        if (tableID) {
          const table = findATableWithAId({
            jsonToSearchIn: parsedJson,
            id: tableID,
          });
          if (table && table.length) {
            const tableLength = table[0].filter((row) =>
              Array.isArray(row)
            ).length;
            const { count } = payload.answers.tables[tableID];
            if (
              count > tableLength &&
              (!table[0][0].maxRows || table[0][0].maxRows > tableLength)
            ) {
              this.props.generateRow(
                json,
                table[0][0].id,
                count - tableLength,
                selectedIndex,
                true
              );
            }
          }
        }
      }
    }
  };

  destroyChat = () => {
    chatSocketHandlers.destroy();
    permissionsSubscriptionsSocketHandlers.destroy();
  };

  render() {
    return this.props.children;
  }
}

export default ChatProvider;
