import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import IncompleteReportLayout from '../../layouts/IncompleteReportLayout/IncompleteReportLayout';
import getReportList from '../../redux/actions/getReportsList';
import {
  deleteReportAction,
  clearDeleteState,
} from '../../redux/actions/deleteReport';
import {
  endMessageForReportTable,
  endMessageReportPagination,
  googleMaps,
} from '../../utils/Constants';
import formReportDetails from '../../redux/actions/addReportCat';
import { setAnswer, clearAnswers } from '../../redux/actions/updateAnswer';
import { reportConstants } from '../../libs/constants/reportConstants';
import { displayToast } from '../../_helpers/commonFunctions';
import setSelectedCustomer, {
  resetReportList,
  setReportName,
  resetSelectedCustomer,
} from '../../redux/actions/setSelectedCustomer';
import endpoints from '../../routes';

export class ReportListDetails extends React.Component {
  constructor(props) {
    super(props);
    this.page = 1;
    this.LIMIT = 50;
  }

  state = {
    searchText: null,
    filter: undefined,
    status: null,
    isShowDetails: null,
    reportCategory: [],
    reportType: null,
    CustomerNameSort: undefined,
    isCrossVisible: false,
    endMessage: endMessageReportPagination,
    startDate: null,
    endDate: null,
    heading: null,
    isClassVisible: {},
    isDeleteConfirmModalVisible: false,
    reportInfoId: null,
    showLoader: false,
    createdFor: null,
  };

  reloadList = () => {
    this.props.getReportList(
      this.page,
      this.LIMIT,
      this.state.searchText,
      this.state.reportType,
      this.state.reportCategory,
      this.state.CustomerNameSort,
      this.state.startDate,
      this.state.endDate,
      this.state.status,
      this.state.createdFor,
      !!(
        this.props.history.location.state &&
        this.props.history.location.state.selectedReport === 'Default'
      )
    );
  };

  componentDidMount() {
    this.page = 1;
    this.updateReportList();
    this.props.setReportName(null);
    this.props.resetSelectedCustomer();
  }

  updateReportList() {
    this.page = 1;
    this.setState(
      {
        status: [
          reportConstants.inprogress,
          reportConstants.created,
          reportConstants.approvalRequired,
          reportConstants.rejected,
          reportConstants.deficiency,
        ],
        reportType: reportConstants.inspection,
        createdFor: this.props.clientInfo._id,
        startDate: null,
        endDate: null,
        heading: reportConstants.incompleteReports,
      },
      () => this.reloadList()
    );
  }

  loadMore = () => {
    this.page += 1;
    this.reloadList();
  };

  setSelectedReportInfoId = (id) => {
    this.setState({ reportInfoId: id, isDeleteConfirmModalVisible: true });
  };

  setSelectedReportEditId = (row) => {
    if (
      !this.props.history.location.state.reportCategory.includes(
        row.reportCategory
      )
    ) {
      if(row.isGeneric) {
        this.props.history.push(`/genericReportDetail/${row._id}`);
        return;
      }
      let formData = {};
      if (row.reportType === 'Inspection') {
        formData = {
          typeValue: row.reportType,
          catValue: row.reportCategory,
          customerValue: row.createdFor,
          frequency: row.frequency,
          reportTemplate: {
            reportJson: row.reportJson,
          },
        };
        // this.props.clearAnswers();
        if (row.answers) {
          this.props.setAnswer({ answers: row.answers });
        }
      }
      this.props.formReportDetails(formData);
      this.props.history.push(`/reportDetail/${row._id}`, {
        selectedReport: this.props.history.location.state.selectedReport,
      });
    } else displayToast('Reports of Same Category Cannot be opened', 'error');
  };

  deleteReportAction = () => {
    const { reportInfoId } = this.state;
    this.props.deleteReportAction(reportInfoId);
    this.page = 1;
  };

  isEndMessageVisible = () =>
    this.page > 1 ||
    (this.props.reportList && this.props.reportList.length) === 0;

  sortData = () => {
    this.page = 1;
    const { CustomerNameSort } = this.state;
    this.setState(
      {
        CustomerNameSort:
          CustomerNameSort === undefined ? 1 : CustomerNameSort * -1,
      },
      this.reloadList()
    );
  };

  setClassVisibility = (employeeId) => {
    const isClassVisible = {};
    if (Object.keys(this.state.isClassVisible)[0] === employeeId) {
      isClassVisible[`${employeeId}`] =
        !this.state.isClassVisible[`${employeeId}`];
    } else {
      isClassVisible[`${employeeId}`] = true;
    }
    this.setState({ isClassVisible });
  };

  handleToastForOfflineMode = (message, isError = true) => {
    if (this.props.offlineMode.isOffline) {
      displayToast(message, isError ? 'error' : 'success');
    }
    return this.props.offlineMode.isOffline;
  };

  handleImage = (e) => {
    e.preventDefault();
    const { reportCat, history, clientInfo } = this.props;
    const reportId = window.location.pathname.split('/')[2];
    this.props.setReportName(reportId);
    if (
      !this.handleToastForOfflineMode(
        'Cell or internet service is required to view Images'
      )
    ) {
      this.props.setSelectedCustomer(
        reportCat.customerValue,
        reportCat.typeValue,
        clientInfo.businessName
      );
      this.props.resetReportList();
      history.push(endpoints.images);
    }
  };

  handleSystemInfo = (e) => {
    e.preventDefault();
    const { reportCat, history, clientInfo } = this.props;
    const reportId = window.location.pathname.split('/')[2];
    this.props.setReportName(reportId);
    if (
      !this.handleToastForOfflineMode(
        'Cell or internet service is required to view System Info'
      )
    ) {
      this.props.setSelectedCustomer(
        reportCat.customerValue,
        reportCat.typeValue,
        clientInfo.businessName
      );
      this.props.resetReportList();
      history.push(endpoints.systemInfo);
    }
  };

  handleBuildingInfo = (e) => {
    e.preventDefault();
    const { reportCat, history, clientInfo } = this.props;
    const reportId = window.location.pathname.split('/')[2];
    this.props.setReportName(reportId);
    if (
      !this.handleToastForOfflineMode(
        'Cell or internet service is required to view Building Info'
      )
    ) {
      this.props.setSelectedCustomer(
        reportCat.customerValue,
        reportCat.typeValue,
        clientInfo.businessName
      );
      this.props.resetReportList();
      history.push(endpoints.buildingInfo);
    }
  };

  handleRedirectionToMaps = () => {
    const { clientInfo } = this.props;
    const { formattedAddress } = clientInfo;
    if (
      !this.handleToastForOfflineMode(
        'Cell or internet service is required to navigate to map'
      )
    ) {
      window.open(googleMaps + formattedAddress.replace('/', '-'));
    }
  };

  render() {
    const { clientInfo } = this.props;

    return (
      <div>
        <div className="report-section">
          <div className="user-name-add-inn">
            <div className="user-name-fld">
              <div className="user-img">
                <img src="./img/img-placeholder.png" alt="" />
              </div>
              <div className="user-name">
                <p
                  style={{
                    width: `${
                      window.innerWidth > 900
                        ? this.props.history.location.pathname.includes(
                            'reportListing'
                          )
                          ? '225px'
                          : '290px'
                        : '236px'
                    }`,
                    wordBreak: 'normal',
                  }}
                >
                  {clientInfo.businessName}
                </p>
                <div className="work-list-icon">
                  <div className="list-icon-wrap">
                    <div className="user-house sprite-icon-before1">
                      <div className="house-list-wrap">
                        <div className="house-list">
                          <ul>
                            <li>
                              <a href="/" onClick={this.handleBuildingInfo}>
                                <FormattedMessage
                                  id="Building Information"
                                  defaultMessage="Building Information"
                                />
                              </a>
                            </li>
                            <li>
                              <a href="/" onClick={this.handleSystemInfo}>
                                <FormattedMessage
                                  id="System Information"
                                  defaultMessage="System Information"
                                />
                              </a>
                            </li>
                            <li>
                              <a href="/" onClick={this.handleImage}>
                                <FormattedMessage
                                  id="Images"
                                  defaultMessage="Images"
                                />
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    {this.state.isShowDetails ? (
                      <div
                        className="imag-upload sprite-icon1"
                        onClick={this.openModal}
                        data-toggle="modal"
                        data-target="#img-upload"
                      ></div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
            <div className="user-address-fld">
              <div className="user-address-fld-inn">
                <div className="ofc-no sprite-icon-before">
                  <p>
                    <FormattedMessage
                      id="Office Number:"
                      defaultMessage="Office Number:"
                    />{' '}
                    <span>
                      {clientInfo.officePhone ? clientInfo.officePhone : 'NA'}
                    </span>
                  </p>
                </div>
                <div className="ofc-cell-no ofc-no sprite-icon-before">
                  <p>
                    <FormattedMessage
                      id="Cell Number:"
                      defaultMessage="Cell Number:"
                    />
                    <span>
                      {clientInfo.cellPhone ? clientInfo.cellPhone : 'NA'}
                    </span>
                  </p>
                </div>
                <div className="ofc-addr sprite-icon-before ">
                  <p style={{ display: 'flex' }}>
                    <FormattedMessage id="Address:" defaultMessage="Address:" />{' '}
                    <span className="pad-rt-12">
                      <div
                        style={{
                          display: 'inline-block',
                          wordBreak: 'break-word',
                        }}
                      >
                        {`${clientInfo.businessName},`}
                        <br />
                        {`${clientInfo.address1}, ${clientInfo.address2 || ''}${
                          clientInfo.address2 ? ',' : ''
                        }`}
                        <br />
                        {`${clientInfo.city}, ${clientInfo.state}, ${clientInfo.zipCode}`}
                      </div>
                    </span>
                  </p>
                </div>
              </div>
              <div
                className="address-icon sprite-icon1"
                onClick={this.handleRedirectionToMaps}
              />
            </div>
          </div>
        </div>
        <div className="incompletetable-sec">
          <IncompleteReportLayout
            isCrossVisible={this.state.isCrossVisible}
            searchText={this.state.searchText}
            reportList={this.props.reportList}
            setFilters={this.setFilters}
            setSearchText={this.setSearchText}
            handleSearch={this.handleOnSearch}
            loadMore={this.loadMore}
            isfetching={this.props.isfetching}
            isClassVisible={this.state.isClassVisible}
            setClassVisibility={this.setClassVisibility}
            reportHeading={this.state.heading}
            hasMoreItems={this.page < this.props.pages}
            sortData={this.sortData}
            filter={this.state.filter}
            endMessage={
              this.props.reportList && this.props.reportList.length > 0
                ? this.state.endMessage
                : endMessageForReportTable
            }
            setReportType={this.setReportType}
            reportType={this.state.reportType}
            setSelectedReportEditId={(row) => this.setSelectedReportEditId(row)}
            setSelectedReportInfoId={(id) => this.setSelectedReportInfoId(id)}
            isEndMessageVisible={this.isEndMessageVisible()}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { offlineMode, addReportCat } = state;
  const { isOffline, customersList, reportListByCustomer, reportListByJobIds } =
    offlineMode;
  const offlineReports = {
    ...reportListByCustomer.reportListByCustomer,
    ...reportListByJobIds.reportListByJobIds,
  };
  return {
    ClientOrOwnerDetails: state.getClientOrOwnerDetails,
    reportCat: state.addReportCat.reportCatData,
    clientInfo: isOffline
      ? customersList[addReportCat.reportCatData.customerValue]
      : state.getClientOrOwnerDetails.clientOrOwnerData,
    reportList: isOffline
      ? Object.values(offlineReports).filter(
          (report) =>
            report.createdFor === addReportCat.reportCatData.customerValue
        )
      : state.getReportList.reportData,
    pages: state.getReportList.pages,
    isfetching: state.getReportList.isfetching,
    deleteReportData: state.deleteReport,
    offlineMode: state.offlineMode,
  };
};

ReportListDetails.propTypes = {
  reportList: PropTypes.array,
  isfetching: PropTypes.boolean,
  pages: PropTypes.number,
  getReportList: PropTypes.any,
  deleteReportAction: PropTypes.any,
  location: PropTypes.object,
  deleteReportData: PropTypes.object,
  clearDeleteState: PropTypes.func,
  formReportDetails: PropTypes.func,
  history: PropTypes.object,
  deleteReportFetching: PropTypes.bool,
  setAnswer: PropTypes.func,
  clearAnswers: PropTypes.func,
  clientInfo: PropTypes.object,
  offlineMode: PropTypes.object,
  reportCat: PropTypes.object,
  setSelectedCustomer: PropTypes.func,
  resetReportList: PropTypes.func,
  setReportName: PropTypes.func,
  resetSelectedCustomer: PropTypes.func,
};

export default connect(mapStateToProps, {
  getReportList,
  deleteReportAction,
  clearDeleteState,
  formReportDetails,
  setAnswer,
  clearAnswers,
  setSelectedCustomer,
  resetReportList,
  setReportName,
  resetSelectedCustomer,
})(ReportListDetails);
