import actionConstants from '../constants/ActionConstants';

const initialState = {
  error: '',
  isfetching: false,
  success: '',
  uploadedPartDetails: null,
  isSuccess: false,
};

const uploadPartStore = (state = initialState, action) => {
  switch (action.type) {
    case actionConstants.UPLOADED_PARTS_DETAILS_REQUEST:
      return {
        ...state,
        isfetching: true,
        isSuccess: false,
      };
    case actionConstants.UPLOADED_PARTS_DETAILS_SUCCESS:
      return {
        ...state,
        uploadedPartDetails: action.payload,
        success: action.message,
        isfetching: false,
        isSuccess: true,
      };
    case actionConstants.UPLOADED_PARTS_DETAILS_FAILURE:
      return {
        ...state,
        error: action.message,
        isSuccess: false,
        isfetching: false,
        success: '',
      };
    case actionConstants.UPLOADED_PARTS_DETAILS_RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export default uploadPartStore;
