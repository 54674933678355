import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import InfiniteScroll from 'react-infinite-scroll-component';
import moment from 'moment';
import { connect } from 'react-redux';
import Scheduler, {
  SchedulerData,
  ViewTypes,
  DnDSource,
} from 'react-big-scheduler';
import 'react-big-scheduler/lib/css/style.css';
import dragTypes from '../../libs/constants/dragTypes';
import {
  reportCategoriesWithRoutes,
  REPORT_CATEGORIES,
} from '../../utils/Constants';
import JobListRow from '../JobList/JobListRow';
import getJobListAction, {
  getJobDetailsAction,
  setCalenderViewData,
  getTeamListWithFiltersAction,
} from '../../redux/actions/jobLinkActions';
import updateStatusAction from '../../redux/actions/updateStatus';
import {
  getTeams,
  displayToast,
  getCustomerId,
  getTeamStatus,
  checkPermission,
} from '../../_helpers/commonFunctions';
import JobLinkPopUp from '../JobLinkPopUp/JobLinkPopUp';
import CustomMultiSelectDropDown from '../CustomMultiSelectDropdown';
import { subPermissions, permissions } from '../../libs/constants/permissions';
import popUpData from './PopUpData';
import JobDetailPopOver from './JobDetailPopUp';
import { EVENTS, saveEvent } from '../../utils/eventAnalytics';

let TEAMS_PER_PAGE = 50;

/* eslint-disable no-restricted-globals */
/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */
export class Basic extends Component {
  constructor(props) {
    super(props);
    this.state = {
      viewModel: new SchedulerData(),
      taskDndSource: new DnDSource(
        (props1) => props1.task,
        JobListRow,
        dragTypes.TASK
      ),
      showChangeStatus: false,
      teamId: '',
      selectedStatus: '',
      reportCategoriesSelected: {},
      search: '',
    };
    this.isMyPermissionChange = checkPermission(
      subPermissions.scheduleMyJobs,
      permissions.jobLink
    );
    this.isOthersPermissionChange = checkPermission(
      subPermissions.scheduleOthersJobs,
      permissions.jobLink
    );
  }

  getInitialScheduler = () => {
    const { startDate } = this.props.calenderViewData;
    const schedulerData = new SchedulerData(
      startDate || new Date().toDateString(),
      this.props.viewType || ViewTypes.Day,
      false,
      false,
      {
        schedulerWidth: '75%',
        dayCellWidth: '7%',
        views: [
          {
            viewName: 'Day',
            viewType: ViewTypes.Day,
            showAgenda: false,
            isEventPerspective: false,
          },
          {
            viewName: 'Week',
            viewType: ViewTypes.Custom,
            showAgenda: false,
            isEventPerspective: false,
          },
        ],
        headerEnabled: false
      },
      {
        getCustomDateFunc: this.getCustomDate,
      }
    );
    schedulerData.localeMoment.locale('en');
    return schedulerData;
  };

  setInitialScheduler = () => {
    const { setSchedulerData, setTaskDndSource } = this.props;
    const schedulerData = this.getInitialScheduler();
    this.setState(
      {
        viewModel: schedulerData,
      },
      () => {
        this.setCalenderData(this.state.viewModel, { id: 2 });
        setSchedulerData(this.state.viewModel);
        setTaskDndSource(this.state.taskDndSource);
      }
    );
  };

  getAllJobsWithResource = () => {
    const { teamList } = this.props.teamListDetails;
    const jobs = { customerNames: {} };
    teamList.forEach((team) => {
      jobs[team._id] = [...team.job];
      jobs.customerNames[team._id] = team.createdFor;
    });
    return jobs;
  };

  getEvents = () => {
    const events = [];
    const jobsWithResource = this.getAllJobsWithResource();
    Object.keys(jobsWithResource).forEach((resource) => {
      const jobs = jobsWithResource[resource];
      if (resource !== 'customerNames') {
        jobs.forEach((job) => {
          if (
            moment(job.startDateTime).valueOf() >=
            moment().startOf('day').valueOf()
          ) {
            const event = {};
            const customerName = jobsWithResource.customerNames[
              resource
            ].filter((customer) => job.createdFor === customer._id);
            event.id = job._id;
            event.resourceId = resource;
            event.title =
              customerName.length && customerName[0].businessName
                ? customerName[0].businessName
                : job.description;
            event.start = job.startDateTime;
            event.end = job.endDateTime;
            event.startResizable = true;
            event.endResizable = true;
            event.state = {
              reportCategory: job.reportCategory,
              duration: job.duration,
              type: job.type,
            };
            events.push(event);
          }
        });
      }
    });
    return events;
  };

  getResourceListForNewHeight = () => {
    if (window.innerWidth >= 1900 && TEAMS_PER_PAGE !== 50) {
      TEAMS_PER_PAGE = 50;
      this.getResources();
    } else if (window.innerWidth < 1900 && TEAMS_PER_PAGE !== 30) {
      TEAMS_PER_PAGE = 30;
      this.getResources();
    }
  };

  // getTeamListWithPermissions =
  //   (id, teamListArray) => teamListArray.filter(item => item.customerId === id);

  static getDerivedStateFromProps(props, state) {
    if (state.search !== props.search) {
      return { search: props.search };
    }
    return state;
  }

  componentDidUpdate(prevProps) {
    const {
      teamListDetails,
      jobList,
      resourceReportCategory,
      statusUpdateError,
      isStatusUpdated,
      handleOnSearch,
      isSearch,
    } = this.props;
    this.getResourceListForNewHeight();
    if (
      (teamListDetails.isSuccess &&
        prevProps.teamListDetails.isSuccess !== teamListDetails.isSuccess) ||
      (jobList.isSuccess && prevProps.jobList.isSuccess !== jobList.isSuccess)
    ) {
      const teams = teamListDetails ? getTeams(teamListDetails.teamList) : [];
      const schedulerData = this.state.viewModel;
      schedulerData.setResources(teams);
      schedulerData.setEvents(this.getEvents());
      this.setState({
        viewModel: schedulerData,
      });
    }
    if (isStatusUpdated && prevProps.isStatusUpdated !== isStatusUpdated) {
      displayToast('Status updated successfully', 'success');
      this.getResources(this.state.viewModel, 1);
    } else if (
      statusUpdateError &&
      prevProps.statusUpdateError !== statusUpdateError
    ) {
      displayToast(statusUpdateError, 'error');
    }
    if (
      JSON.stringify(prevProps.resourceReportCategory) !==
      JSON.stringify(resourceReportCategory)
    ) {
      this.getResources(undefined, undefined, true);
    }
    if (isSearch && prevProps.isSearch !== isSearch) {
      this.getResources();
      handleOnSearch(false);
    }
    if (
      this.isMyPermissionChange !==
      checkPermission(subPermissions.scheduleMyJobs, permissions.jobLink)
    ) {
      this.isMyPermissionChange = checkPermission(
        subPermissions.scheduleMyJobs,
        permissions.jobLink
      );
      this.getResources();
    }
    if (
      this.isOthersPermissionChange !==
      checkPermission(subPermissions.scheduleOthersJobs, permissions.jobLink)
    ) {
      this.isOthersPermissionChange = checkPermission(
        subPermissions.scheduleOthersJobs,
        permissions.jobLink
      );
      this.getResources();
    }
  }

  getResources = (
    schedulerData = this.props.calenderViewData,
    activePage = this.props.teamListDetails.currentPage,
    isDivision = false
  ) => {
    const { resourceReportCategory, isSearch, teamListDetails } = this.props;
    const { currentPage, pages, isfetching } = teamListDetails;
    const { search } = this.state;
    const momentStartDate = moment(schedulerData.startDate).set({
      hour: 0,
      minute: 0,
      second: 0,
      millisecond: 0,
    });
    const momentEndDate = moment(schedulerData.endDate).set({
      hour: 23,
      minute: 59,
      second: 59,
      millisecond: 999,
    });
    const queryParams = {
      startDateTime: schedulerData.startDate
        ? momentStartDate.valueOf()
        : new Date().setHours(0, 0, 0, 0),
      endDateTime: schedulerData.endDate
        ? momentEndDate.valueOf()
        : new Date().setHours(23, 59, 59, 999),
      search,
    };
    if (resourceReportCategory)
      queryParams.reportCategories = resourceReportCategory;
    if (
      !isfetching &&
      (currentPage <= pages || (currentPage === 1 && pages === 0))
    ) {
      this.props.getTeamListWithFiltersAction(
        queryParams,
        isSearch || isDivision ? 1 : activePage,
        TEAMS_PER_PAGE
      );
    }
  };

  handleTeamStatusUpdation = (status, userId) => {
    this.setState({ showChangeStatus: false, teamId: 0 });
    const { teamId } = this.state;
    const upperStatus = status.replace(/ +/g, '').toUpperCase();
    const updateSatusData = {
      customerId: getCustomerId(),
      employeeId: teamId || userId,
      status: upperStatus === 'CLOCKEDOUT' ? 'CHECKOUT' : upperStatus,
      day: moment(new Date()).format('YYYY-MM-DD'),
    };
    this.props.updateStatusAction(updateSatusData);
  };

  pushCategory = (name, value) => {
    let categoriesSelected = {};
    let selectedCategory = [];
    categoriesSelected = { ...this.state.reportCategoriesSelected };
    categoriesSelected[name] = value;
    this.setState({ reportCategoriesSelected: { ...categoriesSelected } });
    selectedCategory = Object.keys(categoriesSelected);
    return selectedCategory;
  };

  deleteCategory = (name) => {
    let categoriesSelected = {};
    let selectedCategory = [];
    categoriesSelected = { ...this.state.reportCategoriesSelected };
    delete categoriesSelected[name];
    this.setState({ reportCategoriesSelected: { ...categoriesSelected } });
    selectedCategory = Object.keys(categoriesSelected);
    return selectedCategory;
  };

  setCategories = (selectedCategory) => {
    this.props.setResourceReportCategory(selectedCategory);
  };

  handleCategoryChange = (name, value) => {
    let selectedCategory = [];
    if (!value) {
      selectedCategory = this.deleteCategory(name);
    } else {
      selectedCategory = this.pushCategory(name, value);
    }
    this.setCategories(selectedCategory);
  };

  componentDidMount() {
    const { setNewEvent } = this.props;
    this.setInitialScheduler();
    setNewEvent(() => this.newEvent);
    this.getResourceListForNewHeight();
    this.props.onRef(this);
  }

  componentWillUnmount() {
    this.props.onRef(null);
  }

  updateEventStart = (schduler, current, newStart) => {
    const newStartDate = new Date(newStart);
    const diff = new Date(current.end).getTime() - newStartDate.getTime();
    const duration = diff / (1000 * 60 * 60);
    this.props.getJobDetailsAction(current.id);
    this.props.setCurrentEvent({ ...current, save: true, isNew: false, start: newStartDate.toISOString(), duration });
    this.props.toggleEditJobModal(true);
  }
  updateEventEnd = (schduler, current, newEnd) => {
    const newEndDate = new Date(newEnd);
    const diff = newEndDate.getTime() - new Date(current.start).getTime();
    const duration = diff / (1000 * 60 * 60);
    this.props.getJobDetailsAction(current.id);
    this.props.setCurrentEvent({ ...current, save: true, isNew: false, duration, end: newEndDate.toISOString() });
    this.props.toggleEditJobModal(true);
  }

  render() {
    const {
      viewModel,
      taskDndSource,
      showChangeStatus,
      selectedStatus,
      reportCategoriesSelected,
    } = this.state;
    const {
      teamListDetails,
      reportCategories,
      isStatusUpdateFetching,
      jobList,
    } = this.props;
    const dndSources = [taskDndSource];
    const filteredReportCategories = reportCategories.filter((category) => {
      const { Other, WorkOrder, Quote } = reportCategoriesWithRoutes;
      return category !== Other && category !== WorkOrder && category !== Quote;
    });
    const index = filteredReportCategories.findIndex(
      (category) => category === REPORT_CATEGORIES.standpipeHose
    );
    if (index) filteredReportCategories[index] = 'Standpipe / Hose';

    return (
      <div style={{ position: 'relative', top: '20px', right: '11px' }}>
        <div>
          {(teamListDetails.isfetching &&
            teamListDetails.currentPage === 1 &&
            !teamListDetails.teamList.length) ||
          jobList.isFetching ||
          isStatusUpdateFetching ? (
            <div className="loader-container">
              <div className="loader"></div>
            </div>
          ) : (
            <div>
              <InfiniteScroll
                className="job-link-table"
                scrollableTarget="scrollableDiv"
                dataLength={
                  this.state.viewModel.resources &&
                  this.state.viewModel.resources.length
                }
                next={() =>
                  this.getResources(
                    this.state.viewModel,
                    this.props.teamListDetails.currentPage + 1
                  )
                }
                hasMore={
                  !this.props.teamListDetails.isfetching &&
                  this.props.teamListDetails.currentPage <
                    this.props.teamListDetails.pages
                }
                endMessage={
                  !this.props.teamListDetails.teamList.length &&
                  !this.props.teamListDetails.isfetching ? (
                    <div className="end-message"></div>
                  ) : (
                    ''
                  )
                }
              >
                <Scheduler
                  schedulerData={viewModel}
                  resourceType="popover-quote"
                  resourceCategory="popover-type"
                  prevClick={this.prevClick}
                  nextClick={this.nextClick}
                  onSelectDate={this.onSelectDate}
                  onViewChange={this.onViewChange}
                  eventItemClick={this.eventClicked}
                  moveEvent={this.moveEvent}
                  // newEvent={this.newEvent}
                  onScrollLeft={this.onScrollLeft}
                  onScrollRight={this.onScrollRight}
                  onEventResize={this.resizeEvent}
                  updateEventStart={this.updateEventStart}
                  updateEventEnd={this.updateEventEnd}
                  dndSources={dndSources}
                  eventItemPopoverTemplateResolver={
                    this.eventItemPopoverTemplateResolver
                  }
                  handleClickResource={this.slotClickedFunc}
                  ResourceHeaderComponent={
                    <CustomMultiSelectDropDown
                      dataTip="Change to another division and Change the working status below"
                      dataFor="joblink-division-tooltip"
                      className="dropdown custom-drop-option"
                      dropDownList={filteredReportCategories}
                      name="reportCategory"
                      handleOnChange={this.handleCategoryChange}
                      placeholder="Division"
                      selectedValues={Object.keys(reportCategoriesSelected)}
                      dropItemSelected={reportCategoriesSelected}
                      width="189px"
                    />
                  }
                  resourceStatus={{
                    slotId: this.state.teamId,
                    resourceStatusComponent: showChangeStatus ? (
                      <JobLinkPopUp
                        heading="Change Status"
                        bodyItems={popUpData}
                        onClickItem={this.handleTeamStatusUpdation}
                        onClickCross={() =>
                          this.setState({ showChangeStatus: false, teamId: 0 })
                        }
                        selectedStatus={selectedStatus}
                      />
                    ) : null,
                  }}
                  EndMessage={
                    <div className="errorMessage">
                      <FormattedMessage
                        id="No Team Members Found!"
                        defaultMessage="No Team Members Found!"
                      />
                    </div>
                  }
                />
              </InfiniteScroll>
              {teamListDetails.isfetching && (
                <div className="loader-container">
                  <div className="loader"></div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    );
  }

  eventItemPopoverTemplateResolver = (
    schedulerData,
    eventItem,
    title,
    start,
    end,
    statusColor
  ) => (
    <JobDetailPopOver
      title={title}
      start={start}
      end={end}
      statusColor={statusColor}
      eventItem={eventItem}
    />
  );

  slotClickedFunc = (slot) => {
    const { teamId } = this.state;
    if ((slot && slot.slotId !== teamId) || !teamId) {
      this.setState({
        showChangeStatus: true,
        teamId: slot.slotId,
        selectedStatus: getTeamStatus(
          this.props.teamListDetails.teamList,
          slot.slotId
        ),
      });
    }
  };

  getCustomDate = (schedulerData, num, date = undefined) => {
    let selectDate = schedulerData.startDate;
    if (date !== undefined) selectDate = date;

    const startDate =
      num === 0
        ? selectDate
        : schedulerData
            .localeMoment(selectDate)
            .add(7 * num, 'days')
            .format('YYYY-MM-DD');
    const endDate = schedulerData
      .localeMoment(startDate)
      .add(6, 'days')
      .format('YYYY-MM-DD');
    const cellUnit = 1;

    return {
      startDate,
      endDate,
      cellUnit,
    };
  };

  setCalenderData = (schedulerData, prevView) => {
    this.props.setCalenderViewData({
      startDate: schedulerData.startDate,
      endDate: schedulerData.endDate,
    });
    this.getResources(
      schedulerData,
      prevView && prevView.id === 2 ? 1 : undefined
    );
  };

  prevClick = (schedulerData) => {
    schedulerData.prev();
    this.setState(
      {
        viewModel: schedulerData,
      },
      () => this.setCalenderData(this.state.viewModel)
    );
  };

  nextClick = (schedulerData) => {
    schedulerData.next();
    this.setState(
      {
        viewModel: schedulerData,
      },
      () => this.setCalenderData(this.state.viewModel)
    );
  };

  onViewChange = (schedulerData, view) => {
    if (this.state.viewModel.viewType !== view.viewType) {
      schedulerData.setViewType(
        view.viewType,
        view.showAgenda,
        view.isEventPerspective
      );
      schedulerData.setEvents(this.getEvents());
      schedulerData.config.nonAgendaDayCellHeaderFormat = 'ha';
      if (view.viewType === 5) {
        schedulerData.config.nonAgendaDayCellHeaderFormat = 'D, ddd |ha';
      }
      this.setState({
        viewModel: schedulerData,
      });
    }
  };

  onSelectDate = (schedulerData, date) => {
    schedulerData.setDate(date);
    this.setState(
      {
        viewModel: schedulerData,
      },
      () => this.setCalenderData(this.state.viewModel)
    );
  };

  eventClicked = (schedulerData, event) => {
    this.props.getJobDetailsAction(event.id);
    this.props.setCurrentEvent(event);
    this.props.toggleEditJobModal(true);
  };

  newEvent = (schedulerData, slotId, slotName, start, end, type, item) => {
    saveEvent(EVENTS.JOB_LINK_DAY_DRAG_AND_DROP_A_JOB, {});
    if (!item) return false;
    const newEvent = {
      id: item.id,
      title: item.name,
      start,
      end,
      resourceId: slotId,
      startResizable: true,
      endResizable: true,
      isNew: true,
      save: true,
    };
    this.props.setCurrentEvent(newEvent);
    this.props.getJobDetailsAction(item.id);
    this.props.toggleEditJobModal(true);
  };

  moveEvent = (schedulerData, event, slotId, slotName, start, end, type, item) => {
    this.props.getJobDetailsAction(event.id);
    this.props.setCurrentEvent({ ...event, start, save: true, isNew: false, resourceId: slotId });
    this.props.toggleEditJobModal(true);
  };

  onScrollRight = (schedulerData, schedulerContent, maxScrollLeft) => {
    if (schedulerData.ViewTypes === ViewTypes.Day) {
      schedulerData.next();
      schedulerData.setEvents(this.getEvents());
      this.setState({
        viewModel: schedulerData,
      });

      schedulerContent.scrollLeft = maxScrollLeft - 10;
    }
  };

  onScrollLeft = (schedulerData, schedulerContent) => {
    if (schedulerData.ViewTypes === ViewTypes.Day) {
      schedulerData.prev();
      schedulerData.setEvents(this.getEvents());
      this.setState({
        viewModel: schedulerData,
      });

      schedulerContent.scrollLeft = 10;
    }
  };
}

Basic.propTypes = {
  setSchedulerData: PropTypes.func,
  setNewEvent: PropTypes.func,
  setTaskDndSource: PropTypes.func,
  toggleEditJobModal: PropTypes.func,
  getJobDetailsAction: PropTypes.func,
  getTeamListWithFiltersAction: PropTypes.func,
  teamListDetails: PropTypes.object,
  setCurrentEvent: PropTypes.func,
  viewType: PropTypes.number,
  onRef: PropTypes.func,
  setCalenderViewData: PropTypes.func,
  calenderViewData: PropTypes.object,
  jobList: PropTypes.object,
  assignedJob: PropTypes.object,
  getJobListAction: PropTypes.func,
  resourceReportCategory: PropTypes.array,
  updateStatusAction: PropTypes.func,
  isStatusUpdated: PropTypes.bool,
  statusUpdateError: PropTypes.string,
  reportCategories: PropTypes.array,
  setResourceReportCategory: PropTypes.func,
  isStatusUpdateFetching: PropTypes.bool,
  search: PropTypes.string,
  isSearch: PropTypes.bool,
  handleOnSearch: PropTypes.func,
};

const mapStateToProps = (store) => ({
  teamListDetails: checkPermission(
    subPermissions.scheduleOthersJobs,
    permissions.jobLink
  )
    ? store.jobListDetails.teamListWithFilters
    : checkPermission(subPermissions.scheduleMyJobs, permissions.jobLink)
    ? {
        ...store.jobListDetails.teamListWithFilters,
        pages: 1,
        totalListCount: 1,
        teamList: store.jobListDetails.teamListWithFilters.teamList.filter(
          (item) => item._id === store.auth.login.user.customerId
        ),
      }
    : store.jobListDetails.teamListWithFilters,
  calenderViewData: store.jobListDetails.calenderViewData,
  jobList: store.jobListDetails.jobList,
  assignedJob: store.jobListDetails.assignedJob,
  isStatusUpdated: store.updateStatus.isSuccess,
  statusUpdateError: store.updateStatus.error,
  isStatusUpdateFetching: store.updateStatus.isfetching,
  reportCategories: Object.keys(store.reportCategory.reportData),
  auth: store.auth,
});

export default connect(mapStateToProps, {
  getJobDetailsAction,
  getTeamListWithFiltersAction,
  setCalenderViewData,
  getJobListAction,
  updateStatusAction,
})(Basic);
