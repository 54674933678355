import actionConstants from '../constants/ActionConstants';

const initialState = {
  error: '',
  isfetching: false,
  isSuccess: false,
};

const createJob = (state = initialState, action) => {
  switch (action.type) {
    case actionConstants.CREATE_JOB_REQUEST:
      return {
        ...state,
        isfetching: true,
      };
    case actionConstants.CREATE_JOB_SUCCESS:
      return {
        ...state,
        isfetching: false,
        isSuccess: true,
      };
    case actionConstants.CREATE_JOB_FAILURE:
      return {
        ...state,
        error: action.message,
      };
    case actionConstants.CLEAR_CREATE_JOB:
      return {
        ...initialState,
      };
    default:
      return {
        ...state,
      };
  }
};

export default createJob;
