import ActionConstants from '../constants/ActionConstants';
import { accountTypes } from '../../utils/Constants';

const initialState = {
  selectedCustomerBusinessName: '',
  type: '',
  selectedCustomerDetails: {
    Master: null,
    Sub: null,
    Building: null,
  },
};

const getDetails = (businessName, type, prevDetails) => {
  let newDetails = { ...initialState.selectedCustomerDetails };
  if (type && type.toUpperCase() === accountTypes[0]) {
    newDetails = {
      Master: businessName,
      Sub: null,
      Building: null,
    };
  } else if (type && type.toUpperCase() === accountTypes[1]) {
    newDetails = {
      Master: prevDetails.Master,
      Sub: businessName,
      Building: null,
    };
  } else if (type && type.toUpperCase() === accountTypes[2]) {
    newDetails = {
      Master: prevDetails.Master,
      Sub: prevDetails.Sub,
      Building: businessName,
    };
  }
  return newDetails;
};

export default (state = initialState, action) => {
  const newBusinessName = action.data ? action.data.buisnessName : '';
  const newType = action.data ? action.data.type : '';
  const newDetails = getDetails(
    newBusinessName,
    newType,
    state.selectedCustomerDetails
  );
  if (action.type === ActionConstants.SET_SELECTED_CUSTOMER_BREADCRUM) {
    return {
      ...state,
      selectedCustomerBusinessName: state.selectedCustomerBusinessName.concat(
        action.data.type,
        '-',
        action.data.buisnessName,
        ' / '
      ),
      selectedCustomerDetails: { ...newDetails },
      isBreadCrumVisible: action.data.isBreadCrumVisible,
    };
  }
  if (action.type === ActionConstants.RESET_SELECTED_CUSTOMER_BREADCRUM) {
    return {
      ...state,
      selectedCustomerBusinessName: '',
      isBreadCrumVisible: false,
    };
  }
  if (action.type === ActionConstants.GET_CLIENT_OR_OWNER_DETAILS_SUCCESS) {
    const {
      businessName,
      type,
      masterBusinessName: Master,
      subBusinessName: Sub,
    } = action.payload;
    const newDetails = getDetails(businessName, type, { Master, Sub });
    return {
      ...state,
      selectedCustomerDetails: newDetails,
    };
  }

  // if (action.type === ActionConstants.RESET_SELECTED_CUSTOMER) {
  //   return {
  //     ...state,
  //     selectedCustomerBusinessName: '',
  //     isBreadCrumVisible: false,
  //   };
  // }

  if (action.type === ActionConstants.RESET_ALL_REDUCERS) {
    return {
      ...initialState,
    };
  }

  return state;
};
