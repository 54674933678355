import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import ProfilePhoto from './ProfilePhoto';

const PeopleListHeader = ({
  showChatModal,
  isGroupChatEnabled,
  showGroupChats,
}) => (
  <Fragment>
    <div className="chat-list-btn">
      <button
        type="button"
        onClick={() => showGroupChats(false)}
        className={`btn individuals ${!isGroupChatEnabled ? 'active' : ''}`}
      >
        <span className="btn-icon-wrap">
          <span className="icon sprite-icon1 individuals"></span>
          <FormattedMessage id={'Individuals'} defaultMessage={'Individuals'} />
        </span>
      </button>
      <button
        type="button"
        onClick={() => showGroupChats(true)}
        className={`btn groupchat ${isGroupChatEnabled ? 'active' : ''}`}
      >
        <span className="btn-icon-wrap">
          <span className="icon sprite-icon1 groupchat"></span>
          <FormattedMessage id={'Group Chats'} defaultMessage={'Group Chats'} />
        </span>
      </button>
    </div>
    <div className="start-new-chat" onClick={showChatModal}>
      <div className="new-chat sprite-icon1"></div>
      <p>
        <FormattedMessage id={'Start New '} defaultMessage={'Start New '} />
        {isGroupChatEnabled ? (
          <FormattedMessage id={'Group '} defaultMessage={'Group '} />
        ) : null}
        <FormattedMessage id={'Chat'} defaultMessage={'Chat'} />
      </p>
    </div>
  </Fragment>
);

PeopleListHeader.propTypes = {
  isGroupChatEnabled: PropTypes.bool.isRequired,
  showChatModal: PropTypes.func.isRequired,
  showGroupChats: PropTypes.func.isRequired,
};

const PeopleListItem = ({
  senderName,
  lastMsgContent,
  lastMsgTime,
  unreadCount,
  chatId,
  activeChatId,
  onClick,
}) => {
  const isActive = activeChatId === chatId;
  const isHighlighted = !isActive && !!unreadCount;
  let className = 'clearfix';
  if (isActive) {
    className = `${className} active`;
  }
  if (isHighlighted) {
    className = `${className} notification`;
  }
  return (
    <li className={className} onClick={onClick}>
      <ProfilePhoto label={senderName} />
      {isHighlighted && <div className="notify">{unreadCount}</div>}
      <div className="about">
        <div className="name">{senderName}</div>
        <div className="status">
          <p>{lastMsgContent}</p>
        </div>
      </div>
      <p className="time">{lastMsgTime}</p>
    </li>
  );
};

PeopleListItem.propTypes = {
  activeChatId: PropTypes.string.isRequired,
  chatId: PropTypes.string.isRequired,
  lastMsgContent: PropTypes.string.isRequired,
  lastMsgTime: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  senderName: PropTypes.string.isRequired,
  unreadCount: PropTypes.number.isRequired,
};

const PeopleList = ({
  peopleList,
  activeChatId,
  setActiveChatId,
  showChatModal,
  showGroupChats,
  isGroupChatEnabled,
}) => (
  <div
    className={`people-list ${activeChatId ? 'people-list-responsive' : ''}`}
    id="people-list"
  >
    <PeopleListHeader
      showChatModal={showChatModal}
      showGroupChats={showGroupChats}
      isGroupChatEnabled={isGroupChatEnabled}
    />
    <ul className="list">
      {peopleList.map((item) => {
        if (
          (isGroupChatEnabled && item.isGroup) ||
          (!isGroupChatEnabled && !item.isGroup)
        ) {
          return (
            <PeopleListItem
              onClick={() => setActiveChatId({ chatId: item.chatId })}
              activeChatId={activeChatId}
              key={item.chatId}
              {...item}
            />
          );
        }
        return null;
      })}
    </ul>
  </div>
);

PeopleList.propTypes = {
  activeChatId: PropTypes.string.isRequired,
  isGroupChatEnabled: PropTypes.bool.isRequired,
  peopleList: PropTypes.array.isRequired,
  setActiveChatId: PropTypes.func.isRequired,
  showChatModal: PropTypes.func.isRequired,
  showGroupChats: PropTypes.func.isRequired,
};

export default PeopleList;
