import React, { useState } from 'react';
import { useIndexedDB } from 'react-indexed-db';
import { OfflineModeContext } from '../../libs/constants/offlineEnhanced';
import Button from '../../components/Button';
import Loader from '../../components/Loader';
import DeleteConfirmationModal from '../../components/DeleteConfirmationModal';
import { toolTips } from '../../utils/toolTips';
import OfflineModeBody from './components/OfflineModeBody/OfflineModeBody';
import OfflineModeModals from './components/OfflineModeModals';
import OfflineModeHeader from './components/OfflineModeHeader';

const OfflineEnhancedLayout = () => {
  const [isResetModalVisible, toggleResetModalVisibility] = useState(false);

  const clearDB = () => {
    const db = useIndexedDB('Signatures');
    db.clear();
  };

  return (
    <OfflineModeContext.Consumer>
      {(value) => {
        const {
          customerListFetchStatus,
          jobsListFetchStatus,
          teamList,
          reportTemplateList,
          deviceList,
          innerDeviceList,
          partList,
          reportListByJobIds,
          reportListByCustomer,
          uploadSignature,
          questionJsonDefaultReports,
        } = value.offlineMode;
        const { employeeDetails } = value;
        const { reportUpdate } = value;
        let signUrl = null;
        if (employeeDetails && employeeDetails.inspectorSignature && employeeDetails.inspectorSignature.url) {
          signUrl = employeeDetails.inspectorSignature.url;
        }
        return (
          <div>
            {(customerListFetchStatus.isFetching ||
              jobsListFetchStatus.isFetching ||
              teamList.isFetching ||
              reportTemplateList.isFetching ||
              deviceList.isFetching ||
              innerDeviceList.isFetching ||
              partList.isFetching ||
              reportListByJobIds.isFetching ||
              reportListByCustomer.isFetching ||
              reportUpdate.isfetching ||
              questionJsonDefaultReports.isFetching ||
              (uploadSignature && uploadSignature.isFetching)) && <Loader />}
            <OfflineModeModals />
            {isResetModalVisible && (
              <DeleteConfirmationModal
                deleteMessage="Are you sure you want to reset offline data?"
                deleteButtonText="Reset"
                headingMessage="Confirm Reset"
                handleClickDelete={() => {
                  value.resetOfflineReducer();
                  toggleResetModalVisibility(false);
                  clearDB();
                }}
                handleClickCross={() => toggleResetModalVisibility(false)}
                handleClickCancel={() => toggleResetModalVisibility(false)}
              />
            )}
            <div>
              <Button
                className="mr-bt-20"
                text="Reset"
                color="#6AA14E"
                onBtnClick={() => toggleResetModalVisibility(true)}
                dataFor="offline-reset-button"
                dataTip={toolTips.offline.reset}
                place="right"
              />
            </div>
            <div className="card">
              <OfflineModeHeader />
              <OfflineModeBody />
            </div>
            <img
              style={{ height: '0px' }}
              src={signUrl}
            />
          </div>
        );
      }}
    </OfflineModeContext.Consumer>
  );
};

export default OfflineEnhancedLayout;
