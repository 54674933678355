import clsx from 'clsx';
import React from 'react'

const TextInput = ({
  prefix,
  maxLength,
  onChange = () => {},
  autoComplete = 'nope',
  inputStyle,
  ...fieldProps
}) => {

  const inputClass = clsx(
    `py-2 px-3 bg-transparent placeholder-gray-400 appearance-none w-full block focus:outline-none`,
    prefix ? 'pl-9' : null
  )

  // const handleChange = (e) => {
  //   if(maxLength != undefined) {
  //     e.target.value = e.target.value.slice(0, maxLength)
  //   }
  //   onChange({ target: e.target.value })
  // }

  return (
    <div className="report-form-control" >
      {Boolean(prefix) && (
        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
          {prefix}
        </div>
      )}
      <input
        className={inputClass}
        onChange={onChange}
        autoComplete={autoComplete}
        style={inputStyle}
        {...fieldProps}
      />
    </div>
  )
};

export default TextInput;