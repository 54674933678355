import React from 'react';
import ReactDOM from 'react-dom';
import { IntlProvider, FormattedMessage } from 'react-intl';
import Modal from '../Modal';
import Button from '../Button';
import messages from '../../i18n/messages';

/**
 * Modal for confirming if the user really wants to switch route without saving data
 * @param {string} message
 * @param {function} callback
 */
const UserConfirmation = (message, callback, lang, metaData) => {
  const container = document.createElement('div');
  container.setAttribute('custom-confirmation-navigation', '');
  document.body.appendChild(container);

  const closeModal = (callbackState) => {
    ReactDOM.unmountComponentAtNode(container);
    callback(callbackState);
  };

  ReactDOM.render(
    <IntlProvider locale={lang} messages={messages[lang]}>
      <Modal
        modalHeading="User Confirmation"
        ModalBody={(props) => (
          <div>
            {metaData && metaData.extraData ? (
              <FormattedMessage
                id="There are unsaved changes that will be lost. Are you sure you want to continue ?"
                defaultMessage="There are unsaved changes that will be lost. Are you sure you want to continue ?"
              />
            ) : (
              <FormattedMessage
                id="Are you sure you want to leave the page? Any changes you made will be lost."
                defaultMessage="Are you sure you want to leave the page? Any changes you made will be lost."
              />
            )}
            <div className="mr-top-14 user-confirmation-modal-body">
              <Button
                text="Cancel"
                onBtnClick={props.onCancel}
                className="user-confirmation-modal-buttons"
                color="#282C37"
              />
              <Button
                text="Continue"
                onBtnClick={props.onContinue}
                className="user-confirmation-modal-buttons"
                color="#b71b1b"
              />
            </div>
          </div>
        )}
        ModalBodyProps={{
          onCancel: () => closeModal(false),
          onContinue: () => closeModal(true),
        }}
        isCrossVisible={false}
      />
    </IntlProvider>,
    container
  );
};

export default UserConfirmation;
