import actionConstants from '../constants/ActionConstants';

const initialState = {
  error: '',
  isfetching: false,
  success: '',
  clientData: [],
  pages: 0,
  total: 0,
  currentPage: 1,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionConstants.GET_CLIENT_LIST_REQUEST:
      return {
        ...state,
        isfetching: true,
        success: '',
        error: '',
      };
    case actionConstants.GET_CLIENT_LIST_SUCCESS:
      return {
        ...state,
        clientData:
          action.payload.page === 1
            ? action.payload.docs
            : state.currentPage === action.payload.page
            ? [...state.clientData]
            : [...state.clientData, ...action.payload.docs],
        isfetching: false,
        success: 'client list fetched successfully!',
        pages: action.payload.pages,
        total: action.payload.total,
        currentPage: action.payload.page,
      };
    case actionConstants.GET_CLIENT_LIST_FAILURE:
      return {
        ...state,
        error: action.message,
        isfetching: false,
        success: '',
      };
    case actionConstants.CLEAR_STATE:
      return {
        ...state,
        error: '',
        success: '',
        total: 0,
        currentPage: 1,
      };
    case actionConstants.RESET_ALL_REDUCERS:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};
