import React, { useEffect, useRef, useState } from 'react'

export default function Dropdown({
  overlay,
  children,
  placement = 'right',
  onOpenChange,
  className = '',
  openOnHover,
  open: initialOpen = false,
  preventClosingOnLeave = false,
  offset = 0,
  disabled = false,
  ...props
}) {

  const [open, setOpen] = useState(initialOpen)
  useEffect(() => setOpen(initialOpen), [initialOpen])

  const dropdownRef = useRef(null)
  const mouseoutTimeout = useRef()
  const eventTimeout = useRef(null)

  const updateOpen = (value = false) => {
    if(eventTimeout.current) return
    setOpen(value)
    onOpenChange && onOpenChange(value)
  }

  const handleMouseEnter = e => {
    clearTimeout(mouseoutTimeout.current) // prevent closing
    openOnHover && updateOpen(true)
  }
  
  const handleMouseLeave = e => {
    if(!preventClosingOnLeave) {
      // @ts-ignore - close only if the user has left the dropdown for 150ms 
      mouseoutTimeout.current = setTimeout(updateOpen, 150)
    }
  }

  const setListeners = (target, action) => {
    target[action]('mouseenter', handleMouseEnter)
    target[action]('mouseleave', handleMouseLeave)
  }

  useEffect(() => {
    const dropdownEl = dropdownRef.current
    setListeners(dropdownEl, 'addEventListener')
    return () => setListeners(dropdownEl, 'removeEventListener')
  }, [setListeners])

  useEffect(() => {
    function clearEventTimeout() {
      clearTimeout(eventTimeout.current)
      eventTimeout.current = null
    }
    if(!open) {
      clearEventTimeout
    } else {
      eventTimeout.current = setTimeout(clearEventTimeout, 150)
    }
  }, [open])

  const toggle = () => {
    if (disabled) return;
    updateOpen(!open)
  }

  if(typeof children == 'function') {
    return (
      <div ref={dropdownRef}>
        {children(open, toggle)}
      </div>
    )
  }

  return (
    <div
      className={`relative ${className}`}
      ref={dropdownRef}
      {...props}
    >
      <children.type onClick={toggle} {...children.props}/>
      {open && (
        <overlay.type
          {...overlay.props}
          style={{
            ...overlay.style || {},
            position: 'absolute',
            zIndex: 50,
            [placement]: offset,
          }}
        />
      )}
    </div>
  )
}