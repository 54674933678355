import ActionTypes from '../constants/ActionConstants';
import { PART_STATUS, REPORT_CATEGORIES } from '../../utils/Constants';

const INITIAL_API_STATE = {
  isSuccess: false,
  isError: false,
  message: '',
};

const populateInitialValues = (filtersLabels) => {
  const filters = {};
  const values = Object.values(filtersLabels);
  for (let i = 0; i < values.length; i += 1) {
    Object.assign(filters, { [values[i]]: false });
  }
  return filters;
};

const INITIAL_FILTER_STATE = {
  expandedIndex: -1,
  statusFilters: {
    ...populateInitialValues(PART_STATUS),
  },
  reportFilters: {
    ...populateInitialValues(REPORT_CATEGORIES),
  },
};

const INITIAL_STATE = {
  isFetching: false,
  apiState: {
    ...INITIAL_API_STATE,
  },
  filterState: {
    ...INITIAL_FILTER_STATE,
  },
  searchText: '',
  isCrossVisible: false,
  reportType: '',
  data: [],
  pages: 0,
  page: 0,
  totalCount: 0,
  refresh: false,
};

const partsListStore = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ActionTypes.INVENTORY_GET_PARTS_LIST_SUCCESS:
      if (action.extra.isPaginatedRequest) {
        return {
          ...state,
          apiState: {
            ...state.apiState,
            isSuccess: true,
          },
          data:
            action.payload && action.payload.docs && state.data
              ? [...state.data, ...action.payload.docs]
              : state.data,
          page: action.payload.page,
          pages: action.payload.pages,
          isFetching: false,
        };
      }
      return {
        ...state,
        apiState: {
          ...state.apiState,
          isSuccess: true,
        },
        data: action.payload.docs,
        totalCount: action.payload.total,
        pages: action.payload.pages,
        page: action.payload.page,
        isFetching: false,
      };
    case ActionTypes.INVENTORY_GET_PARTS_LIST_FAILURE:
      return {
        ...state,
        apiState: {
          ...state.apiState,
          isError: true,
          message: action.message,
        },
        isFetching: false,
      };
    case ActionTypes.INVENTORY_PARTS_RELOAD:
      return {
        ...state,
        refresh: action.payload.value,
      };
    case ActionTypes.INVENTORY_GET_PARTS_LIST_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case ActionTypes.INVENTORY_PARTS_SEARCH_FIELD_UPDATED:
      return {
        ...state,
        isCrossVisible: action.payload !== '',
        searchText: action.payload,
      };
    case ActionTypes.INVENTORY_PARTS_FILTER_HEAD_PRESS:
      return {
        ...state,
        filterState: {
          ...state.filterState,
          expandedIndex:
            action.payload === state.filterState.expandedIndex
              ? -1
              : action.payload,
        },
      };
    case ActionTypes.INVENTORY_PARTS_TOGGLE_FILTER:
      return {
        ...state,
        filterState: {
          ...state.filterState,
          ...action.payload,
        },
      };
    case ActionTypes.CLEAR_STATE:
    case ActionTypes.RESET_ALL_REDUCERS:
      return {
        ...INITIAL_STATE,
      };
    default:
      return state;
  }
};

export default partsListStore;
