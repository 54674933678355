import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Scrollbar from 'react-custom-scrollbars';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import endpoints from '../../routes';
import { setSubMenuVisibility } from '../../redux/actions/setSelectedCustomer';
import './BuildingInfoLayout.scss';
import SetIsPromptAction from '../../redux/actions/isPromptAction';
import NotesSections from './NotesSection';

export const BuildingInfoLayout = (props) => {
  const { notes, handleClickSave, isFetchingSystemInfo, isRenderedInReports } =
    props;
  return (
    <div className="classToAddCustomScroll card-in-building-info">
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center top-search-feild">
        <h1 className={isRenderedInReports && 'mr-lt-20-px'}>
          {props.reportGridShow && (
            <Link
              to={endpoints.reportClientDetail}
              onClick={() => props.setSubMenuVisibility(false)}
            >
              <span className="back-arrow sprite-icon" />{' '}
            </Link>
          )}
          <FormattedMessage
            id="Building Information"
            defaultMessage="Building Information"
          />
        </h1>
      </div>
      <div className="card">
        <div className="card-header">
          <FormattedMessage
            id="Building Information"
            defaultMessage="Building Information"
          />
        </div>
        {isFetchingSystemInfo ? (
          <div className="loader-container">
            <div className="loader"></div>
          </div>
        ) : (
          <Scrollbar>
            <div className="card-body">
              <NotesSections
                notes={notes}
                handleClickSave={handleClickSave}
                SetIsPromptAction={props.SetIsPromptAction}
                isPrompt={props.isPrompt}
              />
            </div>
          </Scrollbar>
        )}
      </div>
    </div>
  );
};

BuildingInfoLayout.propTypes = {
  notes: PropTypes.array,
  handleClickSave: PropTypes.func,
  isFetchingSystemInfo: PropTypes.bool,
  setSubMenuVisibility: PropTypes.func,
  reportGridShow: PropTypes.bool,
  SetIsPromptAction: PropTypes.func,
  isPrompt: PropTypes.bool,
  isRenderedInReports: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  reportGridShow: state.setSelectedCustomer.reportLogoList,
  isPrompt: state.isPromptReducer.isPrompt,
});

export default connect(mapStateToProps, {
  setSubMenuVisibility,
  SetIsPromptAction,
})(BuildingInfoLayout);
