export default {
  shouldBeNumber: 'Should Be a Number',
  stringDefaultMsg: 'Should be a string',
  emailType: 'Must be an email type',
  Title: 'Title is required',
  Email: 'Email is required',
  Description: 'Description is required',
  businessName: 'Business Name is required',
  address1: 'Address 1 is required',
  quantityPos: 'Quantity should be greater than 0',
  address2: 'Address 2 should be less than 50 characters',
  city: 'City is required with maximum 20 characters',
  state: 'State is required',
  country: 'Country is required with maximum 20 characters',
  zipCode: 'Zipcode is required',
  contactName: 'Please enter valid contact name',
  email: 'Email is required',
  billaryHourRate: 'Billable Hourly Rate is required',
  position: 'Position is required',
  cellPhone: 'Please enter valid Cell Phone',
  officePhone: 'Enter valid Office Phone number',
  contactFaxNumber: 'Enter valid contact fax number',
  firstName: 'First Name is required',
  lastName: 'Last Name is required',
  contactNo: 'Contact number is required',
  contactNoLength: 'Please enter correct contact number',
  password: 'Password is required',
  quantity: 'Please select a quantity',
  type: 'Please select a type',
  fill: 'Please fill all the details',
  partDetails: 'Please add parts for assignation to a vehicle',
  details: 'Please add tools for assignation to a vehicle',
  systemDeviceType: 'Please select a System/Device type',
  commentValue: 'Required Field',
  image: 'Required Field',
  required: 'Required Field',
  division: 'Division is required',
  reportType: 'Report Type is required',
  listName: 'List Name is required',
  deviceType: 'Device Type is required field',
  floor: 'Floor is required field',
  location: 'Location is required field',
  otherDeviceType: 'Other is required field',
  make: 'Make is required field',
  model: 'Model is required field',
  oldPassword: 'Old password is required',
  newPassword: 'New password is required',
  Required: 'Required',
  confirmPassword: 'Password does not match',
  fnameCharacterValidation: 'Please enter valid First Name',
  lnameCharacterValidation: 'Please enter valid Last Name',
  businessNameValidation: 'Please enter valid Buisness Name',
  SourceOfGetIn: 'How did you find us is required',
  emailSpacing: 'Please enter valid email',
  passwordLength: 'Must be equal or more than 8 characters',
  citySignUp: 'Please enter valid city',
  citySignUpRequired: 'City is required',
  stateSignUpRequired: 'State is required',
  stateSignUp: 'Please enter valid state',
  zipCodeLength: 'Please enter valid zip code',
  imageName: 'Please upload image',
  BrowserName: 'Browser Name is required',
  BrowserVersion: 'Browser Version is required',
  BrowserOs: 'System Os is required',
  terms: 'Terms is required',
  requiredField: 'Required Field',
  quantityLength: 'Quantity should be less than 1000000000000000',
  updateHrs: 'Invalid hours',
  updateMins: 'Invalid minutes',
  quantityMinLength: 'Min value should be 1',
  minimumValueNumber: 'Minimum value should be 0',
  maximumValueNumber: 'Maximum value should be less than 1000000000000000',
  validNote: 'Enter valid note',
  note: 'Note cannot be empty',
  validateOldPassword: 'Please enter correct password',
  validateNewPassword: 'Password should contain minimum 8 characters.',
  confirmPasswordRequired: 'Confirm Password is required',
  validContactNo: 'Please enter valid contact number',
  validZipCode: 'Please enter valid zip code',
  validQuantity: 'Please enter valid quantity',
  newPasswordLength: 'Must be minimum 8 characters',
  billaryHourRatePositive: 'Billable hour rate should be positive number',
  frequency: 'Frequency is required',
  parity: 'Parity is required',
  year: 'Year is required',
  months: 'Month is required',
  number: 'Should be a number',
  percentage: 'Should not exceed 100',
  toolName: 'Tool Name is required',
  idNumber: 'Id Number is required',
  quantityWareHouse: 'Quantity is required',
  sheduleDate: 'Selection of date is required',
  sheduleMember: 'Selection of team member is required',
  sheduleJobs: 'Selection of jobs is required',
  noOfSheduleJobs: 'Minimum 2 jobs are required to select.',
  sheduleStartLocation: 'Selection of starting location is required',
  minimumPriceLevel: 'Minimum possible value 0.01',
  invalidUrl: 'Please enter a valid URL',
  price: 'Price is required',
  excelFileRequired: 'Excel file is required',
  quantityRequired: 'Quantity is required',
  quantityPositive: 'Quantity must be a positive number',
  validPrice: 'Please enter a valid price',
};
