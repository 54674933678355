import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import ProfilePhoto from './ProfilePhoto';

export const MESSAGE_TYPES = {
  receivedText: 'receivedText',
  sentText: 'sentText',
  system: 'system',
};

export const MESSAGE_STATUS = {
  seen: 'seen',
  sent: 'sent',
  delivered: 'delivered',
  pending: 'pending',
  undelivered: 'undelivered',
};

const MessageStatus = ({ msgStatus }) => {
  let status = '';
  switch (msgStatus) {
    case MESSAGE_STATUS.sent:
      status = '✓';
      break;
    case MESSAGE_STATUS.delivered:
      status = '✓✓';
      break;
    case MESSAGE_STATUS.seen:
      status = 'seen';
      break;
    default:
      status = '🕓';
  }
  return <span className="chat-message-status">{status}</span>;
};

MessageStatus.propTypes = {
  msgStatus: PropTypes.string.isRequired,
};

const SystemMessage = ({ content }) => (
  <div className="chat-system-message">{content}</div>
);

SystemMessage.propTypes = {
  content: PropTypes.string.isRequired,
};

const SentTextMessage = ({
  content,
  msgTime,
  senderName,
  msgStatus,
  onMessageClick,
}) => (
  <Fragment>
    <li className="clearfix message-sent">
      <div className="message other-message float-right">
        <ProfilePhoto label={senderName} size="30px" />
        {content}
        <div className="message-data1">
          <span className="message-data-time chat-message-status-container">
            <span>{msgTime}</span>
            <MessageStatus msgStatus={msgStatus} />
          </span>
        </div>
      </div>
    </li>
    {msgStatus === MESSAGE_STATUS.undelivered ? (
      <div className="chat-undelivered" onClick={onMessageClick}>
        <span className="chat-undelivered-text">
          <FormattedMessage
            id={'This message might not be delivered.\nClick to send again.'}
            defaultMessage={
              'This message might not be delivered.\nClick to send again.'
            }
          />
        </span>
      </div>
    ) : null}
  </Fragment>
);

SentTextMessage.propTypes = {
  content: PropTypes.string.isRequired,
  msgStatus: PropTypes.string.isRequired,
  msgTime: PropTypes.string.isRequired,
  senderName: PropTypes.string.isRequired,
  onMessageClick: PropTypes.func.isRequired,
};

const ReceivedTextMessage = ({ content, msgTime, senderName }) => (
  <li className="message-received">
    <div className="message my-message">
      <ProfilePhoto label={senderName} size="30px" />
      <p>{content}</p>
    </div>
    <div className="message-data">
      <span className="message-data-time">{msgTime}</span>
    </div>
  </li>
);

ReceivedTextMessage.propTypes = {
  content: PropTypes.string.isRequired,
  msgTime: PropTypes.string.isRequired,
  senderName: PropTypes.string.isRequired,
};

const MessageBox = ({ type, ...restProps }) => {
  switch (type) {
    case MESSAGE_TYPES.receivedText:
      return <ReceivedTextMessage {...restProps} />;
    case MESSAGE_TYPES.sentText:
      return <SentTextMessage {...restProps} />;
    case MESSAGE_TYPES.system:
      return <SystemMessage {...restProps} />;
    default:
      return null;
  }
};

export default MessageBox;
