import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Table from '../../components/ReusableTable';
import TaskHeaders from '../../components/TaskHeader/TaskHeader';
import MissedInspectionRow from './MissedInspectionRow';
import TaskListRowMobile from '../TaskListLayout/TaskListRowMobile';

const rowToRender = document.body.offsetWidth > 767 ? MissedInspectionRow : TaskListRowMobile;

const headerFields = [
  {
    title: 'Job Id',
    isSortable: false,
    width: '9%',
  },
  {
    title: 'Name',
    isSortable: true,
    width: '14%',
  },
  {
    title: 'Report Category',
    isSortable: false,
    width: '19%',
  },
  {
    title: 'Frequency',
    isSortable: false,
    width: '16%',
  },
  {
    title: 'Date',
    isSortable: true,
    width: '16%',
  },
  {
    title: 'Type',
    isSortable: false,
    width: '12%',
  },
  {
    title: 'Action',
    isSortable: false,
    width: '14%',
  },
];

const _TableHeadings = JSON.parse(JSON.stringify(headerFields));
_TableHeadings[headerFields.length - 1].title = ' ';

const MissedInspectionLayout = ({
  setSearchText,
  setFilters,
  isCrossVisible,
  handleSearch,
  status,
  reportType,
  filter,
  setDatePeriod,
  setReportType,
  sortData,
  jobList,
  loadMore,
  isfetching,
  hasMoreItems,
  endMessage,
  isEndMessageVisible,
  isClassVisible,
  setClassVisibility,
  handleStartJob,
  reportCategories,
  reportCategory,
  showTaskModdal,
  jobsDoneManually,
  completeJobManually,
  isReportNeeded,
  printPage,
}) => {
  const [openJob, setOpenJob] = useState(null);
  return (
  <React.Fragment>
    <TaskHeaders
      name="Jobs Missed"
      setSearchText={setSearchText}
      isCrossVisible={isCrossVisible}
      setFilters={setFilters}
      handleSearch={handleSearch}
      reportCategory={reportCategory}
      status={status}
      hideFilter={false}
      reportType={reportType}
      filter={filter}
      printPage={printPage}
      setReportType={setReportType}
      maxDate={moment(new Date())}
      reportCategories={reportCategories}
    />

    <Table
      sortData={sortData}
      headerData={isReportNeeded === false ? _TableHeadings : headerFields}
      bodyData={jobList}
      RowToRender={rowToRender}
      loadMore={loadMore}
      isLoaderVisible={isfetching}
      rowClassName="jobMissedTable"
      hasMoreItems={hasMoreItems}
      endMessage={endMessage}
      isEndMessageVisible={isEndMessageVisible}
      jobTable={true}
      firstChildStyle={{ textAlign: 'center' }}
      RowToRenderProps={{
        isClassVisible,
        setClassVisibility,
        handleStartJob,
        showTaskModdal,
        jobsDoneManually,
        completeJobManually,
        isReportNeeded,
        openJob,
        setOpenJob,
      }}
    />
  </React.Fragment>
)};

MissedInspectionLayout.propTypes = {
  setSearchText: PropTypes.func,
  setFilters: PropTypes.func,
  handleSearch: PropTypes.func,
  status: PropTypes.array,
  reportType: PropTypes.string,
  filter: PropTypes.array,
  setDatePeriod: PropTypes.func,
  setReportType: PropTypes.func,
  sortData: PropTypes.func,
  jobList: PropTypes.array,
  loadMore: PropTypes.func,
  isfetching: PropTypes.bool,
  hasMoreItems: PropTypes.bool,
  endMessage: PropTypes.string,
  isEndMessageVisible: PropTypes.bool,
  isCrossVisible: PropTypes.bool,
  isClassVisible: PropTypes.object,
  setClassVisibility: PropTypes.func,
  reportCategories: PropTypes.object,
  reportCategory: PropTypes.any,
  handleStartJob: PropTypes.func,
  showTaskModdal: PropTypes.func,
  jobsDoneManually: PropTypes.array,
  completeJobManually: PropTypes.func,
  isReportNeeded: PropTypes.any,
};
export default MissedInspectionLayout;
