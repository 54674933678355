import { combineReducers } from 'redux';
import auth from './auth';
import dashboardState from './dashboard';
import addEmployee from './addEmployee';
import showTeamList from './showTeamList';
import editTeamMember from './editTeamMember';
import QrResponse from './QrCodeResponse';
import quickBookAuth from './quickBookAuth';
import ViewReports from './ViewReports';
import locale from './locale';
import bugTicktes from './bugTicktes';
import importDevice from './importDevice';
import getClientList from './getClientList';
import serviceRequestList from './serviceRequestList';
import setSelectedCustomer from './setSelectedCustomer';
import getClientOrOwnerDetails from './getClientOrOwnerDetails';
import setClientOrOwnerDetails from './setClientOrOwnerDetails';
import fetchTeamMember from './fetchTeamMember';
import showTeamSheet from './showTeamSheet';
import deleteEmployee from './deleteEmployee';
import updateStatus from './updateStatus';
import jobs from './jobs';
import subscriptionPlan from './subscriptionPlan';
import createUserCard from './createUserCard';
import createPlan from './createPlan';
import SectionStore from './videoSection';
import clientDeviceList from './showClientDeviceList';
import addClientDevice from './addClientDevice';
import getTransactionsList from './getTransactionList';
import signUpStatus from './signUp';
import updateHours from './updateHours';
import emailListForm from './emailListForm';
import companyLogo from './companyLogo';
import getSystemInfo from './getSystemInfo';
import addSystemOrDevice from './addSystemOrDevice';
import deleteSystemOrDevice from './deleteSystemOrDevice';
import uploadImage from './uploadImage';
import addImageDetails from './addImageDetailsBuildingInfo';
import imageDescription from './imageDescriptionBuildingInfo';
import deleteImage from './deleteImage';
import addNotes from './addNotes';
import addCustomer from './addCustomer';
import updateClient from './updateClient';
import sendEmail from './forgetPassword';
import customerDetails from './customerDetails';
import jobDetails from './jobDetails';
import getJobsList from './getJobList';
import setPermissions from './setPermissions';
import jobListDetails from './jobListDetails';
import reportCategory from './reportCategory';
import reportCreate from './reportCreate';
import addReportCat from './addReportCat';
import reportUpdate from './reportUpdate';
import getReportParts from './getReportParts';
import getTeamGPSData from './getTeamGPSData';
import setEmployeeLocation from './setEmployeeLocation';
import fetchGPSEmployeeDetail from './fetchGPSEmployeeDetail';
import genericPermissions from './genericPermissions';
import breadCums from './breadCums';
import chat from './chat';
import settingsReducer from './settingsReducer';
import addParts from './addParts';
import getReportLabour from './getReportLabour';
import addLabour from './addLabour';
import supplierListStore from './inventoryGetSupplierList';
import uploadPartStore from './uploadParts';
import getSupplierInfo from './getSupplierInfo';
import partsListStore from './inventoryGetPartsList';
import addVehicleStore from './inventoryAddVehicle';
import vehicleDetailsStore from './InventoryVehicleDetails';
import addSupplier from './inventoryAddSupplier';
import inventoryToolList from './getToolListReducer';
import addRecurringInspection from './addRecurringInspections';
import fetchRecurringInspections from './fetchRecurringInspections';
import getDeficiencyList from './getDeficiencyList';
import getReportList from './getReportList';
import deleteReport from './deleteReport';
import getReportDetail from './getReportDetails';
import getDeficiencyDetails from './getDeficiencyDetails';
import createJob from './createJob';
import customerHome from './customerHome';
import getXLXSCount from './getXLXSCount';
import addPartsDetailsStore from './inventoryImportPart';
import assignPartsStore from './InventoryAssignPart';
import priceLevelStore from './inventoryPriceLevel';
import getSupplierDetailsStore from './currentSupplierDetails';
import reportTemplateState from './reportTemplates';
import addToolStore from './addTool';
import getTool from './getTool';
import updateTool from './updateTool';
import vehicleListStore from './vehicleListStore';
import assignToolStore from './assignTool';
import getWayPoints from './getWayPointReducer';
import createAutoRouting from './createAutoRouting';
import deleteClient from './deleteClient';
import deleteImageDetails from './deleteCompanyLogo';
import deleteSupplierStore from './inventoryDeleteSupplier';
import setSelectedCustomerBreadcrum from './setSelectedCustomerBreadcrum';
import vehicleUpdateStore from './vehicleUpdate';
import getOfflineSelectedJobs from './getOfflineSelectedJobs';
import globalOfflineDatabase from './globalOfflineDatabase';
import getReportTemplate from './getReportTemplate';
import isPromptReducer from './isPromptReducer';
import offlineMode from './offlineEnhanced';
import setNotificationCount from './setNotificationCount';
import toolDelete from './toolsDelete';
import updateAnswer from './updateAnswer';
import addPartReducer from './addPart';
import registerDeviceToken from './firebaseToken';
import notifications from './notifications';
import updateNotifications from './updateNotifications';
import logOut from './logout';
import reportPdf from './reportPdf';
import servicesDueDetails from './servicesDue';
import invoiceNumber from './invoiceNumber';
import customReports from './customReports';
import showLoader from './showLoader';

const reducer = combineReducers({
  chat,
  auth,
  jobs,
  ViewReports,
  locale,
  getTool,
  isPromptReducer,
  addParts,
  addNotes,
  sendEmail,
  logOut,
  createJob,
  breadCums,
  addLabour,
  jobDetails,
  bugTicktes,
  createPlan,
  updateTool,
  toolDelete,
  addSupplier,
  uploadImage,
  addEmployee,
  addCustomer,
  getJobsList,
  deleteImage,
  updateHours,
  companyLogo,
  getWayPoints,
  updateStatus,
  SectionStore,
  importDevice,
  signUpStatus,
  updateClient,
  showTeamList,
  reportCreate,
  reportUpdate,
  addReportCat,
  deleteClient,
  customerHome,
  deleteReport,
  quickBookAuth,
  getXLXSCount,
  addToolStore,
  getReportList,
  showTeamSheet,
  emailListForm,
  getSystemInfo,
  getClientList,
  dashboardState,
  partsListStore,
  createUserCard,
  getTransactionsList,
  deleteEmployee,
  editTeamMember,
  jobListDetails,
  reportCategory,
  getReportParts,
  getTeamGPSData,
  fetchTeamMember,
  addClientDevice,
  addImageDetails,
  settingsReducer,
  addVehicleStore,
  getReportLabour,
  assignToolStore,
  priceLevelStore,
  customerDetails,
  getReportDetail,
  getSupplierInfo,
  uploadPartStore,
  clientDeviceList,
  assignPartsStore,
  subscriptionPlan,
  imageDescription,
  vehicleListStore,
  getDeficiencyList,
  supplierListStore,
  inventoryToolList,
  addSystemOrDevice,
  createAutoRouting,
  genericPermissions,
  serviceRequestList,
  deleteImageDetails,
  setNotificationCount,
  vehicleUpdateStore,
  deleteSupplierStore,
  setSelectedCustomer,
  setEmployeeLocation,
  vehicleDetailsStore,
  reportTemplateState,
  deleteSystemOrDevice,
  getDeficiencyDetails,
  addPartsDetailsStore,
  fetchGPSEmployeeDetail,
  globalOfflineDatabase,
  getReportTemplate,
  QrResponse,
  updateAnswer,
  addRecurringInspection,
  getOfflineSelectedJobs,
  getClientOrOwnerDetails,
  setClientOrOwnerDetails,
  getSupplierDetailsStore,
  fetchRecurringInspections,
  setSelectedCustomerBreadcrum,
  clientPermissions: setPermissions,
  offlineMode,
  addPartReducer,
  notifications,
  updateNotifications,
  registerDeviceToken,
  reportPdf,
  customReports,
  servicesDueDetails,
  invoiceNumber,
  showLoader,
});

export default reducer;
