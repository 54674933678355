import { connect } from 'react-redux';
import * as chatActions from '../../redux/actions/chat';
import ChatView from './ChatView';

const mapStateToProps = ({ chat, auth }) => ({
  peopleList: chat.peopleList,
  activeChatId: chat.activeChatId,
  chats: chat.chats,
  isChatLoading: chat.isChatLoading,
  totalChatMessagesCount: chat.totalChatMessagesCount,
  currentUser: auth.login.user,
  isGetChatByIdSuccess: chat.isGetChatByIdSuccess,
  isAllPeopleApiLoading: chat.isAllPeopleApiLoading,
  isAllPeopleApiError: chat.isAllPeopleApiError,
  allPeopleApiError: chat.allPeopleApiError,
  allPeople: chat.allPeople,
  page: chat.page,
  totalAllPeopleCount: chat.totalAllPeopleCount,
  isUpdateChatLoading: chat.isUpdateChatLoading,
  isUpdateChatSuccess: chat.isUpdateChatSuccess,
  isUpdateChatError: chat.isUpdateChatError,
  updateChatError: chat.updateChatError,
  chatRoomDetails: chat.chatRoomDetails,
  isChatRoomDetailsLoading: chat.isChatRoomDetailsLoading,
});

export default connect(mapStateToProps, {
  ...chatActions,
})(ChatView);
