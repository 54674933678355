import actionConstants from '../constants/ActionConstants';

const initialState = {
  error: '',
  isfetching: false,
  successMsg: '',
  isSuccessDelete: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionConstants.DELETE_EMPLOYEE_REQUEST:
      return {
        ...state,
        isfetching: true,
        isSuccessDelete: false,
        successMsg: '',
      };
    case actionConstants.DELETE_EMPLOYEE_SUCCESS:
      return {
        ...state,
        isfetching: false,
        isSuccessDelete: true,
        successMsg: action.payload.message,
      };
    case actionConstants.DELETE_EMPLOYEE_FAILURE:
      return {
        ...state,
        error: action.error,
        isfetching: false,
      };
    case actionConstants.RESET_TEAM_REDUCER:
      return {
        ...state,
        isSuccessDelete: false,
        successMsg: '',
        error: '',
      };
    default:
      return state;
  }
};
