import ActionConstants from '../constants/ActionConstants';

const setSelectedCustomer =
  (customerId, type, buisnessName, showMenu = true) =>
  (dispatch) => {
    dispatch({
      type: ActionConstants.SET_SELECTED_CUSTOMER,
      data: {
        customerId,
        type,
        buisnessName,
        showMenu,
      },
    });
  };

const setSubMenuVisibility = (showMenu) => (dispatch) => {
  dispatch({
    type: ActionConstants.SET_SUBMENU_VISIBILITY,
    data: {
      showMenu,
    },
  });
};

const resetSelectedCustomer = () => (dispatch) => {
  dispatch({
    type: ActionConstants.RESET_SELECTED_CUSTOMER,
  });
};

const resetReportList = () => (dispatch) => {
  dispatch({
    type: ActionConstants.RESET_REPORT_LOGO_LIST,
  });
};

const gridListReport = () => (dispatch) => {
  dispatch({
    type: ActionConstants.RESET_GRID_LIST_REPORT,
  });
};

const setReportName = (reportName) => (dispatch) => {
  dispatch({
    type: ActionConstants.SET_REPORT_NAME_BREADCRUMB,
    payload: reportName,
  });
};

export default setSelectedCustomer;
export {
  resetSelectedCustomer,
  resetReportList,
  gridListReport,
  setSubMenuVisibility,
  setReportName,
};
