import { getToken } from '../_helpers/commonFunctions';
import { store } from '../redux/store/initStore';
import call from './apiRequest';
import apiEndpoints from './apiEndpoints';
import { tz } from 'moment-timezone';

/**
 * Api call to download completed reports pdf
 * @param {object} payload
 */
const downloadReportPdf = (payload) => {
  const state = store.getState();
  const language = state.locale.lang || 'en';
  return call({
    method: 'get',
    endpoint: `${apiEndpoints.downloadReportPdf}/${payload.projectName}`,
    authKey: getToken(),
    query: {
      language,
      timeZone: tz.guess(),
    },
    timeout: 15000,
  });
};

/**
 * Api call to delete reports pdf
 * @param {object} payload
 */
export const deleteReportPdf = (reportId) =>
  call({
    method: 'get',
    endpoint: `${apiEndpoints.deleteReportPdf}/${reportId}`,
    authKey: getToken(),
  });

/**
 * Api call to email reports pdf
 * @param {object} payload
 */
export const emailReportPdf = (reportId, recipients) =>
  call({
    method: 'post',
    endpoint: `${apiEndpoints.emailReportPdf}/${reportId}`,
    payload: {
      recipients,
    },
    authKey: getToken(),
  });

export default downloadReportPdf;
