import actionConstants from '../constants/ActionConstants';

const handlePermissionsUpdate = (newPermissions) => (dispatch) =>
  dispatch({
    type: actionConstants.UPDATE_EMPLOYEE_PERMISSIONS,
    payload: {
      newPermissions: JSON.parse(newPermissions),
    },
  });

const handleSubscriptionsUpdate = (newSubscriptions) => (dispatch) => {
  dispatch({
    type: actionConstants.UPDATE_EMPLOYEE_SUBSCRIPTIONS,
    payload: {
      newSubscriptions: JSON.parse(newSubscriptions),
    },
  });
  dispatch({
    type: actionConstants.FETCH_SUBSCRIPTIONS,
    payload: {
      newSubscriptions: JSON.parse(newSubscriptions),
    },
  });
};

export default handlePermissionsUpdate;
export { handleSubscriptionsUpdate };
