import { CheckBoxOutlineBlankOutlined, CheckBoxOutlined } from '@material-ui/icons';
import React from 'react';

const Checkbox = ({checked, checkedColor = '#B71B1B', unCheckedColor = '#CCC', ...props}) => {
  const Icon = checked
    ? CheckBoxOutlined
    : CheckBoxOutlineBlankOutlined;

  const color =  checked ? checkedColor : unCheckedColor;
  
  return (
    <Icon
      style={{ color }}
      {...props}
    />
  )
}
export default Checkbox