/*eslint-disable*/

export const getSelectionOption = (options) => {
  const selectionOption = options;
  const dropdownArray = [];
  if (selectionOption && selectionOption.length > 0) {
    selectionOption.forEach((item) => {
      dropdownArray.push(item.text);
      return null;
    });
  }
  return dropdownArray;
};

export const findIds = ({ jsonToSearchIn = [], keyToSearch = '' }) => {
  let signatureIds = [];
  for (let jsonIndex = 0; jsonIndex < jsonToSearchIn.length; jsonIndex++) {
    const element = jsonToSearchIn[jsonIndex];
    if (!Array.isArray(element)) {
      continue;
    }
    if (element[0].isVertical) {
      signatureIds = signatureIds.concat(
        findIdsInVeritcalTableWithAType({
          type: keyToSearch,
          jsonToSearchIn: element,
        })
      );
    } else if (element[0].isHorizontal) {
      signatureIds = signatureIds.concat(
        findIdsInHorizontalTableWithAType({
          type: keyToSearch,
          jsonToSearchIn: element,
        })
      );
    }
    const foundChild = element.find(child => child && child.subType === keyToSearch);
    if (foundChild) {
      signatureIds.push({
        questionId: jsonToSearchIn[jsonIndex][0].id, answerId: foundChild.id, isInspectorSignature: (foundChild && foundChild.isInspectorSignature) || false
      });
    }
    element.forEach((child) => {
      if (child && child.child && child.child.length) {
        signatureIds = signatureIds.concat(findIds({
          jsonToSearchIn: child.child,
          keyToSearch,
        }));
      }
    });
  }
  return signatureIds;
};

export const findIdsInVeritcalTableWithAType = ({ type, jsonToSearchIn }) => {
  const ids = [];
  const table = jsonToSearchIn[0];
  for (let i = 1; i < jsonToSearchIn.length; i++) {

    const rows = jsonToSearchIn[i];
    if (!jsonToSearchIn[i] || !Array.isArray(rows)) {
      continue;
    }
    for (let row of rows) {
      const elements = [...row.Q[0], ...row.A[0]];
      for (let element of elements) {
        if (element && element.subType === type) {
          ids.push({
            questionId: null,
            answerId: element.id,
            isInspectorSignature: element.isInspectorSignature || false
          });
        }
      }
    }
  }
  return ids;
};

export const findIdsInHorizontalTableWithAType = ({ type, jsonToSearchIn }) => {
  const ids = [];
  const table = jsonToSearchIn[0];
  for (let i = 1; i < jsonToSearchIn.length; i++) {
    if (!jsonToSearchIn[i]) {
      continue;
    }
    const rows = jsonToSearchIn[i]; // Object { Q, QA }
    rows.Q && rows.Q.forEach(element => {
      if (element.subType === type) {
        ids.push({
          questionId: null,
          answerId: element.id,
          isInspectorSignature: element.isInspectorSignature || false
        });
      }
    });
    rows.QA && rows.QA.forEach(element => {
      if (element.Q && element.Q.subType === type) {
        ids.push({
          questionId: null,
          answerId: element.Q.id,
          isInspectorSignature: element.Q.isInspectorSignature || false

        });
      }
      if (element.A && element.A.subType === type) {
        ids.push({
          questionId: null,
          answerId: element.A.id,
          isInspectorSignature: element.A.isInspectorSignature || false

        });
      }
    });
  }
  return ids;
};

export const findIdsWithMemorizeId = ({ jsonToSearchIn, memorizeId }) => {
  let ids = [];
  for (let jsonIndex = 0; jsonIndex < jsonToSearchIn.length; jsonIndex++) {
    const element = jsonToSearchIn[jsonIndex];
    if (!Array.isArray(element)) {
      continue;
    }
    if (element[0].isVertical) {
      ids = ids.concat(findIdsInVericalTableWithMemorizeId({ jsonToSearchIn: element, memorizeId }));
    } else if (element[0].isHorizontal) {
      ids = ids.concat(findIdsInHorizontalTableWithMemorizeId({ jsonToSearchIn: element, memorizeId }));
    }
    const foundChild = element.find(child => child && child.memorizeGroup === memorizeId);
    if (foundChild) {
      ids.push({ questionId: jsonToSearchIn[jsonIndex].id, answerId: foundChild.id });
    }
    element.forEach((child) => {
      if (child && child.child && child.child.length) {
        ids = ids.concat(
          findIdsWithMemorizeId({
            memorizeId,
            jsonToSearchIn: child.child,
          })
        );
      }
    });
  }
  return ids;
};

export const findIdsInHorizontalTableWithMemorizeId = ({ jsonToSearchIn, memorizeId }) => {
  const ids = [];
  const table = jsonToSearchIn[0];
  for (let i = 1; i < jsonToSearchIn.length; i++) {
    if (!table[i]) {
      continue;
    }
    const rows = table[i]; // Object { Q, QA }
    rows.Q && rows.Q.forEach(element => {
      if (element.memorizeGroup === memorizeId) {
        ids.push({
          questionId: null,
          answerId: element.id,
        });
      }
    });
    rows.QA && rows.QA.forEach(element => {
      if (element.Q && element.Q.memorizeGroup === memorizeId) {
        ids.push({
          questionId: null,
          answerId: element.Q.id,
        });
      }
      if (element.A && element.A.memorizeGroup === memorizeId) {
        ids.push({
          questionId: null,
          answerId: element.A.id,
        });
      }
    });
  }
  return ids;
};

export const findIdsInVericalTableWithMemorizeId = ({ jsonToSearchIn, memorizeId }) => {
  const ids = [];
  const table = jsonToSearchIn[0];
  for (let i = 1; i < jsonToSearchIn.length; i++) {
    if (!table[i]) {
      continue;
    }
    const rows = table[i];
    for (let row of rows) {
      const elements = [...row.Q[0], ...row.A[0]];
      for (let element of elements) {
        if (element && element.memorizeGroup === memorizeId) {
          ids.push({
            questionId: null,
            answerId: element.id,
          });
        }
      }
    }
  }
  return ids;
};

export const findInputsWithGroupName = ({ jsonToSearchIn, groupName }) => {
  let elements = [];
  for (let jsonIndex = 0; jsonIndex < jsonToSearchIn.length; jsonIndex++) {
    const element = jsonToSearchIn[jsonIndex];
    if (!Array.isArray(element)) {
      continue;
    }
    if (element[0].isVertical) {
      elements = elements.concat(findInputsInVericalTableWithGroupName({ jsonToSearchIn: element, groupName }));
      continue;
    } else if (element[0].isHorizontal) {
      elements = elements.concat(findInputsInHorizontalTableWithGroupName({ jsonToSearchIn: element, groupName }));
      continue;
    }
    const foundChild = element.find(child => child && (child.subType === 'checkbox' || child.subType === 'radio') && child.inputGroupName === groupName);
    if (foundChild) {
      elements.push(foundChild);
    }
    element.forEach((child) => {
      if (child && child.child && child.child.length) {
        elements = elements.concat(findInputsWithGroupName({ jsonToSearchIn: child.child, groupName }));
      }
    });
  }
  return elements;
};

export const findInputsInHorizontalTableWithGroupName = ({ jsonToSearchIn, groupName }) => {
  const elements = [];
  const table = jsonToSearchIn[0];
  for (let i = 1; i < jsonToSearchIn.length; i++) {
    if (!table[i]) {
      continue;
    }
    const rows = table[i]; // Object { Q, QA }
    rows.Q && rows.Q.forEach(element => {
      if ((element.subType === 'checkbox' || element.subType === 'radio') && element.inputGroupName === groupName) {
        elements.push(element);
      }
    });
    rows.QA && rows.QA.forEach(element => {
      if (element.Q && element.Q.inputGroupName === groupName && (element.Q.subType === 'checkbox' || element.Q.subType === 'radio')) {
        elements.push(element.Q);
      }
      if (element.A && element.A.inputGroupName === groupName && (element.A.subType === 'checkbox' || element.A.subType === 'radio')) {
        elements.push(element.A);
      }
    });
  }
  return elements;
};

export const findInputsInVericalTableWithGroupName = ({ jsonToSearchIn, groupName }) => {
  const ids = [];
  const table = jsonToSearchIn[0];
  for (let i = 1; i < jsonToSearchIn.length; i++) {
    if (!table[i]) {
      continue;
    }
    const rows = table[i];
    for (let row of rows) {
      const elements = [...row.Q[0], ...row.A[0]];
      for (let element of elements) {
        if (element && element.inputGroupName === groupName) {
          ids.push(element);
        }
      }
    }
  }
  return ids;
};

export const findElementWithId = ({ jsonToSearchIn, id, type }) => {
  let elements = [];
  for (let jsonIndex = 0; jsonIndex < jsonToSearchIn.length; jsonIndex++) {
    const element = jsonToSearchIn[jsonIndex];
    if (!Array.isArray(element)) {
      continue;
    }
    if (element[0].isVertical) {
      elements = elements.concat(findInputsInVericalTableWithId({ jsonToSearchIn: element, id, type }));
      continue;
    } else if (element[0].isHorizontal) {
      elements = elements.concat(findInputsInHorizontalTableWithId({ jsonToSearchIn: element, id, type }));
      continue;
    }
    const foundChild = element.find(child => child && child.id === id && (!type || child.subType === type));
    if (foundChild) {
      elements.push(foundChild);
    }
    element.forEach((child) => {
      if (child && child.child && child.child.length) {
        elements = elements.concat(findElementWithId({ jsonToSearchIn: child.child, id, type }));
      }
    });
  }
  return elements;
};

export const findInputsInVericalTableWithId = ({ jsonToSearchIn, id, type }) => {
  const ids = [];
  const table = jsonToSearchIn[0];
  for (let i = 1; i < jsonToSearchIn.length; i++) {
    if (!table[i]) {
      continue;
    }
    const rows = table[i];
    for (let row of rows) {
      const elements = [...row.Q[0], ...row.A[0]];
      for (let element of elements) {
        if (element && element.id === id && (!type || type === element.subType)) {
          ids.push(element);
        }
      }
    }
  }
  return ids;
};

export const findInputsInHorizontalTableWithId = ({ jsonToSearchIn, id, type }) => {
  const elements = [];
  const table = jsonToSearchIn[0];
  for (let i = 1; i < jsonToSearchIn.length; i++) {
    if (!table[i]) {
      continue;
    }
    const rows = table[i]; // Object { Q, QA }
    rows.Q && rows.Q.forEach(element => {
      if (element.id === id && (!type || element.subType === type)) {
        elements.push(element);
      }
    });
    rows.QA && rows.QA.forEach(element => {
      if (element.Q && element.Q.id === id && (!type || element.Q.subType === type)) {
        elements.push(element.Q);
      }
      if (element.A && element.A.id === id && (!type || element.A.subType === type)) {
        elements.push(element.A);
      }
    });
  }
  return elements;
};

export const findAllIds = ({ jsonToSearchIn }) => {
  let elements = [];
  for (let jsonIndex = 0; jsonIndex < jsonToSearchIn.length; jsonIndex++) {
    const element = jsonToSearchIn[jsonIndex];
    if (!Array.isArray(element)) {
      continue;
    }
    if (element[0].isVertical) {
      elements = elements.concat(findAllIdsInVericalTable({ jsonToSearchIn: element }));
      continue;
    } else if (element[0].isHorizontal) {
      elements = elements.concat(findAllIdsInHorizontalTable({ jsonToSearchIn: element }));
      continue;
    }
    element.forEach(child => {
      child && child.id && elements.push(child.id);
    });
    element.forEach((child) => {
      if (child && child.child && child.child.length) {
        elements = elements.concat(findElementWithId({ jsonToSearchIn: child.child }));
      }
    });
  }
  return elements;
};

export const findAllIdsInVericalTable = ({ jsonToSearchIn }) => {
  const ids = [];
  const table = jsonToSearchIn[0];
  for (let i = 1; i < jsonToSearchIn.length; i++) {
    if (!table[i]) {
      continue;
    }
    const rows = table[i];
    for (let row of rows) {
      const elements = [...row.Q[0], ...row.A[0]];
      for (let element of elements) {
        if (element && element.id) {
          ids.push(element.id);
        }
      }
    }
  }
  return ids;
};

export const findAllIdsInHorizontalTable = ({ jsonToSearchIn }) => {
  const elements = [];
  const table = jsonToSearchIn[0];
  for (let i = 1; i < jsonToSearchIn.length; i++) {
    if (!table[i]) {
      continue;
    }
    const rows = table[i]; // Object { Q, QA }
    rows.Q && rows.Q.forEach(element => {
      if (element.id) {
        elements.push(element.id);
      }
    });
    rows.QA && rows.QA.forEach(element => {
      if (element.Q && element.Q.id) {
        elements.push(element.Q.id);
      }
      if (element.A && element.A.id) {
        elements.push(element.A.id);
      }
    });
  }
  return elements;
};

export const findPendingAnswersInVeritcalTableWithAType = (jsonToSearchIn, answer) => {
  const ids = [];
  for (let i = 1; i < jsonToSearchIn.length; i++) {
    const rowArray = jsonToSearchIn[i];
    if (!rowArray) {
      continue;
    }
    for (let row of rowArray) {
      const questionArray = row.Q[0];
      const answerArray = row.A[0];
      if (questionArray.length === 1
        && answerArray.length === 1
        && questionArray[0].type === 'TD'
        && answerArray[0].type === 'TD'
        && questionArray[0].question && answerArray[0].question
      ) {
        continue;
      } else if (questionArray.findIndex(element => element.subType === 'LABEL' || element.subType === 'INPUT') > -1) {
        if (answerArray[0].type === 'TD' && !answerArray.question && answer[answerArray[0].id] === undefined) {
          ids.push(answerArray[0].id);
        }
      }
    }
  }
  return ids;
};


export const findPendingAnswersInHorizontalTableWithAType = (jsonToSearchIn, answer) => {
  const ids = [];
  for (let i = 1; i < jsonToSearchIn.length; i++) {
    if (jsonToSearchIn[i].Q) {
      const inputElement = (jsonToSearchIn[i].Q).filter(element => element.subType);
      if (inputElement && inputElement.length && answer[inputElement[0].id] === undefined) {
        ids.push(inputElement[0].id);
      }
    }
    if (jsonToSearchIn[i].QA) {
      for (let k = 0; k < (jsonToSearchIn[i].QA).length; k++) {
        const question = ((jsonToSearchIn[i].QA)[k]).Q;
        const answers = (jsonToSearchIn[i].QA)[k].A;
        if (answer[answers.id] === undefined) {
          ids.push(answers.id);
        }
      }
    }
  }
  return ids;
};


export const findATableWithAId = ({ jsonToSearchIn, id }) => {
  let elements = [];
  for (let jsonIndex = 0; jsonIndex < jsonToSearchIn.length; jsonIndex++) {
    const element = jsonToSearchIn[jsonIndex];
    if (!Array.isArray(element)) {
      continue;
    }
    if (element[0].isVertical || element[0].isHorizontal) {
      if (element[0].id === id) {
        elements.push(element);
        return elements;
      }
      continue;
    }
    element.forEach((child) => {
      if (child && child.child && child.child.length) {
        elements = elements.concat(findATableWithAId({ jsonToSearchIn: child.child, id }));
      }
    });
  }
  return elements;
};

export const findChildWithAnswerdNotDefined = (jsonToSearchIn = [], answer = {}) => {
  let ids = [];
  for (let jsonIndex = 0; jsonIndex < jsonToSearchIn.length; jsonIndex++) {
    const element = jsonToSearchIn[jsonIndex];
    if (!Array.isArray(element)) {
      continue;
    }
    if (element[0].isVertical) {
      ids = ids.concat(
        findPendingAnswersInVeritcalTableWithAType(
          element,
          answer,
        )
      );
    } else if (element[0].isHorizontal) {
      ids = ids.concat(
        findPendingAnswersInHorizontalTableWithAType(
          element,
          answer,
        )
      );
    }
    const foundChild = element.find(child => child && child.subType && answer[child.id] === undefined);
    if (foundChild) {
      ids.push(foundChild.id);
    }
    element.forEach((child) => {
      if (child && child.child && answer[child.id] && child.isConditional && child.child.length) {
        ids = ids.concat(findChildWithAnswerdNotDefined(child.child));
      }
    });
  }
  return ids;
};
