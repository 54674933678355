import React, { Component, Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { chatSocketHandlers } from '../../../socket';
import MessageBox, { MESSAGE_TYPES } from './MessageBox';
import ChatBodyFooter from './ChatBodyFooter';
import ProfilePhoto from './ProfilePhoto';

const SCROLL_THRESHOLD = 50;
const LIMIT = 30;

export const ChatBodyHeader = ({
  senderName,
  isLoading,
  showChatRoomDetails,
  isGroup,
  onBackClick,
}) => (
  <div
    className="chat-header clearfix"
    onClick={isGroup ? showChatRoomDetails : () => {}}
  >
    <div className="d-flex">
      <h1 className="head-back">
        <span
          className="back-arrow sprite-icon chat-header-back"
          onClick={onBackClick}
        />
      </h1>
      <ProfilePhoto label={senderName} size="30px" />
      <div className="chat-about">
        <div className="chat-with">{senderName}</div>
      </div>
      {isLoading && (
        <img className="chat-wrap-loader" src="./img/loading.gif" />
      )}
    </div>
  </div>
);

ChatBodyHeader.propTypes = {
  isGroup: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  senderName: PropTypes.string.isRequired,
  showChatRoomDetails: PropTypes.func.isRequired,
  onBackClick: PropTypes.func.isRequired,
};

const getMessageType = (messageBody, currentUserId) => {
  if (messageBody.isSystem) {
    return MESSAGE_TYPES.system;
  }
  if (messageBody.senderId === currentUserId) {
    return MESSAGE_TYPES.sentText;
  }
  return MESSAGE_TYPES.receivedText;
};

class ChatBody extends Component {
  constructor(props) {
    super(props);
    this.state = {
      chatRoomInfo: {},
      prevActiveChatId: '',
      prevPeopleList: [],
    };
    this.isPaginated = false;
    this.messagesContainer = React.createRef();
  }

  componentWillUnmount() {
    if (this.messagesContainer.current) {
      this.messagesContainer.current.removeEventListener(
        'scroll',
        this.onScroll
      );
    }
  }

  onScroll = () => {
    if (this.messagesContainer.current.scrollTop < SCROLL_THRESHOLD) {
      this.loadMore();
    }
  };

  loadMore = () => {
    this.isPaginated = true;
    const {
      chats,
      activeChatId,
      currentUser,
      getAllChatMessages,
      isChatLoading,
      totalChatMessagesCount,
    } = this.props;
    const activeChat = chats[activeChatId] || [];
    if (!isChatLoading && totalChatMessagesCount > activeChat.length) {
      getAllChatMessages(
        { chatId: activeChatId, currentUserId: currentUser.customerId }, // payload
        activeChat.length, // skip
        LIMIT, // limit
        true // isPaginatedRequest
      );
    }
  };

  static getDerivedStateFromProps(props, state) {
    if (
      state.prevActiveChatId !== props.activeChatId ||
      props.peopleList !== state.prevPeopleList
    ) {
      return {
        prevActiveChatId: props.activeChatId,
        chatRoomInfo:
          props.peopleList.find((item) => item.chatId === props.activeChatId) ||
          {},
      };
    }
    return null;
  }

  componentDidUpdate(prevProps) {
    const { activeChatId, isGetChatByIdSuccess, chats } = this.props;
    if (activeChatId && this.messagesContainer.current) {
      this.messagesContainer.current.addEventListener(
        'scroll',
        this.onScroll,
        false
      );
    }
    if (activeChatId && prevProps.activeChatId !== activeChatId) {
      this.onChangeActiveChat();
    }
    const activeChat = chats[activeChatId] || [];
    const prevActiveChat = prevProps.chats[prevProps.activeChatId] || [];
    const willScroll =
      (isGetChatByIdSuccess &&
        prevProps.isGetChatByIdSuccess !== isGetChatByIdSuccess) ||
      activeChat.length !== prevActiveChat.length;
    if (willScroll && !this.isPaginated) {
      this.scrollToBottom();
    }
  }

  onChangeActiveChat = () => {
    this.isPaginated = false;
    const { activeChatId, getAllChatMessages, currentUser } = this.props;
    getAllChatMessages(
      { chatId: activeChatId, currentUserId: currentUser.customerId },
      0, // skip
      LIMIT, // limit
      false // isPaginated
    );
    this.getGroupDetails();
    chatSocketHandlers.emitChatIdAsSeen({
      chatId: activeChatId,
      currentUserId: currentUser.customerId,
    });
  };

  handleMessageSend = (msgText) => {
    const { sendTextMessage, currentUser, activeChatId } = this.props;
    sendTextMessage({
      content: msgText,
      currentUser,
      activeChatId,
    });
    this.scrollToBottom();
  };

  scrollToBottom = () => {
    if (this.messagesContainer.current) {
      const { scrollHeight } = this.messagesContainer.current;
      const height = this.messagesContainer.current.clientHeight;
      const maxScrollTop = scrollHeight - height;
      this.messagesContainer.current.scrollTop =
        maxScrollTop > 0 ? maxScrollTop : 0;
    }
  };

  getGroupDetails = () => {
    const { activeChatId, getGroupDetails } = this.props;
    getGroupDetails({ chatId: activeChatId });
  };

  onMessageClick = (msg) => {
    const { currentUser, activeChatId, deleteOldMessage } = this.props;
    deleteOldMessage(msg, currentUser, activeChatId);
    this.handleMessageSend(msg.content);
  };

  render() {
    const {
      chats,
      activeChatId,
      isChatLoading,
      currentUser,
      isUpdateChatLoading,
      showChatRoomDetails,
      isChatRoomDetailsLoading,
      setActiveChatId,
      isGroupChatEnabled,
    } = this.props;
    const {
      chatRoomInfo: { senderName, senderImg, isGroup },
    } = this.state;
    const activeChat = chats[activeChatId] || [];
    return (
      <div className="chat chat-responsive">
        {activeChatId && (
          <Fragment>
            <ChatBodyHeader
              senderName={senderName}
              senderImg={senderImg}
              isLoading={
                isChatLoading || isUpdateChatLoading || isChatRoomDetailsLoading
              }
              getGroupDetails={this.getGroupDetails}
              showChatRoomDetails={showChatRoomDetails}
              isGroup={isGroup}
              activeChatId={activeChatId}
              onBackClick={() => setActiveChatId('')}
            />
            <div
              className="mCustomScrollbar chat-history-wrap"
              data-mcs-theme="dark"
            >
              <div className="chat-history" ref={this.messagesContainer}>
                <ul>
                  {activeChat.map((msg) => (
                    <MessageBox
                      key={msg.clientMsgId || msg.msgId}
                      content={msg.content}
                      type={getMessageType(msg, currentUser.customerId)}
                      msgTime={msg.msgTime}
                      senderImg={msg.senderImg}
                      msgStatus={msg.msgStatus}
                      senderName={msg.senderName}
                      onMessageClick={() => this.onMessageClick(msg)}
                    />
                  ))}
                </ul>
              </div>
            </div>
            <ChatBodyFooter
              onSendPress={this.handleMessageSend}
              isChatLoading={isChatLoading}
              activeChatId={activeChatId}
            />
          </Fragment>
        )}
        {!activeChatId && !isGroupChatEnabled && (
          <p className="no-chat-data">
            <FormattedMessage
              id="noChatIndividual"
              defaultMessage="Click the 'Start New Chat' to start chatting with your team members."
            />
          </p>
        )}
        {!activeChatId && isGroupChatEnabled && (
          <p className="no-chat-data">
            <FormattedMessage
              id="noChatGroup"
              defaultMessage="Click the 'Start New Group Chat' to start chatting with your team members."
            />
          </p>
        )}
      </div>
    );
  }
}

ChatBody.propTypes = {
  activeChatId: PropTypes.string.isRequired,
  chats: PropTypes.array.isRequired,
  currentUser: PropTypes.object.isRequired,
  getAllChatMessages: PropTypes.func.isRequired,
  getGroupDetails: PropTypes.func.isRequired,
  isChatLoading: PropTypes.bool.isRequired,
  totalChatMessagesCount: PropTypes.number.isRequired,
  isChatRoomDetailsLoading: PropTypes.bool.isRequired,
  isGetChatByIdSuccess: PropTypes.bool.isRequired,
  isUpdateChatLoading: PropTypes.bool.isRequired,
  peopleList: PropTypes.array.isRequired,
  sendTextMessage: PropTypes.func.isRequired,
  showChatRoomDetails: PropTypes.func.isRequired,
  setActiveChatId: PropTypes.func.isRequired,
  deleteOldMessage: PropTypes.func.isRequired,
  isGroupChatEnabled: PropTypes.bool.isRequired,
};

export default ChatBody;
