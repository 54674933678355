import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import ToolTip from '../ToolTip';
import DefaultDropDownComponent from './DefaultDropDownComponent';
import DefaultDropDownContainerComponent from './DefaultDropDownContainer';

export class CustomDropDown extends React.PureComponent {
  constructor(props) {
    super(props);
    this.dropDownArea = React.createRef();
    this.state = {
      isOpened: false,
    };
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClick);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClick);
  }

  handleClick = (e) => {
    if (this.dropDownArea.current.contains(e.target)) {
      return;
    }
    this.toggleDropDown(false);
  };

  toggleDropDown = (value) => {
    this.setState({
      isOpened: value,
    });
  };

  updateStatus = (newValue) => {
    this.toggleDropDown(false);
    this.props.onChange(newValue);
  };

  render() {
    const {
      listData,
      DropDownComponent,
      DropDownContainerComponent,
      className,
      Footer,
      isDisabled,
      classname,
      containerClass,
      dataFor,
      place,
      dataTip,
    } = this.props;
    return (
      <React.Fragment>
        <div
          className={className}
          ref={this.dropDownArea}
          data-for={dataFor}
          data-tip={dataTip && this.props.intl.formatMessage({ id: dataTip })}
        >
          <DropDownContainerComponent
            selectedValue={this.props.selectedValue}
            isDropDownOpen={this.state.isOpened}
            isDisabled={isDisabled}
            onToggle={this.toggleDropDown}
            className={containerClass}
          >
            <DropDownComponent
              listData={listData}
              onChange={this.updateStatus}
              className={classname}
            />
          </DropDownContainerComponent>
          {Footer ? <Footer /> : null}
        </div>
        <ToolTip id={dataFor} place={place} />
      </React.Fragment>
    );
  }
}

CustomDropDown.propTypes = {
  listData: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  selectedValue: PropTypes.string.isRequired,
  DropDownComponent: PropTypes.func,
  DropDownContainerComponent: PropTypes.func,
  className: PropTypes.string.isRequired,
  Footer: PropTypes.func,
  isDisabled: PropTypes.bool,
  classname: PropTypes.string,
  containerClass: PropTypes.string,
  dataFor: PropTypes.string,
  place: PropTypes.string,
  dataTip: PropTypes.string,
  intl: intlShape.isRequired,
};

CustomDropDown.defaultProps = {
  listData: [],
  onChange: undefined,
  selectedValue: '',
  DropDownComponent: DefaultDropDownComponent,
  DropDownContainerComponent: DefaultDropDownContainerComponent,
  className: '',
  classname: 'select-items mCustomScrollbar',
  Footer: undefined,
  isDisabled: false,
};

export default injectIntl(CustomDropDown);
