import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { yearMonthFormat, dateFormat } from '../../utils/Constants';

/**
 * Renders each row of table in service due table.
 * @param {*} props
 */
const ServiceDueTableRow = (props) => {
  const { location, floor, manufacturer, serialNumber, size, dueFor, dueDate } =
    props.row;
  const dueDateFormatted = moment(dueDate, dateFormat ).format( yearMonthFormat );


  return (
    <tr>
      <td style={ {width:"10%"} }>{location}</td>
      <td style={ {width:"10%"} }>{floor}</td>
      <td style={ {width:"20%"} }>{manufacturer}</td>
      <td style={ {width:"10%"} }>{serialNumber}</td>
      <td style={ {width:"10%"} }>{size}</td>
      <td style={ {width:"20%"} }>{dueFor}</td>
      <td style={ {width:"20%"} }>{dueDateFormatted}</td>
    </tr>
  );
};

ServiceDueTableRow.propTypes = {
  row: PropTypes.object,
};

export default ServiceDueTableRow;
