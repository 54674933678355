import actionConstants from '../constants/ActionConstants';
import {
  months,
  currentYear,
  previousYear,
  reportsColor,
} from '../../views/constant';

const revenueData = {
  error: '',
  isFetching: false,
  success: '',
  revenueChartData: [],
  totalRevenue: 0,
};

const jobsChartData = {
  error: '',
  isFetching: false,
  success: '',
  dueJobs: [],
  totalDueJobs: 0,
  scheduledJobs: 0,
  completedJobsCount: 0,
  completedJobs: [{ count: 300, color: '#E2E2E2' }],
  invoicedJobsCount: 0,
  invoicedJobs: [{ count: 300, color: '#E2E2E2' }],
  projectedRevenue: 0,
  revenueYearToDate: null,
};

const reportList = {};

const initialState = {
  reportList,
  revenueData,
  jobsChartData,
};

let totalRevenue = 0;
let chartData = [];
let totalDueJobs = 0;
let dueJobs = [];

export default (state = initialState, action) => {
  switch (action.type) {
    case actionConstants.GET_REVENUE_REQUEST:
      return {
        ...state,
        revenueData: {
          ...state.revenueData,
          error: '',
          isFetching: true,
        },
      };
    case actionConstants.GET_REVENUE_SUCCESS:
      totalRevenue = 0;
      chartData = months.map((item, i) => {
        totalRevenue += action.payload.currentYear[i].revenue;
        return {
          name: item.slice(0, 3),
          [previousYear]:
            action.payload.previousYear &&
            action.payload.previousYear[i].revenue,
          [currentYear]: action.payload.currentYear[i].revenue,
        };
      });
      return {
        ...state,
        revenueData: {
          ...state.revenueData,
          error: '',
          isFetching: false,
          success: action.message,
          revenueChartData: [...chartData],
          totalRevenue,
        },
      };
    case actionConstants.GET_REVENUE_FAILURE:
      return {
        ...state,
        revenueData: {
          ...state.revenueData,
          success: '',
          error: action.message,
          isFetching: false,
          totalRevenue: 0,
        },
      };
    case actionConstants.RESET_ALL_REDUCERS:
      return {
        ...initialState,
      };
    case actionConstants.GET_REVENUE_RESET:
      return {
        ...state,
        revenueData: {
          ...initialState.revenueData,
        },
      };

    case actionConstants.GET_JOBS_CHART_DATA_REQUEST:
      return {
        ...state,
        jobsChartData: {
          ...state.jobsChartData,
          error: '',
          isFetching: true,
        },
      };
    case actionConstants.GET_JOBS_CHART_DATA_SUCCESS:
      totalDueJobs = 0;
      dueJobs = action.payload.dueJobs.map((item) => {
        totalDueJobs += item.count;
        return {
          name: item._id,
          count: item.count,
          color: reportsColor[item._id],
        };
      });
      return {
        ...state,
        jobsChartData: {
          ...state.jobsChartData,
          error: '',
          isFetching: false,
          success: action.message,
          dueJobs,
          totalDueJobs,
          invoicedJobsCount: action.payload.invoicedJobs,
          completedJobsCount: action.payload.completedJobs,
          invoicedJobs: [
            {
              count: action.payload.completedJobs + totalDueJobs,
              color: '#E2E2E2',
            },
            { count: action.payload.invoicedJobs, color: '#FCA7A5' },
          ],
          completedJobs: [
            {
              count: action.payload.invoicedJobs + totalDueJobs,
              color: '#E2E2E2',
            },
            { count: action.payload.completedJobs, color: '#4DBCA9' },
          ],
          scheduledJobs: action.payload.scheduledJobs,
          projectedRevenue: action.payload.projectedRevenue,
          revenueYearToDate: action.payload.revenueYearToDate,
        },
      };
    case actionConstants.GET_JOBS_CHART_DATA_FAILURE:
      return {
        ...state,
        jobsChartData: {
          ...state.jobsChartData,
          error: action.message,
          isFetching: false,
          success: '',
        },
      };
    case actionConstants.GET_JOBS_CHART_DATA_RESET:
      return {
        ...state,
        jobsChartData: {
          ...initialState.jobsChartData,
        },
      };

    default:
      return state;
  }
};
