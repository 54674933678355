import Socket from './chatSocket';
import EVENT from './chatSocketEvents';

const init = () => {
  new Socket().getInstance();
  // socket.emit(EVENT.TO.CONNECT);
};

const onConnected = (handleOnConnected) => {
  const socket = new Socket().getInstance();
  socket.on(EVENT.ON.CONNECT, () => {
    handleOnConnected();
    socket.emit(EVENT.TO.CONNECT);
  });
};

const onReceive = (handleMessageReceived) => {
  const socket = new Socket().getInstance();
  socket.on(EVENT.ON.MESSAGE_RECEIVED, handleMessageReceived);
};

const onMsgDelivered = (handleOnMessageDelivered) => {
  const socket = new Socket().getInstance();
  socket.on(EVENT.ON.MESSAGE_DELIVERED, handleOnMessageDelivered);
};

const onMsgSent = (handleOnMessageSent) => {
  const socket = new Socket().getInstance();
  socket.on(EVENT.ON.MESSAGE_SENT, handleOnMessageSent);
};

const onMsgSeen = (handleOnMessageSeen) => {
  const socket = new Socket().getInstance();
  socket.on(EVENT.ON.MESSAGE_SEEN, handleOnMessageSeen);
};

const onUpdateGroupDetails = (handleGroupUpdate) => {
  const socket = new Socket().getInstance();
  socket.on(EVENT.ON.UPDATE_GROUP, handleGroupUpdate);
};

const sendMessage = (message) => {
  const socket = new Socket().getInstance();
  socket.emit(EVENT.TO.SEND_MESSAGE, message);
};

const emitAllMsgsAsDelivered = ({ userId }) => {
  const socket = new Socket().getInstance();
  socket.emit(EVENT.TO.MARK_ALL_DELIVERED, { userId });
};

const emitReceivedMsgAsDelivered = ({ chatId, currentUserId }) => {
  const socket = new Socket().getInstance();
  socket.emit(EVENT.TO.MARK_MESSAGE_AS_DELIVERED, {
    channelId: chatId,
    senderId: currentUserId,
  });
};

const emitChatIdAsSeen = ({ chatId, currentUserId }) => {
  const socket = new Socket().getInstance();
  socket.emit(EVENT.TO.MARK_CHAT_AS_SEEN, {
    channelId: chatId,
    senderId: currentUserId,
  });
};

const emitGPSLocation = (payload) => {
  const socket = new Socket().getInstance();
  socket.emit(EVENT.TO.EMPLOYEE_GPS_LOCATION, payload);
};

const destroy = () => {
  const { getInstance, removeInstance } = new Socket();
  const socket = getInstance();
  socket.off();
  socket.disconnect();
  removeInstance();
};

export {
  init,
  sendMessage,
  onReceive,
  destroy,
  onMsgSent,
  emitChatIdAsSeen,
  emitGPSLocation,
  onMsgSeen,
  onMsgDelivered,
  emitReceivedMsgAsDelivered,
  emitAllMsgsAsDelivered,
  onConnected,
  onUpdateGroupDetails,
};
