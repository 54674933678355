import actionConstants from '../constants/ActionConstants';

const initialState = {
  error: '',
  isSuccess: false,
  isfetching: false,
  clientOrOwnerData: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionConstants.GET_CLIENT_OR_OWNER_DETAILS_REQUEST:
      return {
        ...state,
        isSuccess: false,
        isfetching: true,
      };
    case actionConstants.GET_CLIENT_OR_OWNER_DETAILS_SUCCESS:
      return {
        ...state,
        clientOrOwnerData: action.payload,
        isfetching: false,
        error: '',
        isSuccess: true,
      };
    case actionConstants.GET_CLIENT_OR_OWNER_DETAILS_FAILURE:
      return {
        ...state,
        isSuccess: false,
        error: "Couldn't fetch details!",
        isfetching: false,
      };
    case actionConstants.CLEAR_STATE:
      return {
        ...state,
        isSuccess: false,
        isfetching: false,
        error: '',
      };
    case actionConstants.RESET_ALL_REDUCERS:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};
