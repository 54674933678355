import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

/**
 * Full page loader
 * @param {object} param0
 */
const Loader = ({ containerClass, loading }) => {
  if(!loading) return null;
  return (
    <div id="overlayLoader" className={containerClass || ''}>
      <div className="page-loader-container">
        <div className="pageLoader"></div>
      </div>
    </div>
  );
};

Loader.propTypes = {
  containerClass: PropTypes.string, // Container class in which loader will be visible
};

const mapStateToProps = (state) => ({
  loading: state.showLoader.loading,
});

export default connect(mapStateToProps, { })(Loader);