export default {
  'Customer Info': 'Add or edit customer info.',
  'Owner/Agent Info': 'Add or edit owner/agent info.',
  'Building Info': 'Add or edit building info, system info or images',
  'Upload Devices': 'Import device lists',
  'Recurring Inspections': 'Create inspection and task reminders',
  Deficiencies: 'Track deficiencies',
  'Customer Portal': 'Invite customer to the portal',
  'Add Job': 'Create a one time job',
  'Auto Email':
    'Invite people to receive automatic emails of completed inspections, work orders and quotes.',
  Dashboard: 'View job and financial Information',
  Customers: 'View customer list',
  Team: 'Add team members or view their information',
  JobLink: 'Job Scheduling',
  Reports: 'Start or view reports',
  Jobs: 'View jobs',
  'Service Requests': 'Services requested from customers',
  Inventory: 'View parts and tools',
  'Bug Tickets': 'Send an issue or a comment to firelab',
  GPS: 'View current or historical GPS data',
  TimeCard: 'Edit or view TimeCard information',
  New: 'Start a report',
  Completed: 'View completed reports',
  Incomplete: 'View incomplete reports',
  'Jobs Due': 'View jobs due',
  'Jobs Missed': 'View missed jobs',
  'My Jobs': 'View your scheduled jobs',
  Parts: 'Add/Assign parts',
  Tools: 'Add/Assign tools',
  'Inventory on Vehicles': 'Add/Assign vehicles',
  Transactions: 'View transaction log',
  Suppliers: 'Add/View suppliers',
  Subscriptions: 'Manage your subscription.',
  'QR Code': 'Print QR Codes.',
  Videos: 'Watch explainer videos.',
  'Company Logo': 'Add your logo to reports.',
  'Manage Report': 'Assign custom reports.',
};

export const toolTips = {
  offline: {
    reset: 'Clears all data and allows you to start again.',
    offlineToggle:
      'Switch to offline mode to work with no wifi or cellular service.',
  },
  reports: {
    categoryDropdown: 'Filter by category.',
    typeDropdown: 'Filter by type.',
    backwardForwardArrow: 'Switch between open reports.',
  },
  jobs: {
    calender: 'Filter by date.',
  },
  deficiencies: {
    status: 'Filter by status.',
    quote: 'Quote needs scheduled.',
    workOrder: 'Work Order needs scheduled.',
    pdf: 'View Inspection Report',
    email: 'Send quote out for approval.',
    joblink: 'Put job on the schedule.',
    parts: 'Add parts to the quote.',
    labor: 'Add labor hours to the quote.',
    editQuote: 'Edit quote',
    delete: 'Delete deficiency',
  },
  customerHome: {
    start: 'Start a new Inspection, Work Order or Quote.',
    incompleteReports: 'View reports that have been started and not finished.',
    inspections: 'View completed Inspections.',
    workOrders: 'View completed Work Orders.',
    quotes: 'View completed Quotes.',
    servicesDue: 'View services due.',
  },
  dashboard: {
    month: 'Filter by month.',
    year: 'Filter by year.',
    category: 'Filter by report category.',
  },
  image: {
    preview: 'Preview image.',
    delete: 'Delete image.',
  },
  uploadDevice: {
    import: 'Import a list of devices.',
    add: 'Add devices one at a time.',
    row: 'Click to view devices in this list.',
  },
  joblink: {
    type: 'Filter by report type.',
    view: 'Select view type.',
  },
  services: {
    filter: 'Filter by status or report category.',
  },
  inventory: {
    archiveTool: 'Switch tool from in stock or out of stock.',
    addVehicle: 'Add a vehicle to inventory.',
    archivedVehicle: 'View archived vehicles.',
    archiveVehicle: 'Switch vehicle from in stock or out of stock.',
    addSupplier: 'Add a supplier to inventory.',
    archivedSupplier: 'View archived suppliers.',
    archiveSupplier: 'Switch supplier from active or inactive.',
    importParts: 'Import a list of parts.',
  },
};

export const reportFilterTips = [
  toolTips.customerHome.inspections,
  toolTips.customerHome.workOrders,
  toolTips.customerHome.quotes,
  toolTips.customerHome.incompleteReports,
];

export const reportFilterTipsExt = [
  toolTips.customerHome.inspections,
  toolTips.customerHome.workOrders,
  toolTips.customerHome.quotes,
  toolTips.customerHome.servicesDue,
  toolTips.customerHome.incompleteReports,
];
