import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Button from '../Button';
import DropDown from '../DropDown';
import Input from '../Input';
import { SYSTEM_TYPE } from '../../views/hardCodedReports/Suppression/SuppressionConstant';

const AddSystemModalBody = (props) => {
  const { setSystemInfo, systemInfoErrors, systemInfo, reportCategoryList } =
    props;
  const dropDownItem = [
    {
      name: 'reportType',
      label: 'Report Category*',
    },
    {
      name: 'systemDeviceType',
      label: 'System/Device*',
    },
    {
      name: 'type',
      label: 'Type*',
    },
  ];

  let systemDeviceList = [];
  if (systemInfo.reportType !== '') {
    systemDeviceList = reportCategoryList[systemInfo.reportType];
  }

  let deviceList = [];
  if (systemInfo.systemDeviceType !== '') {
    deviceList = systemInfo.reportType === "Suppression" && systemInfo.systemDeviceType === "System" ? SYSTEM_TYPE.map(({label}) => label) : systemDeviceList[systemInfo.systemDeviceType]
  }
  const filterReportCategory = (value) => {
    const reportCategory = Object.keys(value).filter(
      (category) => Object.keys(value[category]).length > 0
    );
    return reportCategory;
  };

  return (
    <div>
      <DropDown
        className="dis-flex f-dir-column"
        key={dropDownItem[0].name}
        value={systemInfo[dropDownItem[0].name]}
        name={dropDownItem[0].name}
        label={dropDownItem[0].label}
        dropDownList={filterReportCategory(reportCategoryList)}
        handleOnChange={setSystemInfo}
        error={systemInfoErrors && systemInfoErrors[dropDownItem[0].name]}
      />
      <DropDown
        key={dropDownItem[1].name}
        isDisabled={systemDeviceList.length === 0 && true}
        value={systemInfo[dropDownItem[1].name]}
        name={dropDownItem[1].name}
        label={dropDownItem[1].label}
        dropDownList={Object.keys(systemDeviceList)}
        handleOnChange={setSystemInfo}
        error={systemInfoErrors && systemInfoErrors[dropDownItem[1].name]}
      />
      <DropDown
        key={dropDownItem[2].name}
        isDisabled={deviceList.length === 0 && true}
        value={systemInfo[dropDownItem[2].name]}
        name={dropDownItem[2].name}
        label={dropDownItem[2].label}
        dropDownList={deviceList}
        handleOnChange={setSystemInfo}
        error={systemInfoErrors && systemInfoErrors[dropDownItem[2].name]}
      />

      {systemInfo[dropDownItem[2].name] === 'Other' ? (
        <div className="dis-flex f-dir-column mr-top-24">
          {systemInfoErrors.otherDeviceType && (
            <span className="errorMessage">
              {systemInfoErrors.otherDeviceType}
            </span>
          )}
          <Input
            type="text"
            value={systemInfo.otherDeviceType || ''}
            name="otherDeviceType"
            className="form-control"
            onChange={(e) => setSystemInfo(e.target.name, e.target.value)}
          />
          <label htmlFor="usr">
            <FormattedMessage id="Other*" defaultMessage="Other*" />
          </label>
        </div>
      ) : null}
      <div className="dis-flex f-dir-column mr-top-24">
        {systemInfo.showQty && systemInfoErrors.quantity && (
          <span className="errorMessage">
            <FormattedMessage
              defaultMessage={systemInfoErrors.quantity}
              id={systemInfoErrors.quantity}
            />
          </span>
        )}
         {systemInfo && systemInfo.showQty && (
        <><Input
            type="text"
            subType="int"
            value={systemInfo.quantity || ''}
            name="quantity"
            className="form-control"
            onChange={(e) => setSystemInfo(e.target.name, e.target.value)} /><label htmlFor="usr">
              <FormattedMessage id="Quantity*" defaultMessage="Quantity*" />
            </label></>
         )}
      </div>
    </div>
  );
};

const AddSystemModalFooter = ({ handleClickSave, handleClickSaveAndNew }) => (
  <div className="cust-select-btns mr-top-24">
    <Button
      text="Save and New"
      color="#6AA14E"
      onBtnClick={handleClickSaveAndNew}
    />
    <Button text="Save" color="#6AA14E" onBtnClick={handleClickSave} />
  </div>
);

AddSystemModalBody.propTypes = {
  setSystemInfo: PropTypes.func, // sets system info
  systemInfoErrors: PropTypes.object, // object containing system info errors
  systemInfo: PropTypes.object, // system info object
  reportCategoryList: PropTypes.object, // report category list object
};

AddSystemModalFooter.propTypes = {
  handleClickSave: PropTypes.func, // handles save button click event
  handleClickSaveAndNew: PropTypes.func, // handles save and new button click event
  reportCategoryList: PropTypes.object, // report category list object
};

export { AddSystemModalBody, AddSystemModalFooter };
