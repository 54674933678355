import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, intlShape, injectIntl } from 'react-intl';
import { isEqual } from 'lodash';
import moment from 'moment';
import SearchBar from '../SearchBar';
import {
  STATUSES,
  inventoryTransactionTypes,
  inventoryAssets,
  transactionfilterHeads,
  transactions,
} from '../../utils/Constants';
import ExpandableListDropDown from '../ExpandableListDropDown/ExpandableListDropDown';
import CustomDropDowns from '../CustomDropDown/CustomDropDown';
import './TaskHeader.scss';
import DateRangePicker from '../DateRangePicker/DateRangePicker';
import DateMonthPicker from '../DateMonthPicker/DateMonthPicker';
import { toolTips } from '../../utils/toolTips';
import ToolTip from '../ToolTip';

const FILTER_HEADS = ['Report Category'];
const LIST_DATA = ['All', 'Inspection', 'Quote', 'Work Order'];

const populateInitialValues = (filtersLabels, isKey) => {
  const filters = {};
  const values = isKey
    ? Object.keys(filtersLabels)
    : Object.values(filtersLabels);
  for (let i = 0; i < values.length; i += 1) {
    Object.assign(filters, { [values[i]]: false });
  }
  return filters;
};
export class TaskHeader extends React.Component {
  constructor(props) {
    super(props);
    this.dropDownArea = React.createRef();
    this.state = {
      statusFilters: {
        ...populateInitialValues(
          this.props.for === transactions ? inventoryAssets : STATUSES,
          false
        ),
      },
      reportFilters: {
        ...populateInitialValues(
          this.props.for === transactions
            ? inventoryTransactionTypes
            : this.props.reportCategories,
          this.props.for !== transactions
        ),
      },
      expandedIndex: -1,
      isShowing: false,
      startDatePersist: new Date(),
      endDatePersist: new Date(),
    };
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClick);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClick);
  }

  handleClick = (e) => {
    const { dropDownArea } = this;
    const current = dropDownArea ? dropDownArea.current : null;
    if(current && current.contains(e.target)) {
      return;
    }
    this.toggleDropDown(false);
  };

  toggleDropDown = (value) => {
    this.setState({
      isShowing: value,
    });
  };

  persistDate = (date) => {
    if (!date) return;
    this.setState(
      {
        startDatePersist: moment(date).startOf('month'),
        endDatePersist: moment(date).endOf('month'),
        isShowing: false,
      },
      () =>
        this.props.setDatePeriod(
          this.state.startDatePersist,
          this.state.endDatePersist
        )
    );
  };

  resetDate = () => {
    this.setState(
      {
        startDatePersist: moment().startOf('day'),
        endDatePersist: moment().endOf('day'),
        isShowing: false,
      },
      () =>
        this.props.setDatePeriod(
          this.state.startDatePersist,
          this.state.endDatePersist
        )
    );
  };

  resetFilters = () => {
    this.setState(
      {
        statusFilters: {
          ...populateInitialValues(
            this.props.for === transactions ? inventoryAssets : STATUSES,
            false
          ),
        },
        reportFilters: {
          ...populateInitialValues(
            this.props.for === transactions
              ? inventoryTransactionTypes
              : this.props.reportCategories,
            this.props.for !== transactions
          ),
        },
      },
      () => {
        this.props.setFilters({
          statusFilters: this.state.statusFilters,
          reportFilters: this.state.reportFilters,
        });
      }
    );
  };

  toggleFilter = (filterHeadName, value) => {
    if (filterHeadName === 'Status' || filterHeadName === 'Asset Type') {
      const filters = { ...this.state.statusFilters };
      filters[value] = !this.state.statusFilters[value];
      this.setState({
        statusFilters: filters,
      });
    } else {
      const filters = this.state.reportFilters;
      filters[value] = !this.state.reportFilters[value];
      this.setState({
        reportFilters: filters,
      });
    }
  };

  setExpandedIndex = (index) => {
    const expandedIndex = index === this.state.expandedIndex ? -1 : index;
    this.setState({
      expandedIndex,
    });
  };

  mapLocalStateToStore = () => {
    const reportFilters = {
      ...populateInitialValues(
        this.props.for === transactions
          ? inventoryTransactionTypes
          : this.props.reportCategories,
        this.props.for !== transactions
      ),
    };
    const statusFilters = {
      ...populateInitialValues(
        this.props.for === transactions ? inventoryAssets : STATUSES,
        this.props.for !== transactions
      ),
    };
    if (this.props.for === transactions) {
      this.props.status.map((item) => {
        statusFilters[item] = true;
        return item;
      });
    }
    this.props.reportCategory.map((item) => {
      reportFilters[item] = true;
      return item;
    });

    this.setState({
      reportFilters,
      statusFilters,
      expandedIndex: -1,
    });
  };

  componentDidUpdate(prevProps) {
    const { reportCategories } = this.props;
    if (!isEqual(reportCategories, prevProps.reportCategories)) {
      this.setState({
        reportFilters: {
          ...populateInitialValues(
            this.props.for === transactions
              ? inventoryTransactionTypes
              : this.props.reportCategories,
            false
          ),
        },
        statusFilters: {
          ...populateInitialValues(
            this.props.for === transactions ? inventoryAssets : STATUSES,
            this.props.for !== transactions
          ),
        },
      });
    }
  }

  render() {
    const { isShowing } = this.state;
    return (
      <div data-testid="taskheader">
        <div data-testid="searchbar" className="topLayer top-search-field">
          <p className="taskheading">
            <FormattedMessage
              id={this.props.name}
              defaultMessage={this.props.name}
            />
          </p>
          <SearchBar
            onTextChange={(e) => {
              if (e === '' || (e.target && e.target.value === '')) {
                this.props.handleSearch('');
              } else {
                this.props.setSearchText(e.target.value);
              }
            }}
            searchValue={this.props.searchText}
            isCrossVisible={this.props.isCrossVisible}
            onSearchPress={this.props.handleSearch}
          />
        </div>
        <div className="bottomLayer">
          <div className="filter-btn-grp filter-width">
            <div data-testid="customdropdown">
              {!(this.props.for === transactions) && !this.props.hideFilter && (
                <CustomDropDowns
                  listData={LIST_DATA}
                  selectedValue={
                    this.props.reportType === '' ? 'All' : this.props.reportType
                  }
                  onChange={(value) => {
                    this.props.setReportType(value === 'All' ? '' : value);
                  }}
                  className={'dis-flex f-dir-column'}
                  dataTip={toolTips.reports.typeDropdown}
                  dataFor={toolTips.reports.typeDropdown}
                  place="top"
                />
              )}
            </div>
          </div>
          <div className="topright task-header-topright">
            <div>
              <button
                onClick={() => this.props.printPage && this.props.printPage()}
                className="downloadJob"
              >
                Export
              </button>
            </div>
            <div data-testid="expandablelistdropdown">
              <ExpandableListDropDown
                headLabels={
                  this.props.for === transactions
                    ? transactionfilterHeads
                    : FILTER_HEADS
                }
                childLabels={
                  this.props.for === transactions
                    ? [this.state.statusFilters, this.state.reportFilters]
                    : [this.state.reportFilters]
                }
                className={'filter-row margin job-due-fix'}
                onHeadPress={this.setExpandedIndex}
                expandedIndex={this.state.expandedIndex}
                onChildPress={this.toggleFilter}
                onApply={() => {
                  this.props.setFilters({
                    statusFilters: this.state.statusFilters,
                    reportFilters: this.state.reportFilters,
                  });
                }}
                onReset={() => {
                  this.resetFilters();
                }}
                onToggleOpen={(isVisible) => {
                  if (!isVisible) {
                    this.mapLocalStateToStore();
                  }
                }}
                dataFor={toolTips.reports.categoryDropdown}
                dataTip={toolTips.reports.categoryDropdown}
                place="left"
                style={{backgroundColor: 'red'}}
              />
            </div>
            {Boolean(this.props.setDatePeriod) && (
              <div
                data-testid="calendar"
                className="picker"
                ref={this.dropDownArea}
                data-tip={this.props.intl.formatMessage({
                  id: toolTips.jobs.calender,
                })}
                data-for={toolTips.jobs.calender}
              >
                <button
                  data-testid="button"
                  onClick={() => this.toggleDropDown(!this.state.isShowing)}
                  className="date-filter sprite-icon-before mr-lt-20 nullborder"
                ></button>
                {isShowing && (
                  <DateMonthPicker
                    isShowing={this.state.isShowing}
                    onDismiss={() => this.toggleDropDown(false)}
                    persistDate={this.persistDate}
                    resetdate={this.resetDate}
                    startDatePersist={this.state.startDatePersist}
                    endDatePersist={this.state.endDatePersist}
                    minDate={
                      this.props.minDate ? this.props.minDate : undefined
                    }
                    maxDate={
                      this.props.maxDate ? this.props.maxDate : undefined
                    }
                  />
                )}
              </div>
            )}
            <ToolTip id={toolTips.jobs.calender} place="top" />
          </div>
        </div>
      </div>
    );
  }
}
TaskHeader.propTypes = {
  isCrossVisible: PropTypes.bool, // is true if cross is visible
  setDatePeriod: PropTypes.func, // handles selecting date range
  setFilters: PropTypes.func, // handles filters selection
  setReportType: PropTypes.func, // handles report type filter selection,
  printPage: PropTypes.func, // use to print report in excel
  filter: PropTypes.array, // array of all filters
  handleSearch: PropTypes.func, // handles search functionality
  reportType: PropTypes.string, // report type selected
  name: PropTypes.string, // name of html element
  setSearchText: PropTypes.func, // handles change search text
  searchText: PropTypes.string, // current search text
  minDate: PropTypes.any, // minimum date
  maxDate: PropTypes.any, // maximum date
  for: PropTypes.string,
  reportCategories: PropTypes.object, // selected report categories
  reportCategory: PropTypes.array, // all report categories
  hideFilter: PropTypes.bool, // is true if filter is hidden
  status: PropTypes.array,
  intl: intlShape.isRequired,
};

export default injectIntl(TaskHeader);
