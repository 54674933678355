import actionConstants from '../constants/ActionConstants';

const deleteSubscription = {
  error: '',
  success: '',
  isfetching: false,
  isSuccess: false,
};

const initialState = {
  error: '',
  isfetching: false,
  subscriptionPlans: [],
  isSuccess: false,
  deleteSubscription,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionConstants.SUBSCRIPTION_PLAN_REQUEST:
      return {
        ...state,
        error: '',
        isfetching: true,
        isSuccess: false,
      };
    case actionConstants.SUBSCRIPTION_PLAN_SUCCESS:
      return {
        ...state,
        subscriptionPlans: action.payload,
        isfetching: false,
        isSuccess: true,
      };
    case actionConstants.SUBSCRIPTION_PLAN_FAILURE:
      return {
        ...state,
        error: action.message,
        isfetching: false,
      };
    case actionConstants.SUBSCRIPTION_PLAN_RESET:
      return {
        ...initialState,
      };
    case actionConstants.DELETE_SUBSCRIPTION_REQUEST:
      return {
        ...state,
        deleteSubscription: {
          ...deleteSubscription,
          isfetching: true,
        },
      };
    case actionConstants.DELETE_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        deleteSubscription: {
          ...state.deleteSubscription,
          isfetching: false,
          isSuccess: true,
          success: action.message,
        },
      };
    case actionConstants.DELETE_SUBSCRIPTION_FAILURE:
      return {
        ...state,
        deleteSubscription: {
          ...state.deleteSubscription,
          error: action.payload,
          isfetching: false,
          isSuccess: false,
        },
      };
    default:
      return state;
  }
};
