import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import SystemInfoLayout from '../../layouts/SystemInfoLayout';
import getSystemInfoAction from '../../redux/actions/getSystemInfo';
import {
  addSystemOrDeviceAction,
  clearState,
} from '../../redux/actions/addSystemOrDevice';
import addSystemOrDeviceSchema from '../../_helpers/validator/addSystemOrDeviceSchema';
import deleteSystemOrDeviceAction from '../../redux/actions/deleteSystemOrDevice';
import { displayToast } from '../../_helpers/commonFunctions';
import DeleteConfirmationModal from '../../components/DeleteConfirmationModal';
import {
  resetSelectedCustomer,
  setSubMenuVisibility,
} from '../../redux/actions/setSelectedCustomer';
import Loader from '../../components/Loader';
import { reportCat } from '../../redux/actions/reportCategory';
import SetIsPromptAction from '../../redux/actions/isPromptAction';

/**
 * View component for System Information for particular customer.
 */
class SystemInfo extends Component {
  constructor(props) {
    super(props);

    this.state = {
      systemInfo: {
        reportType: '',
        systemDeviceType: '',
        type: '',
        quantity: null,
        otherDeviceType: '',
      },
      systemInfoErrors: {
        reportType: '',
        systemDeviceType: '',
        type: '',
        quantity: '',
        otherDeviceType: '',
      },
      systemInfoId: '',
      isDeleteConfirmModalVisible: false,
      showSystemInfo: false,
      isClassVisible: {},
    };

    this.baseState = JSON.stringify(this.state);
  }

  /**
   * Resets local state.
   */
  resetState = () => {
    const newState = JSON.parse(this.baseState);
    this.setState(newState);
  };

  /**
   * Called on on change for adding system information.
   */
  setSystemInfo = (dropDownName, dropDownVal) => {

    const { systemInfo } = this.state;
    if (dropDownName === 'reportType') {
      systemInfo.systemDeviceType = '';
      systemInfo.type = '';
      systemInfo.showQty =(dropDownVal === 'Pump') ? false: true;
    } else if (dropDownName === 'systemDeviceType') {
      systemInfo.type = '';
    } else if (dropDownName === 'type' && dropDownVal !== 'Other') {
      systemInfo.otherDeviceType = '';
    }
    systemInfo[dropDownName] = dropDownVal;
    this.setState({ systemInfo });
    this.props.SetIsPromptAction(true);
  };

  /**
   * Resets to error object.
   */
  resetErrors = () => {
    this.setState({
      systemInfoErrors: { ...JSON.parse(this.baseState).systemInfoErrors },
    });
  };

  /**
   * Called for updating errors while adding system info.
   */
  handleErrors = (err) => {
    const { systemInfoErrors } = { ...this.state };
    err.inner.forEach((item) => {
      systemInfoErrors[`${item.path}`] = item.message;
    });
    this.setState({ systemInfoErrors });
  };

  /**
   * Called on saving new system information.
   */
  addSystemOrDevice = (cb) => {
    this.resetErrors();
    const { buildingInfoId } = this.props;
    const { systemInfo,systemInfoErrors } = { ...this.state };
    delete systemInfo.showQty;
    if (systemInfo.reportType === 'Pump') {
      systemInfo.quantity = 1;
      
    }
    systemInfo.quantity = Number(systemInfo.quantity);
    addSystemOrDeviceSchema.validate(systemInfo, { abortEarly: false }).then(
      () => {
        const systemInfoOther = { ...systemInfo };
        if (systemInfoOther.type !== 'Other') {
          delete systemInfoOther.otherDeviceType;
        }
        this.props.addSystemOrDeviceAction(buildingInfoId, systemInfoOther);
        this.setState(
          {
            systemInfoErrors: {
              ...JSON.parse(this.baseState).systemInfoErrors,
            },
          },
          () => cb && cb()
        );
      },
      (err) => this.handleErrors(err)
    );
  };

  setSelectedSystemInfoId = (selectedId) => {
    this.setState({
      systemInfoId: selectedId,
      isDeleteConfirmModalVisible: true,
    });
  };

  /**
   * Deleting record of particular system information.
   */
  deleteSystemOrDevice = () => {
    const { buildingInfoId } = this.props;
    const { systemInfoId } = this.state;
    this.props.deleteSystemOrDeviceAction(buildingInfoId, systemInfoId);
  };

  componentDidUpdate(prevProps) {
    if (prevProps.selectedCustomerId !== this.props.selectedCustomerId) {
      this.props.getSystemInfoAction(this.props.selectedCustomerId);
    }
    if (
      this.props.isAddDeviceSuccess &&
      prevProps.isAddDeviceSuccess !== this.props.isAddDeviceSuccess
    ) {
      this.props.clearState();
      this.props.getSystemInfoAction(this.props.selectedCustomerId);
      this.props.reportCat();
      this.setState({
        systemInfo: { ...JSON.parse(this.baseState).systemInfo },
      });
      displayToast(this.props.addDeviceSuccessMessage, 'success');
    }
    if (this.props.deleteSuccessMessage) {
      displayToast(this.props.deleteSuccessMessage, 'success');
      this.props.clearState();
      this.props.getSystemInfoAction(this.props.selectedCustomerId);
    } else if (this.props.deleteErrorMessage) {
      displayToast(this.props.deleteErrorMessage, 'error');
    }
  }

  componentDidMount() {
    this.props.getSystemInfoAction(this.props.selectedCustomerId);
    this.props.setSubMenuVisibility(true);
  }

  infoSystemInfo = (willTooltipShow) => {
    if (this.state.showSystemInfo !== willTooltipShow) {
      this.setState({ showSystemInfo: willTooltipShow });
    }
  };

  // Handle Mobile Table View
  setClassVisibility = (employeeId) => {
    const isClassVisible = {};
    if (Object.keys(this.state.isClassVisible)[0] === employeeId) {
      isClassVisible[`${employeeId}`] =
        !this.state.isClassVisible[`${employeeId}`];
    } else {
      isClassVisible[`${employeeId}`] = true;
    }
    this.setState({ isClassVisible });
  };

  render() {
    const {
      systemInfoErrors,
      isDeleteConfirmModalVisible,
      showSystemInfo,
      isClassVisible,
    } = this.state;
    const { isAddDeviceFetching, isDeleteApiFetching, reportCategoryList } =
      this.props;

    return (
      <div>
        {(isAddDeviceFetching || isDeleteApiFetching) && <Loader />}
        {isDeleteConfirmModalVisible && (
          <DeleteConfirmationModal
            handleClickCancel={() =>
              this.setState({ isDeleteConfirmModalVisible: false })
            }
            handleClickCross={() => {
              this.setState({ isDeleteConfirmModalVisible: false });
            }}
            handleClickDelete={() =>
              this.setState({ isDeleteConfirmModalVisible: false }, () =>
                this.deleteSystemOrDevice()
              )
            }
            deleteMessage="Are you sure you want to delete this System Info?"
          />
        )}
        <SystemInfoLayout
          reportCategoryList={reportCategoryList}
          systemInfo={this.state.systemInfo}
          systemInfoList={this.props.systemInfoList}
          systemInfoErrors={systemInfoErrors}
          isFetching={this.props.isFetching}
          hasMoreItems={false}
          setSystemInfo={this.setSystemInfo}
          handleClickSave={this.addSystemOrDevice}
          resetState={this.resetState}
          setSelectedSystemInfoId={this.setSelectedSystemInfoId}
          resetErrors={this.resetErrors}
          reportGridShow={this.props.reportGridShow}
          callBack={() => this.props.setSubMenuVisibility(false)}
          showSystemInfo={showSystemInfo}
          infoSystemInfo={this.infoSystemInfo}
          isClassVisible={isClassVisible}
          setClassVisibility={this.setClassVisibility}
          SetIsPromptAction={this.props.SetIsPromptAction}
          isRenderedInReports={this.props.isRenderedInReports}
        />
      </div>
    );
  }
}

const mapStateToProps = ({
  getSystemInfo,
  setSelectedCustomer,
  addSystemOrDevice,
  deleteSystemOrDevice,
  reportCategory,
}) => ({
  selectedCustomerId: setSelectedCustomer.selectedCustomerId,
  systemInfoList: getSystemInfo.systemInfoList,
  buildingInfoId: getSystemInfo.systemInfoId,
  isFetching: getSystemInfo.isFetching,
  isAddDeviceSuccess: addSystemOrDevice.isSuccess,
  isAddDeviceFetching: addSystemOrDevice.isFetching,
  isDeleteApiFetching: deleteSystemOrDevice.isFetching,
  deleteSuccessMessage: deleteSystemOrDevice.success,
  deleteErrorMessage: deleteSystemOrDevice.error,
  reportGridShow: setSelectedCustomer.reportLogoList,
  reportCategoryList: reportCategory.reportData,
  addDeviceSuccessMessage: addSystemOrDevice.success,
});

SystemInfo.propTypes = {
  selectedCustomerId: PropTypes.string,
  systemInfoList: PropTypes.array,
  getSystemInfoAction: PropTypes.func,
  isFetching: PropTypes.bool,
  addSystemOrDeviceAction: PropTypes.func,
  buildingInfoId: PropTypes.string,
  deleteSystemOrDeviceAction: PropTypes.func,
  clearState: PropTypes.func,
  isAddDeviceSuccess: PropTypes.bool,
  isDeleteApiFetching: PropTypes.bool,
  deleteSuccessMessage: PropTypes.string,
  deleteErrorMessage: PropTypes.string,
  isAddDeviceFetching: PropTypes.bool,
  setSelectedCustomer: PropTypes.func,
  resetSelectedCustomer: PropTypes.func,
  reportGridShow: PropTypes.bool,
  reportCategoryList: PropTypes.object,
  reportCat: PropTypes.func,
  setSubMenuVisibility: PropTypes.func,
  SetIsPromptAction: PropTypes.func,
  addDeviceSuccessMessage: PropTypes.string,
  isRenderedInReports: PropTypes.bool,
};

export default connect(mapStateToProps, {
  getSystemInfoAction,
  addSystemOrDeviceAction,
  deleteSystemOrDeviceAction,
  clearState,
  reportCat,
  resetSelectedCustomer,
  setSubMenuVisibility,
  SetIsPromptAction,
})(SystemInfo);
