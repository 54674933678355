import { store } from '../../../redux/store/initStore';
import actions from '../../../redux/constants/ActionConstants';
import { genericRequest } from './index';

export default async function newGenericReport({
  createdFor: clientId,
  reportCategory,
  reportType
}, history) {
  store.dispatch({ type: actions.SHOW_LOADER_GLOBAL })
  const { data: { data: { response } } } = await genericRequest({
    url: 'genericReport',
    method: 'post',
    data: { clientId, reportCategory, reportType },
  })
  history.push({
    pathname: `/genericReportDetail/${response._id}`,
    state: response 
  })
  store.dispatch({ type: actions.HIDE_LOADER_GLOBAL })
}