import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import SearchBar from '../SearchBar/SearchBar';
import DnDTable from '../DnDTable';
import {
  fetchJobDetails,
  checkPermission,
} from '../../_helpers/commonFunctions';
import getJobListAction, {
  getMissedJobListAction,
  getNextMonthJobListAction,
} from '../../redux/actions/jobLinkActions';
import { subPermissions } from '../../libs/constants/permissions';
import {
  runCoachMarks,
  toggleCoachmarksVisibility,
} from '../../redux/actions/signUp';
import JobListRow from './JobListRow';
import CustomDropDown from '../ReallyCustomDropDown';
import { ChevronRight } from '@material-ui/icons';
import DropdownSelect from '../../components_2/DropdownSelect';
import SearchInput from '../../components_2/SearchInput';

const headerDataComingJobs = [
  {
    title: 'Due this Month',
    isSortable: false,
  },
];

const headerDataMissedJobs = [
  {
    title: 'Missed Inspections',
    isSortable: false,
  },
];

const headerDataNextMonthJobs = [
  {
    title: 'Due next Month',
    isSortable: false,
  },
];

const listOptions = [
  { value: 'dueThisMonth', label: 'Due this month' },
  { value: 'missedInspections', label: 'Missed Inspections' },
  { value: 'dueNextMonth', label: 'Due next month' },
  { value: 'all', label: 'All' },
]

export class JobList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isJobListOpen: false,
      searchValue: '',
      jobs: [],
      missedJob: [],
      nextMonthJob: [],
      selectedCategories: [],
      defaultList: 'dueThisMonth'
    };
    this.isViewJobs = checkPermission(subPermissions.viewJobsDueMissed);
  }

  jobListBodyRef = React.createRef();

  /**
   * sets new jobs in state
   *
   * @memberof JobList
   */
  setJobs = () => {
    const { missedJobs, nextMonthJobs } = this.props;
    const { jobList } = this.props.jobList;
    const jobs = fetchJobDetails(jobList, true);
    const missedJob = fetchJobDetails(missedJobs.jobList, true);
    const nextMonthJob = fetchJobDetails(nextMonthJobs.jobList, true);
    this.setState({ jobs, missedJob, nextMonthJob });
  };

  /**
   * Fetches due this month jobs list
   *
   * @memberof JobList
   */
  getJobList = (queryParams = {}, page) => {
    this.props.getJobListAction(
      {
        ...queryParams,
        startDate: moment().startOf('day').valueOf(),
        endDate: moment().endOf('month').valueOf(),
      },
      !page ? this.props.jobList.currentPage + 1 : page,
      100
    );
  };

  /**
   * Fetches missed jobs list
   *
   * @memberof JobList
   */
  getMissedJobList = (queryParams = {}, page) => {
    this.props.getMissedJobListAction(
      queryParams,
      !page ? this.props.missedJobs.currentPage + 1 : page,
      100
    );
  };

  /**
   * Fteches due next month jobs list
   *
   * @memberof JobList
   */
  getDueNextMonthJobList = (queryParams = {}, page) => {
    this.props.getNextMonthJobListAction(
      queryParams,
      !page ? this.props.nextMonthJobs.currentPage + 1 : page,
      100
    );
  };

  /**
   * Sets search text
   *
   * @param {object} e
   * @memberof JobList
   */
  setSearchText(e) {
    this.setState(
      {
        searchValue: e.target ? e.target.value : '',
      },
      () => {
        if (!this.state.searchValue) {
          this.handleSearch();
        }
      }
    );
  }

  /**
   * Handles job searching
   *
   * @memberof JobList
   */
  handleSearch = () => {
    const { selectedCategories, searchValue } = this.state;
    const queryParams = {};
    if (selectedCategories.length) {
      queryParams.reportCategory = selectedCategories;
    }
    if (searchValue) {
      queryParams.search = searchValue;
    }
    this.getJobList(queryParams, 1);
    this.getMissedJobList(queryParams, 1);
    this.getDueNextMonthJobList(queryParams, 1);
  };

  fetchCategories = (selectedCategories) => {
    const queryParams = {
      reportCategory: selectedCategories
    };
    this.getJobList(queryParams, 1);
    this.getMissedJobList(queryParams, 1);
    this.getDueNextMonthJobList(queryParams, 1);
  };

  componentDidUpdate(prevProps) {
    const prevIsFetching = prevProps.jobList.isSuccess;
    const newIsFetching = this.props.jobList.isSuccess;
    const prevIsFetchingMissed = prevProps.missedJobs.isSuccess;
    const newIsFetchingMissed = this.props.missedJobs.isSuccess;
    const prevIsFetchingNext = prevProps.nextMonthJobs.isSuccess;
    const newIsFetchingNext = this.props.nextMonthJobs.isSuccess;

    const prevIsError = prevProps.jobList.error;
    const newIsError = this.props.jobList.error;
    const prevIsErrorMissed = prevProps.missedJobs.error;
    const newIsErrorMissed = this.props.missedJobs.error;
    const prevIsErrorNext = prevProps.nextMonthJobs.error;
    const newIsErrorNext = this.props.nextMonthJobs.error;

    if (
      (prevIsFetching !== newIsFetching && newIsFetching) ||
      (prevIsFetchingMissed !== newIsFetchingMissed && newIsFetchingMissed) ||
      (prevIsFetchingNext !== newIsFetchingNext && newIsFetchingNext) ||
      (prevIsError !== newIsError && newIsError) ||
      (prevIsErrorMissed !== newIsErrorMissed && newIsErrorMissed) ||
      (prevIsErrorNext !== newIsErrorNext && newIsErrorNext)
    ) {
      this.setJobs();
    }
    if (checkPermission(subPermissions.viewJobsDueMissed) !== this.isViewJobs) {
      this.isViewJobs = checkPermission(subPermissions.viewJobsDueMissed);
      this.handleSearch();
    }
  }

  componentDidMount() {
    this.setJobs();
  }

  render() {
    const {
      isJobListOpen,
      jobs,
      missedJob,
      searchValue,
      nextMonthJob,
      defaultList,
    } = this.state;

    const {
      schedulerData,
      newEvent,
      taskDndSource,
      jobList,
      handleClickJobListItem,
      missedJobs,
      nextMonthJobs,
      currentCoachmarkTarget,
    } = this.props;

    const { isFetching, pages, currentPage } = jobList;

    const jobsLists = (
      <div className="p-3 border-md mt-2 bg-white rounded-md w-72 sm:w-80">
        <SearchInput
          onSearch={this.handleSearch}
          onChange={e => this.setSearchText(e)}
          value={searchValue}
          placeholder="Search"
        />
        <DropdownSelect
          multiple={true}
          allowClear
          placeholder="Filter by category"
          onChange={({target}) => {
            this.setState({ selectedCategories: target.value });
            this.fetchCategories(target.value);
          }}
          value={this.state.selectedCategories}
          options={this.props.reportCategories}
          className="mt-2"
        />
        <DropdownSelect
          value={defaultList}
          options={listOptions}
          onChange={({target}) => {
            this.setState({ defaultList: target.value })
          }}
          className="mt-2"
        />
        {missedJob.length > 0 &&
          (defaultList == 'missedInspections' || defaultList == 'all') && (
            <DnDTable
              headerData={headerDataMissedJobs}
              bodyData={missedJob}
              RowToRender={JobListRow}
              loadMore={this.getMissedJobList}
              isFetching={missedJobs.isFetching}
              hasMoreItems={missedJobs.currentPage < missedJobs.pages}
              className="missedJobTables"
              isLoaderVisible={missedJobs.isFetching}
              isEndMessageVisible={
                !missedJob.length && !missedJobs.isFetching
              }
              tableClassName="table-responsive global-table global-sm-table mr-bt-10 job-list-item-coachmark"
              schedulerData={schedulerData}
              newEvent={newEvent}
              taskDndSource={taskDndSource}
              rowToRenderProps={{
                handleClickJobListItem,
                toggleCoachmarksVisibility:
                  this.props.toggleCoachmarksVisibility,
                currentCoachmarkTarget,
              }}
              componentMaxHeight="328px"
            />
          )}
        {(defaultList == 'dueThisMonth' || defaultList == 'all')  && (
          <DnDTable
            headerData={headerDataComingJobs}
            bodyData={jobs}
            RowToRender={JobListRow}
            loadMore={this.getJobList}
            isFetching={isFetching}
            hasMoreItems={currentPage < pages}
            className="missedJobTables"
            isLoaderVisible={isFetching}
            isEndMessageVisible={!jobs.length && !isFetching}
            tableClassName="table-responsive global-table global-sm-table mr-bt-10 job-list-item-coachmark"
            schedulerData={schedulerData}
            newEvent={newEvent}
            taskDndSource={taskDndSource}
            rowToRenderProps={{
              handleClickJobListItem,
              toggleCoachmarksVisibility:
                this.props.toggleCoachmarksVisibility,
              currentCoachmarkTarget,
            }}
            componentMaxHeight="328px"
          />
        )}
        {nextMonthJob.length > 0 &&
          (defaultList == 'dueNextMonth' || defaultList == 'all') && (
            <DnDTable
              headerData={headerDataNextMonthJobs}
              bodyData={nextMonthJob}
              RowToRender={JobListRow}
              loadMore={this.getDueNextMonthJobList}
              isFetching={nextMonthJobs.isFetching}
              hasMoreItems={
                nextMonthJobs.currentPage < nextMonthJobs.pages
              }
              className="missedJobTables"
              isLoaderVisible={nextMonthJobs.isFetching}
              isEndMessageVisible={!nextMonthJob.length && !isFetching}
              tableClassName="table-responsive global-table global-sm-table mr-bt-10"
              schedulerData={schedulerData}
              newEvent={newEvent}
              taskDndSource={taskDndSource}
              rowToRenderProps={{
                handleClickJobListItem,
              }}
              componentMaxHeight="328px"
            />
          )}
      </div>
    )

    return (
      <CustomDropDown
        open={isJobListOpen}
        onOpenChange={open => this.setState({ isJobListOpen: open })}
        overlay={jobsLists}
        placement="right"
        className="flex-shrink-0"
      >
        <div className="bg-gray-700 text-white pl-3 pr-2 py-1.5 rounded text-lg flex items-center cursor-pointer">
          <FormattedMessage id="JOB LIST" defaultMessage="JOB LIST" />
          <ChevronRight
            className={`transform text-white ml-2 rotate-90`}
          />
        </div>
      </CustomDropDown>
    );
  }
}

JobList.propTypes = {
  schedulerData: PropTypes.object, // job board data
  newEvent: PropTypes.func, // current selected event
  taskDndSource: PropTypes.object,
  jobList: PropTypes.object, // jobs list
  getJobListAction: PropTypes.func, // fetches due this month jobs
  reportCategories: PropTypes.array, // all report categories
  handleClickJobListItem: PropTypes.func, // handles click event for job list item
  getMissedJobListAction: PropTypes.func, // fetches missed jobs
  missedJobs: PropTypes.object, // missed jobs
  getNextMonthJobListAction: PropTypes.func, // fetches next month jobs
  nextMonthJobs: PropTypes.object, // next month jobs
  runCoachMarks: PropTypes.func,
  toggleCoachmarksVisibility: PropTypes.func,
  currentCoachmarkTarget: PropTypes.string,
};

const mapStateToProps = ({
  jobListDetails,
  reportCategory,
  auth,
  signUpStatus,
}) => ({
  jobList: jobListDetails.jobList,
  missedJobs: jobListDetails.missedJobList,
  nextMonthJobs: jobListDetails.nextMonthJobList,
  reportCategories: Object.keys(reportCategory.reportData).map(value => ({ value, label: value })),
  auth,
  currentCoachmarkTarget: signUpStatus.currentTarget,
});

export default connect(mapStateToProps, {
  getJobListAction,
  getMissedJobListAction,
  getNextMonthJobListAction,
  runCoachMarks,
  toggleCoachmarksVisibility,
})(JobList);
