import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Button from '../../components/Button';

const ImageUploadModalBody = (props) => {
  const {
    handleChange,
    selectedImage,
    onSave,
    validationErrs,
    handleClickBack,
  } = props;
  return (
    <div className="modal-body">
      <div className="custom-input-file">
        <label>
          <span className="inner-label">
            <span className="upload-text">
              {selectedImage && selectedImage.name ? (
                <span style={{ color: 'red' }}>{selectedImage.name}</span>
              ) : (
                <FormattedMessage
                  id="Upload Image"
                  defaultMessage="Upload Image"
                />
              )}
            </span>
            <img src="/img/upload.png" style={{ width: '45px' }} />
            <input
              type="file"
              name="filename"
              accept="image/*"
              size={60}
              onChange={handleChange}
            />
          </span>
        </label>
        {validationErrs && validationErrs.image && (
          <span className="errorMessage">
            <FormattedMessage
              defaultMessage={`*${validationErrs.image}`}
              id={`*${validationErrs.image}`}
            />
          </span>
        )}
      </div>
      <br />
      <div className="cust-select-btns">
        <Button
          type="button"
          color="#b71b1b"
          onBtnClick={handleClickBack}
          text="Back"
        />
        <Button type="button" color="#6AA14E" onBtnClick={onSave} text="Save" />
      </div>
    </div>
  );
};

ImageUploadModalBody.propTypes = {
  handleChange: PropTypes.func,
  selectedImage: PropTypes.object,
  onSave: PropTypes.func,
  validationErrs: PropTypes.object,
  handleClickBack: PropTypes.func,
};

export default ImageUploadModalBody;
