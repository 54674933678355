import actionConstants from '../constants/ActionConstants';
import { notificationCountApi } from '../../api/apiCallsCustomer';
import ActionDispatcher from '../../api/ActionDispatcher';

const getNotificationCount = () =>
  ActionDispatcher(
    notificationCountApi.bind(null),
    actionConstants.SET_NOTIFICATION_COUNT,
    actionConstants.SET_NOTIFICATION_COUNT_FAILURE,
    actionConstants.SET_NOTIFICATION_COUNT_REQUEST
  );

export default getNotificationCount;
