import actionConstants from '../constants/ActionConstants';
import { updateStatus } from '../../api';
import ActionDispatcher from '../../api/ActionDispatcher';

const showTeamListAction = (employeeUpdatedStatus) =>
  ActionDispatcher(
    updateStatus.bind(null, employeeUpdatedStatus),
    actionConstants.UPDATE_EMPLOYEE_STATUS_SUCCESS,
    actionConstants.UPDATE_EMPLOYEE_STATUS_FAILURE,
    actionConstants.UPDATE_EMPLOYEE_STATUS_REQUEST
  );

export default showTeamListAction;
