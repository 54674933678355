import React, { useContext } from 'react';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { isEmpty } from 'lodash';
import {
  OfflineModeContext,
  toggleOfflineErrorMessage,
} from '../../../../libs/constants/offlineEnhanced';
import { displayToast } from '../../../../_helpers/commonFunctions';
import ToolTip from '../../../../components/ToolTip';
import { toolTips } from '../../../../utils/toolTips';
import { EVENTS, saveEvent } from '../../../../utils/eventAnalytics';

const OfflineModeHeader = (props) => {
  const offlineContextValue = useContext(OfflineModeContext);

  const { isOffline, jobsList, reportListByJobIds, reportListByCustomer } =
    offlineContextValue.offlineMode;

  const handleToggleOffline = () => {
    saveEvent(EVENTS.OFFLINE_MODE_SWITCH_ONLINE_OFFLINE, { isOffline });
    const isOfflineReportListEmpty =
      isEmpty(jobsList) &&
      isEmpty(reportListByCustomer.reportListByCustomer) &&
      isEmpty(reportListByJobIds.reportListByJobIds);
    if (!isOffline) {
      if (isOfflineReportListEmpty) {
        offlineContextValue.toggleModalVisibility(true);
      } else {
        displayToast(toggleOfflineErrorMessage, 'error');
      }
    } else {
      offlineContextValue.toggleOffline(false);
    }
  };

  return (
    <>
      <div className="card-header offline-enhanced">
        <FormattedMessage id="Online" defaultMessage="Online" />
        <ul className="tg-list subDivOfflineBtn">
          <li className="tg-list-item">
            <input
              className="tgl tgl-light"
              id="cb2"
              checked={offlineContextValue.offlineMode.isOffline}
              type="checkbox"
              onClick={handleToggleOffline}
            />
            <label
              className="tgl-btn tgl-btn-offline"
              htmlFor="cb2"
              data-tip={props.intl.formatMessage({
                id: toolTips.offline.offlineToggle,
              })}
              data-for="offline-toggle-switch"
            ></label>
          </li>
        </ul>
        <FormattedMessage id="Offline" defaultMessage="Offline" />
      </div>
      <ToolTip id="offline-toggle-switch" place="top" />
    </>
  );
};

OfflineModeHeader.propTypes = {
  intl: intlShape.isRequired,
};

export default injectIntl(OfflineModeHeader);
