import 'babel-polyfill';
import React, { useEffect, useState, Suspense } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import { Route, Switch, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { IntlProvider } from 'react-intl';
import isEmpty from 'lodash/isEmpty';
import LoginRouteProtector from '../components/RouterProtectors/LoginRouteProtector';
import LogoutRouteProtector from '../components/RouterProtectors/LogoutRouteProtector';
import InstructionsPWA from '../views/InstructionsPWA';
import messages from '../i18n/messages';
import endPoints from '../routes/endpoints';
import TermsAndCondition from '../views/TermsAndCondition/TermsAndCondition';
import resetAllReducersAction from '../redux/actions/resetState';
import Loader from '../components/Loader';
import LoaderGlobal from '../components/LoaderGlobal';
import { loginauth } from '../redux/actions/auth';
import { IntercomProvider } from 'react-use-intercom';
import { API } from '../config';
import PwaSnackbarUpdate from '../components/PwaSnackBar/PwaSnackbarUpdate';
import PullToRefresh from 'react-simple-pull-to-refresh';
import { ConfigProvider } from 'antd';
import 'moment/locale/es';
import { setDefaultOptions } from 'date-fns';
import { localesAntd, localesDateFns } from '../utils/utils';
import { GeoLocationProvider } from '../components/GeoLocation/GeoLocationProvider';
import moment from 'moment';
import { store } from '../redux/store/initStore';
import posthog from 'posthog-js';

const Header = React.lazy(() => import('../components/Header'));
const ForgetPassword = React.lazy(() => import('../views/ForgetPassword'));
const ChangePassword = React.lazy(() => import('../views/ChangePassword'));
const SignUp = React.lazy(() => import('../views/SignUp'));
const Login = React.lazy(() => import('../views/Login'));
const PrivacyPolicy = React.lazy(() => import('../views/PrivacyPolicy'));
const Dashboard = React.lazy(() => import('../views/Dashboard'));
const Home = React.lazy(() => import('../layouts/Home'));
const ResetPassword = React.lazy(() => import('../views/ResetPassword'));

const App = (props) => {
  toast.configure({
    autoClose: 3000,
    draggable: false,
    hideProgressBar: true,
    pauseOnHover: false,
  });

  const { lang } = props;
  setDefaultOptions({ locale: localesDateFns[lang] }); // set default locale for date-fns
  moment.locale(lang); // set default locale for moment
  const [renderRedirect, setRenderRedirect] = useState(false);
  const [isPullToRefreshEnabled, setIsPullToRefreshEnabled] = useState(false);

  const handleRedirectFromAdmin = (customerDetails) => {
    if (customerDetails) {
      const { data } = customerDetails;
      let body = null;
      try {
        body = !!data && typeof data === 'string' && JSON.parse(data);
      } catch (error) {}
      if (body && !isEmpty(body) && body.statusCode === 200) {
        props.loginauth(undefined, undefined, body, true);
      }
    }
  };

  useEffect(() => {
    const state = store.getState();
    const { auth } = state;
    if (auth && auth.loginData && auth.loginData.adminEmail && process.env.BUILD_ENV === 'production') {
      const { adminEmail, email, _id, customerId, userName } = auth.loginData;
      posthog.init('phc_6EeSORyrgFmGJvPxAIm8g012H7rkFDdBNIsLXTq5Gl3', { api_host: 'https://us.i.posthog.com' })
      posthog.identify(_id, { email, adminEmail, customerId, userName });
    }
    window.addEventListener('message', handleRedirectFromAdmin, false);
    if (window.location.search.includes('isAdmin=true')) {
      // reset all reducers if viewing customer details from admin
      if (window.opener && window.opener.parent) {
        window.opener.parent.postMessage('firelab-ready-for-login', '*');
      }
      props.resetAllReducersAction();
    }
    const path = window.location.pathname.split('/');
    if (
      !props.isCustomerChild &&
      path.length > 2 &&
      path.includes('customer')
    ) {
      // redirects to customer if user is trying to access any incorrect route
      setRenderRedirect(true);
    }
    
    const interval = setInterval(() => {
      const isMobileDevice =
        'ontouchstart' in window ||
        navigator.maxTouchPoints > 0 ||
        navigator.msMaxTouchPoints > 0;

      const gpsUrls = [
        '/team/gps',
        '/jobLink/autoRouting',
        /^\/team\/locations\//,
      ];

      const currentUrl = localStorage.getItem('url');
      const isAtGps = gpsUrls.some((url) => {
        if (typeof url === 'string') {
          return currentUrl === url;
        } else if (url instanceof RegExp) {
          return url.test(currentUrl);
        }
        return false;
      });

      const enableRefresh = isMobileDevice && !isAtGps;
      setIsPullToRefreshEnabled(enableRefresh);
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const onRefresh = () => {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve();
        window.location.reload();
      }, 500);
    });
  };

  return (
    <IntercomProvider appId={API.INTERCOM_APP_ID}>
      <ConfigProvider locale={localesAntd[lang]}>
        <IntlProvider locale={lang} messages={messages[lang]}>
          <GeoLocationProvider>
            <Suspense fallback={<Loader />}>
              <InstructionsPWA />
              <PwaSnackbarUpdate />
              <LoaderGlobal />
              <PullToRefresh
                onRefresh={onRefresh}
                isPullable={isPullToRefreshEnabled}
              >
                {renderRedirect && <Redirect to={endPoints.customer} />}
                <ToastContainer hideProgressBar />
                <Header />
                <Switch>
                  <Route
                    path={endPoints.login}
                    component={LogoutRouteProtector(Login)}
                  />
                  <Route
                    path={endPoints.resetPassword}
                    component={ForgetPassword}
                  />
                  <Route
                    path={endPoints.tokenExpired}
                    component={LogoutRouteProtector(ForgetPassword)}
                  />
                  <Route
                    path={endPoints.forgetPassword}
                    component={LogoutRouteProtector(ResetPassword)}
                  />
                  <Route
                    path={endPoints.signUp}
                    component={LogoutRouteProtector(SignUp)}
                  />
                  <Route
                    path={endPoints.termsAndConditions}
                    component={TermsAndCondition}
                  />
                  <Route
                    path={endPoints.privacyPolicy}
                    component={PrivacyPolicy}
                  />
                  <Route
                    path={endPoints.changePassword}
                    component={LoginRouteProtector(ChangePassword)}
                  />
                  <Route
                    path={endPoints.default}
                    exact
                    component={LoginRouteProtector(Dashboard)}
                  />
                  <Route
                    path={`${endPoints.default}:id`}
                    component={LoginRouteProtector(Home)}
                  />
                </Switch>
              </PullToRefresh>
            </Suspense>
          </GeoLocationProvider>
        </IntlProvider>
      </ConfigProvider>
    </IntercomProvider>
  );
};

App.propTypes = {
  lang: PropTypes.string.isRequired, // language selected for app
  isCustomerChild: PropTypes.bool, // is true if the route accessed is a submenu of customer
  history: PropTypes.object, // history object
  resetAllReducersAction: PropTypes.func, // resets all reducers
  loginauth: PropTypes.func,
};

const mapStateToProps = (state) => ({
  lang: state.locale.lang,
});

export default connect(mapStateToProps, { resetAllReducersAction, loginauth })(
  App
);
