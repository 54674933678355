import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';

const CHAT_MESSAGE_MAX_LEN = 200;

class ChatBodyFooter extends Component {
  state = {
    textValue: '',
  };

  componentDidUpdate(prevProps) {
    const { activeChatId } = this.props;
    if (activeChatId !== prevProps.activeChatId) {
      this.resetTextValue();
    }
  }

  resetTextValue = () => {
    this.setState({ textValue: '' });
  };

  handleTextValueChange = (e) => {
    const value = e.target.value.trimLeft().slice(0, CHAT_MESSAGE_MAX_LEN);
    this.setState({ textValue: value });
  };

  handleSendPress = (e, isSendDisabled) => {
    e.preventDefault();
    if (isSendDisabled) {
      return;
    }
    const { textValue } = this.state;
    this.props.onSendPress(textValue.trim());
    this.setState({ textValue: '' });
  };

  render() {
    const { textValue } = this.state;
    const { isChatLoading } = this.props;
    const isSendDisabled = isChatLoading || textValue.trim() === '';
    const placeholder = `${this.props.intl.formatMessage({
      id: 'Write message here',
    })}…`;
    return (
      <div className="chat-message clearfix">
        <textarea
          name="message-to-send"
          spellCheck="true"
          className="message-to-send"
          placeholder={placeholder}
          value={textValue}
          onChange={this.handleTextValueChange}
        />
        <button
          className={`send-btn sprite-icon1 ${
            isSendDisabled ? 'send-btn-disabled' : ''
          }`}
          onClick={(e) => this.handleSendPress(e, isSendDisabled)}
          title={isChatLoading && 'chat is re-connecting'}
        />
      </div>
    );
  }
}

ChatBodyFooter.propTypes = {
  activeChatId: PropTypes.string.isRequired,
  isChatLoading: PropTypes.bool.isRequired,
  onSendPress: PropTypes.func.isRequired,
  intl: intlShape.isRequired,
};

export default injectIntl(ChatBodyFooter);
