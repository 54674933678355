import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isEqual } from 'lodash';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import DeleteConfirmationModal from '../DeleteConfirmationModal';
import ToolTip from '../ToolTip';
import {
  getInitialInspectorNotes,
  getInitialCustomerNotes,
  getNotes,
} from '../../_helpers/commonFunctions';

export class NotesBody extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inspectorNotes: getInitialInspectorNotes(this.props.iNotes),
      customerNotes: getInitialCustomerNotes(this.props.cNotes),
      isDelete: false,
      deletionInfo: {
        type: '',
        id: null,
      },
    };
  }

  handleChangeNotes = (noteIndex, noteValue, isInspector = false) => {
    const { handleModalNotes } = this.props;
    let notes = JSON.parse(JSON.stringify(this.state.customerNotes));
    if (isInspector) {
      notes = JSON.parse(JSON.stringify(this.state.inspectorNotes));
    }
    notes[noteIndex][noteIndex] = noteValue;
    const modalNotes = getNotes(notes);
    if (isInspector) {
      this.setState({ inspectorNotes: notes });
      if (noteValue) handleModalNotes('inspector', modalNotes);
      return;
    }
    this.setState({ customerNotes: notes });
    if (noteValue) handleModalNotes('customer', modalNotes);
  };

  handleAddNote = (e, isInspector = false) => {
    e.preventDefault();
    const { customerNotes, inspectorNotes } = { ...this.state };
    const note = {};
    if (isInspector) {
      note[inspectorNotes.length] = '';
      inspectorNotes.push(note);
      this.setState({ inspectorNotes });
    } else {
      note[customerNotes.length] = '';
      customerNotes.push(note);
      this.setState({ customerNotes });
    }
  };

  handleDelete = () => {
    const { handleModalNotes } = this.props;
    const { deletionInfo } = this.state;
    const customerNotes = [...this.state.customerNotes];
    const inspectorNotes = [...this.state.inspectorNotes];
    if (deletionInfo.type === 'customer') {
      customerNotes.splice(deletionInfo.id, 1);
      const notes = getNotes(customerNotes);
      handleModalNotes('customer', notes);
      this.setState({
        customerNotes,
        isDelete: false,
        deletionInfo: { id: null, type: '' },
      });
    } else if (deletionInfo.type === 'inspector') {
      inspectorNotes.splice(deletionInfo.id, 1);
      const notes = getNotes(inspectorNotes);
      handleModalNotes('inspector', notes);
      this.setState({
        inspectorNotes,
        isDelete: false,
        deletionInfo: { id: null, type: '' },
      });
    }
  };

  handleClickDelete = (
    isDelete = false,
    deletionInfo = { id: null, type: '' }
  ) => {
    this.setState({ isDelete, deletionInfo });
  };

  componentDidUpdate(prevProps) {
    const iNotes = [...this.props.iNotes];
    const cNotes = [...this.props.cNotes];
    if (!isEqual(iNotes.sort(), prevProps.iNotes.sort())) {
      this.setState({
        inspectorNotes: getInitialInspectorNotes(this.props.iNotes),
      });
    }
    if (!isEqual(cNotes.sort(), prevProps.cNotes.sort())) {
      this.setState({
        customerNotes: getInitialCustomerNotes(this.props.cNotes),
      });
    }
  }

  render() {
    const { inspectorNotes, customerNotes, isDelete } = this.state;

    const { intl } = this.props;

    return (
      <div className="note-description-area">
        <div className="row">
          {isDelete && (
            <DeleteConfirmationModal
              deleteMessage="Are you sure you want to delete this note?"
              handleClickCross={() => this.handleClickDelete()}
              handleClickCancel={this.handleClickDelete}
              handleClickDelete={this.handleDelete}
            />
          )}
          <div className="col-md-6">
            <label htmlFor="comment">
              <span
                data-tip={intl.formatMessage({
                  id: 'Add a note for the tech to see.',
                })}
                data-for="inspector-note-tooltip"
              >
                <FormattedMessage
                  id="Inspector’s Note"
                  defaultMessage="Inspector’s Note"
                />
              </span>
            </label>
            <ToolTip id="inspector-note-tooltip" place="right" />
            {inspectorNotes.map((iNote, index) => (
              <div className="textarea-wrap" key={index}>
                <textarea
                  type="textarea"
                  spellCheck="true"
                  className="form-control"
                  rows="3"
                  id="comment"
                  name="commente"
                  value={inspectorNotes[index][Object.keys(iNote)[0]]}
                  onChange={(e) => {
                    this.handleChangeNotes(index, e.target.value, true);
                    this.props.setIsPrompt(true);
                  }}
                />
                <span
                  className="dlt-icon sprite-icon"
                  onClick={() =>
                    this.handleClickDelete(true, {
                      id: index,
                      type: 'inspector',
                    })
                  }
                ></span>
              </div>
            ))}
            <a
              href="#"
              className="add-note"
              onClick={(e) => this.handleAddNote(e, true)}
            >
              <span className="plus">+</span>
              <FormattedMessage
                id={inspectorNotes.length ? 'Add Another Note' : 'Add Note'}
                defaultMessage={
                  inspectorNotes.length ? 'Add Another Note' : 'Add Note'
                }
              />
            </a>
          </div>

          <div className="col-md-6">
            <label htmlFor="comment">
              <span
                data-tip={intl.formatMessage({
                  id: 'Keep notes from customer correspondence.',
                })}
                data-for="customer-note-tooltip"
              >
                <FormattedMessage
                  id="Customer Correspondence Note"
                  defaultMessage="Customer Correspondence Note"
                />
              </span>
            </label>
            <ToolTip id="customer-note-tooltip" place="right" />
            {customerNotes.map((cNote, index) => (
              <div className="textarea-wrap" key={index}>
                <textarea
                  type="textarea"
                  spellCheck="true"
                  className="form-control"
                  rows="3"
                  id="comment"
                  name="commente"
                  value={customerNotes[index][Object.keys(cNote)[0]]}
                  onChange={(e) => {
                    this.handleChangeNotes(index, e.target.value);
                    this.props.setIsPrompt(true);
                  }}
                />
                <span
                  className="dlt-icon sprite-icon"
                  onClick={() =>
                    this.handleClickDelete(true, {
                      id: index,
                      type: 'customer',
                    })
                  }
                ></span>
              </div>
            ))}
            <a href="#" className="add-note" onClick={this.handleAddNote}>
              <span className="plus">+</span>
              <FormattedMessage
                id={customerNotes.length ? 'Add Another Note' : 'Add Note'}
                defaultMessage={
                  customerNotes.length ? 'Add Another Note' : 'Add Note'
                }
              />
            </a>
          </div>
        </div>
      </div>
    );
  }
}

NotesBody.propTypes = {
  iNotes: PropTypes.array,
  cNotes: PropTypes.array,
  handleModalNotes: PropTypes.func,
  setIsPrompt: PropTypes.func,
  intl: intlShape.isRequired,
};

export default injectIntl(NotesBody);
