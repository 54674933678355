export const JANUARY = 'JANUARY';
export const FEBRUARY = 'FEBRUARY';
export const MARCH = 'MARCH';
export const APRIL = 'APRIL';
export const MAY = 'MAY';
export const JUNE = 'JUNE';
export const JULY = 'JULY';
export const AUGUST = 'AUGUST';
export const SEPTEMBER = 'SEPTEMBER';
export const OCTOBER = 'OCTOBER';
export const NOVEMBER = 'NOVEMBER';
export const DECEMBER = 'DECEMBER';

const CURRENT_YEAR = 0;
const PREV_YEAR = -1;
const NEXT_YEAR = 1;

export const mappedMonths = {
  [JANUARY]: 1,
  [FEBRUARY]: 2,
  [MARCH]: 3,
  [APRIL]: 4,
  [MAY]: 5,
  [JUNE]: 6,
  [JULY]: 7,
  [AUGUST]: 8,
  [SEPTEMBER]: 9,
  [OCTOBER]: 10,
  [NOVEMBER]: 11,
  [DECEMBER]: 12,
};

// QUARTERLY CONFIGS
const QUARTERLY_CONFIG_1 = {
  [JANUARY]: {month: JANUARY, year: CURRENT_YEAR},
  [FEBRUARY]: {month: APRIL, year: CURRENT_YEAR},
  [MARCH]: {month: APRIL, year: CURRENT_YEAR},
  [APRIL]: {month: APRIL, year: CURRENT_YEAR},
  [MAY]: {month: JULY, year: CURRENT_YEAR},
  [JUNE]: {month: JULY, year: CURRENT_YEAR},
  [JULY]: {month: JULY, year: CURRENT_YEAR},
  [AUGUST]: {month: OCTOBER, year: CURRENT_YEAR},
  [SEPTEMBER]: {month: OCTOBER, year: CURRENT_YEAR},
  [OCTOBER]: {month: OCTOBER, year: CURRENT_YEAR},
  [NOVEMBER]: {month: JANUARY, year: NEXT_YEAR},
  [DECEMBER]: {month: JANUARY, year: NEXT_YEAR},
};
const QUARTERLY_CONFIG_2 = {
  [JANUARY]: {month: FEBRUARY, year: CURRENT_YEAR},
  [FEBRUARY]: {month: FEBRUARY, year: CURRENT_YEAR},
  [MARCH]: {month: MAY, year: CURRENT_YEAR},
  [APRIL]: {month: MAY, year: CURRENT_YEAR},
  [MAY]: {month: MAY, year: CURRENT_YEAR},
  [JUNE]: {month: AUGUST, year: CURRENT_YEAR},
  [JULY]: {month: AUGUST, year: CURRENT_YEAR},
  [AUGUST]: {month: AUGUST, year: CURRENT_YEAR},
  [SEPTEMBER]: {month: NOVEMBER, year: CURRENT_YEAR},
  [OCTOBER]: {month: NOVEMBER, year: CURRENT_YEAR},
  [NOVEMBER]: {month: NOVEMBER, year: CURRENT_YEAR},
  [DECEMBER]: {month: FEBRUARY, year: NEXT_YEAR},
};
const QUARTERLY_CONFIG_3 ={
  [JANUARY]: {month: MARCH, year: CURRENT_YEAR},
  [FEBRUARY]: {month: MARCH, year: CURRENT_YEAR},
  [MARCH]: {month: MARCH, year: CURRENT_YEAR},
  [APRIL]: {month: JUNE, year: CURRENT_YEAR},
  [MAY]: {month: JUNE, year: CURRENT_YEAR},
  [JUNE]: {month: JUNE, year: CURRENT_YEAR},
  [JULY]: {month: SEPTEMBER, year: CURRENT_YEAR},
  [AUGUST]: {month: SEPTEMBER, year: CURRENT_YEAR},
  [SEPTEMBER]: {month: SEPTEMBER, year: CURRENT_YEAR},
  [OCTOBER]: {month: DECEMBER, year: CURRENT_YEAR},
  [NOVEMBER]: {month: DECEMBER, year: CURRENT_YEAR},
  [DECEMBER]: {month: DECEMBER, year: CURRENT_YEAR},
}
export const QUARTERLY_CONFIGS = {
  [JANUARY]: QUARTERLY_CONFIG_1,
  [FEBRUARY]: QUARTERLY_CONFIG_2,
  [MARCH]: QUARTERLY_CONFIG_3,
  [APRIL]: QUARTERLY_CONFIG_1,
  [MAY]: QUARTERLY_CONFIG_2,
  [JUNE]: QUARTERLY_CONFIG_3,
  [JULY]: QUARTERLY_CONFIG_1,
  [AUGUST]: QUARTERLY_CONFIG_2,
  [SEPTEMBER]: QUARTERLY_CONFIG_3,
  [OCTOBER]: QUARTERLY_CONFIG_1,
  [NOVEMBER]: QUARTERLY_CONFIG_2,
  [DECEMBER]: QUARTERLY_CONFIG_3,
};
// QUARTERLY CONFIGS

// SEMI_ANNUAL CONFIGS
const SEMI_ANNUAL_CONFIG_1 = {
  [JANUARY]: {month: JANUARY, year: CURRENT_YEAR},
  [FEBRUARY]: {month: JULY, year: CURRENT_YEAR},
  [MARCH]: {month: JULY, year: CURRENT_YEAR},
  [APRIL]: {month: JULY, year: CURRENT_YEAR},
  [MAY]: {month: JULY, year: CURRENT_YEAR},
  [JUNE]: {month: JULY, year: CURRENT_YEAR},
  [JULY]: {month: JULY, year: CURRENT_YEAR},
  [AUGUST]: {month: JANUARY, year: NEXT_YEAR},
  [SEPTEMBER]: {month: JANUARY, year: NEXT_YEAR},
  [OCTOBER]: {month: JANUARY, year: NEXT_YEAR},
  [NOVEMBER]: {month: JANUARY, year: NEXT_YEAR},
  [DECEMBER]: {month: JANUARY, year: NEXT_YEAR},
};
const SEMI_ANNUAL_CONFIG_2 = {
  [JANUARY]: {month: FEBRUARY, year: CURRENT_YEAR},
  [FEBRUARY]: {month: FEBRUARY, year: CURRENT_YEAR},
  [MARCH]: {month: AUGUST, year: CURRENT_YEAR},
  [APRIL]: {month: AUGUST, year: CURRENT_YEAR},
  [MAY]: {month: AUGUST, year: CURRENT_YEAR},
  [JUNE]: {month: AUGUST, year: CURRENT_YEAR},
  [JULY]: {month: AUGUST, year: CURRENT_YEAR},
  [AUGUST]: {month: AUGUST, year: CURRENT_YEAR},
  [SEPTEMBER]: {month: FEBRUARY, year: NEXT_YEAR},
  [OCTOBER]: {month: FEBRUARY, year: NEXT_YEAR},
  [NOVEMBER]: {month: FEBRUARY, year: NEXT_YEAR},
  [DECEMBER]: {month: FEBRUARY, year: NEXT_YEAR},
};
const SEMI_ANNUAL_CONFIG_3 = {
  [JANUARY]: {month: MARCH, year: CURRENT_YEAR},
  [FEBRUARY]: {month: MARCH, year: CURRENT_YEAR},
  [MARCH]: {month: MARCH, year: CURRENT_YEAR},
  [APRIL]: {month: SEPTEMBER, year: CURRENT_YEAR},
  [MAY]: {month: SEPTEMBER, year: CURRENT_YEAR},
  [JUNE]: {month: SEPTEMBER, year: CURRENT_YEAR},
  [JULY]: {month: SEPTEMBER, year: CURRENT_YEAR},
  [AUGUST]: {month: SEPTEMBER, year: CURRENT_YEAR},
  [SEPTEMBER]: {month: SEPTEMBER, year: CURRENT_YEAR},
  [OCTOBER]: {month: MARCH, year: NEXT_YEAR},
  [NOVEMBER]: {month: MARCH, year: NEXT_YEAR},
  [DECEMBER]: {month: MARCH, year: NEXT_YEAR},
};
const SEMI_ANNUAL_CONFIG_4 = {
  [JANUARY]: {month: APRIL, year: CURRENT_YEAR},
  [FEBRUARY]: {month: APRIL, year: CURRENT_YEAR},
  [MARCH]: {month: APRIL, year: CURRENT_YEAR},
  [APRIL]: {month: APRIL, year: CURRENT_YEAR},
  [MAY]: {month: OCTOBER, year: CURRENT_YEAR},
  [JUNE]: {month: OCTOBER, year: CURRENT_YEAR},
  [JULY]: {month: OCTOBER, year: CURRENT_YEAR},
  [AUGUST]: {month: OCTOBER, year: CURRENT_YEAR},
  [SEPTEMBER]: {month: OCTOBER, year: CURRENT_YEAR},
  [OCTOBER]: {month: OCTOBER, year: CURRENT_YEAR},
  [NOVEMBER]: {month: APRIL, year: NEXT_YEAR},
  [DECEMBER]: {month: APRIL, year: NEXT_YEAR},
};
const SEMI_ANNUAL_CONFIG_5 = {
  [JANUARY]: {month: MAY, year: CURRENT_YEAR},
  [FEBRUARY]: {month: MAY, year: CURRENT_YEAR},
  [MARCH]: {month: MAY, year: CURRENT_YEAR},
  [APRIL]: {month: MAY, year: CURRENT_YEAR},
  [MAY]: {month: MAY, year: CURRENT_YEAR},
  [JUNE]: {month: NOVEMBER, year: CURRENT_YEAR},
  [JULY]: {month: NOVEMBER, year: CURRENT_YEAR},
  [AUGUST]: {month: NOVEMBER, year: CURRENT_YEAR},
  [SEPTEMBER]: {month: NOVEMBER, year: CURRENT_YEAR},
  [OCTOBER]: {month: NOVEMBER, year: CURRENT_YEAR},
  [NOVEMBER]: {month: NOVEMBER, year: CURRENT_YEAR},
  [DECEMBER]: {month: MAY, year: NEXT_YEAR},
};
const SEMI_ANNUAL_CONFIG_6 = {
  [JANUARY]: {month: JUNE, year: CURRENT_YEAR},
  [FEBRUARY]: {month: JUNE, year: CURRENT_YEAR},
  [MARCH]: {month: JUNE, year: CURRENT_YEAR},
  [APRIL]: {month: JUNE, year: CURRENT_YEAR},
  [MAY]: {month: JUNE, year: CURRENT_YEAR},
  [JUNE]: {month: JUNE, year: CURRENT_YEAR},
  [JULY]: {month: DECEMBER, year: CURRENT_YEAR},
  [AUGUST]: {month: DECEMBER, year: CURRENT_YEAR},
  [SEPTEMBER]: {month: DECEMBER, year: CURRENT_YEAR},
  [OCTOBER]: {month: DECEMBER, year: CURRENT_YEAR},
  [NOVEMBER]: {month: DECEMBER, year: CURRENT_YEAR},
  [DECEMBER]: {month: DECEMBER, year: NEXT_YEAR},
};
export const SEMI_ANNUAL_CONFIGS = {
  [JANUARY]: SEMI_ANNUAL_CONFIG_1,
  [FEBRUARY]: SEMI_ANNUAL_CONFIG_2,
  [MARCH]: SEMI_ANNUAL_CONFIG_3,
  [APRIL]: SEMI_ANNUAL_CONFIG_4,
  [MAY]: SEMI_ANNUAL_CONFIG_5,
  [JUNE]: SEMI_ANNUAL_CONFIG_6,
  [JULY]: SEMI_ANNUAL_CONFIG_1,
  [AUGUST]: SEMI_ANNUAL_CONFIG_2,
  [SEPTEMBER]: SEMI_ANNUAL_CONFIG_3,
  [OCTOBER]: SEMI_ANNUAL_CONFIG_4,
  [NOVEMBER]: SEMI_ANNUAL_CONFIG_5,
  [DECEMBER]: SEMI_ANNUAL_CONFIG_6,
};

