import { getToken } from '../_helpers/commonFunctions';
import call from './apiRequest';
import apiEndpoints from './apiEndpoints';

/**
 * Api call to delete employee
 * @param {string} employeeId
 */
const deleteEmployee = (employeeId) =>
  call({
    method: 'delete',
    endpoint: `${apiEndpoints.customer}/${employeeId}`,
    authKey: getToken(),
  });

export default deleteEmployee;
