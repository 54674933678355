import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import isEmpty from 'lodash/isEmpty';
import InfiniteScroll from 'react-infinite-scroll-component';
import debounce from 'lodash/debounce';
import { displayToast } from '../../_helpers/commonFunctions';
import Loader from '../PageLoader';

class SearchDropDown extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isShowDropDown: false,
      pageNo: 1,
      searchValue: '',
    };
    this.node = React.createRef();
  }

  hideDropdown = () => {
    this.setState({
      isShowDropDown: false,
      pageNo: 1,
      searchValue: '',
    });
  };

  handleClick = (e) => {
    if (this.node.current.contains(e.target)) {
      return;
    }
    this.hideDropdown();
  };

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClick);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClick);
    this.props.resetList();
  }

  componentDidUpdate(_prevProps, prevState) {
    const { error, resetList, fetchList: fetchData } = this.props;
    if (error) {
      displayToast(error, 'error');
      resetList();
    }
    const { searchValue, pageNo, isShowDropDown } = this.state;
    if (pageNo !== prevState.pageNo && this.state.isShowDropDown) {
      fetchData(
        { search: searchValue, pageNo, ...this.props.extraArgsForFetch },
        pageNo
      );
    }
    if (!prevState.isShowDropDown && isShowDropDown) {
      this.props.resetList();
      fetchData(
        { search: searchValue, pageNo, ...this.props.extraArgsForFetch },
        pageNo
      );
    }
  }

  loadMore = () => {
    const { currentPage } = this.props;
    this.setState({
      pageNo: currentPage + 1,
    });
  };

  handleChange = (item) => {
    const { onChangeList } = this.props;
    onChangeList(item);
    this.hideDropdown();
    this.props.resetList();
  };

  fetchDebounce = debounce(() => {
    const { fetchList, resetList } = this.props;
    resetList();
    fetchList({
      search: this.state.searchValue,
      pageNo: 1,
      ...this.props.extraArgsForFetch,
    });
  }, 500);

  handleSearch = (e) => {
    this.setState(
      {
        searchValue: e.target.value,
        isShowDropDown: true,
        pageNo: 1,
      },
      () => {
        this.fetchDebounce();
      }
    );
  };

  render() {
    const { isShowDropDown, searchValue } = this.state;
    const {
      currentPage,
      totalCount,
      totalPages,
      list,
      disabled,
      selected,
      isFetching,
      displayKey,
      styleToMainWrapper,
      endMessage,
      extraField,
    } = this.props;

    return (
      <div ref={this.node} style={styleToMainWrapper}>
        <div
          className={` custom-select ${!isShowDropDown ? 'label-color' : ''}`}
        >
          <div
            className={`select-selected ${
              isShowDropDown ? 'select-arrow-active' : ''
            }`}
            onClick={
              !disabled
                ? () => {
                    this.setState(
                      {
                        isShowDropDown: !isShowDropDown,
                      },
                      () => {
                        if (!this.state.isShowDropDown) this.hideDropdown();
                      }
                    );
                  }
                : () => {}
            }
          >
            {!isEmpty(selected) ? (
              this.props.isOfflinePageOn ? (
                <FormattedMessage
                  id={`${selected.createdFor.businessName} - ${selected.description} - ${selected.reportCategory}`}
                  defaultMessage="Select Here"
                />
              ) : (
                selected[displayKey]
              )
            ) : (
              <FormattedMessage id="Select Here" defaultMessage="Select Here" />
            )}
          </div>
          {isShowDropDown && (
            <div
              className="select-items mCustomScrollbar"
              data-mcs-theme="minimal-dark"
              style={{
                overflowY: 'auto',
                maxHeight:
                  window.outerWidth > 800
                    ? 0.3 * window.outerWidth
                    : 0.6 * window.outerHeight,
              }}
            >
              <div
                className="form-group d-flex"
                style={{ marginBottom: '0px' }}
              >
                <input
                  type="text"
                  placeholder="Search"
                  value={searchValue}
                  className="form-control"
                  onChange={this.handleSearch}
                  style={{
                    borderWidth: 0,
                    borderBottom: '1px solid black',
                    borderRadius: '0px',
                  }}
                />
              </div>
              <InfiniteScroll
                dataLength={list.length}
                next={() => {
                  this.loadMore();
                }}
                hasMore={currentPage < totalPages && totalCount > list.length}
                height={
                  window.outerWidth > 800
                    ? 0.2 * window.outerWidth
                    : 0.5 * window.outerHeight
                }
                loader={
                  <div className="loader-container">
                    <div className="loader" />
                  </div>
                }
                endMessage={<div className="end-message" />}
                className="tableScroller"
              >
                {isFetching && list.length === 0 ? (
                  <Loader />
                ) : (
                  <div>
                    {list.length === 0 && !extraField ? (
                      <p className="end-message tableScroller">
                        {' '}
                        <FormattedMessage
                          id={endMessage}
                          defaultMessage={endMessage}
                        />
                      </p>
                    ) : (
                      <div>
                        {list.map((item) => (
                          <div
                            key={item._id}
                            className="selecthover"
                            style={{
                              color: item._id === 'Other' ? 'red' : 'black',
                            }}
                            onClick={() => {
                              this.handleChange(item);
                            }}
                          >
                            {this.props.isOfflinePageOn
                              ? `${item.createdFor.businessName} - ${item.description} - ${item.reportCategory}`
                              : `${item[displayKey]}`}
                          </div>
                        ))}
                        {extraField && (
                          <div
                            key={extraField._id}
                            className="selecthover"
                            style={{
                              color:
                                extraField._id === 'Other' ? 'red' : 'black',
                            }}
                            onClick={() => {
                              this.handleChange(extraField);
                            }}
                          >
                            {this.props.isOfflinePageOn
                              ? `${extraField.createdFor.businessName} - ${extraField.description} - ${extraField.reportCategory}`
                              : `${extraField[displayKey]}`}
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                )}
              </InfiniteScroll>
            </div>
          )}
        </div>
      </div>
    );
  }
}

SearchDropDown.propTypes = {
  fetchList: PropTypes.func.isRequired,
  resetList: PropTypes.func.isRequired,
  selected: PropTypes.object.isRequired,
  error: PropTypes.string,
  isFetching: PropTypes.bool.isRequired,
  list: PropTypes.array,
  totalCount: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  onChangeList: PropTypes.func.isRequired,
  totalPages: PropTypes.number.isRequired,
  displayKey: PropTypes.string.isRequired,
  resetState: PropTypes.func,
  disabled: PropTypes.bool,
  extraArgsForFetch: PropTypes.object,
  isOfflinePageOn: PropTypes.bool,
  styleToMainWrapper: PropTypes.object,
  endMessage: PropTypes.string,
  extraField: PropTypes.object,
};

SearchDropDown.defaultProps = {
  displayKey: 'firstName',
  error: '',
  disabled: false,
  list: [],
  extraArgsForFetch: {},
  endMessage: 'No data found',
};
export default SearchDropDown;
