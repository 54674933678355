import React from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import Moment from 'moment';
import '../DnDTable/DnDTable.scss';
import { checkPermission } from '../../_helpers/commonFunctions';
import { subPermissions, permissions } from '../../libs/constants/permissions';
import coachMarks from '../../libs/constants/coachMarks';

const JobListRow = (props) => {
  const {
    isDragging,
    connectDragSource,
    connectDragPreview,
    handleClickJobListItem,
    toggleCoachmarksVisibility,
    currentCoachmarkTarget,
  } = props;

  const { customerName, frequency, jobId, reportCategory, type, id, allDetails } =
    props.task;
  const { description } = allDetails;
  const event = {
    start: Moment().format(),
    end: Moment().format(),
    id,
  };
  const typeId = type === 'Work Order' ? 'WO' : type[0].toUpperCase();

  const dragContent = (
    <div data-tip data-for="happyFace" className="dnd-row">
      {props.task && props.task.name}
      <span className="popover-quote" id={typeId}>
        {typeId}
      </span>
    </div>
  );
  const tooltipPlace = window.innerWidth <= 551 ? 'top' : 'left';
  return isDragging ? null : (
    <tr
      className="job-details-hover"
      style={{
        cursor: 'grab',
      }}
      onClick={
        checkPermission(subPermissions.scheduleMyJobs, permissions.jobLink) ||
        checkPermission(subPermissions.scheduleOthersJobs, permissions.jobLink)
          ? () => handleClickJobListItem(event)
          : () => {}
      }
    >
      <td
        onClick={() => {
          if (
            currentCoachmarkTarget ===
            coachMarks.inspectionReminders.jobList.target
          ) {
            toggleCoachmarksVisibility(
              null,
              null,
              coachMarks.inspectionReminders.jobList.nextTarget
            );
          }
        }}
      >
        {connectDragPreview(connectDragSource(dragContent))}
        <ReactTooltip id="happyFace" type="none" place={tooltipPlace} effect="solid">
          <span className="job-details-hover-text">
            <ul>
              <li>Name:- {customerName}</li>
              <li>Id:- {jobId}</li>
              <li>Category:- {reportCategory}</li>
              <li>Type:- {type}</li>
              <li>Frequency:- {frequency}</li>
              <li>Description:- {description}</li>
            </ul>
          </span>
        </ReactTooltip>
      </td>
    </tr>
  );
};

JobListRow.propTypes = {
  row: PropTypes.object, // row data
  task: PropTypes.object, // task data
  isDragging: PropTypes.bool, // is true if the row is being dragged
  connectDragSource: PropTypes.func, // handles drag source
  connectDragPreview: PropTypes.func, // handles drag preview
  handleClickJobListItem: PropTypes.func, // handles click item for row
  toggleCoachmarksVisibility: PropTypes.func,
  currentCoachmarkTarget: PropTypes.string,
};

export default JobListRow;
