import actionConstants from '../constants/ActionConstants';

const initialState = {
  error: '',
  isFetching: false,
  success: '',
  isSuccess: false,
  teamGpsEmployeeData: [],
  pages: 0,
  currentPage: null,
  totalPages: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionConstants.GET_TEAM_GPS_DATA_INITIAL_REQUEST:
      return {
        ...state,
        isFetching: true,
        teamGpsEmployeeData: [],
        isSuccess: false,
      };
    case actionConstants.GET_TEAM_GPS_DATA_REQUEST:
      return {
        ...state,
        isFetching: true,
        isSuccess: false,
      };
    case actionConstants.GET_TEAM_GPS_DATA_SUCCESS:
      if (action.payload.page === 1) {
        return {
          ...state,
          teamGpsEmployeeData: action.payload.docs,
          isFetching: false,
          success: action.message,
          totalPages: action.payload.pages,
          currentPage: action.payload.page,
          isSuccess: true,
        };
      }
      return {
        ...state,
        teamGpsEmployeeData: [
          ...state.teamGpsEmployeeData,
          ...action.payload.docs,
        ],
        isFetching: false,
        success: action.message,
        pages: action.payload.pages,
        currentPage: action.page,
        totalPages: action.total,
        isSuccess: true,
      };
    case actionConstants.GET_TEAM_GPS_DATA_FAILURE:
      return {
        ...state,
        error: action.message,
        isFetching: false,
        isSuccess: false,
      };
    case actionConstants.SEARCH_GPS_EMPLOYEE_REQUEST:
      return {
        ...state,
        isFetching: true,
        teamGpsEmployeeData: [],
        totalPages: null,
        currentPage: null,
      };
    case actionConstants.SEARCH_GPS_EMPLOYEE_SUCCESS:
      return {
        ...state,
        teamGpsEmployeeData:
          action.payload.page === 1
            ? [...action.payload.docs]
            : [...state.teamGpsEmployeeData, ...action.payload.docs],
        totalPages: action.payload.total,
        currentPage: action.payload.page,
        pages: action.payload.pages,
        isFetching: false,
      };
    case actionConstants.SEARCH_GPS_EMPLOYEE_FAILURE:
      return {
        ...state,
        currentPage: null,
        totalPages: null,
        error: action.error,
        isFetching: false,
      };
    case actionConstants.CLEAR_STATE:
      return {
        ...state,
        error: '',
        success: '',
        isSuccess: false,
      };

    case actionConstants.RESET_ALL_REDUCERS:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};
