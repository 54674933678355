import actionConstants from '../constants/ActionConstants';

const createSectionState = {
  isFetching: false,
  section: {},
  success: '',
  error: '',
};

const sectionListState = {
  total: 0,
  limit: 10,
  currentPage: 1,
  totalPages: 1,
  sectionList: [],
  error: '',
  isFetching: false,
};

const sectionDetailState = {
  isFetching: false,
  error: '',
  section: {},
};

const uploadVideoState = {
  isFetching: false,
  error: '',
  success: '',
};

const initialState = {
  createSectionState,
  sectionListState,
  sectionDetailState,
  uploadVideoState,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionConstants.FETCH_SECTION_DETAIL_REQUEST:
      return {
        ...state,
        sectionDetailState: {
          ...state.sectionDetailState,
          isFetching: true,
        },
      };

    case actionConstants.FETCH_SECTION_DETAIL_FAILURE:
      return {
        ...state,
        sectionDetailState: {
          ...state.sectionDetailState,
          isFetching: false,
          error: action.message,
        },
      };

    case actionConstants.FETCH_SECTION_DETAIL_SUCCESS:
      return {
        ...state,
        sectionDetailState: {
          ...state.sectionDetailState,
          isFetching: false,
          section: action.payload,
        },
      };

    case actionConstants.FETCH_SECTION_DETAIL_RESET:
      return {
        ...state,
        sectionDetailState: initialState.sectionDetailState,
      };

    case actionConstants.FETCH_SECTION_DETAIL_RESET_ERROR:
      return {
        ...state,
        sectionDetailState: {
          ...state.sectionDetailState,
          error: '',
        },
      };

    case actionConstants.FETCH_SECTION_LIST_RESET_ERROR:
      return {
        ...state,
        sectionListState: {
          ...state.sectionListState,
          error: '',
        },
      };

    case actionConstants.FETCH_SECTION_LIST_RESET:
      return {
        ...state,
        sectionListState: initialState.sectionListState,
      };

    case actionConstants.FETCH_SECTION_LIST_FAILURE:
      return {
        ...state,
        sectionListState: {
          ...state.sectionListState,
          error: action.message,
          isFetching: false,
        },
      };

    case actionConstants.FETCH_SECTION_LIST_REQUEST:
      return {
        ...state,
        sectionListState: {
          ...state.sectionListState,
          isFetching: true,
          error: '',
        },
      };

    case actionConstants.FETCH_SECTION_LIST_SUCCESS:
      return {
        ...state,
        sectionListState: {
          ...state.sectionListState,
          error: '',
          sectionList: action.payload.docs,
          total: action.payload.total,
          limit: action.payload.limit,
          currentPage: action.payload.page,
          totalPages: action.payload.pages,
          isFetching: false,
        },
      };

    case actionConstants.FETCH_MORE_SECTION_SUCCESS:
      return {
        ...state,
        sectionListState: {
          ...state.sectionListState,
          error: '',
          sectionList: [
            ...state.sectionListState.sectionList,
            ...action.payload.docs,
          ],
          total: action.payload.total,
          limit: action.payload.limit,
          currentPage: action.payload.page,
          totalPages: action.payload.pages,
          isFetching: false,
        },
      };
    default:
      return state;
  }
};
