import React from "react";
import { FormattedMessage } from "react-intl";

const fieldComponent = (field, value, divClassName='', style={}, className='col') => (
  <div className={className} style={style}>
    <div className={`form-group ${divClassName}`}>
      <label><FormattedMessage id={field} defaultMessage={field}/></label>
      <input
        className="form-control"
        maxLength="50"
        autoComplete="off"
        value={value || ""}
        disabled={true}
      />
    </div>
  </div>
);

export const JobsDetailsRow = ({ openJob, jobId, job }) => {
  const firstRowClass = 'mr-top-16'
  if (openJob != jobId) return null;
  return (<>
    {openJob === jobId && (
      <tr rowSpan="10%" className="accordion-opened" style={{ maxHeight: 'none' }}>
        <td colSpan="7" style={{ padding: '0px', height: '100%' }}>
          <div className="row mx-3">
            { fieldComponent('Contact Name', job.contactName, firstRowClass)}
            { fieldComponent('Email', job.email, firstRowClass, { width: '40%', paddingLeft: '15px', paddingRight: '15px' }, '') }
            { fieldComponent('Cell Phone', job.cellPhone, firstRowClass)}
            { fieldComponent('Office Phone', job.officePhone, firstRowClass)}
          </div>
          <div className="row mx-3">
            { fieldComponent('Fax Number', job.contactFaxNumber) }
            { fieldComponent('ID/Account', job.accountId) }
            { fieldComponent('Address', job.formattedAddress, undefined, { width: '60%', paddingLeft: '15px', paddingRight: '15px' }, '') }
          </div>
        </td>
      </tr>
    )}
  </>)
};