import 'babel-polyfill';
import { getToken } from '../_helpers/commonFunctions';
import call from './apiRequest';
import apiEndpoints from './apiEndpoints';

/**
 * Api call to set employee location
 * @param {object} location
 */
const setEmployeeLocation = (location) =>
  call({
    method: 'post',
    endpoint: apiEndpoints.gpsEmployeeList,
    payload: location,
    authKey: getToken(),
  });

export default setEmployeeLocation;
