import React from 'react';
import PropTypes from 'prop-types';

const Checkbox = ({
  type = 'checkbox',
  name,
  checked = false,
  onChange,
  className,
  isDisabled,
  style,
}) => (
  <input
    type={type}
    style={style}
    name={name}
    className={className}
    checked={checked}
    disabled={isDisabled}
    onChange={(e) => {
      onChange(e.target.name, e.target.checked);
    }}
  />
);

Checkbox.propTypes = {
  type: PropTypes.string, // type attribute of checkbox
  name: PropTypes.string.isRequired, // name attribute for checkbox
  checked: PropTypes.bool, // value of checkbox
  onChange: PropTypes.func, // function to be called on checkbox value change
  style: PropTypes.object, // style attribute for checkbox
  className: PropTypes.string, // class name for checkbox
  isDisabled: PropTypes.bool, // is checkbox disabled
};

Checkbox.defaultProps = {
  isDisabled: false,
  onChange: () => {},
};

export default Checkbox;
