import actionConstants from '../constants/ActionConstants';
import { setSystemInfoApiCall } from '../../api/apiCallsCustomer';
import ActionDispatcher from '../../api/ActionDispatcher';

const addSystemOrDeviceAction = (systemInfoId, systemInfo) =>
  ActionDispatcher(
    setSystemInfoApiCall.bind(null, systemInfoId, systemInfo),
    actionConstants.ADD_SYSTEM_OR_DEVICE_SUCCESS,
    actionConstants.ADD_SYSTEM_OR_DEVICE_FAILURE,
    actionConstants.ADD_SYSTEM_OR_DEVICE_REQUEST
  );

const clearState = () => ({
  type: actionConstants.CLEAR_STATE,
});

export { addSystemOrDeviceAction, clearState };
