import { createStore, applyMiddleware, compose } from 'redux';
import storage from 'redux-persist/lib/storage';
import { persistStore, persistReducer, createMigrate } from 'redux-persist';
import { migrations, MIGRATIONS_DEBUG } from './migrations';
import thunk from 'redux-thunk';
import reducer from '../reducer';
import config from '../../config';

const { DEV_TOOLS } = config;

let composeEnhancer = compose;

if (DEV_TOOLS && DEV_TOOLS.enableReduxDevTools) {
  composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    : compose;
}

const reducers = persistReducer(
  {
    key: 'root',
    version: 1,
    migrate: createMigrate(migrations, { debug: MIGRATIONS_DEBUG }),
    storage,
    blacklist: [
      'serviceRequestList',
      'emailListForm',
      'jobs',
      'ViewReports',
      'addClientDevice',
      'addCustomer',
      'addEmployee',
      'addLabour',
      'addParts',
      'addSystemOrDevice',
      'breadCums',
      'bugTickets',
      'companyLogo',
      'createPlan',
      'createUserCard',
      'customerDetails',
      'deleteDevice',
      'deleteEmployee',
      'deleteImage',
      'deleteSystemOrDevice',
      'editTeamMember',
      'emailListForm',
      'fetchGPSEmployeeDetail',
      'fetchTeamMember',
      'forgetPassword',
      'getJobList',
      'getReportLabour',
      'getReportParts',
      'getTeamGPSData',
      'imageDescriptionBuildingInfo',
      'importDevice',
      'jobDetails',
      'jobs',
      'reportCreate',
      'reportUpdate',
      'serviceRequestList',
      'setClientOrOwnerDetails',
      'showTeamSheet',
      'signUp',
      'updateClient',
      'updateHours',
      'updateStatus',
      'uploadImage',
      'videoSection',
      'addRecurringInspection',
      'chat',
      'jobListDetails',
      'assignPartsStore',
      'vehicleListStore',
      'supplierListStore',
      'showLoader',
    ],
  },
  reducer
);

export const store = createStore(
  reducers,
  composeEnhancer(applyMiddleware(thunk))
);

export const persistor = persistStore(store);
