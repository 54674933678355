import React, { useState } from 'react';
import { isEmpty, orderBy } from 'lodash';
import customerListHeadings, {
  OfflineModeContext,
  offlineModeOptions,
} from '../../../../libs/constants/offlineEnhanced';
import ReusableTable from '../../../../components/ReusableTable';
import Button from '../../../../components/Button';
import DeleteConfirmationModal from '../../../../components/DeleteConfirmationModal';
import CustomerListTableRows from './CustomerListTableRow';

const OfflineModeBody = () => {
  const [isDeleteModalVisible, toggleDeleteModalVisibility] = useState(false);
  const [selectedJobToDelete, setSelectedJobToDelete] = useState(null);
  const [isReport, setIsReport] = useState(false);
  const [isClassVisible, setIsClassVisible] = useState({});

  const setClassVisibility = (jobId) => {
    const isClassVisibles = {};
    isClassVisibles[`${jobId}`] = !isClassVisible[`${jobId}`];
    setIsClassVisible(isClassVisibles);
  };
  return (
    <OfflineModeContext.Consumer>
      {(value) => (
        <React.Fragment>
          {!isEmpty(value.offlineMode.jobsList) ||
          !isEmpty(value.offlineMode.reportListByJobIds.reportListByJobIds) ||
          !isEmpty(
            value.offlineMode.reportListByCustomer.reportListByCustomer
          ) ||
          value.offlineMode.isOffline ? (
            <div className="offline-customer-list-table-container">
              {isDeleteModalVisible && (
                <DeleteConfirmationModal
                  deleteButtonText="Delete"
                  handleClickCancel={() => toggleDeleteModalVisibility(false)}
                  handleClickCross={() => toggleDeleteModalVisibility(false)}
                  handleClickDelete={() => {
                    if (isReport) {
                      if (
                        value.offlineMode.selectedOfflineModeOption ===
                        offlineModeOptions[0]
                      ) {
                        value.deleteReportByCustomer(selectedJobToDelete);
                      } else {
                        value.deleteReportByJobId(selectedJobToDelete);
                      }
                      if (selectedJobToDelete < 100) {
                        value.deleteJob(selectedJobToDelete);
                      }
                    } else {
                      value.deleteJob(selectedJobToDelete);
                    }
                    toggleDeleteModalVisibility(false);
                  }}
                />
              )}
              <ReusableTable
                headerData={customerListHeadings}
                firstChildStyle={{
                  paddingLeft: '24px',
                }}
                bodyData={orderBy(
                  [
                    ...Object.values(value.offlineMode.jobsList),
                    ...Object.values(
                      value.offlineMode.reportListByJobIds.reportListByJobIds
                    ),
                    ...Object.values(
                      value.offlineMode.reportListByCustomer
                        .reportListByCustomer
                    ),
                  ],
                  ['createdAt'],
                  ['desc']
                )}
                RowToRender={CustomerListTableRows}
                mainTableClass="mr-top-0 offline-customer-list-table-wrapper"
                isLoaderVisible={false}
                RowToRenderProps={{
                  isClassVisible,
                  setClassVisibility,
                  offlineMode: value.offlineMode,
                  deleteSelectedJob: (jobId, _isReport) => {
                    setIsReport(_isReport);
                    setSelectedJobToDelete(jobId);
                    toggleDeleteModalVisibility(true);
                  },
                  clearInspectionState: value.clearInspectionState,
                  formReportDetails: value.formReportDetails,
                  updateReport: value.updateReport,
                  setReportSelectedToUpload: value.setReportSelectedToUpload,
                  clearAnswers: value.clearAnswers,
                  setAnswer: value.setAnswer,
                  setUpdatedAnswersReportData:
                    value.setUpdatedAnswersReportData,
                  uploadSignatures: value.uploadSignatures,
                  setSignatureType: value.setSignatureType,
                  uploadSignaturesReset: value.uploadSignaturesReset,
                  updateReportState: value.reportUpdate,
                  createInspection: value.createInspection,
                  entries: orderBy(
                    [
                      ...Object.values(value.offlineMode.jobsList),
                      ...Object.values(
                        value.offlineMode.reportListByJobIds.reportListByJobIds
                      ),
                      ...Object.values(
                        value.offlineMode.reportListByCustomer
                          .reportListByCustomer
                      ),
                    ],
                    ['createdAt'],
                    ['desc']
                  ),
                }}
                Footer={() =>
                  value.offlineMode.selectedOfflineModeOption ===
                    offlineModeOptions[0] && value.offlineMode.isOffline ? (
                    <div style={{ marginTop: '20px' }}>
                      <Button
                        text="Add More"
                        onBtnClick={() =>
                          value.toggleCustomerModalVisibility(true)
                        }
                      />
                    </div>
                  ) : null
                }
              />
            </div>
          ) : (
            <div className="card-body">
              {value.offlineMode.selectedOfflineModeOption ===
                offlineModeOptions[0] &&
                !isEmpty(value.offlineMode.jobsList) &&
                value.offlineMode.isOffline && (
                  <div>
                    <Button
                      text="Add More"
                      onBtnClick={() =>
                        value.toggleCustomerModalVisibility(true)
                      }
                    />
                  </div>
                )}
            </div>
          )}
        </React.Fragment>
      )}
    </OfflineModeContext.Consumer>
  );
};

export default OfflineModeBody;
