import React from 'react';
import { AlarmOffOutlined, BuildOutlined, FreeBreakfastOutlined, LanguageOutlined, LocalShippingOutlined } from "@material-ui/icons";

const spanClass = 'joblink-icon1 sprite-icon';

export default [
  {
    wrapperClass: 'driving',
    name: 'Driving',
    spanClass,
    icon: <LocalShippingOutlined/>,
    accentColor: '#52b1ec',
    value: 'DRIVING',
  },
  {
    wrapperClass: 'working',
    name: 'Working',
    spanClass,
    icon: <BuildOutlined/>,
    accentColor: '#bb2f2a',
    value: 'WORKING',
  },
  {
    wrapperClass: 'break',
    name: 'Break',
    spanClass,
    icon: <FreeBreakfastOutlined/>,
    accentColor: '#6aa14e',
    value: 'BREAK',
  },
  {
    wrapperClass: 'checkout',
    name: 'Clocked Out',
    spanClass,
    icon: <AlarmOffOutlined/>,
    accentColor: '#a1a1a1',
    value: 'CHECKOUT',
  },
  {
    wrapperClass: 'miscellaneous',
    name: 'Miscellaneous',
    spanClass,
    icon: <LanguageOutlined/>,
    accentColor: '#f4cf49',
    value: 'MISCELLANEOUS',
  },
];
