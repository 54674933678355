import actionConstants from '../constants/ActionConstants';

const initialState = {
  error: '',
  isfetching: false,
  partName: '',
  partId: '',
  isSuccess: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionConstants.SAVE_PART_REQUEST:
      return {
        ...state,
        isfetching: true,
      };
    case actionConstants.SAVE_PART_SUCCESS:
      return {
        ...state,
        isfetching: false,
        isSuccess: true,
        partName: action.payload.partName,
        partId: action.payload._id,
      };
    case actionConstants.SAVE_PART_FAILURE:
      return {
        ...state,
        error: action.message,
      };
    case actionConstants.CLEAR_PART_REDUCER:
      return {
        ...state,
        isfetching: false,
        isSuccess: false,
        error: '',
      };
    default:
      return state;
  }
};
