const SettingsContstants = {
  heading: 'Report Categories',
  para: "Below is a list of Aries report categories. If your company doesn't offer some of the report categories you can unselect to remove them from your list.",
  quickbookHeading: 'Link QuickBooks Account',
  quickbookPara:
    'Click the button below to begin the process of linking Aries to your QuickBooks account.',
  invoiceHeading: 'Invoice Number',
  invoicePara:
    "Select the method you'd like to use for creating Invoice, Work Order and Job numbers.",
};
export default SettingsContstants;
