import React from 'react';
import PropTypes from 'prop-types';

const PageLoader = ({ containerClass }) => (
  <div className={`loader-container ${containerClass || ''}`}>
    <div className="loader"></div>
  </div>
);

PageLoader.propTypes = {
  containerClass: PropTypes.string, // container class in which loader will be visible
};
export default PageLoader;
