import moment from 'moment';
import ActionConstants from '../constants/ActionConstants';

const INITIAL_FORM_STATE = {
  description: '',
  type: '',
  reportCategory: '',
  invoiceNo: '',
  serviceRequestId: undefined,
  poNumber: '',
  jobDate: moment(),
  jobId: null,
  isJobIdPermanent: false,
  isJobIdApplicable: false,
  descriptionError: '',
  typeError: '',
  reportCategoryError: '',
  invoiceNoError: '',
  poNumberError: '',
  jobDateError: '',
  jobIdError: '',
};

const INITIAL_API_STATE = {
  isSuccess: false,
  isError: false,
  message: '',
  code: undefined,
};

const INITIAL_STATE = {
  formState: {
    ...INITIAL_FORM_STATE,
  },
  apiState: {
    ...INITIAL_API_STATE,
  },
  isFetching: false,
  serviceRequestId: undefined,
};

const addJobReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ActionConstants.INITIALIZE_DATE_FOR_ADD_JOB:
      return {
        ...state,
        formState: {
          ...state.formState,
          jobDate: moment(),
        },
      };
    case ActionConstants.ADD_JOBS_SUBMIT_DATA_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case ActionConstants.ADD_JOBS_SUBMIT_DATA_ERROR:
      return {
        ...state,
        apiState: {
          ...state.apiState,
          isError: true,
          message: action.message,
          code: action.code,
        },
        isFetching: false,
      };
    case ActionConstants.ADD_JOBS_SUBMIT_DATA_SUCCESS:
      return {
        ...state,
        apiState: {
          ...state.apiState,
          isSuccess: true,
          message: action.message,
        },
        isFetching: false,
      };
    case ActionConstants.ADD_JOB_UPDATE_FIELD:
      return {
        ...state,
        formState: {
          ...state.formState,
          [action.payload.key]: action.payload.value,
          [`${action.payload.key}Error`]: '',
        },
      };
    case ActionConstants.ADD_JOB_FIELD_ERROR:
      return {
        ...state,
        formState: {
          ...state.formState,
          [action.payload.key]: action.payload.value,
        },
      };
    case ActionConstants.ADD_JOB_CLEAR_API_STATE:
      return {
        ...state,
        apiState: {
          ...INITIAL_API_STATE,
        },
      };
    case ActionConstants.ADD_JOB_CLEAR_STATE:
      return {
        ...INITIAL_STATE,
      };
    case ActionConstants.RESET_ALL_REDUCERS:
      return {
        ...INITIAL_STATE,
      };
    default:
      return state;
  }
};

export default addJobReducer;
