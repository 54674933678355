import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import JobLinkCalendar from '../../components/JobLinkCalendar';
import EditJobModals from '../../components/EditJobModal/EditJobModal';
import JobLinkMonthCalenders from '../../components/JobLinkCalendar/JobLinkMonthCalender';
import endpoints from '../../routes';
import MakeNote, { ChooseModal } from './MakeNotes';
import JobLinkHeader from './JobLinkHeader';
import JobLinkMobile from './JobLinkMoblie';

export const JobLinkLayout = (props) => {
  const [schedulerData, setSchedulerData] = useState({});
  const [newEvent, setNewEvent] = useState(null);
  const [taskDndSource, setTaskDndSource] = useState({});
  const [isEditJobModalVisible, toggleEditJobModal] = useState(false);
  const [currentEvent, setCurrentEvent] = useState({});
  const [viewType, setViewType] = useState('day');
  const [childRef, setChildRef] = useState(null);
  const [isMonthCal, setMonthCal] = useState(false);
  const [eventCategory, setEventCategory] = useState('All');
  const [resourceReportCategory, setResourceReportCategory] = useState([]);
  const [search, setSearch] = useState('');
  const [isSearch, handleOnSearch] = useState(false);
  const [isChooseModalVisible, setChooseModalVisibility] = useState(false);
  const [isMakeNotesVisible, setMakeNotesVisibility] = useState(false);

  /**
   * handles the different view type
   * @param {*} name
   * @param {*} value
   */
  const handleChangeViewType = viewType => {
    setViewType(viewType);
    if (viewType == 'day') {
      props.history.push(endpoints.jobLink);
      if (isMonthCal) {
        setMonthCal(false);
      } else if (childRef) {
        const view = {
          showAgenda: false,
          isEventPerspective: false,
          viewType,
        };
        childRef.onViewChange(childRef.state.viewModel, view);
        childRef.setCalenderData(childRef.state.viewModel);
      }
    } else {
      props.history.push(`/${endpoints.joblinkMonth}`);
      setMonthCal(true);
    }
  };

  useEffect(() => {
    if (props.location.pathname.includes('monthView')) {
      setViewType('month');
      setMonthCal(true);
    }
  }, []);

  /**
   * handles the click of particular job list item
   * @param {*} jobItem
   */
  const handleClickJobListItem = (jobItem) => {
    setCurrentEvent(jobItem);
    setChooseModalVisibility(true);
  };

  return (
    <div>
      {isChooseModalVisible && (
        <ChooseModal
          handleClickCross={() => setChooseModalVisibility(false)}
          handleClickScheduleJob={() => {
            toggleEditJobModal(true);
            setChooseModalVisibility(false);
          }}
          handleClickMakeNotes={() => {
            setMakeNotesVisibility(true);
            setChooseModalVisibility(false);
          }}
          runCoachMarks={props.runCoachMarks}
          toggleCoachmarksVisibility={props.toggleCoachmarksVisibility}
          currentCoachmarkTarget={props.currentCoachmarkTarget}
        />
      )}
      {isMakeNotesVisible && (
        <MakeNote handleClickCross={() => setMakeNotesVisibility(false)} />
      )}
      {isEditJobModalVisible && (
        <EditJobModals
          onCloseModal={toggleEditJobModal}
          currentEvent={currentEvent}
          resourceReportCategory={resourceReportCategory}
        />
      )}
      <JobLinkHeader
        viewType={viewType}
        onChangeViewType={handleChangeViewType}
        schedulerData={schedulerData}
        newEvent={newEvent}
        taskDndSource={taskDndSource}
        eventCategory={eventCategory}
        setEventCategory={setEventCategory}
        handleClickJobListItem={handleClickJobListItem}
        setSearch={setSearch}
        search={search}
        handleOnSearch={handleOnSearch}
        resourceReportCategory={resourceReportCategory}
        setResourceReportCategory={setResourceReportCategory}
        desktopCalendar={childRef}
      />
      {isMonthCal ? (
        <JobLinkMonthCalenders
          changeViewType={handleChangeViewType}
          eventCategory={[eventCategory]}
        />
      ) : (
        <>
          <span className="hidden md:block">
            <JobLinkCalendar
              viewType={viewType.id}
              setSchedulerData={setSchedulerData}
              setNewEvent={setNewEvent}
              setTaskDndSource={setTaskDndSource}
              toggleEditJobModal={toggleEditJobModal}
              setCurrentEvent={setCurrentEvent}
              onRef={(ref) => setChildRef(ref)}
              resourceReportCategory={resourceReportCategory}
              setResourceReportCategory={setResourceReportCategory}
              search={search}
              handleOnSearch={handleOnSearch}
              isSearch={isSearch}
            />
          </span>
          <span className="block md:hidden">
            <JobLinkMobile
              desktopCalendar={childRef}
              schedulerData={schedulerData}
              history={props.history}
            />
          </span>
        </>
      )}
    </div>
  );
};

JobLinkLayout.propTypes = {
  viewType: PropTypes.string,
  changeViewType: PropTypes.func,
  history: PropTypes.object,
  location: PropTypes.object,
  runCoachMarks: PropTypes.func,
  toggleCoachmarksVisibility: PropTypes.func,
  currentCoachmarkTarget: PropTypes.string,
};

export default withRouter(JobLinkLayout);
