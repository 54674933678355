import Socket from './permissionsSubscriptionsSocket';
import EVENT from './reportSocketEvents';

const updateAnswerEvent = (updatedAnswers) => {
  const socket = new Socket().getInstance();
  socket.emit(EVENT.UPDATE_ANSWER, updatedAnswers);
};

const reportStartEvent = (message) => {
  const socket = new Socket().getInstance();
  socket.emit(EVENT.REPORT_START, message);
};

const reportUpdated = (handleReportUpdated) => {
  const socket = new Socket().getInstance();
  socket.on(EVENT.REPORT_UPDATED, handleReportUpdated);
};

const reportEndEvent = (message) => {
  const socket = new Socket().getInstance();
  socket.emit(EVENT.REPORT_END, message);
};
export { reportUpdated, updateAnswerEvent, reportStartEvent, reportEndEvent };
