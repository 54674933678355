import actionConstants from '../constants/ActionConstants';

const initialState = {
  isSuccess: false,
  isFetching: false,
  error: '',
  success: '',
  wayPoint: null,
  points: null,
  status: null,
  distances: [],
  isDistanceSuccess: '',
  isDistanceError: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionConstants.GET_WAY_POINT_REQUEST:
      return {
        ...state,
        isFetching: true,
        wayPoint: null,
        isSuccess: false,
      };
    case actionConstants.GET_WAY_POINT_SUCCESS:
      return {
        ...state,
        isFetching: false,
        success: action.message,
        wayPoint: action.payload.routes,
        status: action.payload.status,
        isSuccess: true,
      };
    case actionConstants.GET_WAY_POINT_FAILURE:
      return {
        ...state,
        error: action.message,
        isFetching: false,
        isSuccess: false,
      };
    case actionConstants.GET_WAY_POINT_DISTANCE_REQUEST:
      return {
        ...state,
        isFetching: true,
        distance: [],
        isDistanceSuccess: false,
      };
    case actionConstants.GET_WAY_POINT_DISTANCE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        isDistanceSuccess: 'Success',
        distance: action.payload.rows[0].elements,
      };
    case actionConstants.GET_WAY_POINT_DISTANCE_FAILURE:
      return {
        ...state,
        isDistanceSuccess: false,
        isFetching: false,
      };
    case actionConstants.CLEAR_WAYPOINT_DISTANCE:
      return {
        ...state,
        isDistanceSuccess: '',
        isDistanceError: '',
        isFetching: false,
      };
    case actionConstants.CLEAR_STATE:
      return {
        ...state,
        error: '',
        success: '',
        isFetching: false,
        isSuccess: false,
        status: null,
      };
    default:
      return state;
  }
};
