import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { isEmpty } from 'lodash';
import {
  getAuthUser,
  setLoginUserDetails,
  clearAllReducers,
} from '../../redux/actions/auth';
import { reportCat } from '../../redux/actions/reportCategory';
import endpoints from '../../routes';
import { getToken } from '../../_helpers/commonFunctions';
import config from '../../config';

const { API } = config;

export default function (ComposedComponent) {
  class LogoutRequired extends React.Component {
    componentWillMount() {
      this.authenitcateUser();
    }

    /**
     * Checks if auth token is available and valid
     */
    authenitcateUser() {
      const { auth } = this.props;
      const { login, loginData } = auth;
      if (isEmpty(loginData) && !login.isFetching) {
        this.props.getAuthUser({});
      }
    }

    render() {
      const { auth } = this.props;
      const { login, isTermsAndCondition, loginData } = auth;
      if (!login.isFetching && !isEmpty(loginData)) {
        this.props.reportCat();
        if (loginData.role === 'CLIENT') {
          // Redirecting to client portal.
          const token = getToken();
          this.props.clearAllReducers();
          window.open(`${API.CLIENT_URL}?id=${token}`, '_self');
        } else if (!isTermsAndCondition) {
          return (
            <Redirect
              to={{
                pathname: endpoints.termsAndConditions,
              }}
            />
          );
        } else if (
          loginData.role === 'CUSTOMER' ||
          loginData.role === 'EMPLOYEE'
        ) {
          this.props.setLoginUserDetails();
          return (
            <Redirect
              to={{
                pathname: '/',
                state: { from: this.props.location },
              }}
            />
          );
        }
      }
      return <ComposedComponent {...this.props} />;
    }
  }

  LogoutRequired.propTypes = {
    auth: PropTypes.object.isRequired, // contains user login data
    getAuthUser: PropTypes.func.isRequired, // returns auth token of user
    location: PropTypes.object.isRequired,
    setLoginUserDetails: PropTypes.func, // sets user login details
    clearAllReducers: PropTypes.func, // clears the app state
    reportCat: PropTypes.func, // handles report categories
  };

  const mapStateToProps = ({ auth }) => ({ auth });

  return connect(mapStateToProps, {
    getAuthUser,
    reportCat,
    setLoginUserDetails,
    clearAllReducers,
  })(LogoutRequired);
}
