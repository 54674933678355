import axios from 'axios';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import apiEndpoints from '../../api/apiEndpoints';
import DaySelector from '../../components_2/DaySelector';
import configFile from '../../config';
import formReportDetails from '../../redux/actions/addReportCat';
import { clearInspectionState } from '../../redux/actions/updateAnswer';
import endpoints from '../../routes';
import { displayToast, getToken } from '../../_helpers/commonFunctions';
import JobCard from './MobileJobCard';
import StatusSelector from './StatusSelector';
const { API } = configFile;

const initialState = {
  jobs: [],
  userId: null,
  status: 'CHECKOUT'
}

const selectJobsAndStatus = ({ jobListDetails, auth }) => {
  const userId = auth?.login?.user?._id;
  const teamList = jobListDetails?.teamListWithFilters?.teamList;
  if(!userId || !teamList) return initialState;
  const userItem = teamList.find(({_id}) => _id == userId);
  if(!userItem) return initialState;

  const mapCreatedFor = userItem.createdFor.reduce((acc, client) => {
    acc[client._id] = client;
    return acc
  }, {});

  const jobs = userItem.job.map(jobItem => ({
    ...jobItem,
    createdFor: mapCreatedFor[jobItem.createdFor]
  }));

  return {
    jobs,
    userId,
    status: userItem?.timeCardId?.lastStatus || initialState.status
  };
};

const JobLinkMobile = ({ desktopCalendar, schedulerData, history }) => {

  const dispatch = useDispatch()
  const { jobs, userId, status } = useSelector(selectJobsAndStatus);
  const [date, setDate] = useState(new Date());

  const handleDateChange = newDate => {
    setDate(newDate);
    schedulerData.setDate(newDate);
    desktopCalendar.setState({ viewModel: schedulerData });
    desktopCalendar.setCalenderData(schedulerData);
  };

  const handleStart = async clickedJob => {
    try {
      const url = `${API.BASE_URL}/${apiEndpoints.jobs}`
      const { data } = await axios.get(url, {
        params: { jobid: clickedJob._id },
        headers: {
          'Authorization': getToken()
        }
      });
      const selectedJob = data.data

      let formData = {
        typeValue: clickedJob.type,
        catValue: clickedJob.reportCategory,
        customerValue: clickedJob.createdFor._id,
        price: selectedJob.price,
      };
      const frequency = selectedJob.recurringInspectionDetails?.frequency;
      if(frequency) formData.frequency = frequency;
      dispatch(clearInspectionState());
      dispatch(formReportDetails(formData));
      displayToast('Report started successfully', 'success');
      history.push(endpoints.reportClientDetail, {
        jobId: selectedJob._id,
      });
    } catch(err) {
      console.error(err)
      displayToast('Error when starting report', 'error');
    }
  };

  const statusSelect = (
    <StatusSelector
      desktopCalendar={desktopCalendar}
      userId={userId}
      status={status}
    />
  )

  return (
    <div>
      <DaySelector
        value={date}
        onChange={handleDateChange}
      />
      {Boolean(jobs) && (
        <div className="relative">
          <div className={`absolute h-full w-0.5 bg-gray-300 left-5 top-0`} />
          {jobs.map(job => (
            <JobCard
              key={job._id}
              job={job}
              onStartClick={() => handleStart(job)}
              statusSelect={statusSelect}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default JobLinkMobile;