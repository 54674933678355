import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import CompleteReportLayout from '../../layouts/CompleteReportLayout/CompleteReportLayout';
import getReportList from '../../redux/actions/getReportsList';
import {
  deleteReportAction,
  clearDeleteState,
} from '../../redux/actions/deleteReport';
import {
  endMessageForReportTable,
  endMessageReportPagination,
} from '../../utils/Constants';
import formReportDetails from '../../redux/actions/addReportCat';
import {
  setAnswerAndViewJson,
  clearAnswers,
} from '../../redux/actions/updateAnswer';
import { reportConstants } from '../../libs/constants/reportConstants';
import { runCoachMarks } from '../../redux/actions/signUp';

export class CompletedReports extends React.Component {
  constructor(props) {
    super(props);
    this.page = 1;
    this.LIMIT = 50;
  }

  state = {
    searchText: null,
    filter: undefined,
    status: null,
    isShowDetails: null,
    reportCategory: [],
    reportType: null,
    CustomerNameSort: undefined,
    isCrossVisible: false,
    endMessage: endMessageReportPagination,
    startDate: null,
    endDate: null,
    heading: null,
    isClassVisible: {},
    isDeleteConfirmModalVisible: false,
    reportInfoId: null,
    showLoader: false,
    createdFor: null,
  };

  reloadList = () => {
    this.props.getReportList(
      this.page,
      this.LIMIT,
      this.state.searchText,
      this.state.reportType,
      this.state.reportCategory,
      this.state.CustomerNameSort,
      this.state.startDate,
      this.state.endDate,
      this.state.status,
      this.state.createdFor,
      this.props.history.location.state.selectedReport === 'Default'
    );
  };

  componentDidMount() {
    this.page = 1;
    this.updateReportList();
    this.props.runCoachMarks(true);
  }

  updateReportList() {
    this.page = 1;
    this.setState(
      {
        status: reportConstants.completed,
        reportType: reportConstants.inspection,
        reportCategory: this.props.reportCat.catValue,
        startDate: null,
        endDate: null,
        createdFor: this.props.reportCat.customerValue,
        heading: reportConstants.completeReports,
      },
      () => this.reloadList()
    );
  }

  loadMore = () => {
    this.page += 1;
    this.reloadList();
  };

  setSelectedReportInfoId = (id) => {
    this.setState({ reportInfoId: id, isDeleteConfirmModalVisible: true });
  };

  setSelectedReportEditId = (row) => {
    // this.props.clearAnswers();
    if (
      this.props.history.location &&
      this.props.history.location.state &&
      this.props.history.location.state.jobId
    ) {
      this.props.history.push(`${reportConstants.reportDetailURL}/${row._id}`, {
        isViewing: true,
        jobId: this.props.history.location.state.jobId,
        selectedReport: this.props.history.location.state.selectedReport,
      });
    } else {
      this.props.history.push(`${reportConstants.reportDetailURL}/${row._id}`, {
        isViewing: true,
        selectedReport: this.props.history.location.state.selectedReport,
      });
    }
  };

  deleteReportAction = () => {
    const { reportInfoId } = this.state;
    this.props.deleteReportAction(reportInfoId);
    this.page = 1;
  };

  isEndMessageVisible = () =>
    this.page > 1 ||
    (this.props.reportList && this.props.reportList.length) === 0;

  sortData = () => {
    this.page = 1;
    const { CustomerNameSort } = this.state;
    this.setState(
      {
        CustomerNameSort:
          CustomerNameSort === undefined ? 1 : CustomerNameSort * -1,
      },
      this.reloadList()
    );
  };

  setSearchText = (text) => {
    this.setState({ searchText: text, isCrossVisible: true });
  };

  handleOnSearch = (text) => {
    this.page = 1;
    if (text === '') {
      this.setState({ searchText: '', isCrossVisible: false }, () =>
        this.reloadList()
      );
    }
    this.reloadList();
  };

  setClassVisibility = (employeeId) => {
    const isClassVisible = {};
    if (Object.keys(this.state.isClassVisible)[0] === employeeId) {
      isClassVisible[`${employeeId}`] =
        !this.state.isClassVisible[`${employeeId}`];
    } else {
      isClassVisible[`${employeeId}`] = true;
    }
    this.setState({ isClassVisible });
  };

  render() {
    return (
      <div>
        <div className="incompletetable-sec">
          <CompleteReportLayout
            isCrossVisible={this.state.isCrossVisible}
            searchText={this.state.searchText}
            reportList={this.props.reportList}
            setFilters={this.setFilters}
            setSearchText={this.setSearchText}
            handleSearch={this.handleOnSearch}
            loadMore={this.loadMore}
            isfetching={this.props.isfetching}
            isClassVisible={this.state.isClassVisible}
            setClassVisibility={this.setClassVisibility}
            reportHeading={this.state.heading}
            hasMoreItems={this.page < this.props.pages}
            sortData={this.sortData}
            filter={this.state.filter}
            endMessage={
              this.props.reportList && this.props.reportList.length > 0
                ? this.state.endMessage
                : endMessageForReportTable
            }
            setReportType={this.setReportType}
            reportType={this.state.reportType}
            setSelectedReportEditId={this.setSelectedReportEditId}
            setSelectedReportInfoId={this.setSelectedReportInfoId}
            isEndMessageVisible={this.isEndMessageVisible()}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  ClientOrOwnerDetails: state.getClientOrOwnerDetails,
  reportCat: state.addReportCat.reportCatData,
  clientInfo: state.getClientOrOwnerDetails.clientOrOwnerData,
  reportList: state.getReportList.reportData,
  pages: state.getReportList.pages,
  isfetching: state.getReportList.isfetching,
  deleteReportData: state.deleteReport,
});

CompletedReports.propTypes = {
  reportList: PropTypes.array,
  isfetching: PropTypes.boolean,
  pages: PropTypes.number,
  getReportList: PropTypes.any,
  deleteReportAction: PropTypes.any,
  location: PropTypes.object,
  deleteReportData: PropTypes.object,
  clearDeleteState: PropTypes.func,
  formReportDetails: PropTypes.func,
  history: PropTypes.object,
  deleteReportFetching: PropTypes.bool,
  setAnswerAndViewJson: PropTypes.func,
  clearAnswers: PropTypes.func,
  reportCat: PropTypes.object,
  runCoachMarks: PropTypes.func,
};

export default connect(mapStateToProps, {
  getReportList,
  deleteReportAction,
  clearDeleteState,
  formReportDetails,
  setAnswerAndViewJson,
  clearAnswers,
  runCoachMarks,
})(CompletedReports);
