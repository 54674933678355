const IndexedDBconfig = {
  name: 'OfflineStore',
  version: 1,
  objectStoresMeta: [
    {
      store: 'Signatures',
      storeConfig: { keyPath: 'reportId', autoIncrement: false },
      storeSchema: [
        { name: 'reportId', keypath: 'reportId', options: { unique: true } },
        {
          name: 'signatures',
          keypath: 'signatures',
          options: { unique: false },
        },
      ],
    },
  ],
};

export default IndexedDBconfig;
