import actionConstants from '../constants/ActionConstants';
import { displayToast } from '../../_helpers/commonFunctions';

const initialState = {
  count: 0,
};

const setNotiReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionConstants.SET_NOTIFICATION_COUNT:
      return {
        ...state,
        count: action.payload.count,
      };
    case actionConstants.SET_NOTIFICATION_COUNT_FAILURE:
      if (action.message !== '') {
        displayToast('error', 'error');
      }
      return {
        ...state,
      };
    case actionConstants.RESET_ALL_REDUCERS:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export default setNotiReducer;
