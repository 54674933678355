import { tz } from 'moment-timezone';
import call from './apiRequest';
import apiEndpoints from './apiEndpoints';

/**
 * Api call for customer signup
 * @param {object} customerSignUpData
 */
const CustomerSignUp = (customerSignUpData) => 
  call({
    method: 'post',
    endpoint: apiEndpoints.customer,
    payload: customerSignUpData,
  });

export default CustomerSignUp;
