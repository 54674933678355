import ActionConstants from '../constants/ActionConstants';

const initialState = {
  mainHeading: null,
  insideMainHeading: null,
  empName: null,
};

const breadCums = (state = initialState, action) => {
  switch (action.type) {
    case ActionConstants.ADD_BREAD_CUMS:
      return {
        ...state,
        mainHeading: action.payload.mainHeading,
        insideMainHeading: action.payload.insideMainHeading,
        empName: action.payload.empName,
      };
    case ActionConstants.CLEAR_BREAD_CUMS:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export default breadCums;
