import actionConstants from '../constants/ActionConstants';

const initialState = {
  error: '',
  isfetching: false,
  success: '',
  isSuccess: false,
};

const updateSuccess = (state = initialState, action) => {
  switch (action.type) {
    case actionConstants.UPDATE_EMPLOYEE_STATUS_REQUEST:
      return {
        ...state,
        isfetching: true,
        isSuccess: false,
        error: '',
      };
    case actionConstants.UPDATE_EMPLOYEE_STATUS_SUCCESS:
      return {
        ...state,
        isfetching: false,
        isSuccess: true,
        success: action.message,
      };
    case actionConstants.UPDATE_EMPLOYEE_STATUS_FAILURE:
      return {
        ...state,
        isfetching: false,
        error: action.message,
        isSuccess: false,
      };
    case actionConstants.RESET_TEAM_REDUCER:
      return {
        ...state,
        isfetching: false,
        isSuccess: false,
        success: '',
        error: '',
      };
    default:
      return state;
  }
};

export default updateSuccess;
