import actionConstants from '../constants/ActionConstants';
import {
  getReportList,
  fetchServiceDueList,
  fetchServiceDueForCount,
} from '../../api';
import ActionDispatcher from '../../api/ActionDispatcher';

const getReportListAction = (data) =>
  ActionDispatcher(
    getReportList.bind(null, data),
    actionConstants.GET_REPORT_LIST_SUCCESS,
    actionConstants.GET_REPORT_LIST_FAILURE,
    actionConstants.GET_REPORT_LIST_REQUEST
  );

export const getServiceDueList = (payload) =>
  ActionDispatcher(
    fetchServiceDueList.bind(null, payload),
    actionConstants.GET_SERVICE_DUE_LIST_SUCCESS,
    actionConstants.GET_SERVICE_DUE_LIST_FAILURE,
    actionConstants.GET_SERVICE_DUE_LIST_REQUEST
  );

export const getServiceDueForCount = (payload) =>
  ActionDispatcher(
    fetchServiceDueForCount.bind(null, payload),
    actionConstants.GET_SERVICE_DUE_FOR_COUNT_SUCCESS,
    actionConstants.GET_SERVICE_DUE_FOR_COUNT_FAILURE,
    actionConstants.GET_SERVICE_DUE_FOR_COUNT_REQUEST
  );

export default getReportListAction;
