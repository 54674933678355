import actionConstants from '../constants/ActionConstants';
import {
  jobListApiCall,
  printJobListApiCall,
  completeJobManuallyApiCall,
} from '../../api/apiCallsCustomer';
import ActionDispatcher from '../../api/ActionDispatcher';

const getJobList = (
  page,
  limit,
  searchText,
  status,
  ReportCategory,
  jobDateSort,
  businessNameSort,
  startDate,
  endDate,
  reportType,
  extraParams = {},
) =>
  ActionDispatcher(
    jobListApiCall.bind(
      null,
      page,
      limit,
      searchText,
      status,
      ReportCategory,
      jobDateSort,
      businessNameSort,
      startDate,
      endDate,
      reportType,
      extraParams
    ),
    actionConstants.GET_JOB_LIST_SUCCESS,
    actionConstants.GET_JOB_LIST_FAILURE,
    actionConstants.GET_JOB_LIST_REQUEST
  );

export const printJobList = (
  page,
  limit,
  searchText,
  status,
  ReportCategory,
  jobDateSort,
  businessNameSort,
  startDate,
  endDate,
  reportType,
  onlySelf = false,
  extraParams = {},
) =>
  ActionDispatcher(
    printJobListApiCall.bind(
      null,
      page,
      null,
      searchText,
      status,
      ReportCategory,
      jobDateSort,
      businessNameSort,
      startDate,
      endDate,
      reportType,
      onlySelf,
      extraParams
    ),
    actionConstants.PRINT_JOB_LIST_SUCCESS,
    actionConstants.PRINT_JOB_LIST_FAILURE,
    actionConstants.PRINT_JOB_LIST_REQUEST
  );

export const printJobListFinished = () => ({
  type: actionConstants.PRINT_JOB_LIST_SUCCESS,
});

export const completeJobManually = (payload) =>
  ActionDispatcher(
    completeJobManuallyApiCall.bind(null, payload),
    actionConstants.COMPLETE_JOB_MANUALLY_SUCCESS,
    actionConstants.COMPLETE_JOB_MANUALLY_FAILURE,
    actionConstants.COMPLETE_JOB_MANUALLY_REQUEST
  );

export default getJobList;
