import React from 'react';
import { Clear, SearchOutlined } from '@material-ui/icons';
import TextInput from '../TextInput';

const Search = ({
  onSearch,
  className,
  ...inputProps
}) => {

  const clearButton = (
    <Clear
      className="absolute -left-7 cursor-pointer text-gray-300"
      onClick={() => {
        inputProps.onChange && inputProps.onChange('')
      }}
    />
  )

  const searchButton = (
    <div
      className="absolute cursor-pointer bg-red-700 text-white rounded-r-md h-full right-0 top-0 w-12 flex items-center justify-center"
      onClick={onSearch}
    >
      {inputProps.value?.length ? clearButton : null}
      <SearchOutlined />
    </div>
  );

  return (
    <div className={`relative ${className}`}>
      <TextInput
        inputStyle={{ width: 'calc(100% - 60px)' }}
        {...inputProps}
      />
      {searchButton}
    </div>
  );
};

export default Search;