import React from 'react';
import PropType from 'prop-types';
import { DateRange } from 'react-date-range';
import { FormattedMessage } from 'react-intl';
import './DateRangePicker.css';
import moment from 'moment';
import { getCurrentLanguage, getPrettyMonthDateFormat } from '../../utils/utils';
import es from 'date-fns/locale/es';
import en from 'date-fns/locale/en-US';
import { registerLocale } from 'react-datepicker';

registerLocale('en', en)
registerLocale('es', es)


const DateRangePicker = (props) => {
  moment.locale(getCurrentLanguage())

  return (
    <div
      className={`daterange ${props.className}`}
      style={props.customStyle}
      ref={props.refCallback}
    >
      <DateRange
        onChange={props.persistDate}
        calendars={1}
        startDate={moment(props.startDatePersist)}
        endDate={moment(props.endDatePersist)}
        locale={getCurrentLanguage()}
      />
      <button
        className="resetbuttondaterange"
        style={props.resetBtnStyle}
        onClick={props.resetdate}
      >
        <FormattedMessage id="RESET" defaultMessage="RESET" />{' '}
      </button>
    </div>
  )
}

DateRangePicker.propTypes = {
  persistDate: PropType.func,
  startDatePersist: PropType.object.isRequired,
  endDatePersist: PropType.object.isRequired,
  minDate: PropType.object,
  maxDate: PropType.object,
  resetdate: PropType.func,
  customStyle: PropType.object,
  className: PropType.string,
  resetBtnStyle: PropType.object,
  refCallback: PropType.func,
};

DateRangePicker.defaultProps = {
  minDate: undefined,
  maxDate: undefined,
  resetdate: () => {},
};

export default DateRangePicker;
