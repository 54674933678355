import actionConstants from '../constants/ActionConstants';
import { clientListApiCall } from '../../api/apiCallsCustomer';
import ActionDispatcher from '../../api/ActionDispatcher';

const getClientsList = (
  page,
  limit,
  searchText,
  active,
  sortBy,
  sortOrder,
  parentDetails
) =>
  ActionDispatcher(
    clientListApiCall.bind(
      null,
      page,
      limit,
      searchText,
      active,
      sortBy,
      sortOrder,
      parentDetails
    ),
    actionConstants.GET_CLIENT_LIST_SUCCESS,
    actionConstants.GET_CLIENT_LIST_FAILURE,
    actionConstants.GET_CLIENT_LIST_REQUEST
  );

export default getClientsList;
