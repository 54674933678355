/*eslint-disable*/

import actionConstants from '../constants/ActionConstants';
import moment from 'moment';

const DEFAULT_ASYNC_STATE = {
  isSuccess: false,
  isError: false,
  shouldNotUpdate: false,
  message: '',
};

const DEFAULT_CLIENT_STATE = {
  isSuccess: false,
  isError: false,
  isFetching: false,
  clientData: {}
}

const initialState = {
  answerState: {},
  missedItems: {},
  error: '',
  isFetching: false,
  success: '',
  isSuccess: false,
  isDeviceAdded: false,
  isDeviceDragged: false,
  selectedFrequency: null,
  inspectionAnswer: {},
  jsonData: [],
  scannedQR: null,
  reportData: [],
  answerData: [],
  missedData: [],
  signatureData: [],
  updateKey: false,
  keyToUpdate: '',
  getDeviceListApi: {
    error: '',
    isfetching: false,
    success: '',
    deviceListData: [],
    isSuccess: false,
    totalCount: 0,
    totalPages: 0,
    currentPage: 0,
  },
  clientDetails: {
    ...DEFAULT_CLIENT_STATE
  },
  generateNewRowState: {
    ...DEFAULT_ASYNC_STATE,
  },

  shiftRowState: {
    ...DEFAULT_ASYNC_STATE
  },
  deleteRowState: {
    ...DEFAULT_ASYNC_STATE
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionConstants.SET_INSPECTION_ANSWERS:
      return {
        ...state,
        answerState: {
          ...state.answerState,
          [action.payload.subId]: {
            value: action.payload.value,
            status: '',
            time: new Date().toISOString(),
            employeeId: action.payload.employeeId,
            employeeName: action.payload.employeeName
          }
        },
        missedItems: {
          ...state.missedItems,
          [action.payload.questionId]: {
            [action.payload.subId]: action.payload.value
          }
        }
      };
    case actionConstants.SET_INSPECTION_ANSWERS_INDEX:
      return {
        ...state,
        answerData: [
          ...state.answerData.slice(0, action.payload.selectedIndex),
          {
            ...state.answerData[action.payload.selectedIndex],
            [action.payload.subId]: {
              value: action.payload.value,
              status: '',
              time: new Date().toISOString(),
              employeeId: action.payload.employeeId,
              employeeName: action.payload.employeeName,
              label: action.payload.label
            }
          },
          ...state.answerData.slice(action.payload.selectedIndex + 1),
        ],
        missedData: [
          ...state.missedData.slice(0, action.payload.selectedIndex),
          {
            ...state.missedData[action.payload.selectedIndex],
            // [action.payload.questionId]: {
            //   [action.payload.subId]: action.payload.value
            // }
            [action.payload.subId]: action.payload.value
          },
          ...state.missedData.slice(action.payload.selectedIndex + 1),
        ]
      };
    case actionConstants.SET_INSPECTION_VIEW_ANSWERS_AND_JSON:
      return {
        ...state,
        answerState: {
          ...action.payload.answers
        },
        jsonData: [
          ...action.payload.jsonData
        ],
        selectedFrequency: action.payload.selectedFrequency
      };
    case actionConstants.FETCH_REPORT_DETAIL_SUCCESS:
      if (action.payload.reportType === 'Inspection' && !action.extra && !action.payload.questionJson) {
        const index = state.reportData.findIndex(x => x._id === action.payload._id);
        if (index > -1) {
          return {
            ...state,
            reportData: [
              ...state.reportData.slice(0, index),
              action.payload,
              ...state.reportData.slice(index + 1),
            ],
            answerData: [
              ...state.answerData.slice(0, index),
              action.payload.answers ? action.payload.answers : {},
              ...state.answerData.slice(index + 1)
            ],
            missedData: [
              ...state.missedData.slice(0, index),
              action.payload.missedItems ? action.payload.missedItems : {},
              ...state.missedData.slice(index + 1)
            ]
          };
        }
        return {
          ...state,
          reportData: [
            ...state.reportData,
            action.payload,
          ],
          answerData: [
            ...state.answerData,
            action.payload.answers ? action.payload.answers : {}
          ],
          missedData: [
            ...state.missedData,
            action.payload.missedItems ? action.payload.missedItems : {}
          ]
        };
      }
      return {
        ...state
      };
    case actionConstants.SET_INSPECTION_ARRAY_ANSWERS:
      if (state.answerData
        && state.answerData[action.payload.selectedIndex][action.payload.sectionName]) {
        return {
          ...state,
          answerData: [
            ...state.answerData.slice(0, action.payload.selectedIndex),
            {
              ...state.answerData[action.payload.selectedIndex],
              [action.payload.sectionName]: [...state
                .answerData[action.payload.selectedIndex][action.payload.sectionName],
              ...action.payload.data],
            },
            ...state.answerData.slice(action.payload.selectedIndex + 1),
          ],
          isDeviceAdded: true
        };
      }
      return {
        ...state,
        answerState: {
          ...state.answerState,
          [action.payload.sectionName]: [...action.payload.data],
        },
        isDeviceAdded: true
      };
    case actionConstants.REPLACE_INSPECTION_ARRAY_ANSWERS:
      return {
        ...state,
        answerData: [
          ...state.answerData.slice(0, action.payload.selectedIndex),
          {
            ...state.answerData[action.payload.selectedIndex],
            [action.payload.sectionName]: [...action.payload.data]
          },
          ...state.answerData.slice(action.payload.selectedIndex + 1),
        ],
        isDeviceDragged: true
      };
    case actionConstants.UPDATE_ANSWER_SUCCESS:
      if (action.extra.screenName === 'Devices') {
        return {
          ...state,
          isFetching: false,
          isSuccess: true,
          screenName: action.extra.screenName,
          inspectionAnswer: action.payload,
        };
      }
      return {
        ...state,
        isFetching: false,
        isSuccess: true,
        inspectionAnswer: action.payload,
      };
    case actionConstants.UPDATE_SOCKET_ANSWER_INSPECTION: {
      const index = state.reportData.findIndex(x => x._id === action.payload.reportId);
      const answerKeys = action.payload.answers ? Object.keys(action.payload.answers) : [];
      const answerKey = answerKeys && answerKeys.length && answerKeys[0];

      if (index > -1 && action.payload.image && action.payload.image._id) {
        const imageIndex = state.reportData[index].images.findIndex(x => (
          x._id === action.payload.image._id));
        if (action.payload.image.isDeleted) {
          return {
            ...state,
            reportData: [
              ...state.reportData.slice(0, index),
              {
                ...state.reportData[index],
                images: [
                  ...state.reportData[index].images.slice(0, imageIndex),
                  ...state.reportData[index].images.slice(imageIndex + 1)

                ]
              },
              ...state.answerData.slice(index + 1),
            ],
          };
        }
        if (imageIndex > -1) {
          return {
            ...state,
            reportData: [
              ...state.reportData.slice(0, index),
              {
                ...state.reportData[index],
                images: [
                  ...state.reportData[index].images.slice(0, imageIndex),
                  {
                    ...state.reportData[index].images[imageIndex],
                    ...action.payload.image
                  },
                  ...state.reportData[index].images.slice(imageIndex + 1)

                ]
              },
              ...state.answerData.slice(index + 1),
            ],
          };
        }
        return {
          ...state,
          reportData: [
            ...state.reportData.slice(0, index),
            {
              ...state.reportData[index],
              images: [...state.reportData[index].images, action.payload.image]
            },
            ...state.answerData.slice(index + 1),
          ],
        };
      }
      if (index > -1) {
        if (answerKey === 'tables') {
          return {
            ...state,
            answerData: [
              ...state.answerData.slice(0, index),
              {
                ...state.answerData[index],
                [answerKey]: {
                  ...state.answerData[index][answerKey],
                  ...action.payload.answers[answerKey]
                }
              },
              ...state.answerData.slice(index + 1),
            ],

          }
        }
        return {
          ...state,
          answerData: [
            ...state.answerData.slice(0, index),
            {
              ...state.answerData[index],
              [answerKey]: {
                ...state.answerData[index][answerKey],
                ...action.payload.answers[answerKey]
              }
            },
            ...state.answerData.slice(index + 1),
          ],
          missedData: [
            ...state.missedData.slice(0, index),
            {
              ...state.missedData[index],
              [answerKey]: action.payload.missedData[answerKey]
            },
            ...state.missedData.slice(index + 1),
          ],
          updateKey: true,
          keyToUpdate: answerKey
        };
      }
      return {
        ...state
      };
    }
    case actionConstants.CLEAR_UPDATE_KEY:
      return {
        ...state,
        updateKey: false,
        keyToUpdate: ''
      };
    case actionConstants.UPDATE_ANSWER_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.message,
      };
    case actionConstants.UPDATE_ANSWER_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case actionConstants.SET_INSPECTION_FETCH_ANSWERS:
      return {
        ...state,
        answerState: {
          ...action.payload.answers
        }
      };
    case actionConstants.CLEAR_ANSWERS:
      return {
        ...state,
        answerState: {},
        jsonData: [],
        reportData: [],
        answerData: [],
        missedData: [],
        signatureData: []
      };
    case actionConstants.CLEAR_INSPECTION_ANSWER_SUCCESS:
      return {
        ...state,
        error: '',
        isFetching: false,
        success: '',
        isSuccess: false,
        isDeviceAdded: false,
        isDeviceDragged: false,
        screenName: null
      };
    case actionConstants.DELETE_DEVICE_FROM_PARTICULAR_INDEX:
      return {
        ...state,
        answerData: [
          ...state.answerData.slice(0, action.payload.selectedIndex),
          {
            ...state.answerData[action.payload.selectedIndex],
            [action.payload.sectionName]: state.answerData[action.payload
              .selectedIndex][action.payload.sectionName].filter((item,
                index1) => index1 !== action.payload.index),
          },
          ...state.answerData.slice(action.payload.selectedIndex + 1),
        ],
        isDeviceDragged: true
      };
    case actionConstants.REPLACE_INSPECTION_ARRAY_ANSWERS_PARTICULAR_INDEX:
      return {
        ...state,
        answerData: [
          ...state.answerData.slice(0, action.payload.selectedIndex),
          {
            ...state.answerData[action.payload.selectedIndex],
            [action.payload.sectionName]: state.answerData[action.payload
              .selectedIndex][action.payload.sectionName].map((item,
                indx) => {
                if (indx === action.payload.index) {
                  return { ...action.payload.data };
                }
                return item;
              }),
          },
          ...state.answerData.slice(action.payload.selectedIndex + 1),
        ],
        isDeviceAdded: true
      };
    case actionConstants.UPDATE_SWITCHES_INSPECTION:
      return {
        ...state,
        answerData: [
          ...state.answerData.slice(0, action.payload.selectedIndex),
          {
            ...state.answerData[action.payload.selectedIndex],
            [action.payload.id]: {
              ...state.answerData[action.payload.selectedIndex][action.payload.id],
              [action.payload.key]: action.payload.value
            }
          },
          ...state.answerData.slice(action.payload.selectedIndex + 1),
        ]
      };
    case actionConstants.SAVE_SIGNATURE_IDS:
      return {
        ...state,
        signatureData: [
          ...state.signatureData.slice(0, action.payload.selectedIndex),
          [...action.payload.ids],
          ...state.signatureData.slice(action.payload.selectedIndex + 1),
        ]
      };
    case actionConstants.STORE_SIGNATURE_PROFILE_IN_ANSWERS:
      {
        const obKeys = Object.keys(action.payload.answer);
        let missedObj = {};
        for (let i = 0; i < obKeys.length; i += 1) {
          missedObj = {
            [obKeys[i]]: action.payload.answer[obKeys[i]].value
          };
        }
        if (!(action.payload.isViewing)) {
          return {
            ...state,
            answerData: [
              ...state.answerData.slice(0, action.payload.selectedIndex),
              {
                ...state.answerData[action.payload.selectedIndex],
                ...action.payload.answer
              },
              ...state.answerData.slice(action.payload.selectedIndex + 1),
            ],
            missedData: [
              ...state.missedData.slice(0, action.payload.selectedIndex),
              {
                ...state.missedData[action.payload.selectedIndex],
                ...missedObj
              },
              ...state.missedData.slice(action.payload.selectedIndex + 1),
            ]
          };
        }
      }
      return {
        ...state
      }

    case actionConstants.ADD_ROW:
      const answersObject = state.answerData[action.payload.selectedIndex]
        ? state.answerData[action.payload.selectedIndex] : {};
      const tables = answersObject.tables ? answersObject.tables : {};
      return {
        ...state,
        answerData: [
          ...state.answerData.slice(0, action.payload.selectedIndex),
          {
            ...state.answerData[action.payload.selectedIndex],
            tables: {
              ...tables,
              [action.payload.tableID]: {
                ...tables[action.payload.tableID],
                count: action.payload.count,
              },
            }
          },
          ...state.answerData.slice(action.payload.selectedIndex + 1),
        ]
      };

    case actionConstants.UPDATE_DEVICES_IN_REPORT:
      const previousDevices = state.answerData[action.payload.selectedIndex].devices ? state.answerData[action.payload.selectedIndex].devices : []
        return {
          ...state,
          answerData: [
            ...state.answerData.slice(0, action.payload.selectedIndex),
            {
              ...state.answerData[action.payload.selectedIndex],
              devices: [
                ...previousDevices.slice(0, action.payload.rowIndex),
                {
                  ...previousDevices[action.payload.rowIndex],
                  ...action.payload.deviceObj
                },
                ...previousDevices.slice(action.payload.rowIndex + 1),
            ]
            },
            ...state.answerData.slice(action.payload.selectedIndex + 1),
          ]
        };
    case actionConstants.DELETE_DEVICES_REPORT: 
      return {
        ...state,
          answerData: [
            ...state.answerData.slice(0, action.payload.selectedIndex),
            {
              ...state.answerData[action.payload.selectedIndex],
              devices: [
                ...state.answerData[action.payload.selectedIndex].devices.slice(0, action.payload.rowIndex),
                ...state.answerData[action.payload.selectedIndex].devices.slice(action.payload.rowIndex + 1),
            ]
            },
            ...state.answerData.slice(action.payload.selectedIndex + 1),
          ]
        };

    case actionConstants.SAVE_QR_STRING:
      return {
        ...state,
        scannedQR: action.payload.qrString
      };

    case actionConstants.ADD_DEVICES_REPORT:
        const oldDevices = state.answerData[action.payload.selectedIndex].devices ? state.answerData[action.payload.selectedIndex].devices : [];
        const newDevices = [{
          floor: null,
          location: null,
          make: null,
          model: null,
          reportType: state.reportData[action.payload.selectedIndex].reportCategory
        }];
        return {
          ...state,
          answerData: [
            ...state.answerData.slice(0, action.payload.selectedIndex),
            {
              ...state.answerData[action.payload.selectedIndex],
              devices: [
                ...oldDevices,
                ...newDevices
              ]
            },
            ...state.reportData.slice(action.payload.selectedIndex + 1),
  
          ]
        }
    case actionConstants.GENERATE_NEW_ROW_SUCCESS:
      const shouldNotUpdate = typeof (action.payload.shouldNotUpdate) == 'boolean' ? action.payload.shouldNotUpdate : false
      return {
        ...state,
        isFetching: false,
        generateNewRowState: {
          ...state.generateNewRowState,
          isSuccess: true,
          shouldNotUpdate
        },
        reportData: [
          ...state.reportData.slice(0, action.payload.selectedIndex),
          {
            ...state.reportData[action.payload.selectedIndex],
            reportJson: action.payload.reportJson,
            parsedJson: action.payload.parsedJson,
          },
          ...state.reportData.slice(action.payload.selectedIndex + 1),
        ],
        answerData: [
          ...state.answerData.slice(0, action.payload.selectedIndex),
          {
            ...state.answerData[action.payload.selectedIndex],
            // devices: [
            //   ...oldDevices,
            //   ...newDevices
            // ]
          },
          ...state.reportData.slice(action.payload.selectedIndex + 1),

        ]
      };
    case actionConstants.GENERATE_NEW_ROW_ERROR:
      return {
        ...state,
        isFetching: false,
        generateNewRowState: {
          ...state.generateNewRowState,
          isError: true,
          message: 'Could not generate new row.',
        },
      };
    case actionConstants.GENERATE_NEW_ROW_PROGRESS:
      return {
        ...state,
        isFetching: true,
      };
    case actionConstants.GENERATE_NEW_ROW_CLEAR_UPDATE_STATE:
      return {
        ...state,
        generateNewRowState: {
          ...DEFAULT_ASYNC_STATE,
        },
      };
    case actionConstants.DELETE_ROW_SUCCESS: {
      const shouldNotNavigate = action.payload ? action.payload.shouldNotNavigate : false;
      let columnIds = action.payload.columnIds;
      columnIds = columnIds.reduce((accumulator, currentValue) => {
        return { ...accumulator, [currentValue]: undefined };
      }, {});
      return {
        ...state,
        isFetching: false,
        deleteRowState: {
          ...state.deleteRowState,
          isSuccess: true,
        },
        reportData: [
          ...state.reportData.slice(0, action.payload.selectedIndex),
          {
            ...state.reportData[action.payload.selectedIndex],
            reportJson: action.payload.reportJson,
            parsedJson: action.payload.parsedJson,
          },
          ...state.reportData.slice(action.payload.selectedIndex + 1)
        ],
        answerData: [
          ...state.answerData.slice(0, action.payload.selectedIndex),
          {
            ...state.answerData[action.payload.selectedIndex],
            tables: {
              ...state.answerData[action.payload.selectedIndex].tables,
              [action.payload.tableID]: {
                ...state.answerData[action.payload.selectedIndex].tables[action.payload.tableID],
                count: state.answerData[action.payload.selectedIndex].tables[action.payload.tableID].count
                  ? state.answerData[action.payload.selectedIndex].tables[action.payload.tableID].count - 1 : 0,
              },
            },
            ...columnIds,
          },
          ...state.answerData.slice(action.payload.selectedIndex + 1)
        ],

        missedData: [
          ...state.missedData.slice(0, action.payload.selectedIndex),
          {
            ...state.missedData[action.payload.selectedIndex],
            ...columnIds,
          },
          ...state.answerData.slice(action.payload.selectedIndex + 1)
        ],
        // shouldNotNavigate,
      };
    }
    case actionConstants.DELETE_ROW_ERROR:
      return {
        ...state,
        isFetching: false,
        deleteRowState: {
          ...state.deleteRowState,
          isError: true,
          message: 'Could not remove row.',
        },
      };
    case actionConstants.DELETE_ROW_PROGRESS:
      return {
        ...state,
        isFetching: true
      };
    case actionConstants.DELETE_ROW_CLEAR_API_STATE:
      return {
        ...state,
        deleteRowState: {
          ...DEFAULT_ASYNC_STATE,
        },
      };
    case actionConstants.SET_ANSWER_TABLE_PARTICULAR_INDEX:
      return {
        ...state,
        answerData: [
          ...state.answerData.slice(0, action.payload.selectedIndex),
          {
            ...action.payload.answers,
            devices: action.payload.devices
          },
          ...state.answerData.slice(action.payload.selectedIndex + 1)
        ],
        missedData: [
          ...state.missedData.slice(0, action.payload.selectedIndex),
          { ...action.payload.missedItems },
          ...state.missedData.slice(action.payload.selectedIndex + 1)
        ]
      }
    case actionConstants.GET_DEVICES_FOR_REPORT_SUCCESS:
      if (action.payload.page == 1) {
        return {
          ...state,
          getDeviceListApi: {
            ...state.getDeviceListApi,
            isSuccess: true,
            isfetching: false,
            deviceListData: [...action.payload.docs],
            totalPages: action.payload.pages,
            totalCount: action.payload.total,
            currentPage: action.payload.page
          }
        }
      }
      return {
        ...state,
        getDeviceListApi: {
          ...state.getDeviceListApi,
          isSuccess: true,
          isfetching: false,
          deviceListData: [...state.getDeviceListApi.deviceListData, ...action.payload.docs],
          totalPages: action.payload.pages,
          totalCount: action.payload.total,
          currentPage: action.payload.page
        }
      }
    case actionConstants.CLEAR_DEVICES_REPORT_SUCCESS: {
      return {
        ...state,
        getDeviceListApi: {
          ...state.getDeviceListApi,
          isSuccess: false,
          error: ''
        }
      }
    }

    case actionConstants.GET_DEVICES_FOR_REPORT_FAILURE: {
      return {
        ...state,
        getDeviceListApi: {
          ...state.getDeviceListApi,
          isSuccess: false,
          isfetching: false,
          error: action.payload.message
        }
      }
    }

    case actionConstants.GET_DEVICES_FOR_REPORT_REQUEST: {
      return {
        ...state,
        getDeviceListApi: {
          ...state.getDeviceListApi,
          isSuccess: false,
          isfetching: true,
          error: ''
        }
      }
    };
    case actionConstants.CLEAR_DEVICE_REPORT_DATA:
      return {
        ...state,
        getDeviceListApi: {
          error: '',
          isfetching: false,
          success: '',
          deviceListData: [],
          isSuccess: false,
          totalCount: 0,
          totalPages: 0,
          currentPage: 0,
        },

      };
    case actionConstants.GET_REPORT_CLIENT_DETAILS_REQUEST:
      return {
        ...state,
        clientDetails: {
          ...state.clientDetails,
          isFetching: true
        }
      }

    case actionConstants.GET_REPORT_CLIENT_DETAILS_SUCCESS:
      return {
        ...state,
        clientDetails: {
          ...state.clientDetails,
          isFetching: false,
          isSuccess: true,
          clientData: action.payload
        }
      }

    case actionConstants.GET_REPORT_CLIENT_DETAILS_FAILURE:
      return {
        ...state,
        clientDetails: {
          ...state.clientDetails,
          isFetching: false,
          isError: true,
        }
      }

    case actionConstants.GET_REPORT_CLIENT_DETAILS_CLEAR:
      return {
        ...state,
        clientDetails: {
          ...state.clientDetails,
          isSuccess: false,
          isFetching: false,
          isError: false
        }
      };


    case actionConstants.INSPECTION_ROW_SHIFT_SUCCESS:
      return {
        ...state,
        isFetching: false,
        shiftRowState: {
          ...state.shiftRowState,
          isSuccess: true,
        },
        reportData: [
          ...state.reportData.slice(0, action.payload.selectedIndex),
          {
            ...state.reportData[action.payload.selectedIndex],
            reportJson: action.payload.reportJson,
            parsedJson: action.payload.parsedJson,
          },
          ...state.reportData.slice(action.payload.selectedIndex + 1),
        ],
      };
    case actionConstants.INSPECTION_ROW_SHIFT_ERROR:
      return {
        ...state,
        isFetching: false,
        shiftRowState: {
          ...state.shiftRowState,
          isError: true,
          message: action.payload.error,
        },
      };
    case actionConstants.CANCEL_PARTICULAR_REPORT:
      return {
        ...state,
        answerData: [
          ...state.answerData.slice(0, action.payload.selectedIndex),
          ...state.answerData.slice(action.payload.selectedIndex + 1),
        ],
        missedData: [
          ...state.missedData.slice(0, action.payload.selectedIndex),
          ...state.missedData.slice(action.payload.selectedIndex + 1),
        ],
        signatureData: [
          ...state.signatureData.slice(0, action.payload.selectedIndex),
          ...state.signatureData.slice(action.payload.selectedIndex + 1),
        ],
        reportData: [
          ...state.reportData.slice(0, action.payload.selectedIndex),
          ...state.reportData.slice(action.payload.selectedIndex + 1),
        ]
      }

    case actionConstants.INSPECTION_ROW_SHIFT_PROGRESS:
      return {
        ...state,
        isFetching: true,
      };
    case actionConstants.INSPECTION_ROW_SHIFT_CLEAR_API_STATE:
      return {
        ...state,
        isFetching: false,
        shiftRowState: {
          ...DEFAULT_ASYNC_STATE
        },
      };
    case actionConstants.RESET_UPDATE_INSPECTION_ARRAY_INITIAL_STATE:
      return {
        ...state,
        ...initialState
      };
    default:
      return state;
  }
};
