import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

const DefaultDropDownComponent = (props) => {
  const { listData, onChange, className } = props;
  return (
    <div
      className={className}
      style={{
        maxHeight:
          window.outerWidth > 800
            ? 0.3 * window.outerWidth
            : 0.6 * window.outerHeight,
      }}
    >
      {listData &&
        listData.map((value) => (
          <div
            className="selecthover"
            key={value}
            onClick={() => onChange(value)}
          >
            {value ? (
              <FormattedMessage id={value} defaultMessage={value} />
            ) : (
              ''
            )}
          </div>
        ))}
    </div>
  );
};

DefaultDropDownComponent.propTypes = {
  listData: PropTypes.array,
  onChange: PropTypes.func,
  className: PropTypes.string,
};

DefaultDropDownComponent.defaultProps = {
  className: 'select-items mCustomScrollbar',
};

export default DefaultDropDownComponent;
