import { getToken } from '../_helpers/commonFunctions';
import call from './apiRequest';
import apiEndpoints from './apiEndpoints';

/**
 * Api call to fetch subscription plans
 */
const subscriptionPlanFetch = () =>
  call({
    endpoint: `${apiEndpoints.subscriptionPlans}`,
    authKey: getToken(),
  });

/**
 * Api call to fetch active subscriptions
 */
const fetchSubs = () =>
  call({
    endpoint: `${apiEndpoints.subscription}`,
    authKey: getToken(),
  });

/**
 * Api call to create user payment card
 * @param {object} payload
 */
const userCardCreation = (payload) =>
  call({
    method: 'post',
    endpoint: `${apiEndpoints.userCardCreate}`,
    payload,
    authKey: getToken(),
  });

/**
 * Api call to create subscription plan
 * @param {object} payload
 */
const createPlan = (payload) =>
  call({
    method: 'post',
    endpoint: `${apiEndpoints.createPlan}`,
    payload,
    authKey: getToken(),
  });

/**
 * Api call to get customer details
 * @param {string} id
 */
const customerDetail = (id) =>
  call({
    endpoint: `${apiEndpoints.customerDetail}${id}`,
    authKey: getToken(),
  });

/**
 * Api call to delete subscription plan
 * @param {string} subscriptionId
 */
const deleteSubscriptionApi = (subscriptionId) =>
  call({
    endpoint: apiEndpoints.createPlan,
    method: 'delete',
    authKey: getToken(),
    payload: {
      planId: subscriptionId,
    },
  });

export {
  subscriptionPlanFetch,
  userCardCreation,
  createPlan,
  customerDetail,
  fetchSubs,
  deleteSubscriptionApi,
};
