import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import ToolTip from '../ToolTip/index';
import { actAsIntEvent, actAsNumberEvent, actAsNumberSpecialEvent } from '../../views/hardCodedReports/helpers/numberTextSpecialHelper';

const Input = (props) => {
  const {
    dataFor,
    dataTip,
    place,
    value,
    onChange,
    name,
    className,
    type,
    subType,
    max,
    disabled,
    placeholder,
    onClick,
    noMax,
    step,
  } = props;
  useEffect(()=>{
    const inputElement = document.getElementById(name);
    if (subType === 'number' && inputElement) inputElement.addEventListener('textInput', actAsNumberEvent);
    if (subType === 'int' && inputElement) inputElement.addEventListener('textInput', actAsIntEvent);
    if (subType === 'numberSpecial' && inputElement) inputElement.addEventListener('textInput', actAsNumberSpecialEvent);
  })
  return (
    <Fragment>
      <input
        data-for={dataFor}
        data-tip={dataTip && props.intl.formatMessage({ id: dataTip })}
        type={type}
        value={value}
        disabled={disabled}
        onChange={onChange}
        name={name}
        id={name}
        {...(step && { step })}
        onClick={onClick}
        className={className}
        placeholder={props.intl.formatMessage({ id: placeholder })}
        {...(!noMax && { maxLength: max || 50 })}
        autoComplete="nope"
        spellCheck="true"
      />
      <ToolTip id={dataFor} place={place} />
    </Fragment>
  );
};

Input.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]), // Value of input field
  onChange: PropTypes.func, // handles input field on change
  name: PropTypes.string, // name of input field
  className: PropTypes.string, // class name of input field
  type: PropTypes.string, // type of input field
  max: PropTypes.number, // max attribute of input field
  disabled: PropTypes.bool, // disabled attribute of input field
  placeholder: PropTypes.string, // placeholder of input field
  onClick: PropTypes.func, // handles input field on click
  intl: intlShape.isRequired,
  place: PropTypes.string,
  dataFor: PropTypes.string,
  dataTip: PropTypes.string,
};

Input.defaultProps = {
  placeholder: 'Input Here',
  place: 'bottom',
};

export default injectIntl(Input);
