import actionConstants from '../constants/ActionConstants';

const initialState = {
  error: '',
  isFetching: false,
  success: '',
  deletedSystemInfo: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionConstants.DELETE_SYSTEM_OR_DEVICE_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: '',
        success: '',
      };
    case actionConstants.DELETE_SYSTEM_OR_DEVICE_SUCCESS:
      return {
        ...state,
        deletedSystemInfo: action.payload.systemInfo,
        isFetching: false,
        success: 'System info deleted successfuly',
        error: '',
      };
    case actionConstants.DELETE_SYSTEM_OR_DEVICE_FAILURE:
      return {
        ...state,
        error: action.message,
        isFetching: false,
        success: '',
      };
    case actionConstants.CLEAR_STATE:
      return {
        ...state,
        error: '',
        success: '',
      };
    case actionConstants.RESET_ALL_REDUCERS:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};
