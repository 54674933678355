import * as actionTypes from '../constants/ActionConstants';

const INITIAL_STATE = {
  loading: false,
  requestAmount: 0,
};

const LoaderReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.default.SHOW_LOADER_GLOBAL:
      return {
        ...state,
        loading: true,
        requestAmount: state.requestAmount + 1
      }
    case actionTypes.default.HIDE_LOADER_GLOBAL:
      return {
        ...state,
        loading: state.requestAmount > 1,
        requestAmount: state.requestAmount - 1
      }
    default:
      return state;
    }
  };

export default LoaderReducer;