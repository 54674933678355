import { API } from '../config';
import { proxyUrl } from '../views/constant';
import call from './apiRequest';

const baseURL = API.GEO_CODE_API;
const key = API.GEO_LOCATION_KEY;

/**
 * APi call to get address using lat long
 * @param {string} latitude
 * @param {string} longitude
 */
export const getAddressApi = (latitude, longitude) =>
  call({
    url: proxyUrl + baseURL,
    query: {
      key,
      latlng: `${latitude},${longitude}`,
    },
  });

/**
 * Api call to get lat long with address
 * @param {string} address
 */
export const getAddressApiWithAddress = (address) =>
  call({
    url: proxyUrl + baseURL,
    query: {
      key,
      address: `${address}`,
    },
  });
