import actionConstants from '../constants/ActionConstants';

const initialState = {
  error: '',
  isfetching: false,
  reportLabourData: [],
  isSuccess: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionConstants.ADD_REPORT_LABOUR:
      return {
        ...state,
        reportLabourData: [...state.reportLabourData, action.payload],
        isfetching: false,
        isSuccess: true,
      };
    default:
      return state;
  }
};
