import React from 'react'
import { Button } from 'antd';
import { AlarmOff } from '@material-ui/icons'
import Dropdown from '../../components/ReallyCustomDropDown';

export const getDateTime = (date) => {
  const d = new Date(date)
  return d.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true })
}

const JobCard = ({ job, onStartClick, statusSelect }) => {
    
  const whiteCard = (
    <div className="bg-white rounded-lg p-3 flex items-center justify-between flex-1 relative">
      <div className="absolute -top-6 font-medium">
        {getDateTime(job.startDateTime)}
      </div>
      <div className="mr-3">
        <h3 className="line-clamp-2">
          {job.createdFor.businessName}
        </h3>
        <div className="text-lg bg-opacity-70">
          {job.reportCategory}
        </div>
      </div>
      <Button
        danger
        type="primary"
        className="rounded-md"
        style={{backgroundColor: '#b71b1b'}}
        size="large"
        onClick={onStartClick}
      >
        Start
      </Button>
    </div>
  );

  return (
    <div className="flex items-center pt-12">
      {statusSelect}
      {whiteCard}
    </div>
  );
};

export default JobCard;