import React from 'react';
import clsx from 'clsx'
import PropTypes from 'prop-types';

const mapTypesClasses = {
  default: 'border-gray-500 hover:bg-gray-500 text-gray-500',
  primary: 'border-aries-light-blue hover:bg-aries-light-blue text-aries-light-blue',
  success: 'border-green-600 hover:bg-green-600 text-green-600',
  danger: 'border-red-700 hover:bg-red-700 text-red-700',
  warning: 'border-yellow-500 hover:bg-yellow-500 text-yellow-500'
};

const mapSizesClasses = {
  small: 'pr-3 py-1',
  default: 'pr-4 py-1.5 font-medium',
  large: 'pr-5 py-2 text-lg font-semibold'
};

const Button = ({
  children,
  onClick = () => {},
  color,
  size = 'default',
  disabled = false,
  className,
  type = 'default',
  htmlType = 'button',
  icon,
  form,
}) => {

  const plClasses = {
    small: icon ? 'pl-1' : 'pl-3',
    default: icon ? 'pl-2' : 'pl-4',
    large: icon ? 'pl-3' : 'pl-5'
  };

  const buttonClass = clsx(
    'group cursor-pointer border rounded overflow-hidden relative bg-white transition-all select-none',
    plClasses[size] || plClasses.default,
    mapSizesClasses[size] || mapSizesClasses.default,
    mapTypesClasses[type],
    disabled ? 'pointer-events-none opacity-50' : null,
    className
  );

  const innerIcon = icon ? (
    <span className="mr-1 flex w-6 relative items-center">
      <span className="absolute left-0">
        {icon}
      </span>
    </span>
  ) : null;

  const handleRef = el => {
    if(!color || !el) return
    el.style.setProperty('border-color', color, 'important');
    el.style.setProperty('color', color, 'important');
  };

  const handleBackRef = el => {
    if(!color || !el) return
    el.style.setProperty('background-color', color, 'important');
  };

  return (
    <button
      className={buttonClass}
      ref={handleRef}
      onClick={onClick}
      type={htmlType}
      form={form}
    >
      <span className="z-10 relative group-hover:text-white flex items-center justify-center">
        {innerIcon}
        {children}
      </span>
      <div
        className="opacity-0 group-hover:opacity-100 absolute z-0 h-full w-full left-0 top-0 transition-opacity"
        ref={handleBackRef}
      />
    </button>
  );
};

Button.propTypes = {
  children: PropTypes.any,
  onClick: PropTypes.func,
  color: PropTypes.string,
  size: PropTypes.oneOf(Object.keys(mapSizesClasses)),
  disabled: PropTypes.bool,
  className: PropTypes.string,
  type: PropTypes.oneOf(Object.keys(mapTypesClasses)),
};

export default Button;