/*eslint-disable*/
import _ from 'lodash';

const cloneDeep = _.cloneDeep;

const parser = (json) => {
  let QuestionJson = [];
  let currentQuestion = [];
  let istableContent = false;
  let parentElement = {};
  for (let elementIndex = 0, jsonLength = json.length; elementIndex < jsonLength; elementIndex++) {
    let currentElement = json[elementIndex];
    parentElement = {
      id: currentElement.id,
      value: currentElement.value,
      isConditional: currentElement.conditional ? currentElement.conditional.conditional : false,
      simultaneousGroupId: currentElement.simultaneousGroupId,
      frequency: currentElement.frequency,
      section: currentElement.section,
      memorizeGroup: currentElement.memorizeGroup,
      notQuestion: currentElement.notQuestion,
      isHeader: currentElement.isHeader,
    }
    if (!currentElement.notQuestion && !currentElement.isHeader && currentElement.conditional ? !currentElement.conditional.conditional : true) {
      if (currentElement.type == 'DIV' && currentElement.childEntity && Object.keys(currentElement.childEntity).length) {
        currentQuestion = []
        getChildEntity(currentElement.childEntity)
        if (currentQuestion.length)
          QuestionJson.push(currentQuestion)

        if (currentElement.conditional ? currentElement.conditional.child : false) {
          let childrens = currentElement.conditional.child.split(',');
          for (let child = 0; child < childrens.length; child++) {
            currentQuestion = []
            let index = json.findIndex(element => {
              return element.id == childrens[child]
            })
            if (index >= 0) {
              parentElement = {
                id: json[index].id,
                value: json[index].value,
                isConditional: json[index].conditional ? json[index].conditional.conditional : false,
                simultaneousGroupId: json[index].simultaneousGroupId,
                frequency: json[index].frequency,
                section: json[index].section,
                memorizeGroup: json[index].memorizeGroup,
                notQuestion: json[index].notQuestion,
                isHeader: json[index].isHeader
              }
              getChildEntity(json[index].childEntity)

              if (currentQuestion.length && !istableContent) {
                QuestionJson[QuestionJson.length - 1][1].child.push(currentQuestion)
              }
            }
          }
        }
      }
    }
  }

  function getChildEntity(childEntity) {
    for (let key in childEntity) {
      let currentObject;
      if (childEntity[key].childEntity && childEntity[key].conditional ? !childEntity[key].conditional.conditional : false)
        currentObject = getCurrentLine(
          key,
          childEntity[key].type,
          childEntity[key].css,
          childEntity[key].subType,
          childEntity[key].text,
          childEntity[key].image,
          false,
          childEntity[key].options,
          childEntity[key].conditional,
          childEntity[key] ? childEntity[key].childEntity : false,
          childEntity[key] ? childEntity[key].simultaneousGroupId : false,
          childEntity[key] ? childEntity[key].frequency : false,
          childEntity[key] ? childEntity[key].section : false,
          childEntity[key] ? childEntity[key].memorizeGroup : false,
          childEntity[key] ? childEntity[key].isVertical : false,
          childEntity[key] ? childEntity[key].isHorizontal : false,
          childEntity[key] ? childEntity[key].isInspectorSignature : false,
          childEntity[key] ? childEntity[key].maxRows : null,
          childEntity[key] ? childEntity[key].minRows : null
        )
      else
        currentObject = getCurrentLine(
          key,
          childEntity[key].type,
          childEntity[key].css,
          childEntity[key].subType,
          childEntity[key].text,
          childEntity[key].image,
          true,
          childEntity[key].options,
          childEntity[key].conditional,
          childEntity[key] ? childEntity[key].childEntity : false,
          childEntity[key] ? childEntity[key].simultaneousGroupId : false,
          childEntity[key] ? childEntity[key].frequency : false,
          childEntity[key] ? childEntity[key].section : false,
          childEntity[key] ? childEntity[key].memorizeGroup : false,
          childEntity[key] ? childEntity[key].isVertical : false,
          childEntity[key] ? childEntity[key].isHorizontal : false,
          childEntity[key] ? childEntity[key].isInspectorSignature : false,
          childEntity[key] ? childEntity[key].maxRows : null,
          childEntity[key] ? childEntity[key].minRows : null
        )

      if (currentObject == 'backfire')
        return
      if (currentObject) {
        if (currentQuestion.length < 1)
          currentQuestion.push(parentElement)
        currentQuestion.push(currentObject)
      }

      if (childEntity[key] ? childEntity[key].childEntity : false && childEntity[key].type != 'TBODY') {
        getChildEntity(childEntity[key].childEntity);
        currentQuestion.push(currentObject);
      }
    }
  }

  function getCurrentLine(id,
    type,
    css,
    subType,
    text,
    image,
    isChildDiv,
    options,
    conditional,
    childEntity,
    simultaneousGroupId,
    frequency,
    section,
    memorizeGroup,
    isVertical,
    isHorizontal,
    isInspectorSignature,
    maxRows,
    minRows,
    value = ''
  ) {
    let currentObject = {
      id: null,
      type: null,
      question: null,
      answerId: null,
      renderingText: null,
      option: null,
      subType: null,
      value: null,
      isConditional: null,
      simultaneousGroupId: null,
      frequency: null,
      section: null,
      memorizeGroup: null,
      isVertical: null,
      isHorizontal: null,
      child: [],
      isInspectorSignature,
      maxRows,
      minRows,
    }
    if (type == 'HR' || type == 'IMG' || type == 'TABLE' || type == 'TR' || type == 'DIV' && subType != 'signature')
      return;
    if (type == 'TBODY' && isVertical) {
      const deepcloned = cloneDeep(childEntity);
      verticalTable(deepcloned);
      childEntity = null
      return 'backfire'
    }
    else
      if (type == 'TBODY' && isHorizontal) {
        horizontalTable(childEntity);
        return 'backfire'
      }
    currentObject.id = id;
    currentObject.isConditional = conditional ? conditional.conditional : false;
    currentObject.type = type;
    currentObject.option = options;
    currentObject.question = text;
    currentObject.subType = subType;
    currentObject.value = value;
    currentObject.value = simultaneousGroupId;
    currentObject.value = frequency;
    currentObject.value = memorizeGroup;
    currentObject.value = value;
    currentObject.isInspectorSignature = isInspectorSignature;
    currentObject.maxRows = maxRows
    currentObject.minRows = minRows



    if ((subType == 'radio' || subType == 'checkbox')) {
      if (value)
        currentObject.value = 'checked';
      else
        currentObject.value = null;
    }

    if (conditional ? conditional.child : false) {
      let childrens = conditional.child.split(',');
      for (let i = 0; i < childrens.length; i++) {
        let index = json.findIndex(element => {
          return element.id == childrens[i]
        })
        getChildEntity(json[index].childEntity)
      }
    }
    return currentObject;
  }


  function verticalTable(rows) {
    istableContent = true
    let rowsKeys = Object.keys(rows);
    let Questions = rows[rowsKeys[0]].childEntity
    let QuestionsKeys = Object.keys(Questions);
    let QuestionsLenth = QuestionsKeys.length;
    let verticalTable = []
    for (let j = 1, rowsLength = rowsKeys.length; j < rowsLength; j++) {
      let verticalRow = []
      for (let i = 0; i < QuestionsLenth; i++) {
        let currentQusAns = { Q: [], A: [] }

        //------------------------------------Question Management-----------------------------------------
        currentQusAns.Q = [[Questions[QuestionsKeys[i]]]]
        if (currentQusAns.Q[0][0].conditional ? currentQusAns.Q[0][0].conditional.child : false) {
          let childrens = currentQusAns.Q[0][0].conditional.child.split(',');
          currentQusAns.Q = []
          currentQuestion = []
          for (let child = 0; child < childrens.length; child++) {
            let index = json.findIndex(element => {
              return element.id == childrens[child]
            })
            getChildEntity(json[index].childEntity)
          }
          if (currentQuestion.length)
            currentQusAns.Q.push(currentQuestion)

        }
        currentQusAns.Q = processCurrentLine(currentQusAns.Q, QuestionsKeys, i)
        //-------------------------------------Answer Management-------------------------------------------
        currentQusAns.A = [[rows[rowsKeys[j]].childEntity[Object.keys(rows[rowsKeys[j]].childEntity)[i]]]]
        if (currentQusAns.A[0][0].conditional ? currentQusAns.A[0][0].conditional.child : false) {
          let index = json.findIndex(element => {
            return element.id == currentQusAns.A[0][0].conditional.child
          })

          currentQusAns.A = []
          currentQuestion = []
          getChildEntity(json[index].childEntity)
          if (currentQuestion.length)
            currentQusAns.A.push(currentQuestion)
        }
        //-------------------------------------------------------------------------------------------------
        currentQusAns.A = processCurrentLine(currentQusAns.A, false, i, rows, rowsKeys, j)
        verticalRow.push(currentQusAns)
      }
      if (verticalTable.length < 1) {
        parentElement.isVertical = true
        parentElement.isHorizontal = false
        verticalTable.push(parentElement)
      }
      verticalTable.push(verticalRow)
    }
    currentQuestion = []
    currentQuestion = (verticalTable)
    istableContent = false
  }


  function processCurrentLine(currentLine, QuestionsKeys, i, rows, rowsKeys, j) {
    let Qdata = [];
    for (let k = 0; k < currentLine.length; k++) {
      let data = [];
      for (let l = 0; l < currentLine[k].length; l++) {
        data[l] = getCurrentLine(QuestionsKeys ? QuestionsKeys[i] : Object.keys(rows[rowsKeys[j]].childEntity)[i],
          currentLine[k][l].type,
          currentLine[k][l].css,
          currentLine[k][l].subType,
          currentLine[k][l].question ? currentLine[k][l].question : currentLine[k][l].text,
          currentLine[k][l].image,
          false,
          currentLine[k][l].options,
          currentLine[k][l].conditional,
          currentLine[k] ? currentLine[k].childEntity : false,
          currentLine[k] ? currentLine[k].isVertical : false,
          currentLine[k] ? currentLine[k].isHorizontal : false,
          currentLine[k] ? currentLine[k].isInspectorSignature : false,
          currentLine[k] ? currentLine[k].maxRows : null,
          currentLine[k] ? currentLine[k].minRows : null
        )
      }
      Qdata.push(data)
    }
    return Qdata;
  }


  function horizontalTable(rows) {
    istableContent = true
    let rowsKeys = Object.keys(rows);
    let Questions = rows[rowsKeys[0]].childEntity//{td1:{}}
    let QuestionsKeys = Object.keys(Questions);//[td1,td2]
    let QuestionsLenth = QuestionsKeys.length;
    let horizontalTable = []

    for (let j = 0, QuesLength = QuestionsKeys.length; j < QuesLength; j++) {
      let ParentQ = null
      let questRow = null
      if (Questions[QuestionsKeys[j]].subQuestions[0][0]) {
        currentQuestion = []
        getCurrentLine(
          Questions[QuestionsKeys[j]].id,
          Questions[QuestionsKeys[j]].type,
          Questions[QuestionsKeys[j]].css,
          Questions[QuestionsKeys[j]].subType,
          Questions[QuestionsKeys[j]].text,
          Questions[QuestionsKeys[j]].image,
          Questions[QuestionsKeys[j]].isChildDiv,
          Questions[QuestionsKeys[j]].options,
          Questions[QuestionsKeys[j]].conditional,
          Questions[QuestionsKeys[j]].childEntity,
          Questions[QuestionsKeys[j]].simultaneousGroupId,
          Questions[QuestionsKeys[j]].frequency,
          Questions[QuestionsKeys[j]].section,
          Questions[QuestionsKeys[j]].memorizeGroup,
          Questions[QuestionsKeys[j]].isVertical,
          Questions[QuestionsKeys[j]].isHorizontal,
          Questions[QuestionsKeys[j]].isInspectorSignature,
          Questions[QuestionsKeys[j]].maxRows,
          Questions[QuestionsKeys[j]].minRows
        )

        ParentQ = [...currentQuestion]
        currentQuestion = []
        let subQues = Questions[QuestionsKeys[j]].subQuestions
        questRow = []
        for (let l = 0; l < subQues.length; l++) {
          if (subQues[l][0]) {
            let QuesAns = {}
            let data = searchInRow(rows, rowsKeys, subQues[l][0])
            let currentObject = getCurrentLine(
              subQues[l][0],
              data.type,
              data.css,
              data.subType,
              data.text,
              data.image,
              data.isChildDiv,
              data.options,
              data.conditional,
              data.childEntity,
              data.simultaneousGroupId,
              data.frequency,
              data.section,
              data.memorizeGroup,
              data.isVertical,
              data.isHorizontal,
              data.isInspectorSignature,
              data.maxRows,
              data.minRows
            )
            QuesAns.Q = currentObject

            data = searchInRow(rows, rowsKeys, subQues[l][1])
            currentObject = getCurrentLine(
              subQues[l][1],
              data.type,
              data.css,
              data.subType,
              data.text,
              data.image,
              data.isChildDiv,
              data.options,
              data.conditional,
              data.childEntity,
              data.simultaneousGroupId,
              data.frequency,
              data.section,
              data.memorizeGroup,
              data.isVertical,
              data.isHorizontal,
              data.isInspectorSignature,
              data.maxRows,
              data.minRows
            )
            QuesAns.A = currentObject
            questRow.push(QuesAns)
          }
        }
      }
      if (ParentQ && ParentQ.length) {
        let data = { Q: ParentQ, QA: questRow }
        if (horizontalTable.length < 1) {
          parentElement.isVertical = false
          parentElement.isHorizontal = true
          horizontalTable.push(parentElement)
        }
        horizontalTable.push(data);
      }
    }
    currentQuestion = horizontalTable
    istableContent = false
  }


  //search a element from table rows
  function searchInRow(rows, rowskey, key) {
    for (let i = 1; i < rowskey.length; i++) {
      let tds = rows[rowskey[i]].childEntity;
      let tdsKey = Object.keys(tds);
      let index = tdsKey.indexOf(key);
      if (index >= 0)
        return tds[tdsKey[index]];
    }
  }
  QuestionJson.push(json[json.length - 1]); //for sections
  return QuestionJson;
};

export function addTableRow(htmlJson, id, rows = 2) {
  const divElement = htmlJson.find(child => child.id === id);
  if (!divElement) {
    throw 'ID not found';
  }
  id = Object.keys(divElement.childEntity)[1];
  for (let i = 0; i < htmlJson.length; i++) {
    if (!htmlJson[i].childEntity) {
      continue;
    }
    let element = Object.keys(htmlJson[i].childEntity);
    if (element.length > 1 && element[1] === id) {
      let tableBodyId = Object.keys(htmlJson[i].childEntity[element[1]].childEntity)[0];
      let tableRowId = Object.keys(htmlJson[i].childEntity[element[1]].childEntity[tableBodyId].childEntity)[0];
      const largestID = getLargestID(Object.keys(htmlJson[i].childEntity[element[1]].childEntity[tableBodyId].childEntity)) + 1;
      // Adding Rows
      for (let j = 0; j < rows; j++) {
        let tableRow = htmlJson[i].childEntity[element[1]].childEntity[tableBodyId].childEntity[tableRowId];
        let tableCellId = Object.keys(tableRow.childEntity)[0];
        let cell = { ...tableRow.childEntity[tableCellId], text: '' };
        let columns = Object.keys(tableRow.childEntity).length;
        let newCell;
        //Updating Cells
        for (let k = 0; k < columns; k++) {
          newCell = { ...newCell, [tableBodyId + '-' + (largestID + j) + '-' + k]: cell };
        }
        tableRow = { ...htmlJson[i].childEntity[element[1]].childEntity[tableBodyId].childEntity[tableRowId], childEntity: newCell };
        let newRow = {
          ...htmlJson[i].childEntity[element[1]].childEntity[tableBodyId].childEntity,
          [tableBodyId + '-' + (largestID + j)]: tableRow,
        };
        let updatedTableBody = {
          ...htmlJson[i].childEntity[element[1]].childEntity[tableBodyId],
          childEntity: newRow,
        };
        htmlJson[i].childEntity[element[1]].childEntity = {
          ...htmlJson[i].childEntity[element[1]].childEntity,
          [tableBodyId]: updatedTableBody,
        };
      }
    }
  }
  return htmlJson;
}

function getLargestID(ids) {
  return ids.map((id) => Number.parseInt(id.split('-').splice(-1), 10)).splice(-1)[0];
}

export function deleteTableRow(htmlJson, id, rowIndex) {
  const divElement = htmlJson.find(child => child.id === id);
  if (!divElement) {
    throw 'ID not found';
  }
  id = Object.keys(divElement.childEntity)[1];
  let columnIds = [];
  for (let i = 0; i < htmlJson.length; i++) {
    if (!htmlJson[i].childEntity) {
      continue;
    }
    let element = Object.keys(htmlJson[i].childEntity);
    if (element[1] === id) {
      let tableBodyId = Object.keys(
        htmlJson[i].childEntity[element[1]].childEntity
      )[0];
      let tableRowIds = Object.keys(
        htmlJson[i].childEntity[element[1]].childEntity[tableBodyId].childEntity
      );
      const rowId = tableRowIds[rowIndex];
      columnIds = getRowIds(htmlJson[i].childEntity[element[1]].childEntity[tableBodyId].childEntity[rowId]);
      delete htmlJson[i].childEntity[element[1]].childEntity[tableBodyId].childEntity[rowId];
      break;
    }
  }
  return { htmlJson, columnIds };
}

export function insertAndShift(htmlJson, id, to, from) {
  const divElement = htmlJson.find(child => child.id === id);
  if (!divElement) {
    throw 'ID not found';
  }
  id = Object.keys(divElement.childEntity)[1];
  for (let i = 0; i < htmlJson.length; i++) {
    if (!htmlJson[i].childEntity) {
      continue;
    }
    let element = Object.keys(htmlJson[i].childEntity);
    if (element[1] === id) {
      let tableBodyId = Object.keys(
        htmlJson[i].childEntity[element[1]].childEntity
      )[0];
      let tableRowIds = Object.keys(
        htmlJson[i].childEntity[element[1]].childEntity[tableBodyId].childEntity
      );
      const fromRowId = tableRowIds[from];
      const toRowId = tableRowIds[to];
      const objectToInsert = htmlJson[i].childEntity[element[1]].childEntity[tableBodyId].childEntity[fromRowId];
      if (from < to) { // moved item forward
        for (let rowIndex = from + 1; rowIndex <= to; rowIndex++) { // shifting up
          const toID = tableRowIds[rowIndex - 1];
          const fromID = tableRowIds[rowIndex];
          htmlJson[i].childEntity[element[1]].childEntity[tableBodyId].childEntity[toID] = htmlJson[i].childEntity[element[1]].childEntity[tableBodyId].childEntity[fromID];
        }
        htmlJson[i].childEntity[element[1]].childEntity[tableBodyId].childEntity[toRowId] = objectToInsert;
      } else if (to < from) { // moved item backward
        for (let rowIndex = from - 1; rowIndex >= to; rowIndex--) { // shifting down
          const toID = tableRowIds[rowIndex + 1];
          const fromID = tableRowIds[rowIndex];
          htmlJson[i].childEntity[element[1]].childEntity[tableBodyId].childEntity[toID] = htmlJson[i].childEntity[element[1]].childEntity[tableBodyId].childEntity[fromID];
        }
        htmlJson[i].childEntity[element[1]].childEntity[tableBodyId].childEntity[toRowId] = objectToInsert;
      }
    }
  }
  return htmlJson;
}

const getRowIds = (row) => {
  let columnIds = [];
  if (row && row.childEntity) {
    columnIds = Object.keys(row.childEntity);
  }
  return columnIds;
};

export default parser;