import axios from 'axios';
import { getToken } from '../../../_helpers/commonFunctions';
import configFile from '../../../config';

const { API } = configFile;

export const fetcher = url => {
  const axiosConfig = {
    method: 'get',
    url: `${API.BASE_URL}/${url}`,
    headers: {
      'Authorization': getToken()
    }
  };
  return axios(axiosConfig);
};

export const genericRequest = ({ data, url, method = 'patch'}) => {
  const axiosConfig = {
    method,
    url: `${API.BASE_URL}/${url}`,
    headers: {
      'Authorization': getToken()
    },
    data
  };
  return axios(axiosConfig);
};