import actionConstants from '../constants/ActionConstants';
import { imageDescription } from '../../api/apiCallsCustomer';
import ActionDispatcher from '../../api/ActionDispatcher';

const imageDescriptionAction = (descriptionData, id) =>
  ActionDispatcher(
    imageDescription.bind(null, descriptionData, id),
    actionConstants.IMAGE_DESCRIPTION_SUCCESS,
    actionConstants.IMAGE_DESCRIPTION_FAILURE,
    actionConstants.IMAGE_DESCRIPTION_REQUEST
  );

export default imageDescriptionAction;
