import actionConstants from '../constants/ActionConstants';

const initialState = {
  customer: {},
  teamList: [],
  inventoryParts: [],
  isFetchingDatabase: false,
  isSuccessFetchedDatabase: false,
  successMsgForFetchedDatabase: '',
  ErrorMsgForFetchedDatabase: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    /** GLOBAL ACTION FOR REQUEST FIRED */
    case actionConstants.GLOBAL_OFFLINE_DATABASE_REQUEST:
      return {
        ...state,
        isFetchingDatabase: true,
        isSuccessFetchedDatabase: false,
        successMsgForFetchedDatabase: '',
        ErrorMsgForFetchedDatabase: '',
      };
    /** GLOBAL ACTION FOR SUCCESS FIRED */
    case actionConstants.GLOBAL_OFFLINE_DATABASE_SUCCESS:
      return {
        ...state,
        customer: { ...action.payload.customer, ...state.customer },
        isFetchingDatabase: false,
        isSuccessFetchedDatabase: true,
        successMsgForFetchedDatabase: 'Details fetched successfully.',
      };
    case actionConstants.GLOBAL_OFFLINE_DATABASE_FAILURE:
      return {
        ...state,
        isFetchingDatabase: false,
        isSuccessFetchedDatabase: false,
        ErrorMsgForFetchedDatabase:
          'Error while downloading. Please try again.',
      };
    /** UPDATION OF CUSTOMER STATE */
    case actionConstants.UPDATE_GLOBAL_DATABASE:
      return {
        ...state,
        customer: action.payload.customer,
        isFetchingDatabase: false,
        isSuccessFetchedDatabase: true,
      };
    /** GLOBAL ACTION SUCCESS FOR TEAM MEMBER FIRED */
    case actionConstants.GLOBAL_OFFLINE_TEAM_MEMBER_SUCCESS:
      return {
        ...state,
        teamList: [...[action.payload.teamMember], ...state.teamList],
      };
    /** ADDING INVENTORY PARTS TO STATE */
    case actionConstants.GLOBAL_OFFLINE_INVENTORY_PARTS_SUCCESS:
      return {
        ...state,
        inventoryParts: action.payload.inventoryParts,
      };
    /** CLEAR ACTION FOR GLOBAL STATES */
    case actionConstants.CLEAR_GLOBAL_STATE_BOOLS:
      return {
        ...state,
        isSuccessFetchedDatabase: false,
        successMsgForFetchedDatabase: '',
        ErrorMsgForFetchedDatabase: '',
      };
    case actionConstants.RESET_GLOBAL_STATE:
      return {
        ...initialState,
      };
    /** RESETTING ALL REDUCERS */
    case actionConstants.RESET_ALL_REDUCERS:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};
