import React from 'react';
import { FormattedMessage } from 'react-intl';

const ChatScreenHeader = () => (
  <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center top-search-feild">
    <h1>
      <FormattedMessage id="Chat" defaultMessage="Chat" />
    </h1>
  </div>
);

export default ChatScreenHeader;
