import ActionTypes from '../redux/constants/ActionConstants';
import { store } from '../redux/store/initStore';

const logout = () => {
  const { auth } = store.getState();
  const user = auth.login && auth.login.user;
  if (user && user.token) {
    store.dispatch({
      type: ActionTypes.RESET_ALL_REDUCERS,
    });
  }
  if ('caches' in window) {
    caches.keys().then((names) => {
      console.log("Preparing to delete caches", names)
      for (let name of names) {
        caches.delete(name);
      }
    });
  }
};

export default logout;
