import Socket from './permissionsSubscriptionsSocket';
import EVENT from './permissionsSubscriptionsEvents';

const employeePermissionsUpdate = (handlePermissionsUpdate) => {
  const socket = new Socket().getInstance();
  socket.on(EVENT.EMPLOYEE_PERMISSIONS_UPDATE, handlePermissionsUpdate);
};

const init = () => {
  new Socket().getInstance();
  // socket.connect();
  // socket.emit(EVENT.CONNECT);
};

const onConnected = () => {
  const socket = new Socket().getInstance();
  socket.on(EVENT.CONNECTED, () => {
    socket.emit(EVENT.CONNECT);
  });
};

const notificationCount = (onNotiFication) => {
  const socket = new Socket().getInstance();
  socket.on(EVENT.COUNT, onNotiFication);
};

const subscriptionsUpdate = (handleSubscriptionsUpdate) => {
  const socket = new Socket().getInstance();
  socket.on(EVENT.SUBSCRIPTIONS_UPDATE, handleSubscriptionsUpdate);
};

const destroy = () => {
  const { getInstance, removeInstance } = new Socket();
  const socket = getInstance();
  socket.off();
  socket.disconnect();
  removeInstance();
};

export {
  init,
  employeePermissionsUpdate,
  subscriptionsUpdate,
  destroy,
  notificationCount,
  onConnected,
};
