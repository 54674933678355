/*eslint-disable*/

import actionConstants from '../constants/ActionConstants';
import ActionDispatcher from '../../api/ActionDispatcher';
import reportInspectionUpdatedAnswer, { getDevicesApi, getClientDetailsApi, updateClientDetails } from '../../api/apiCallReportInspection';
import { reportEventHandlers } from '../../socket';
import parser, { addTableRow, deleteTableRow, insertAndShift } from '../../_helpers/parser';

const inspectionAnswers = ({
  reportId, reportCategory,
  reportType, createdFor, createdForName, answers, missedItems,
  reportJson, parsedJson,
  templateType,
  clientDevices,
  isFinished = false,
  screenName
}) => ActionDispatcher(
  reportInspectionUpdatedAnswer.bind(null, {
    reportId,
    reportCategory,
    reportType,
    createdFor,
    createdForName,
    answers,
    missedItems,
    reportJson,
    parsedJson,
    clientDevices,
    templateType,
    isFinished
  }),
  actionConstants.UPDATE_ANSWER_SUCCESS,
  actionConstants.UPDATE_ANSWER_FAILURE,
  actionConstants.UPDATE_ANSWER_REQUEST,
  { screenName }
);

const setAnswer = payload => (dispatch) => {
  dispatch({
    type: actionConstants.SET_INSPECTION_FETCH_ANSWERS,
    payload
  });
};

const setAnswerAndViewJson = payload => (dispatch) => {
  dispatch({
    type: actionConstants.SET_INSPECTION_VIEW_ANSWERS_AND_JSON,
    payload
  });
};

const updateAnswer = payload => (dispatch) => {
  dispatch({
    type: actionConstants.SET_INSPECTION_ANSWERS,
    payload
  });
};

const updateAnswerIndexState = payload => (dispatch) => {
  dispatch({
    type: actionConstants.SET_INSPECTION_ANSWERS_INDEX,
    payload
  });
  reportEventHandlers.updateAnswerEvent({
    reportId: payload.reportId,
    userId: payload.employeeId,
    answers: {
      [payload.subId]: {
        value: payload.value,
        status: '',
        time: new Date().toISOString(),
        employeeId: payload.employeeId,
        employeeName: payload.employeeName,
        label: payload.label
      }
    },
    missedData: {
      [payload.subId]: payload.value
    }
  });
};

export const emitUpdateReportAnswer = (payload) => {
  reportEventHandlers.updateAnswerEvent(payload)
}
const updateArrayTypeAnswers = payload => (dispatch) => {
  dispatch({
    type: actionConstants.SET_INSPECTION_ARRAY_ANSWERS,
    payload
  });
};

const clearAnswers = () => (dispatch) => {
  dispatch({
    type: actionConstants.CLEAR_ANSWERS
  });
};

const replaceArrayAfterDrag = payload => (dispatch) => {
  dispatch({
    type: actionConstants.REPLACE_INSPECTION_ARRAY_ANSWERS,
    payload
  });
};

const clearSuccess = () => (dispatch) => {
  dispatch({
    type: actionConstants.CLEAR_INSPECTION_ANSWER_SUCCESS
  });
};

const clearInspectionState = () => (dispatch) => {
  dispatch({
    type: actionConstants.RESET_UPDATE_INSPECTION_ARRAY_INITIAL_STATE
  });
};

const replaceItemAtParticularIndex = payload => (dispatch) => {
  dispatch({
    type: actionConstants.REPLACE_INSPECTION_ARRAY_ANSWERS_PARTICULAR_INDEX,
    payload
  });
};

const deleteDevice = payload => (dispatch) => {
  dispatch({
    type: actionConstants.DELETE_DEVICE_FROM_PARTICULAR_INDEX,
    payload
  });
};

const updateSwitches = payload => (dispatch) => {
  dispatch({
    type: actionConstants.UPDATE_SWITCHES_INSPECTION,
    payload
  });
  reportEventHandlers.updateAnswerEvent({
    reportId: payload.reportId,
    userId: payload.employeeId,
    answers: {
      [payload.id]: {
        [payload.key]: payload.value
      }
    }
  });
};

const makeReportChannel = payload => () => {
  reportEventHandlers.reportStartEvent({
    reportId: payload.reportId
  });
};

const handleReportAnswerUpdated = payload => (dispatch) => {
  dispatch({
    type: actionConstants.UPDATE_SOCKET_ANSWER_INSPECTION,
    payload
  });
};

const reportEndSocket = payload => () => {
  reportEventHandlers.reportEndEvent({
    reportId: payload.reportId
  });
};

const clearUpdateKeyAction = () => (dispatch) => {
  dispatch({
    type: actionConstants.CLEAR_UPDATE_KEY
  });
};

const imageChangeSocket = (payload) => {
  reportEventHandlers.updateAnswerEvent({
    reportId: payload.reportId,
    userId: payload.employeeId,
    answers: {},
    image: {
      ...payload.imageDetails
    }
  });
};

const saveSignatureIds = payload => (dispatch) => {
  dispatch({
    type: actionConstants.SAVE_SIGNATURE_IDS,
    payload
  });
};

const storeAnswerSignature = payload => (dispatch) => {
  dispatch({
    type: actionConstants.STORE_SIGNATURE_PROFILE_IN_ANSWERS,
    payload
  });
};

const setRowCount = payload => (dispatch) => {
  dispatch({
    type: actionConstants.ADD_ROW,
    payload
  });

  reportEventHandlers.updateAnswerEvent({
    reportId: payload.reportId,
    userId: payload.employeeId,
    answers: payload.answers
  });
};


const generateRow = (reportJson, id, numberOfRows, selectedIndex, shouldNotUpdate = false) => dispatch => {
  dispatch({ type: actionConstants.GENERATE_NEW_ROW_PROGRESS });
  const promise = new Promise((resolve, reject) => {
    try {
      const newReportJson = addTableRow([...JSON.parse(JSON.stringify(reportJson))], id, numberOfRows);
      const parsedJson = parser(newReportJson);
      resolve({
        reportJson: newReportJson,
        parsedJson,
        selectedIndex,
        shouldNotUpdate
      });
    } catch (e) {
      reject(e);
    }
  });
  promise.then((json) => {
    dispatch({
      type: actionConstants.GENERATE_NEW_ROW_SUCCESS,
      payload: {
        reportJson: json.reportJson,
        parsedJson: json.parsedJson,
        selectedIndex: json.selectedIndex,
        shouldNotUpdate: json.shouldNotUpdate,
        numberOfRows
      },
    });
  }).catch((error) => {
    dispatch({
      type: actionConstants.GENERATE_NEW_ROW_ERROR,
      payload: {
        error,
      },
    });
  });
};

const clearGenerateReportState = () => ({
  type: actionConstants.GENERATE_NEW_ROW_CLEAR_UPDATE_STATE,
});

export const deleteRowFromJson = (reportJson, id, rowIndex, selectedIndex, shouldNotNavigate) => dispatch => {
  dispatch({ type: actionConstants.DELETE_ROW_PROGRESS });
  const promise = new Promise((resolve, reject) => {
    try {
      const { htmlJson, columnIds } = deleteTableRow(reportJson, id, rowIndex);
      const parsedJson = parser(htmlJson);
      resolve({
        reportJson: htmlJson,
        parsedJson,
        columnIds,
      });
    } catch (e) {
      reject(e);
    }
  });
  promise.then((json) => {
    dispatch({
      type: actionConstants.DELETE_ROW_SUCCESS,
      payload: {
        reportJson: json.reportJson,
        parsedJson: json.parsedJson,
        shouldNotNavigate,
        tableID: id,
        columnIds: json.columnIds,
        selectedIndex
      },
    });
  }).catch((error) => {
    dispatch({
      type: actionConstants.DELETE_ROW_ERROR,
      payload: {
        error,
      },
    });
  });
};

export const clearDeleteReportState = () => ({
  type: actionConstants.DELETE_ROW_CLEAR_API_STATE,
});

export const clearDeviceSuccess = () => ({
  type: actionConstants.CLEAR_DEVICES_REPORT_SUCCESS,
});

export const getDevices = (page, limit, listId) => ActionDispatcher(
  getDevicesApi.bind(null, page, limit, listId),
  actionConstants.GET_DEVICES_FOR_REPORT_SUCCESS,
  actionConstants.GET_DEVICES_FOR_REPORT_FAILURE,
  actionConstants.GET_DEVICES_FOR_REPORT_REQUEST,
);

export const getClientDetails = (id) => ActionDispatcher(
  getClientDetailsApi.bind(null, id),
  actionConstants.GET_REPORT_CLIENT_DETAILS_SUCCESS,
  actionConstants.GET_REPORT_CLIENT_DETAILS_FAILURE,
  actionConstants.GET_REPORT_CLIENT_DETAILS_REQUEST,
);

export const updateClientSignature = (payload) => (
  ActionDispatcher(updateClientDetails.bind(null, payload),
    actionConstants.SET_CLIENT_DETAILS_SUCCESS,
    actionConstants.SET_CLIENT_OR_OWNER_DETAILS_FAILURE,
    actionConstants.SET_CLIENT_OR_OWNER_DETAILS_REQUEST,
  )
);

export const clearClientDetailSuccess = () => (dispatch) => {
  dispatch({
    type: actionConstants.GET_REPORT_CLIENT_DETAILS_CLEAR
  })
}

export const setAnswers = (payload) => (dispatch) => {
  dispatch({
    type: actionConstants.SET_ANSWER_TABLE_PARTICULAR_INDEX,
    payload
  });
}

export const clearDeviceData = () => (dispatch) => {
  dispatch({
    type: actionConstants.CLEAR_DEVICE_REPORT_DATA,
  });
}


export const moveRowInJson = (reportJson, id, from, to, selectedIndex) => dispatch => {
  dispatch({ type: actionConstants.INSPECTION_ROW_SHIFT_PROGRESS });
  const promise = new Promise((resolve, reject) => {
    try {
      const htmlJson = insertAndShift(reportJson, id, to, from);
      const parsedJson = parser(htmlJson);
      resolve({
        reportJson: htmlJson,
        parsedJson,
      });
    } catch (e) {
      reject(e);
    }
  });
  promise.then((json) => {
    dispatch({
      type: actionConstants.INSPECTION_ROW_SHIFT_SUCCESS,
      payload: {
        reportJson: json.reportJson,
        parsedJson: json.parsedJson,
        selectedIndex
      },
      extra: {
        tableID: id,
      },
    });
  }).catch((error) => {
    dispatch({
      type: actionConstants.INSPECTION_ROW_SHIFT_ERROR,
      payload: {
        error,
      },
    });
  });
};

export const clearMoveRowInJson = () => ({
  type: actionConstants.INSPECTION_ROW_SHIFT_CLEAR_API_STATE,
});

export const deleteReport = (payload) => ({
  type: actionConstants.CANCEL_PARTICULAR_REPORT,
  payload
});

export const updateDevice = (payload) => ({
  type: actionConstants.UPDATE_DEVICES_IN_REPORT,
  payload
});

export const deleteDeviceReport = (payload) => ({
  type: actionConstants.DELETE_DEVICES_REPORT,
  payload
});

export const saveQRString = (payload) => ({
  type: actionConstants.SAVE_QR_STRING,
  payload
});

export const addDeviceReport = (payload) => ({
  type: actionConstants.ADD_DEVICES_REPORT,
  payload
});

export {
  updateAnswer, updateArrayTypeAnswers,
  inspectionAnswers, setAnswer,
  clearAnswers, updateSwitches,
  replaceArrayAfterDrag, clearSuccess, updateAnswerIndexState,
  replaceItemAtParticularIndex, deleteDevice,
  clearInspectionState, setAnswerAndViewJson,
  handleReportAnswerUpdated, makeReportChannel,
  reportEndSocket, clearUpdateKeyAction, imageChangeSocket, saveSignatureIds,
  storeAnswerSignature, setRowCount, generateRow, clearGenerateReportState
};
