import actionConstants from '../constants/ActionConstants';

const initialState = {
  error: '',
  isfetching: false,
  success: '',
  pages: 0,
  searchText: '',
  deficiencyData: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionConstants.GET_DEFICIENCY_LIST_REQUEST:
      return {
        ...state,
        isfetching: true,
      };
    case actionConstants.GET_DEFICIENCY_LIST_SUCCESS:
      return {
        ...state,
        deficiencyData:
          action.payload.page === 1
            ? action.payload.docs
            : [...state.deficiencyData, ...action.payload.docs],
        isfetching: false,
        success: action.payload.message,
        pages: action.payload.pages,
      };
    case actionConstants.GET_DEFICIENCY_LIST_FAILURE:
      return {
        ...state,
        error: action.message,
        isfetching: false,
      };
    case actionConstants.CLEAR_STATE:
      return {
        ...state,
        error: '',
        success: '',
        isfetching: false,
        deficiencyData: [],
      };
    case actionConstants.RESET_ALL_REDUCERS:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};
