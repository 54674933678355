import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { subPermissions } from '../../libs/constants/permissions';
import { checkPermission } from '../../_helpers/commonFunctions';

class SystemInfoTableRow extends Component {
  render() {
    const { quantity, reportType, systemDeviceType, type, _id } =
      this.props.row;

    const { setSelectedSystemInfoId, isClassVisible } = this.props;

    const idToExpand = Object.keys(isClassVisible);
    const isIdToExpand = isClassVisible[idToExpand[0]];

    return (
      <tr
        className={
          _id === idToExpand[0] && isIdToExpand ? 'accordion-opened' : ''
        }
        style={
          _id === idToExpand[0] && isIdToExpand
            ? { maxHeight: '1000px' }
            : { maxHeight: '60px' }
        }
      >
        <td onClick={() => this.props.setClassVisibility(_id)}>
          <FormattedMessage id={reportType} defaultMessage={reportType} />
        </td>
        <td>
          <h3>
            <FormattedMessage id="Device" defaultMessage="Device" />
          </h3>
          <FormattedMessage
            id={systemDeviceType}
            defaultMessage={systemDeviceType}
          />
        </td>
        <td>
          <h3>
            <FormattedMessage id="Type" defaultMessage="Type" />
          </h3>
          <FormattedMessage id={type} defaultMessage={type} />
        </td>
        <td>
          <h3>
            <FormattedMessage id="Quantity" defaultMessage="Quantity" />
          </h3>
          {quantity}
        </td>
        {checkPermission(subPermissions.editDeleteExistingCustomers) && (
          <td className="posi-relative">
            <span
              className="delete-icon sprite-icon"
              onClick={() => setSelectedSystemInfoId(_id)}
            ></span>
          </td>
        )}
        <span
          className="icon-accordion"
          onClick={() => this.props.setClassVisibility(_id)}
        >
          {_id === idToExpand[0] && isIdToExpand ? '-' : '+'}
        </span>
      </tr>
    );
  }
}

SystemInfoTableRow.propTypes = {
  row: PropTypes.object,
  setSelectedSystemInfoId: PropTypes.func,
  setClassVisibility: PropTypes.func,
  isClassVisible: PropTypes.bool,
};

export default SystemInfoTableRow;
