import ActionConstants from '../constants/ActionConstants';

const initialState = {
  success: '',
  error: '',
  isfetching: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ActionConstants.CLEAR_LOGOUT:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};
