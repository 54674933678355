import actionConstants from '../constants/ActionConstants';

const initialState = {
  error: '',
  errorMsg: '',
  isFetching: false,
  data: null,
  success: '',
  isSuccess: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionConstants.UPDATE__VEHICLE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        isSuccess: true,
        data: action.payload,
        success: action.message,
      };
    case actionConstants.UPDATE__VEHICLE_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.message,
      };

    case actionConstants.UPDATE__VEHICLE_REQUEST:
      return {
        ...state,
        isFetching: true,
      };

    case actionConstants.UPDATE__VEHICLE_RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};
