import actionConstants from '../constants/ActionConstants';
import { reportDeleteApiCall } from '../../api/apiCallsCustomer';
import ActionDispatcher from '../../api/ActionDispatcher';

const deleteReportAction = (id) =>
  ActionDispatcher(
    reportDeleteApiCall.bind(null, id),
    actionConstants.DELETE_REPORT_LIST_SUCCESS,
    actionConstants.DELETE_REPORT_LIST_FAILURE,
    actionConstants.DELETE_REPORT_LIST_REQUEST
  );

const clearDeleteState = () => (dispatch) => {
  dispatch({ type: actionConstants.CLEAR_REPORT_DELETE_STATE });
};

export { deleteReportAction, clearDeleteState };
