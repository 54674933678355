import { createSelector } from 'reselect';

export const selectClientInfo = createSelector(
  (state) => state.getClientOrOwnerDetails,
  (client) => client.clientOrOwnerData
);

export const selectClientInfoPending = createSelector(
  (state) => state.getClientOrOwnerDetails,
  (client) => client.isfetching
);
