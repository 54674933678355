import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Modal from '../../components/Modal';

export const ServicesDueGridBody = ({
  serviceDueForCount,
  toggleModalVisiblity,
  isModalVisible,
  isModalBody,
}) => (
  <div
    className={`service-due-grid-wrapper ${
      isModalBody && 'service-due-grid-wrapper-on-modal'
    }`}
  >
    {serviceDueForCount.map((serviceDueForItem, index) => {
      if (!isModalVisible && serviceDueForCount.length > 6 && index === 5) {
        return (
          <div
            key={serviceDueForItem._id}
            onClick={toggleModalVisiblity}
            className="count-service-due"
          >
            <span>{`+${serviceDueForCount.length - 5} more`}</span>
          </div>
        );
      }
      if (isModalVisible || index <= 5) {
        return (
          <div key={serviceDueForItem._id}>
            <span>{`${serviceDueForItem._id} : `}</span>
            <span className="due-for-count-digit">
              {`${serviceDueForItem.count}`}
            </span>
          </div>
        );
      }
      return null;
    })}
  </div>
);

const ServicesDueGrid = (props) => {
  const [isGridModalVisible, setGridModalVisibility] = useState(false);
  const { serviceDueForCount } = props;
  return (
    <div>
      {isGridModalVisible && (
        <Modal
          modalHeading="Due for count"
          ModalBody={ServicesDueGridBody}
          ModalBodyProps={{
            serviceDueForCount: serviceDueForCount.serviceDueForCount,
            toggleModalVisiblity: () => setGridModalVisibility(true),
            isModalVisible: isGridModalVisible,
            isModalBody: true,
          }}
          handleClickCross={() => setGridModalVisibility(false)}
        />
      )}
      <div>
        <ServicesDueGridBody
          serviceDueForCount={serviceDueForCount.serviceDueForCount}
          toggleModalVisiblity={() => setGridModalVisibility(true)}
          isModalVisible={isGridModalVisible}
        />
      </div>
    </div>
  );
};

ServicesDueGridBody.propTypes = {
  serviceDueForCount: PropTypes.array,
  toggleModalVisiblity: PropTypes.func,
  isModalVisible: PropTypes.func,
  isModalBody: PropTypes.bool,
};

ServicesDueGrid.propTypes = {
  serviceDueForCount: PropTypes.object,
};

export default ServicesDueGrid;
