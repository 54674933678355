import { getCustomerId, getToken } from '../_helpers/commonFunctions';
import call from './apiRequest';
import apiEndpoints from './apiEndpoints';

/**
 * Api call for getting clients list
 * @param {number} page
 * @param {number} limit
 * @param {string} searchText
 * @param {boolean} active
 * @param {string} sortBy
 * @param {number} sortOrder
 * @param {object} defaultParams
 */
const clientListApiCall = (
  page,
  limit = 10,
  searchText,
  active,
  sortBy,
  sortOrder,
  defaultParams = {
    type: ['MASTER'],
    parentFieldName: 'customerId',
    parentFieldValue: getCustomerId(),
  }
) => {
  const { type, parentFieldName, parentFieldValue } = defaultParams;
  const payload = {
    type,
    parentFieldName,
    parentFieldValue,
    sort: {},
  };
  if (sortBy) {
    payload.sort[sortBy] = sortOrder;
  } else {
    payload.sort.businessName = 1;
  }

  if (searchText) {
    payload.search = searchText;
  }

  if (active !== undefined) {
    payload.active = active;
  }

  return call({
    method: 'post',
    endpoint: `${apiEndpoints.clientList}?page=${page}&limit=${limit}`,
    payload,
    authKey: getToken(),
  });
};

/**
 * Api call for getting client or owner details
 * @param {string} id
 */
const clientOrOwnerDetails = (id) =>
  call({
    method: 'get',
    endpoint: `${apiEndpoints.clientOrOwnerDetails}`,
    authKey: getToken(),
    query: {
      id,
    },
  });

/**
 * Api call for uploading client details
 * @param {string} id
 * @param {object} clientDetails
 */
const setClientDetails = (id, clientDetails) =>
  call({
    method: 'put',
    endpoint: `${apiEndpoints.clientOrOwnerDetails}`,
    payload: clientDetails,
    authKey: getToken(),
    query: {
      id,
    },
  });

/**
 * Api call for getting notifications count
 */
export const notificationCountApi = () =>
  call({
    method: 'get',
    endpoint: apiEndpoints.notificationCount,
    authKey: getToken(),
  });

export const QrCodeResponseApiCall = (QrCode) =>
  call({
    method: 'get',
    endpoint: apiEndpoints.qrCode,
    authKey: getToken(),
    query: {
      qrCode: QrCode,
    },
  });

export const QrCodeDetailsApiCall = (QrCode) => 
  call({
    method: 'get',
    endpoint: apiEndpoints.qrDetails,
    authKey: getToken(),
    query: {
      qrCode: QrCode,
    },
  });

/**
 * Api call for uploading owner details
 * @param {string} id
 * @param {object} ownerDetails
 */
const setOwnerDetails = (id, ownerDetails) =>
  call({
    method: 'put',
    endpoint: `${apiEndpoints.clientOrOwnerDetails}`,
    payload: { ownerInfo: ownerDetails },
    authKey: getToken(),
    query: {
      id,
    },
  });

/**
 * Api call for getting system information
 * @param {string} id
 */
const getSystemInfoApiCall = (id) =>
  call({
    method: 'get',
    endpoint: `${apiEndpoints.systemInfo}`,
    authKey: getToken(),
    query: {
      id,
    },
  });

/**
 * Api call for uploading system information
 * @param {string} systemInfoId
 * @param {object} systemInfo
 */
const setSystemInfoApiCall = (systemInfoId, systemInfo) =>
  call({
    method: 'put',
    endpoint: `${apiEndpoints.addSystemOrDevice}`,
    authKey: getToken(),
    query: {
      id: systemInfoId,
    },
    payload: systemInfo,
  });

/**
 * Api call for deleting system info
 * @param {string} buildingInfoId
 * @param {string} systemInfoId
 */
const deleteSystemInfoApiCall = (buildingInfoId, systemInfoId) =>
  call({
    method: 'delete',
    endpoint: `${apiEndpoints.addSystemOrDevice}`,
    authKey: getToken(),
    query: {
      id: buildingInfoId,
    },
    payload: {
      _id: systemInfoId,
    },
  });

/**
 * Api call for getting generic report types
 */
const getGenericRepotTypes = () =>
  call({
    method: 'get',
    endpoint: apiEndpoints.genericReportType,
    authKey: getToken,
  });

/**
 * Api call for uploading image
 * @param {object} image
 */
const uploadImage = (image) =>
  call({
    method: 'post',
    endpoint: `${apiEndpoints.fileUpload}`,
    type: 'multipart/form-data',
    payload: image,
    authKey: getToken(),
  });

/**
 * Api call for uploading building image details
 * @param {object} imageDetails
 * @param {string} buildingInfoId
 */
const addBuildingImageDedtails = (imageDetails, buildingInfoId) =>
  call({
    method: 'post',
    endpoint: `${apiEndpoints.buildinginfo}/${apiEndpoints.addImageDetails}/?id=${buildingInfoId}`,
    payload: imageDetails,
    authKey: getToken(),
  });

/**
 * Api call for getting buildings for a client
 * @param {string} clientId
 */
const getBuildingInfo = (clientId) =>
  call({
    method: 'get',
    endpoint: `${apiEndpoints.buildinginfo}/?id=${clientId}`,
    authKey: getToken(),
  });

/**
 * Api call for uploading image decription
 * @param {object} imageDescr
 * @param {string} buildingInfoId
 */
const imageDescription = (imageDescr, buildingInfoId) =>
  call({
    method: 'put',
    endpoint: `${apiEndpoints.buildinginfo}/${apiEndpoints.addImageDetails}/?id=${buildingInfoId}`,
    payload: imageDescr,
    authKey: getToken(),
  });

/**
 * Api call for deleting image
 * @param {string} imageId
 * @param {string} buildingInfoId
 */
const deleteImage = (imageId, buildingInfoId) =>
  call({
    method: 'delete',
    endpoint: `${apiEndpoints.buildinginfo}/${apiEndpoints.addImageDetails}/?id=${buildingInfoId}`,
    payload: { _id: imageId },
    authKey: getToken(),
  });

/**
 * Api call for uploading notes
 * @param {string} buildingInfoId
 * @param {Array} notes
 */
const addNotesApiCall = (buildingInfoId, notes) =>
  call({
    method: 'put',
    endpoint: `${apiEndpoints.systemInfo}`,
    authKey: getToken(),
    query: {
      id: buildingInfoId,
    },
    payload: notes,
  });

/**
 * Api call for getting client device list
 * @param {number} page
 * @param {number} limit
 * @param {string} id
 */
const clientDeviceList = (page = 1, limit = 10, id, reportType) =>
  call({
    method: 'post',
    endpoint: `${apiEndpoints.clientDevice}/${apiEndpoints.clientDeviceList}`,
    payload: { id },
    query: { page, limit, reportType },
    authKey: getToken(),
  });

/**
 * Api call for getting inner client device list
 * @param {number} page
 * @param {number} limit
 * @param {string} listId
 */
const clientInnerDeviceList = (page = 1, limit = 10, listId) =>
  call({
    method: 'get',
    endpoint: `${apiEndpoints.clientDevice}/${apiEndpoints.clientDeviceList}/${listId}`,
    query: { page, limit },
    authKey: getToken(),
  });

/**
 * Api call for uploading device details
 * @param {object} uploadedDeviceDetails
 */
const clientUploadXLXS = (uploadedDeviceDetails) =>
  call({
    method: 'post',
    endpoint: `${apiEndpoints.clientDevice}/${apiEndpoints.clientUploadFile}`,
    payload: uploadedDeviceDetails,
    type: 'multipart/form-data',
    authKey: getToken(),
  });

/**
 * Api call for uploading client device list
 * @param {object} deviceListData
 */
const createClientDeviceList = (deviceListData) =>
  call({
    method: 'post',
    endpoint: `${apiEndpoints.clientDevice}`,
    payload: deviceListData,
    authKey: getToken(),
  });

/**
 * Api call for deleting device
 * @param {string} deviceId
 * @param {string} clientDeviceId
 */
const deleteDevice = (deviceId, clientDeviceId) =>
  call({
    method: 'delete',
    endpoint: `${apiEndpoints.clientDevice}/${apiEndpoints.clientDeleteDevice}/?id=${clientDeviceId}`,
    payload: { id: deviceId },
    authKey: getToken(),
  });

/**
 * Api call for deleting client device
 * @param {string} deviceId
 */
const deleteClientDevice = (deviceId) =>
  call({
    method: 'delete',
    endpoint: `${apiEndpoints.clientDevice}/?id=${deviceId}`,
    authKey: getToken(),
  });

/**
 * Api call for adding device
 * @param {object} deviceDetails
 * @param {string} id
 */
const addDevice = (deviceDetails, id) =>
  call({
    method: 'post',
    endpoint: `${apiEndpoints.clientDevice}/${apiEndpoints.clientDeleteDevice}/?id=${id}`,
    payload: deviceDetails,
    authKey: getToken(),
  });

/**
 * Api call for editing device
 * @param {object} updatedDeviceData
 * @param {string} id
 */
const editDevice = (updatedDeviceData, id) =>
  call({
    method: 'put',
    endpoint: `${apiEndpoints.clientDevice}/${apiEndpoints.clientDeleteDevice}/?id=${id}`,
    payload: updatedDeviceData,
    authKey: getToken(),
  });

/**
 * Api call for adding customer
 * @param {object} clientDetails
 */
const addCustomerApiCall = (clientDetails) =>
  call({
    method: 'post',
    endpoint: `${apiEndpoints.clientOrOwnerDetails}`,
    payload: clientDetails,
    authKey: getToken(),
  });

/**
 * Api call for updating client details
 * @param {string} clientId
 * @param {object} body
 */
const updateClientApiCall = (clientId, body) => {
  const payload = {};
  if (body.stateTax) {
    payload.stateTax = body.stateTax;
  }
  if (body.pricingLevel) {
    payload.pricingLevel = body.pricingLevel;
  }
  if (body.isActive !== undefined) {
    payload.isActive = body.isActive;
  }

  return call({
    method: 'post',
    endpoint: `${apiEndpoints.updateClient}`,
    payload,
    authKey: getToken(),
    query: {
      id: clientId,
    },
  });
};

/**
 * Api call for getting job list
 * @param {number} page
 * @param {number} limit
 * @param {string} searchText
 * @param {string} status
 * @param {string} reportCategory
 * @param {number} jobDateSort
 * @param {number} businessNameSort
 * @param {string} startDate
 * @param {string} endDate
 * @param {string} reportType
 */
const jobListApiCall = (
  page,
  limit,
  searchText,
  status,
  reportCategory,
  jobDateSort,
  businessNameSort,
  startDate,
  endDate,
  reportType,
  extraParams = {},
) => {
  const payload = {
    type: ['MASTER'],
    parentFieldName: 'customerId',
    parentFieldValue: getCustomerId(),
  };

  return call({
    method: 'get',
    endpoint: `${apiEndpoints.jobList}?page=${page}&limit=${limit}`,
    payload,
    query: {
      search: searchText,
      jobDateSort,
      businessNameSort,
      jobStatus: ['Created', 'Assigned'],
      startDate,
      endDate,
      type: reportType,
      reportCategory,
      extraParams,
    },
    authKey: getToken(),
  });
};

/**
 * Api call for getting job list
 * @param {number} page
 * @param {number} limit
 * @param {string} searchText
 * @param {string} status
 * @param {string} reportCategory
 * @param {number} jobDateSort
 * @param {number} businessNameSort
 * @param {string} startDate
 * @param {string} endDate
 * @param {string} reportType
 * @param {boolean} onlySelf
 */
const printJobListApiCall = (
  page,
  limit,
  searchText,
  status,
  reportCategory,
  jobDateSort,
  businessNameSort,
  startDate,
  endDate,
  reportType,
  onlySelf,
  extraParams
) => {
  const payload = {
    type: ['MASTER'],
    parentFieldName: 'customerId',
    parentFieldValue: getCustomerId(),
  };

  return call({
    method: 'get',
    endpoint: `${apiEndpoints.printJobList}?page=${page}&limit=${limit}`,
    payload,
    query: {
      search: searchText,
      jobDateSort,
      businessNameSort,
      jobStatus: ['Created', 'Assigned'],
      startDate,
      endDate,
      type: reportType,
      reportCategory,
      onlySelf,
      extraParams
    },
    authKey: getToken(),
  });
};

/**
 * Api call for getting transaction list
 * @param {number} page
 * @param {number} limit
 * @param {object} queryParams
 */
const getTransactionListApiCall = (page, limit, queryParams) =>
  call({
    method: 'get',
    endpoint: `${apiEndpoints.transaction}?page=${page}&limit=${limit}`,
    query: { ...queryParams },
    authKey: getToken(),
  });

/**
 * Api call for getting transaction detail
 * @param {string} id
 */
const TransactionDetailApiCall = (id) =>
  call({
    method: 'get',
    endpoint: `${apiEndpoints.transaction}/${id}`,
    payload: {
      id,
    },
    authKey: getToken(),
  });

/**
 * Api call for delting transaction
 * @param {object} params
 */
const deleteTransactionApicall = (params) =>
  call({
    method: 'delete',
    endpoint: apiEndpoints.transaction,
    payload: {
      ...params,
    },
  });

/**
 * Api call for getting my jobs list
 * @param {number} page
 * @param {number} limit
 * @param {object} queryParam
 */
const myJobListApiCall = (page, limit, queryParam) =>
  call({
    method: 'get',
    endpoint: `${apiEndpoints.jobList}?page=${page}&limit=${limit}`,
    query: queryParam,
    authKey: getToken(),
  });

/**
 * Api call for getting deficiencies list
 * @param {number} page
 * @param {number} limit
 * @param {string} searchText
 * @param {string} status
 * @param {string} reportCategory
 * @param {number} CustomerNameSort
 * @param {string} customerId
 */
const deficiencyListApiCall = (
  page,
  limit,
  searchText,
  status,
  reportCategory,
  CustomerNameSort,
  customerId
) => {
  const payload = {
    type: ['MASTER'],
    parentFieldName: 'customerId',
    parentFieldValue: getCustomerId(),
  };

  return call({
    method: 'get',
    endpoint: `${apiEndpoints.deficiency}?page=${page}&limit=${limit}`,
    payload,
    query: {
      search: searchText,
      customerNameSort: CustomerNameSort,
      status,
      createdFor: customerId,
      reportCategory,
    },
    authKey: getToken(),
  });
};

/**
 * Api call for uploading customers by sheet
 * @param {array} clients
 */
const importCustomerApiCall = (clients) =>
  call({
    method: 'post',
    endpoint: `${apiEndpoints.importCustomer}`,
    payload: clients,
    authKey: getToken(),
    type: 'multipart/form-data',
  });

/**
 * Api call for deleting report
 * @param {string} id
 */
const reportDeleteApiCall = (id) =>
  call({
    method: 'delete',
    endpoint: `${apiEndpoints.reportList}/${id}`,
    authKey: getToken(),
  });

/**
 * Api call for getting reports list
 * @param {number} page
 * @param {number} limit
 * @param {string} searchText
 * @param {string} reportType
 * @param {string} reportCategory
 * @param {number} CustomerNameSort
 * @param {string} startDate
 * @param {string} endDate
 * @param {string} status
 * @param {string} createdFor
 */
const reportsListApiCall = (
  page,
  limit,
  searchText,
  reportType,
  reportCategory,
  CustomerNameSort,
  startDate,
  endDate,
  status,
  createdFor,
  isDefault,
  isLegacy,
  createdBy,
  hideMigrated
) => {
  const query = {
    search: searchText,
    clientNameSort: CustomerNameSort,
    reportType,
    reportCategory,
    startDate,
    endDate,
    status,
    isDefault,
    hideMigrated,
  };
  if (createdFor) {
    query.createdFor = createdFor;
  }
  if (createdBy) {
    query.createdBy = createdBy;
  }
  if (isLegacy) {
    query.isLegacyReport = isLegacy;
  }

  return call({
    method: 'get',
    endpoint: `${apiEndpoints.reportList}?page=${page}&limit=${limit}`,
    query,
    authKey: getToken(),
  });
};

/**
 * Api call for deleting customer
 * @param {string} clientId
 */
export const deleteCustomerApiCall = (clientId) =>
  call({
    method: 'delete',
    endpoint: apiEndpoints.clientOrOwnerDetails,
    query: {
      id: clientId,
    },
    authKey: getToken(),
  });

const getInvoiceNo = () =>
  call({
    method: 'get',
    endpoint: apiEndpoints.autoGenerateInvoice,
    authKey: getToken(),
  });

/**
 * Api call to mark a job as Completed, manually.
 * @param {object} clientId
 */
const completeJobManuallyApiCall = (payload) =>
  call({
    method: 'post',
    endpoint: apiEndpoints.completeJobManually,
    payload,
    authKey: getToken(),
  });

export {
  deficiencyListApiCall,
  clientListApiCall,
  clientOrOwnerDetails,
  setClientDetails,
  setOwnerDetails,
  getSystemInfoApiCall,
  setSystemInfoApiCall,
  deleteSystemInfoApiCall,
  addBuildingImageDedtails,
  uploadImage,
  getBuildingInfo,
  imageDescription,
  deleteImage,
  addNotesApiCall,
  clientDeviceList,
  clientUploadXLXS,
  deleteDevice,
  deleteTransactionApicall,
  createClientDeviceList,
  addDevice,
  editDevice,
  TransactionDetailApiCall,
  getTransactionListApiCall,
  deleteClientDevice,
  addCustomerApiCall,
  updateClientApiCall,
  getGenericRepotTypes,
  jobListApiCall,
  importCustomerApiCall,
  reportsListApiCall,
  reportDeleteApiCall,
  clientInnerDeviceList,
  myJobListApiCall,
  getInvoiceNo,
  completeJobManuallyApiCall,
  printJobListApiCall,
};
