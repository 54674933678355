import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Table from '../../components/ReusableTable';
import TaskHeaders from '../../components/TaskHeader/TaskHeader';
import './TaskLayout.css';
import TableHeadings from '../../utils/tableHeadings';
import TaskListRow from './TaskListRow';
import TaskListRowMobile from './TaskListRowMobile';

const _TableHeadings = JSON.parse(
  JSON.stringify(TableHeadings.TaskTableHeading)
);
_TableHeadings[TableHeadings.TaskTableHeading.length - 1].title = ' ';

const rowToRender = document.body.offsetWidth > 767 ? TaskListRow : TaskListRowMobile;

const TaskListLayout = ({
  setSearchText,
  setFilters,
  handleSearch,
  status,
  reportType,
  filter,
  setDatePeriod,
  setReportType,
  sortData,
  jobList,
  loadMore,
  isfetching,
  hasMoreItems,
  endMessage,
  isEndMessageVisible,
  searchText,
  isCrossVisible,
  isClassVisible,
  showTaskModdal,
  setClassVisibility,
  reportCategories,
  reportCategory,
  handleStartJob,
  jobsDoneManually,
  completeJobManually,
  isReportNeeded,
  printPage,
}) => {
  const [openJob, setOpenJob] = useState(null);
  return (
  <React.Fragment>
    <TaskHeaders
      name="Jobs Due"
      setSearchText={setSearchText}
      isCrossVisible={isCrossVisible}
      reportCategory={reportCategory}
      searchText={searchText}
      setFilters={setFilters}
      printPage={printPage}
      hideFilter={false}
      handleSearch={handleSearch}
      status={status}
      reportType={reportType}
      filter={filter}
      setDatePeriod={setDatePeriod}
      setReportType={setReportType}
      minDate={moment(new Date())}
      reportCategories={reportCategories}
    />

    <Table
      sortData={sortData}
      headerData={
        isReportNeeded === false
          ? _TableHeadings
          : TableHeadings.TaskTableHeading
      }
      bodyData={jobList}
      RowToRender={rowToRender}
      loadMore={loadMore}
      endMessage={endMessage}
      rowClassName="jobsDueTable"
      isLoaderVisible={isfetching}
      mainTableSubClass="rowwidthtasktable"
      hasMoreItems={hasMoreItems}
      isEndMessageVisible={isEndMessageVisible}
      jobTable={true}
      firstChildStyle={{ textAlign: 'center' }}
      RowToRenderProps={{
        isClassVisible,
        setClassVisibility,
        handleStartJob,
        showTaskModdal,
        jobsDoneManually,
        completeJobManually,
        isReportNeeded,
        openJob,
        setOpenJob,
      }}
    />
  </React.Fragment>
);}
TaskListLayout.propTypes = {
  setSearchText: PropTypes.func,
  setFilters: PropTypes.func,
  isCrossVisible: PropTypes.bool,
  handleSearch: PropTypes.func,
  printPage: PropTypes.func,
  status: PropTypes.array,
  reportType: PropTypes.string,
  filter: PropTypes.array,
  setDatePeriod: PropTypes.func,
  setReportType: PropTypes.func,
  sortData: PropTypes.func,
  jobList: PropTypes.array,
  loadMore: PropTypes.func,
  isfetching: PropTypes.bool,
  hasMoreItems: PropTypes.bool,
  endMessage: PropTypes.string,
  isEndMessageVisible: PropTypes.bool,
  searchText: PropTypes.string,
  isClassVisible: PropTypes.object,
  setClassVisibility: PropTypes.func,
  reportCategories: PropTypes.object,
  reportCategory: PropTypes.any,
  handleStartJob: PropTypes.func,
  showTaskModdal: PropTypes.func,
  jobsDoneManually: PropTypes.array,
  completeJobManually: PropTypes.func,
  isReportNeeded: PropTypes.any,
};

export default TaskListLayout;
