import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import uploadImageAction from '../../redux/actions/uploadImage';
import clearStateAction from '../../redux/actions/clearState';
import getSystemInfoAction from '../../redux/actions/getSystemInfo';
import addImageDetailsAction from '../../redux/actions/addImageDetailsBuildingInfo';
import imageDescriptionAction from '../../redux/actions/imageDecriptionBuildingInfo';
import deleteImageAction from '../../redux/actions/deleteImage';
import ImageBuildingInfoLayouts from '../../layouts/ImageBuildingInfoLayout/ImageBuildingInfoLayout';
import { displayToast } from '../../_helpers/commonFunctions';
import { saveReport } from '../../redux/actions/reportCreate';
import validationMessage from '../../_helpers/validator/validationMessage';
import './ImageUploadBuilding.scss';
import Loader from '../../components/Loader';
import {
  updateReport,
  clearSuccess,
  setImageSocketDetails,
} from '../../redux/actions/reportUpdate';
import { setSubMenuVisibility } from '../../redux/actions/setSelectedCustomer';
import SetIsPromptAction from '../../redux/actions/isPromptAction';
import { getJobIdForIncompleteReport } from '../../_helpers/offlineEnhanced';
import { fillJobDetails } from '../../redux/actions/offlineEnhanced';

class ImageUploadBuildingInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isAddImageModal: false,
      isCommentModal: false,
      previewImage: false,
      isDeleteModal: false,
      selectedImage: null,
      imageUrl: null,
      commentValue: null,
      idOfImage: null,
      validationErrs: null,
      toggleReportSection: false,
      stateDelete: '',
    };
  }

  componentDidMount() {
    if (!this.props.isReportImage) {
      const { selectedCustomerId } = this.props.setSelectedCustomer;
      this.props.getSystemInfoAction(selectedCustomerId);
    }
    this.props.setSubMenuVisibility(true);
  }

  getMessageString = (isImage, isDesciption, isDelete) => {
    let message = '';
    const { addImageDetails, imageDescription, deleteImage } = this.props;
    if (isImage) {
      message = addImageDetails.success;
    } else if (isDesciption) {
      message = imageDescription.success;
    } else if (isDelete) {
      message = deleteImage.successMsg;
    }
    return message;
  };

  // not used anywhere
  // showReport = () => {
  //   this.setState({ toggleReportSection: !this.state.toggleReportSection });
  //   const div = document.querySelector('.report-container');
  //   if (this.state.toggleReportSection) {
  //     div.style.display = 'block';
  //   } else {
  //     div.style.display = 'none';
  //   }
  // }

  componentDidUpdate() {
    const { isSuccess, uploadedImageDetails } = this.props.uploadImage;
    const { addImageDetails } = this.props;
    const { imageDescription } = this.props;
    const { isSuccessDelete } = this.props.deleteImage;
    if (isSuccess) {
      this.props.clearStateAction();
      const { filename, mimetype, size } = uploadedImageDetails.file;
      const imagDetails = {
        mimeType: mimetype,
        fileSize: size,
        title: filename,
        url: uploadedImageDetails.Location,
        fileName: filename,
      };
      const { systemInfoId } = this.props.getSystemInfo;
      this.props.addImageDetailsAction(imagDetails, systemInfoId);
    }
    this.handleSuccess(
      addImageDetails.isSuccess,
      imageDescription.isSuccess,
      isSuccessDelete
    );
  }

  handleSuccess = (isSuccesAddImage, isSuccessDesc, isSuccessDelete) => {
    if (isSuccesAddImage || isSuccessDesc || isSuccessDelete) {
      const { selectedCustomerId } = this.props.setSelectedCustomer;
      this.props.getSystemInfoAction(selectedCustomerId);
      this.props.clearStateAction();
      displayToast(
        this.getMessageString(isSuccesAddImage, isSuccessDesc, isSuccessDelete),
        'success'
      );
    }
  };

  onAddImage = () => {
    this.setState({ isAddImageModal: !this.state.isAddImageModal });
    this.resetState();
  };

  deleteImage = (id, stateDelete) => {
    this.setState({
      isDeleteModal: !this.state.isDeleteModal,
      idOfImage: id,
      stateDelete,
    });
  };

  onConfirmDelete = () => {
    const { systemInfoId } = this.props.getSystemInfo;
    if (this.state.stateDelete === 'image') {
      if (this.props.isReportImage) {
        const imageObj = {
          reportCategory: this.props.reportCat.catValue,
          createdFor: this.props.reportCat.customerValue,
          reportType: this.props.reportCat.typeValue,
          createdForName: this.props.clientInfo.businessName,
          reportId: this.props.reportId,
          images: [
            {
              isDeleted: true,
              _id: this.state.idOfImage,
            },
          ],
        };
        // if (this.props.reportCat.typeValue === 'Inspection') {
        //   this.props.setImageSocketDetails({ _id: this.state.idOfImage, isDeleted: true });
        // }
        if (this.props.offlineMode.isOffline) {
          this.props.fillJobDetails(this.props.reportEntryId, {
            image: imageObj.images[0],
          });
        } else {
          this.props.saveReport(imageObj);
        }
      } else {
        this.props.deleteImageAction(this.state.idOfImage, systemInfoId);
      }
    } else if (this.props.isReportImage) {
      const imageObj = {
        reportCategory: this.props.reportCat.catValue,
        createdFor: this.props.reportCat.customerValue,
        reportType: this.props.reportCat.typeValue,
        createdForName: this.props.clientInfo.businessName,
        reportId: this.props.reportId,
        images: [
          {
            description: '',
            _id: this.state.idOfImage,
          },
        ],
      };
      // if (this.props.reportCat.typeValue === 'Inspection') {
      //   this.props.setImageSocketDetails({ _id: this.state.idOfImage, description: '' });
      // }
      if (this.props.offlineMode.isOffline) {
        this.props.fillJobDetails(this.props.reportEntryId, {
          image: imageObj.images[0],
        });
      } else {
        this.props.saveReport(imageObj);
      }
    } else {
      const imageDescription = {
        _id: this.state.idOfImage,
        description: ' ',
      };
      this.props.imageDescriptionAction(imageDescription, systemInfoId);
    }
    this.deleteImage();
  };

  showPreview = (imageUrl) => {
    this.setState({ previewImage: !this.state.previewImage, imageUrl });
  };

  handleChange = (event) => {
    if (event.target.files) {
      if (!event.target.files[0].type.includes('image')) {
        displayToast('Please select image', 'error');
        return;
      }
      this.setState({ selectedImage: event.target.files[0] });
    } else {
      this.setState({ commentValue: event.target.value });
    }
    this.props.SetIsPromptAction(true);
  };

  resetState = () => {
    this.setState({
      imageUrl: null,
      selectedImage: null,
      commentValue: null,
      idOfImage: null,
      validationErrs: null,
    });
  };

  onSave = () => {
    const { selectedImage, isAddImageModal, commentValue, idOfImage } =
      this.state;
    const validationErrs = { ...this.state.validationErrs };
    if (isAddImageModal) {
      if (selectedImage) {
        const image = new FormData();
        image.append('image', selectedImage);
        this.props.uploadImageAction(image);
        this.onAddImage();
        this.resetState();
      } else {
        validationErrs.image = validationMessage.imageName;
        this.setState({ validationErrs });
      }
    } else if (idOfImage && commentValue) {
      const imageDescription = {
        _id: idOfImage,
        description: commentValue,
      };
      const { systemInfoId } = this.props.getSystemInfo;
      if (this.props.isReportImage) {
        const imageObj = {
          reportCategory: this.props.reportCat.catValue,
          createdFor: this.props.reportCat.customerValue,
          reportType: this.props.reportCat.typeValue,
          createdForName: this.props.clientInfo.businessName,
          reportId: this.props.reportId,
          images: [
            {
              description: commentValue,
              _id: idOfImage,
            },
          ],
        };
        // if (this.props.reportCat.typeValue === 'Inspection') {
        //   this.props.setImageSocketDetails({ _id: idOfImage, description: commentValue });
        // }
        if (this.props.offlineMode.isOffline) {
          this.props.fillJobDetails(this.props.reportEntryId, {
            image: imageObj.images[0],
          });
        } else {
          this.props.saveReport(imageObj);
        }
      } else {
        this.props.imageDescriptionAction(imageDescription, systemInfoId);
      }
      this.commentAdder();
      this.resetState();
    } else {
      validationErrs.description = validationMessage.commentValue;
      this.setState({ validationErrs });
    }
  };

  commentAdder = (description, id) => {
    this.setState({
      isCommentModal: !this.state.isCommentModal,
      idOfImage: id,
      commentValue: description,
    });
  };

  render() {
    const { images, isFetching } = this.props.getSystemInfo;
    const uploadImageActionFetching = this.props.uploadImage.isfetching;
    const imageDetailsFetching = this.props.addImageDetails.isfetching;
    const imageDescriptionFetching = this.props.imageDescription.isFetching;
    const imageDeleteFetching = this.props.deleteImage.isfetching;
    let reportImgs = [];
    if (this.props.isReportImage) {
      reportImgs =
        this.props.reportUpdatedData && this.props.reportUpdatedData.images
          ? this.props.reportUpdatedData.images
          : [];
    }
    const {
      isAddImageModal,
      isCommentModal,
      selectedImage,
      previewImage,
      imageUrl,
      commentValue,
      isDeleteModal,
      validationErrs,
    } = this.state;
    return (
      <div>
        {uploadImageActionFetching ||
        imageDetailsFetching ||
        imageDescriptionFetching ||
        imageDeleteFetching ? (
          <Loader />
        ) : (
          <ImageBuildingInfoLayouts
            isModalVisible={isAddImageModal}
            isCommentModal={isCommentModal}
            addImage={this.onAddImage}
            handleChange={this.handleChange}
            deleteComment={this.deleteComment}
            selectedImage={selectedImage}
            onSave={this.onSave}
            imageDetails={this.props.isReportImage ? reportImgs : images}
            isReportImage={this.props.isReportImage}
            imageUrl={imageUrl}
            isPreviewImage={previewImage}
            commentAdder={this.commentAdder}
            showPreview={this.showPreview}
            commentDescrip={commentValue}
            isDeleteModal={isDeleteModal}
            deleteImage={this.deleteImage}
            onConfirmDelete={this.onConfirmDelete}
            isFetching={isFetching}
            uploadImageActionFetching={uploadImageActionFetching}
            imageDetailsFetching={imageDetailsFetching}
            validationErrs={validationErrs}
            isRenderedInReports={this.props.isRenderedInReports}
            isViewing={this.props.isViewing}
          />
        )}
      </div>
    );
  }
}

ImageUploadBuildingInfo.propTypes = {
  uploadImageAction: PropTypes.func,
  clearStateAction: PropTypes.func,
  addImageDetailsAction: PropTypes.func,
  uploadImage: PropTypes.object,
  getBuildingInfoAction: PropTypes.func,
  getSystemInfoAction: PropTypes.object,
  addImageDetails: PropTypes.object,
  imageDescriptionAction: PropTypes.func,
  imageDescription: PropTypes.object,
  deleteImage: PropTypes.object,
  deleteImageAction: PropTypes.func,
  getSystemInfo: PropTypes.object,
  setSelectedCustomer: PropTypes.object,
  isReportImage: PropTypes.bool,
  reportUpdatedData: PropTypes.object,
  saveReport: PropTypes.func,
  reportCat: PropTypes.object,
  clientInfo: PropTypes.object,
  reportDetails: PropTypes.object,
  updateReport: PropTypes.func,
  reportId: PropTypes.string,
  setSubMenuVisibility: PropTypes.func,
  SetIsPromptAction: PropTypes.func,
  setImageSocketDetails: PropTypes.func,
  fillJobDetails: PropTypes.func,
  reportEntryId: PropTypes.string,
  offlineMode: PropTypes.object,
  isRenderedInReports: PropTypes.bool,
  isViewing: PropTypes.bool,
};

const mapStateToProps = ({
  uploadImage,
  addImageDetails,
  getBuildingInfo,
  imageDescription,
  deleteImage,
  getSystemInfo,
  setSelectedCustomer,
  addReportCat,
  getClientOrOwnerDetails,
  reportCreate,
  reportUpdate,
  offlineMode,
}) => {
  const reportId = window.location.pathname.split('/')[2];
  const jobId = getJobIdForIncompleteReport(
    {
      ...offlineMode.reportListByJobIds.reportListByJobIds,
      ...offlineMode.reportListByCustomer.reportListByCustomer,
    },
    reportId
  );
  return {
    uploadImage,
    addImageDetails,
    getBuildingInfo,
    imageDescription,
    getSystemInfoAction,
    deleteImage,
    getSystemInfo,
    setSelectedCustomer,
    reportCat: addReportCat.reportCatData,
    clientInfo: getClientOrOwnerDetails.clientOrOwnerData,
    reportDetails: reportCreate.createReportData,
    reportUpdatedData: offlineMode.isOffline
      ? jobId[0] && jobId[0].jobId
        ? offlineMode.reportEntries[jobId[0].jobId]
        : offlineMode.reportEntries[reportId]
      : reportUpdate.updateReportData,
    offlineMode,
    reportEntryId: offlineMode.isOffline
      ? (jobId[0] && jobId[0].jobId) || reportId
      : '',
  };
};

export default connect(mapStateToProps, {
  uploadImageAction,
  clearStateAction,
  addImageDetailsAction,
  getSystemInfoAction,
  imageDescriptionAction,
  deleteImageAction,
  updateReport,
  clearSuccess,
  saveReport,
  setSubMenuVisibility,
  SetIsPromptAction,
  setImageSocketDetails,
  fillJobDetails,
})(ImageUploadBuildingInfo);
