import { createSelector } from 'reselect';

const selectGetClientOrOwnerDetails = (state) => state.getClientOrOwnerDetails;
const offlineMode = (state) => state.offlineMode;
const addReportCat = (state) => state.addReportCat;
const addPartsDetailsStore = (state) => state.addPartsDetailsStore;
const addPartReducer = (state) => state.addPartReducer;

export const selectCreatedFor = createSelector(
  selectGetClientOrOwnerDetails,
  offlineMode,
  addReportCat,
  (getClientOrOwnerDetails, offlineMode, addReportCat) =>
    offlineMode.isOffline
      ? addReportCat.reportCatData.customerValue
      : getClientOrOwnerDetails.clientOrOwnerData._id
);

export const deletePartSucceeded = createSelector(
  addPartsDetailsStore,
  (addPartsDetailsStore) => addPartsDetailsStore.isSuccessDelete
);

export const latestPartIdAdded = createSelector(
  addPartReducer,
  (addPartReducer) => addPartReducer.partId
);