import React, { useState } from 'react';
import Dropdown from '../../components/ReallyCustomDropDown';
import PopUpData from '../../components/JobLinkCalendar/PopUpData';

const StatusSelector = ({ desktopCalendar, userId, status }) => {

  const [selectorOpen, setSelectorOpen] = useState(false);

  const selectedStatus = PopUpData.find(({value}) => value == status);

  const statusMenu =  (
    <div className="py-2 rounded-md border shadow mt-2 bg-white">
      {PopUpData.map(status => (
        <div
          key={status.value}
          className="flex pl-2 py-1.5 items-center pr-3"
          onClick={() => {
            desktopCalendar.handleTeamStatusUpdation(status.name, userId);
            setSelectorOpen(false);
          }}
        >
          <div
            className="w-8 h-8 cursor-pointer hover:bg-gray-100 flex items-center justify-center rounded-full"
            style={{
              backgroundColor: status.accentColor,
              color: 'white',
              fontSize: 5
            }}
          >
            {status.icon}
          </div>
          <div className="ml-2">
            {status.name}
          </div>
        </div>
      ))}
    </div>
  );

  return (
    <Dropdown
      open={selectorOpen}
      onOpenChange={setSelectorOpen}
      overlay={statusMenu}
      placement="left"
    >
      <div
        className="text-white px-3 py-1.5 rounded-md cursor-pointer mr-2"
        style={{
          backgroundColor: selectedStatus.accentColor
        }}
      >
        {selectedStatus.icon}
      </div>
    </Dropdown>
  );
};

export default StatusSelector;