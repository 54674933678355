import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import './IconButton.scss';
import ToolTip from '../ToolTip/index';
export class IconButton extends Component {
  state = {
    hover: false,
  };

  /**
   * Returns the classname of button container
   *
   * @memberof IconButton
   */
  getWrapperClassName = () => {
    const { uploadButton, blockButton, muteButton, crossButton } = this.props;

    if (blockButton) {
      return 'btn btn_checkout checkout-btn block_btn btn_table';
    }
    if (uploadButton) {
      return 'btn btn_primary upload-icon btn_table';
    }
    if (muteButton) {
      return 'btn mute-btn';
    }
    if (crossButton) {
      return 'search-chip crossbtn';
    }
    return 'btn btn_primary add';
  };

  /**
   * Returns the button class name
   *
   * @memberof IconButton
   */
  getSpanClassName = () => {
    const { uploadButton, blockButton, muteButton, crossButton } = this.props;

    if (blockButton) {
      return 'icon sprite-icon1 checkout';
    }
    if (uploadButton) {
      return 'icon sprite-icon1 upload-icon';
    }
    if (muteButton) {
      return 'mute-icon sprite-icon';
    }
    if (crossButton) {
      return 'sprite-icon';
    }
    return 'icon sprite-icon1 add';
  };

  getButtonTextClass = () => {
    const { text } = this.props;
    if (text === 'Add Custom Question'){
      return 'add-question';
    }
    if (text === 'Add QR Code'){
      return 'add-qr-code';
    }
  }

  render() {
    const { hover } = this.state;
    const {
      dataFor,
      dataTip,
      place,
      text,
      color,
      muteButton,
      crossButton,
      isDisabled,
      handleOnClick,
      id,
      className,
    } = this.props;

    return (
      <Fragment>
        <button
          data-for={dataFor}
          data-tip={dataTip && this.props.intl.formatMessage({ id: dataTip })}
          type="button"
          id={id}
          onClick={handleOnClick}
          disabled={isDisabled}
          className={`${this.getWrapperClassName()} ${this.getButtonTextClass()} ${className}`}
          onTouchStart={() => this.setState({ hover: true})}
          onTouchEnd={() => this.setState({ hover: false})}
          onMouseOver={() =>
            !muteButton && !crossButton && this.setState({ hover: true })
          }
          onMouseOut={() =>
            !muteButton && !crossButton && this.setState({ hover: false })
          }
          style={
            muteButton || crossButton
              ? {}
              : hover
              ? {
                  border: `1.5px solid ${color}`,
                  color: '#ffffff',
                  background: `${color}`,
                }
              : {
                  border: `1.5px solid ${color}`, // #282C37
                  color: `${color}`,
                  background: '#ffffff',
                }
          }
        >
          {crossButton ? `${text}` : ''}
          {muteButton || crossButton ? (
            <span className={this.getSpanClassName()}></span>
          ) : (
            <span className="btn-icon-wrap">
              <span className={this.getSpanClassName()}></span>
              <FormattedMessage id={text || ' '} defaultMessage={text} />
            </span>
          )}
          {muteButton ? `${text}` : ''}
        </button>
        <ToolTip id={dataFor} place={place} />
      </Fragment>
    );
  }
}

IconButton.propTypes = {
  color: PropTypes.string, // colour of button
  text: PropTypes.string, // text on button
  uploadButton: PropTypes.bool, // is upload button
  blockButton: PropTypes.bool, // is block button
  muteButton: PropTypes.bool, // is mute button
  crossButton: PropTypes.bool, // is cross button
  handleOnClick: PropTypes.func, // handles button on click
  id: PropTypes.string, // id of button
  dataFor: PropTypes.string,
  dataTip: PropTypes.string,
  place: PropTypes.string,
  intl: intlShape.isRequired,
  isDisabled: PropTypes.bool,
};

IconButton.defaultProps = {
  color: '#4DBCE9',
  text: 'Add Email Id',
  uploadButton: false,
  blockButton: false,
  muteButton: false,
  isDisabled: false,
  crossButton: false,
  handleOnClick: () => {},
  id: '',
};

export default injectIntl(IconButton);
