import { genericRequest } from './index';

const deleteGenericReport = async (reportId) => {
  await genericRequest({
    url: 'genericReport',
    method: 'delete',
    data: { reportId },
  })
}

export default deleteGenericReport