import { getToken } from '../_helpers/commonFunctions';
import call from './apiRequest';
import apiEndpoints from './apiEndpoints';

/**
 * This method is called to for adding new recurring inspections.
 * @param {*} data Set of record added recurring inspections.
 */
export const addRecurringInspections = (data) =>
  call({
    method: 'post',
    endpoint: apiEndpoints.recurringInspections,
    payload: data,
    authKey: getToken(),
  });

/**
 * This method is called to fetch the listing of recurring inspections.
 * @param {*} page Page Number
 * @param {*} limit No of records per page
 * @param {*} selectedCustomerId selected client id
 */
export const fetchRecurringInspections = (selectedCustomerId, page, limit) =>
  call({
    method: 'get',
    endpoint: apiEndpoints.recurringInspections,
    query: {
      clientId: selectedCustomerId,
      page,
      limit,
    },
    authKey: getToken(),
  });

/**
 * This method is called to delete inspection.
 * @param {*} inspectionid Id of inspection to delete
 * @param {*} createdFor Client Id
 */
export const deleteRecurringInspection = (inspectionid, createdFor) =>
  call({
    method: 'delete',
    endpoint: apiEndpoints.deleteRecurrInspection,
    authKey: getToken(),
    query: {
      inspectionid,
    },
    payload: {
      createdFor,
    },
  });

/**
 * This method is called to fetch details of particular inspection.
 * @param {*} inspectionid Id of inspection to fetch
 */
export const fetchRecurringInspectionDetail = (inspectionId) =>
  call({
    method: 'get',
    endpoint: `${apiEndpoints.fetchRecurringInspectionDetail}/${inspectionId}`,
    authKey: getToken(),
  });

/**
 * This method is called to update to editted inspection.
 * @param {*} inspectionid Id of inspection to update
 * @param {*} updatedInspection data set of editted inspection
 */
export const updateRecurringInspection = (inspectionId, updatedInspection) =>
  call({
    method: 'put',
    endpoint: `${apiEndpoints.recurringInspections}?inspectionid=${inspectionId}`,
    payload: updatedInspection,
    authKey: getToken(),
  });

/**
 * This method is called to fetch the listing of recurring inspections.
 * @param {object} params
 * @param {*} page Page Number
 * @param {*} limit No of records per page
 * @param {*} selectedCustomerId selected client id
 */
export const fetchRecurringInspectionsCount = (params, selectedCustomerId, page, limit = 10) => {
  const queryParams = { ...params };
  if (!queryParams.startDate) {
    delete queryParams.startDate;
  }
  return call({
    method: 'get',
    endpoint: apiEndpoints.recurringInspectionsCount,
    query: {
      ...queryParams, businessNameSort: 1,
      clientId: selectedCustomerId,
      page,
      limit,
    },
    authKey: getToken(),
  });
}

/**
 * Api call for getting job list
 * @param {object} params
 * @param {number} page
 * @param {number} limit
 */
export const getJobsByInspection = (params, page, limit = 10) => {
  const queryParams = { ...params };
  if (!queryParams.startDate) {
    delete queryParams.startDate;
  }
  return call({
    method: 'get',
    endpoint: `${apiEndpoints.recurringInspectionJobs}?page=${page}&limit=${limit}`,
    query: { ...queryParams, businessNameSort: 1 },
    authKey: getToken(),
  });
};


 
  