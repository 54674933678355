import React, { useState, useEffect } from 'react';
import PropType from 'prop-types';
import Picker from 'react-month-picker';
import { FormattedMessage } from 'react-intl';
import './DateMonthPicker.css';
import { getMonthsAbbreaviated } from '../../utils/utils';

const pickAMonth = React.createRef();
const DateMonthPicker = (props) => {
  const currentDate = new Date(props.startDatePersist)
  const currentYear = currentDate.getFullYear()
  const currentMonth = currentDate.getMonth() + 1
  const [value, setValue] = useState({
    year: currentYear,
    month: currentMonth,
  });

  useEffect(() => {
    if (props.isShowing) {
      pickAMonth.current.show();
    }

    if (props.startDatePersist) {
      const year = new Date(props.startDatePersist).getFullYear();
      const month = new Date(props.startDatePersist).getMonth() + 1;
      setValue({
        year,
        month,
      });
    }
  }, [props.isShowing, props.startDatePersist]);
  return (
    <div className="datemonth" ref={props.refCallback}>
      <div>
        <Picker
          ref={pickAMonth}
          years={{ min: 2014, max: currentYear + 4 }}
          value={value}
          lang={ getMonthsAbbreaviated() }
          onChange={(year, month) => {
            props.persistDate(new Date(`${year}/${month}`));
          }}
          onDismiss={() => {
            props.onDismiss && props.onDismiss();
          }}
        ></Picker>
      </div>
      <button
        className="resetbuttondatemonth"
        style={props.resetBtnStyle}
        onClick={props.resetdate}
      >
        <FormattedMessage id="RESET" defaultMessage="RESET" />{' '}
      </button>
    </div>
  );
};

DateMonthPicker.propTypes = {
  persistDate: PropType.func,
  startDatePersist: PropType.object.isRequired,
  endDatePersist: PropType.object.isRequired,
  minDate: PropType.object,
  maxDate: PropType.object,
  resetdate: PropType.func,
  customStyle: PropType.object,
  className: PropType.string,
  resetBtnStyle: PropType.object,
  refCallback: PropType.func,
};

DateMonthPicker.defaultProps = {
  minDate: undefined,
  maxDate: undefined,
  resetdate: () => {},
};

export default DateMonthPicker;
