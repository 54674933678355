import actionConstants from '../constants/ActionConstants';

const initialState = {
  inspectionsDue: {
    isFetching: false,
    isSuccess: false,
    inspectionsDueList: [],
    error: '',
    currentPage: null,
    totalPages: null,
  },
  inspectionsDone: {
    isFetching: false,
    isSuccess: false,
    inspectionsDoneList: [],
    error: '',
    currentPage: null,
    totalPages: null,
  },
  inspectionsCount: {
    isFetching: false,
    isSuccess: false,
    inspectionsCountList: [],
    error: '',
    currentPage: null,
    totalPages: null,
  },
  inspectionJobs: {
    isFetching: false,
    isSuccess: false,
    inspectionsJobsList: [],
    error: '',
    currentPage: null,
    totalPages: null,
  },
  pricingLevel: {
    isFetching: false,
    error: '',
    isSuccess: false,
    pricingLevelData: [],
  },
  clientListForTypeChange: {
    isFetching: false,
    isSuccess: false,
    clientList: [],
    error: '',
    currentPage: null,
    totalPages: null,
  },
  clientAccountTypeChangeStatus: {
    isFetching: false,
    error: '',
    isSuccess: false,
    success: '',
  },
};

export default (state = initialState, action) => {
  const {
    inspectionsDue,
    inspectionsDone,
    inspectionsCount,
    pricingLevel,
    clientListForTypeChange,
    inspectionJobs
  } = state;
  switch (action.type) {
    case actionConstants.GET_INSPECTIONS_DUE_LIST_REQUEST:
      return {
        ...state,
        inspectionsDue: {
          ...inspectionsDue,
          isFetching: true,
          isSuccess: false,
          error: '',
        },
      };
    case actionConstants.GET_INSPECTIONS_DUE_LIST_SUCCESS:
      return {
        ...state,
        inspectionsDue: {
          ...inspectionsDue,
          isFetching: false,
          isSuccess: true,
          error: '',
          inspectionsDueList:
            action.payload.page === 1
              ? action.payload.docs
              : [...inspectionsDue.inspectionsDueList, ...action.payload.docs],
          currentPage: action.payload.page,
          totalPages: action.payload.pages,
        },
      };
    case actionConstants.GET_INSPECTIONS_DUE_LIST_FAILURE:
      return {
        ...state,
        inspectionsDue: {
          ...inspectionsDue,
          isFetching: false,
          error: action.message,
          isSuccess: false,
        },
      };
    case actionConstants.GET_INSPECTIONS_DONE_LIST_REQUEST:
      return {
        ...state,
        inspectionsDone: {
          ...inspectionsDone,
          isFetching: true,
          isSuccess: false,
          error: '',
        },
      };
    case actionConstants.GET_INSPECTIONS_DONE_LIST_SUCCESS:
      return {
        ...state,
        inspectionsDone: {
          ...inspectionsDone,
          isFetching: false,
          isSuccess: true,
          error: '',
          inspectionsDoneList:
            action.payload.page === 1
              ? action.payload.docs
              : [
                  ...inspectionsDone.inspectionsDoneList,
                  ...action.payload.docs,
                ],
          currentPage: action.payload.page,
          totalPages: action.payload.pages,
        },
      };
    case actionConstants.GET_INSPECTIONS_DONE_LIST_FAILURE:
      return {
        ...state,
        inspectionsDone: {
          ...inspectionsDone,
          isFetching: false,
          error: action.message,
          isSuccess: false,
        },
      };
    case actionConstants.GET_INSPECTIONS_COUNT_LIST_REQUEST:
        return {
          ...state,
          inspectionsCount: {
            ...inspectionsCount,
            isFetching: true,
            isSuccess: false,
            error: '',
          },
        };
    case actionConstants.GET_INSPECTIONS_COUNT_LIST_SUCCESS:
        return {
          ...state,
          inspectionsCount: {
            ...inspectionsCount,
            isFetching: false,
            isSuccess: true,
            error: '',
            inspectionsCountList:
              action.payload.page === 1
                ? action.payload.docs
                : [...inspectionsCount.inspectionsCountList, ...action.payload.docs],
            currentPage: action.payload.page,
            totalPages: action.payload.pages,
          },
        };
     
    case actionConstants.GET_INSPECTIONS_COUNT_LIST_FAILURE:
      return {
        ...state,
        inspectionsCount: {
          ...inspectionsCount,
          isFetching: false,
          error: action.message,
          isSuccess: false,
        },
      };
      case actionConstants.GET_INSPECTIONS_JOBS_LIST_REQUEST:
        return {
          ...state,
          inspectionJobs: {
            ...inspectionJobs,
            isFetching: true,
            isSuccess: false,
            error: '',
          },
        };
    case actionConstants.GET_INSPECTIONS_JOBS_LIST_SUCCESS:
        return {
          ...state,
          inspectionJobs: {
            ...inspectionJobs,
            isFetching: false,
            isSuccess: true,
            error: '',
            inspectionsJobsList: action.payload.docs,
          },
        };
     
    case actionConstants.GET_INSPECTIONS_JOBS_LIST_FAILURE:
      return {
        ...state,
        inspectionJobs: {
          ...inspectionJobs,
          isFetching: false,
          error: action.message,
          isSuccess: false,
        },
      };
    case actionConstants.GET_PRICING_LEVEL_REQUEST:
      return {
        ...state,
        pricingLevel: {
          ...pricingLevel,
          isFetching: true,
          isSuccess: false,
          error: '',
        },
      };
    case actionConstants.GET_PRICING_LEVEL_SUCCESS:
      return {
        ...state,
        pricingLevel: {
          ...pricingLevel,
          isFetching: false,
          isSuccess: true,
          error: '',
          pricingLevelData: action.payload,
        },
      };
    case actionConstants.GET_PRICING_LEVEL_FAILURE:
      return {
        ...state,
        pricingLevel: {
          ...pricingLevel,
          isFetching: false,
          error: action.message,
          isSuccess: false,
        },
      };
    case actionConstants.GET_CLIENT_LIST_FOR_TYPE_CHANGE_REQUEST:
      return {
        ...state,
        clientListForTypeChange: {
          ...clientListForTypeChange,
          isFetching: true,
          isSuccess: false,
          error: '',
        },
      };
    case actionConstants.GET_CLIENT_LIST_FOR_TYPE_CHANGE_SUCCESS:
      return {
        ...state,
        clientListForTypeChange: {
          ...clientListForTypeChange,
          isFetching: false,
          isSuccess: true,
          error: '',
          clientList:
            action.payload.page === 1
              ? action.payload.docs
              : [...clientListForTypeChange.clientList, ...action.payload.docs],
          currentPage: action.payload.page,
          totalPages: action.payload.pages,
        },
      };
    case actionConstants.GET_CLIENT_LIST_FOR_TYPE_CHANGE_FAILURE:
      return {
        ...state,
        clientListForTypeChange: {
          ...clientListForTypeChange,
          isFetching: false,
          error: action.message,
          isSuccess: false,
        },
      };
    case actionConstants.CHANGE_CLIENT_ACCOUNT_TYPE_REQUEST:
      return {
        ...state,
        clientAccountTypeChangeStatus: {
          isFetching: true,
          isSuccess: false,
          error: '',
          success: '',
        },
      };
    case actionConstants.CHANGE_CLIENT_ACCOUNT_TYPE_SUCCESS:
      return {
        ...state,
        clientAccountTypeChangeStatus: {
          isFetching: false,
          isSuccess: true,
          error: '',
          success: action.message,
        },
      };
    case actionConstants.CHANGE_CLIENT_ACCOUNT_TYPE_FAILURE:
      return {
        ...state,
        clientAccountTypeChangeStatus: {
          isFetching: false,
          error: action.message,
          isSuccess: false,
        },
      };
    case actionConstants.RESET_ALL_REDUCERS:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};
