import React, { useState, useEffect } from 'react';
import { Calendar, Badge } from 'antd';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Moment from 'moment';
import getJobListAction, {
  setCalenderViewData,
  resetTeamListWithFiltersAction,
} from '../../redux/actions/jobLinkActions';
import 'antd/dist/antd.css';
import './Calendar.css';
import { getCurrentLanguage } from '../../utils/utils';
import endpoints from '../../routes';
import { withRouter } from 'react-router-dom';

const limit = 1000;

const getEventType = (eventCat) => {
  switch (eventCat) {
    case 'Quote':
      return 'error';
    case 'Inspection':
      return 'warning';
    case 'Work Order':
      return 'success';
    default:
  }
  return '';
};

const getListData = (value, list, eventCategory) => {
  const listData = [];
  list.forEach((listItem) => {
    if (
      listItem.jobStatus === 'Assigned' &&
      Moment(listItem.startDateTime).valueOf() >=
        Moment().startOf('day').valueOf()
    ) {
      const event = {};
      event.type = getEventType(listItem.type);
      event.content = listItem.description;
      event.id = listItem._id;
      const eventDate = new Date(listItem.startDateTime).toDateString();
      const calendarDate = new Date(Moment(value).toDate()).toDateString();
      if (
        eventDate === calendarDate &&
        (eventCategory.includes('All') || eventCategory.includes(listItem.type))
      ) {
        listData.push(event);
      }
    }
  });
  return listData;
};

export const JobLinkMonthCalender = (props) => {
  const [eventCategory, setEventCategory] = useState(['All']);
  const { changeViewType, jobList } = props;
  const lang = getCurrentLanguage();

  const onSelect = (value) => {
    props.setCalenderViewData({
      startDate: new Date(value._d).toDateString(),
      endDate: new Date(value._d).toDateString(),
    });
    changeViewType('viewtype', {
      name: 'Day',
      id: 0,
    });
    props.history.push(endpoints.jobLink);
  };

  useEffect(() => {
    setEventCategory(props.eventCategory);
  }, props.eventCategory);

  useEffect(() => {
    props.resetTeamListWithFiltersAction();
    setTimeout(() => {
      props.getJobListAction({ jobStatus: ['Assigned'] }, 1, limit);
    }, 1000);
  }, []);

  const dateFullCellRender = (date) => {
    const listData = getListData(date, jobList.jobList, eventCategory);
    date.locale(lang);
    return (
      <div className="ant-fullcalendar-date">
        <div className="ant-fullcalendar-value">{date.format('DD, MMMM')}</div>
        <span className="job-list-count">{listData.length}</span>
        <div className="ant-fullcalendar-content">
          <ul className="events">
            {listData.map((item) => (
              <li key={item.id}>
                <Badge status={item.type} text={item.content} />
              </li>
            ))}
          </ul>
        </div>
      </div>
    );
  };

  return jobList.isFetching ? (
    <div className="loader-container">
      <div className="loader"></div>
    </div>
  ) : (
    <Calendar dateFullCellRender={dateFullCellRender} onSelect={onSelect} />
  );
};

JobLinkMonthCalender.propTypes = {
  changeViewType: PropTypes.func,
  setCalenderViewData: PropTypes.func,
  jobList: PropTypes.object,
  eventCategory: PropTypes.array,
  getJobListAction: PropTypes.func,
  resetTeamListWithFiltersAction: PropTypes.func,
};

export default connect(
  ({ jobListDetails }) => ({
    jobList: jobListDetails.jobList,
  }),
  {
    setCalenderViewData,
    getJobListAction,
    resetTeamListWithFiltersAction,
  }
)(withRouter(JobLinkMonthCalender));