import React from 'react';
import Modal from '../../components/Modal';

const DownloadReportModal = ({ pdfUrl, onClose }) => {
  const ModalBody = () => (
    <div className="modal-body">
      <h3 style={{ marginTop: 10 }}>
        Please click
        <a
          href={pdfUrl}
          className="mx-2"
          target="_blank"
          style={{
            color: 'blue',
            textDecoration: 'underline',
          }}
        >
          here
        </a>
        to download it.
      </h3>
    </div>
  );

  return (
    <Modal
      handleClickCross={onClose}
      ModalBody={ModalBody}
      modalHeading="Your report is ready"
    />
  );
};

export default DownloadReportModal;
