import React from 'react';
import PropTypes from 'prop-types';
import Table from '../../components/ReusableTable';
import { taskTableHeaders } from './constants';
import taskTableRow from './tasktableRow';
import { getCorrectedTasks } from './taskModalBodyUtils';

const TaskModalBody = (props) => (
  <Table
    headerData={taskTableHeaders}
    bodyData={getCorrectedTasks(props.task)}
    RowToRender={taskTableRow}
    hasMoreItems={props.inspectionDetails && props.inspectionDetails.isFetching}
    isLoaderVisible={
      props.inspectionDetails && props.inspectionDetails.isFetching
    }
    height={props.height}
  />
);

TaskModalBody.propTypes = {
  task: PropTypes.array,
  inspectionDetails: PropTypes.object,
  height: PropTypes.string,
};

export default TaskModalBody;
