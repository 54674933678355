import actionConstants from '../constants/ActionConstants';

const initialState = {
  reportCatData: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionConstants.SET_REPORT_CAT:
      return {
        ...state,
        reportCatData: action.payload,
      };
    default:
      return state;
  }
};
