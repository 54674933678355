import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { date, checkPermission } from '../../_helpers/commonFunctions';
import { reportConstants } from '../../libs/constants/reportConstants';
import { subPermissions } from '../../libs/constants/permissions';
import Dropdown from '../../components/ReallyCustomDropDown';

/**
 * Returns answers for downloading reports
 * @param {object} answersData
 */
const getAnswers = (answersData) => {
  let answers = {};
  if (answersData) {
    const { Devices } = answersData;
    if (Devices && Devices.length) {
      Devices.forEach((device) => {
        answers = {
          ...answers,
          ...device,
        };
      });
    } else {
      answers = {
        ...answersData,
      };
    }
  }
  delete answers.id;
  delete answers.child;
  return answers;
};

const IconButton = ({ icon, className = '', disabled = false, ...props }) => (
  <div
    className={`icon-button ${className} ${disabled ? 'disabled' : ''}`}
    {...props}
  >
    {icon}
  </div>
)

const MaterialIcon = ({ icon, style = {}, ...props }) => (
  <i
    className="material-icons-outlined"
    style={{
      fontSize: 26,
      ...style
    }}
    {...props}
  >{icon}</i>
)

/**
 * Renders each row of table in filters report list table.
 * @param {*} props
 */
const ReportFilterListTableBody = (props) => {
  const {
    row,
    handleDelete,
    handleShowProfile,
    handleEditReport,
    downloadReportPDF,
    emailReportPDF,
    isReportNeeded,
  } = props;
  const {
    createdForName,
    createdAt,
    firstCompletionDate,
    completionDate,
    _id,
    status,
    reportJson,
    answers,
    updateHistory,
    isLegacyReport,
    pdfUrl,
    isGeneric,
    clientName,
  } = row;

  const downloadNewReport = () => {
    downloadReportPDF({
      htmlJson: reportJson,
      answers: getAnswers(answers),
      projectName: _id,
    });
  };

  const downloadButton = pdfUrl ? (
    <a href={pdfUrl}>
      <IconButton 
        icon={<MaterialIcon icon="cloud_download" style={{ color: '#578241' }} />}
        disabled={pdfUrl === 'error'}
        className="ml-1"
      />
    </a>
  ) : (
    <IconButton 
      icon={<MaterialIcon icon="cloud_download" style={{ color: '#578241' }} />}
      className="ml-1"
      onClick={downloadNewReport}
    />
  );

  const actionsButtons = (
    <div className="action-buttons-row-report">
      <IconButton
        icon={<MaterialIcon icon="account_circle" style={{ color: '#3E97BB' }} />}
        className="ml-1"
        onClick={() => handleShowProfile(updateHistory)}
      />
      {status === reportConstants.completed && (
        <IconButton
          icon={<MaterialIcon icon="mail" style={{ color: '#9D58C8' }} />}
          className="ml-1"
          onClick={() => emailReportPDF(_id)}
        />
      )}
      {status === reportConstants.completed && downloadButton}
      {(status !== reportConstants.completed ||
        checkPermission(subPermissions.editDeleteReports)) &&
        !isLegacyReport && (
          <IconButton
            icon={<MaterialIcon icon="delete" style={{ color: '#333333' }} />}
            className="ml-1"
            onClick={() => handleDelete(_id, createdForName || clientName, isGeneric)}
          />
        )}
      {(status !== reportConstants.completed ||
        checkPermission(subPermissions.editDeleteReports)) &&
        !isLegacyReport && (
          <IconButton
            icon={<MaterialIcon icon="edit" style={{ color: '#932520' }} />  }
            className="ml-1"
            onClick={() => handleEditReport(row)}
          />
        )}
    </div>
  )

  const actionsDropDown = (
    <Dropdown
      overlay={actionsButtons}
      placement="right"
      style={{
        display: 'inline-block',
        marginRight: 10,
      }}
    >
      <IconButton
        icon={<MaterialIcon icon="more_horiz" style={{ color: '#333333' }} />}
      />
    </Dropdown>
  )

  return (
    <tr className="customer-report-tr">
      <td>
        <FormattedMessage
          id={row.frequency || 'NA'}
          defaultMessage={row.frequency || 'NA'}
        >
          {msg => (
            <>
              {msg}
              {' - '}
              {status !== reportConstants.completed
                ? date(createdAt)
                : firstCompletionDate
                ? date(firstCompletionDate)
                : date(completionDate)}
            </>
          )}
        </FormattedMessage>
        <span
          className="block md:hidden flex-1"
          style={{
            textAlign: 'right',
            overflow: 'visible',
          }}
        >
          {actionsDropDown}
        </span>
      </td>
      <td className="hidden md:table-cell">{createdForName || clientName}</td>
      <td style={{ textAlign: 'right' }} className="hidden md:table-cell">
        {isReportNeeded === false ? (
          <span
            className={
              isReportNeeded !== false
                ? 'Subscibe'
                : 'Subscibe report-not-needed'
            }
          >
            <FormattedMessage id="Completed" defaultMessage="Completed" />
            {isReportNeeded === false && (
              <span className="material-icons material-icon-checkmark-report">
                check_box
              </span>
            )}
          </span>
        ) : actionsDropDown}
      </td>
    </tr>
  );
};

ReportFilterListTableBody.propTypes = {
  row: PropTypes.object,
  handleDelete: PropTypes.func,
  handleShowProfile: PropTypes.func,
  handleEditReport: PropTypes.func,
  downloadReportPDF: PropTypes.func,
  clearReportPdf: PropTypes.func,
  emailReportPDF: PropTypes.func,
  isReportNeeded: PropTypes.any,
};

export default ReportFilterListTableBody;
