import { API } from '../config';
import { proxyUrl } from '../views/constant';
import call from './apiRequest';

const baseURL = API.WAY_POINT_API;
const distanceBaseURL = API.DISTANCE_MATRIX_API;
const key = API.MAP_KEY;

/**
 * Api call to get way points
 * @param {object} origin
 * @param {object} destination
 * @param {Array} waypoints
 */
export const getWayPoints = (origin, destination, waypoints) =>
  call({
    url: proxyUrl + baseURL,
    query: {
      key,
      origin,
      destination,
      waypoints: `optimize:true|${waypoints}`,
    },
  });

/**
 * Api call to get distance between way points
 * @param {array} origins
 * @param {array} destinations
 */
export const getWayPointsDistance = (origins, destinations) =>
  call({
    url: proxyUrl + distanceBaseURL,
    query: {
      key,
      origins,
      destinations,
    },
  });
