import actionConstants from '../constants/ActionConstants';

const initialState = {
  error: '',
  isfetching: false,
  success: '',
  notificationData: [],
  olderNotificationData: [],
  pages: 0,
  total: 0,
  currentPage: 1,
  isSuccess: false,
  deleteSuccess: '',
  deleteIsFetching: false,
  deleteError: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionConstants.GET_NOTIFICATIONS_REQUEST:
      return {
        ...state,
        isfetching: true,
        isSuccess: false,
      };
    case actionConstants.GET_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        notificationData:
          action.payload.page === 1
            ? action.payload.docs
            : state.currentPage === action.payload.page
            ? [...state.notificationData]
            : [...state.notificationData, ...action.payload.docs],
        isfetching: false,
        success: 'client list fetched successfully!',
        pages: action.payload.pages,
        isSuccess: true,
        total: action.payload.total,
        currentPage: action.payload.page,
      };
    case actionConstants.GET_NOTIFICATIONS_FAILURE:
      return {
        ...state,
        error: action.message,
        isfetching: false,
        success: '',
        isSuccess: false,
      };
    case actionConstants.GET_NOTIFICATIONS_CLEAR:
      return {
        ...state,
        error: '',
        isSuccess: false,
        success: '',
      };
    case actionConstants.GET_OLDER_NOTIFICATIONS_REQUEST:
      return {
        ...state,
        isfetching: true,
        isSuccess: false,
      };
    case actionConstants.GET_OLDER_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        olderNotificationData:
          action.payload.page === 1
            ? action.payload.docs
            : state.currentPage === action.payload.page
            ? [...state.olderNotificationData]
            : [...state.olderNotificationData, ...action.payload.docs],
        isfetching: false,
        success: 'client list fetched successfully!',
        pages: action.payload.pages,
        isSuccess: true,
        total: action.payload.total,
        currentPage: action.payload.page,
      };
    case actionConstants.GET_OLDER_NOTIFICATIONS_FAILURE:
      return {
        ...state,
        error: action.message,
        isfetching: false,
        success: '',
        isSuccess: false,
      };
    case actionConstants.DELETE_NOTIFICATION_REQUEST:
      return {
        ...state,
        deleteIsFetching: true,
      };
    case actionConstants.DELETE_NOTIFICATION_SUCCESS:
      return {
        ...state,
        deleteSuccess: action.message,
        deleteIsFetching: false,
      };
    case actionConstants.DELETE_NOTIFICATION_FAILURE:
      return {
        ...state,
        deleteError: action.message,
        deleteIsFetching: false,
      };
    case actionConstants.CLEAR_DELETE_NOTIFICATION:
      return {
        ...state,
        deleteError: '',
        deleteIsFetching: false,
        deleteSuccess: '',
      };
    case actionConstants.RESET_ALL_REDUCERS:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};
