export const modifyCustomerHomeAddCountJobs = (state) => {
  const inspectionsCount = {
    isFetching: false,
    isSuccess: false,
    inspectionsCountList: [],
    error: '',
    currentPage: null,
    totalPages: null,
  };
  const inspectionJobs = {
    isFetching: false,
    isSuccess: false,
    inspectionsJobsList: [],
    error: '',
    currentPage: null,
    totalPages: null,
  };
  const customerHome = {...state.customerHome, inspectionsCount, inspectionJobs};
  const newState = {...state, customerHome};
  return newState;
}
