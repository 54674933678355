import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import getSystemInfoAction from '../../redux/actions/getSystemInfo';
import BuildingInfoLayout from '../../layouts/BuildingInfoLayout';
import addNotesAction from '../../redux/actions/addNotes';
import { clearState } from '../../redux/actions/addSystemOrDevice';
import { displayToast } from '../../_helpers/commonFunctions';
import { setSubMenuVisibility } from '../../redux/actions/setSelectedCustomer';

class BuildingInfo extends Component {
  handleClickSaveNotes = (notes) => {
    const { buildingInfoId } = this.props;
    this.props.addNotesAction(buildingInfoId, notes);
  };

  componentDidUpdate(prevProps) {
    if (prevProps.selectedCustomerId !== this.props.selectedCustomerId) {
      this.props.getSystemInfoAction(this.props.selectedCustomerId);
    }
    if (this.props.isAddNotesSuccess) {
      this.props.clearState();
      this.props.getSystemInfoAction(this.props.selectedCustomerId);
      displayToast(this.props.addNotesSuccessMessage, 'success');
    } else if (this.props.addNotesError) {
      this.props.clearState();
      displayToast(this.props.addNotesError, 'error');
    }
  }

  componentDidMount() {
    this.props.getSystemInfoAction(this.props.selectedCustomerId);
    this.props.setSubMenuVisibility(true);
  }

  render() {
    const { buildingInfoNotes, isFetchingSystemInfo, isAddNotesFetching } =
      this.props;
    return (
      <div>
        {isAddNotesFetching && (
          <div id="overlayLoader">
            <div className="page-loader-container">
              <div className="pageLoader"></div>
            </div>
          </div>
        )}
        <BuildingInfoLayout
          notes={buildingInfoNotes}
          handleClickSave={this.handleClickSaveNotes}
          isFetchingSystemInfo={isFetchingSystemInfo}
          isRenderedInReports={this.props.isRenderedInReports}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ getSystemInfo, setSelectedCustomer, addNotes }) => ({
  selectedCustomerId: setSelectedCustomer.selectedCustomerId,
  buildingInfoNotes: getSystemInfo.notes,
  clientId: getSystemInfo.clientId,
  buildingInfoId: getSystemInfo.systemInfoId,
  isAddNotesSuccess: addNotes.isSuccess,
  addNotesError: addNotes.error,
  isAddNotesFetching: addNotes.isFetching,
  isFetchingSystemInfo: getSystemInfo.isFetching,
  addNotesSuccessMessage: addNotes.success,
});

BuildingInfo.propTypes = {
  selectedCustomerId: PropTypes.string,
  buildingInfoNotes: PropTypes.array,
  clientId: PropTypes.string,
  buildingInfoId: PropTypes.string,
  getSystemInfoAction: PropTypes.func,
  addNotesAction: PropTypes.func,
  addNotesError: PropTypes.string,
  isAddNotesSuccess: PropTypes.bool,
  clearState: PropTypes.func,
  isFetchingSystemInfo: PropTypes.bool,
  isAddNotesFetching: PropTypes.bool,
  setSubMenuVisibility: PropTypes.func,
  addNotesSuccessMessage: PropTypes.string,
  isRenderedInReports: PropTypes.bool,
};

export default connect(mapStateToProps, {
  getSystemInfoAction,
  addNotesAction,
  clearState,
  setSubMenuVisibility,
})(BuildingInfo);
