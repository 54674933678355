import { getToken } from '../_helpers/commonFunctions';
import call from './apiRequest';
import apiEndpoints from './apiEndpoints';

/**
 * Api call for getting revenue data
 * @param {string} timezone
 */
const getRevenueData = (timezone) =>
  call({
    method: 'get',
    endpoint: `${apiEndpoints.revenue}`,
    authKey: getToken(),
    query: {
      timezone,
    },
  });

/**
 * Api call for getting jobs data
 * @param {string} startDateTime
 * @param {string} endDateTime
 * @param {Array} reportCategories
 */
const getJobsData = (startDateTime, endDateTime, reportCategories) =>
  call({
    method: 'get',
    endpoint: `${apiEndpoints.jobsbChart}`,
    authKey: getToken(),
    query: {
      startDateTime: `${startDateTime}`,
      endDateTime: `${endDateTime}`,
      reportCategories,
    },
  });

export { getRevenueData, getJobsData };
