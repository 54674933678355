import React, { useEffect, useRef, useState } from 'react';
import { useGeolocated } from 'react-geolocated';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import * as Selectors from '../../redux/selectors';
import setEmployeeLocation from '../../redux/actions/setEmployeeLocation';
import { getAddessWithCoordinates } from '../../utils/utils';

export const GeoLocationContext = React.createContext();

/**
 *
 * @param {number} degree
 * @returns The degree in radians
 */
function toRadians(degree) {
  return (Math.PI / 180) * degree;
}

/**
 *
 * @param {number} lat1
 * @param {number} long1
 * @param {number} lat2
 * @param {number} long2
 * @returns distance in meters between two points
 */
function distance(lat1, long1, lat2, long2) {
  if (lat1 == lat2 && long1 == long2) return 0;
  // Convert the latitudes and longitudes from degree to radians.
  lat1 = toRadians(lat1);
  long1 = toRadians(long1);
  lat2 = toRadians(lat2);
  long2 = toRadians(long2);
  // Haversine Formula
  const dlong = long2 - long1;
  const dlat = lat2 - lat1;

  let ans =
    Math.pow(Math.sin(dlat / 2), 2) +
    Math.cos(lat1) * Math.cos(lat2) * Math.pow(Math.sin(dlong / 2), 2);
  ans = 2 * Math.asin(Math.sqrt(ans));
  // Radius of Earth in Kilometers, R = 6371
  // Use R = 3956 for miles
  const R = 6371e3;
  // Calculate the result
  ans = ans * R;

  return parseInt(ans);
}

export const GeoLocationProvider = ({ children }) => {
  const user = useSelector(Selectors.selectUser);
  const geolocationValues = useGeolocated({
    positionOptions: {
      enableHighAccuracy: true,
      watchPosition: true,
    },
    userDecisionTimeout: 5000,
    watchLocationPermissionChange: true,
  });
  const { isGeolocationEnabled, positionError, coords } = geolocationValues;
  const [gpsCaptureStatus, setGpsCaptureStatus] = useState('Reading GPS...');
  const [lastLocation, setLastLocation] = useState(null);
  const initialRender = useRef(true);
  const dispatch = useDispatch();

  const [storedAddesses, setStoredAddesses] = useState( () => localStorage.getItem('storedAddesses') ? JSON.parse(localStorage.getItem('storedAddesses')) : []);

  /**
   * @param {*} dispatch The dispatch hook to call the save employee location action
   * @param {*} user The user object from redux
   * @param {*} coords The coordinates to save
   * @param {*} isGeolocationEnabled The GPS status
   * @returns The formatted address got from the API based on the coordinates
   */
  const saveLocation = async(dispatch, user, coords, isGeolocationEnabled) => {
    const locationData = {
      employeeId: user.customerId,
      customerId: user.ownerId ? user.ownerId : user.customerId,
      address: {
        type: 'Point',
        coordinates: [coords.latitude, coords.longitude],
      },
      formattedAddress: user.formattedAddress,
      gpsEnabled: isGeolocationEnabled,
    };
    const storedAddress = searchAddress(coords);

    if( storedAddress ) locationData.formattedAddress = storedAddress;
    else  locationData.formattedAddress = await getAddessWithCoordinates(coords.latitude, coords.longitude)

    dispatch(setEmployeeLocation(locationData));
    return locationData.formattedAddress;
  };

  /**
   * @param {Object} coordinates The coordinates to search for based on lat and lng
   * @returns the address if it exists in the stored addresses, otherwise returns null
   */
  const searchAddress = (coordinates) => {
    const { latitude: lat, longitude: lng } = coordinates;
    const address = storedAddesses.find((item) => item.lat === lat.toFixed(4) && item.lng === lng.toFixed(4) );
    return address ? address.address : null;
  }

  /**
   * @param {Object} address If the address is not null and not exists in the stored addresses, then it will be added
   */
  const saveAddress = (address) => {
    if (address) {
      const { latitude: lat, longitude: lng } = coords;
      const addressExists = searchAddress(coords);
      if (!addressExists)
        setStoredAddesses([...storedAddesses, {
          lat: lat.toFixed(4),
          lng: lng.toFixed(4),
          address,
          coords: {
            latitude: lat,
            longitude: lng
          }  
        }]);
    }
  }

  // This useEffect is used to keep track of the initial render, and we add a delay of 1 second because
  // isGeolocationEnabled is true by default, and then updates acording to what the GPS status is.
  // This is to avoid showing the error message on the initial render if the GPS is disabled.
  useEffect(() => {
    const timer = setTimeout(() => {
      initialRender.current = false;
    }, 5000);
    return () => clearTimeout(timer); // cleanup on unmount
  }, []);

  useEffect(() => {
    if (!isGeolocationEnabled) {
      setGpsCaptureStatus('Please enable GPS to use this feature');
      if (!initialRender.current) toast.error('You disabled your GPS');
    }
  }, [isGeolocationEnabled]);

  useEffect(() => {
    (async () => {
        console.log('coords', coords, user);
        if (!coords) return; // if coords is null, don't save location
        if (!user.token) return; // if not logged in, don't save location
        if (!lastLocation) {
          const addess = await saveLocation(dispatch, user, coords, isGeolocationEnabled);
          if (addess) saveAddress(addess);
          setLastLocation(coords);
          return;
        }
        const distanceMeters = distance(
          coords.latitude,
          coords.longitude,
          lastLocation.latitude,
          lastLocation.longitude
        );
        console.log('distanceMeters', distanceMeters);
        if (distanceMeters < 150) return; // if distance is less than 150 meters, don't save location
        const address = await saveLocation(dispatch, user, coords, isGeolocationEnabled);
        if (address) saveAddress(address);
        setLastLocation(coords);
      }
    )()
    
  }, [coords]);

  // Position code errors https://developer.mozilla.org/en-US/docs/Web/API/GeolocationPositionError
  // 1: PERMISSION_DENIED
  // 2: POSITION_UNAVAILABLE
  // 3: TIMEOUT
  useEffect(() => {
    if (positionError && positionError.code !== 1) {
      if (positionError.code === 3) {
        toast.error('GPS Timeout');
        setGpsCaptureStatus('Took too long to fetch location');
      } else {
        toast.error('GPS error');
        setGpsCaptureStatus(`${positionError.code} : ${positionError.message}`);
      }
    }
  }, [positionError]);

  /**
   * Update the stored addresses in localStorage
   */
  useEffect(() => {
    localStorage.setItem('storedAddesses', JSON.stringify(storedAddesses));
  }, [storedAddesses]);

  return (
    <GeoLocationContext.Provider
      value={{
        ...geolocationValues,
        gpsCaptureStatus,
      }}
    >
      {children}
    </GeoLocationContext.Provider>
  );
};
