import actionConstants from '../constants/ActionConstants';

const initialState = {
  error: '',
  isfetching: false,
  success: '',
  isSuccess: false,
  supplierData: null,
  editSuccess: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionConstants.INVENTORY_ADD_SUPPLIER_REQUEST:
      return {
        ...state,
        isfetching: true,
        isSuccess: false,
      };
    case actionConstants.INVENTORY_ADD_SUPPLIER_SUCCESS:
      return {
        ...state,
        isfetching: false,
        success: action.message,
        isSuccess: true,
        supplierData: action.payload,
      };
    case actionConstants.INVENTORY_ADD_SUPPLIER_FAILURE:
      return {
        ...state,
        error: action.message,
        isfetching: false,
      };
    case actionConstants.INVENTORY_ADD_SUPPLIER_RESET:
      return {
        ...initialState,
      };
    /** Edit Supplier Reducer */
    case actionConstants.INVENTORY_EDIT_SUPPLIER_REQUEST:
      return {
        ...state,
        isfetching: true,
        isSuccess: false,
      };
    case actionConstants.INVENTORY_EDIT_SUPPLIER_FAILURE:
      return {
        ...state,
        error: action.message,
        isfetching: false,
      };
    case actionConstants.INVENTORY_EDIT_SUPPLIER_SUCCESS:
      return {
        ...state,
        supplierData: action.payload,
        success: action.message,
        editSuccess: action.message,
      };

    case actionConstants.INVENTORY_EDIT_SUPPLIER_CLEAR:
      return {
        ...state,
        editSuccess: '',
      };
    case actionConstants.INVENTORY_EDIT_SUPPLIER_RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};
