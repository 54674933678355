import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import setSelectedCustomer, {
  resetReportList,
  setReportName,
  resetSelectedCustomer,
} from '../../redux/actions/setSelectedCustomer';
import getClientOrOwnerDetailsAction from '../../redux/actions/getClientOrOwnerDetails';
import {
  createReport,
  createReportClear,
  createReportInspection,
} from '../../redux/actions/reportCreate';
import { displayToast } from '../../_helpers/commonFunctions';
import { clearJobDetails } from '../../redux/actions/jobDetails';
import ImageUploadModalBody from '../../layouts/ImageBuildingInfoLayout/ImageUploadModalBody';
import Modal from '../../components/Modal';
import {
  AddNewReportModalBody,
  AddNewReportModalFooter,
} from '../../components/AddNewReport';
import {
  ConfirmNewOrCopyBody,
  ConfirmNewOrCopyFooter,
} from '../../components/ConfirmNewOrCopy';

import {
  ReportSelectionModalBody,
  ReportSelectionModalFooter,
} from '../../components/ReportSelectionModal';
import {
  updateReport,
  clearSuccess,
  updateReportClear,
} from '../../redux/actions/reportUpdate';
import uploadImageAction from '../../redux/actions/uploadImage';
import clearStateAction from '../../redux/actions/clearState';
import validationMessage from '../../_helpers/validator/validationMessage';
import Loader from '../../components/Loader';
import { reportConstants } from '../../libs/constants/reportConstants';
import formReportDetails from '../../redux/actions/addReportCat';
import toasterMessages from '../../libs/constants/toasterMessages';
import {
  getReportTemplateList,
  clearReportTemplateSuccess,
} from '../../redux/actions/getReportTemplate';
import {
  RECURRING_INSPECTION_VALID_FREQUENCIES as FREQUENCIES,
  googleMaps,
} from '../../utils/Constants';
import { getJobIdForIncompleteReport } from '../../_helpers/offlineEnhanced';
import {
  fillJobDetails,
  setUpdatedAnswersReportData,
} from '../../redux/actions/offlineEnhanced';
import BuildingInfo from '../BuildingInfo';
import ImageUploadBuildingInfo from '../ImageUploadBuildingInfo';
import SystemInfo from '../SystemInfo';
import newGenericReport from '../GenericReports/api/newGenericReport';
import config from '../../config';
import { EVENTS, saveEvent } from '../../utils/eventAnalytics';

const { GENERIC_CATEGORIES } = config;

export class ClientDetailsReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      typeValue: null,
      visible: false,
      isShowDetails: false,
      imageUpload: false,
      selectedImage: null,
      validationErrs: null,
      isFrequencyModalVisible: false,
      showLoader: false,
      reDirect: false,
      validFrequencies: [],
      frequency: props.reportCat.frequency || null,
      reportTemplateList: [],
      catValue: null,
      reportTemplate: null,
      isCopyModal: false,
      reportSelectionModal: false,
      selectedReport: null,
      isBuildingInfo: false,
      isImages: false,
      isSystemInfo: false,
    };
  }

  componentDidUpdate(prevProps) {
    const { isSuccess, uploadedImageDetails } = this.props.uploadImage;
    const { isSuccessReport, isErrorReport, getReportTemplateData } =
      this.props;
    if (isSuccessReport && prevProps.isSuccessReport !== isSuccessReport) {
      this.props.createReportClear();
      this.props.updateReportClear();
      this.handleFormVisibility();
    }
    if (
      getReportTemplateData.success &&
      prevProps.getReportTemplateData.success !== getReportTemplateData.success
    ) {
      this.props.clearReportTemplateSuccess();
      const reportTemplateList = this.getReportTempList(
        this.props.reportCat.catValue
      );
      this.setState({
        reportTemplateList,
      });
    }
    if (isErrorReport) {
      displayToast(isErrorReport, 'error');
      this.props.createReportClear();
    }

    if (isSuccess && !this.state.isImages) {
      this.props.clearStateAction();
      const { filename, mimetype, size } = uploadedImageDetails.file;

      const imageObj = {
        reportCategory: this.props.reportCat.catValue,
        createdFor: this.props.reportCat.customerValue,
        reportType: this.props.reportCat.typeValue,
        createdForName: this.props.clientInfo.businessName,
        reportId: this.props.reportDetails._id,
        images: [
          {
            mimeType: mimetype,
            fileSize: size,
            title: filename,
            url: uploadedImageDetails.Location,
            fileName: filename,
            description: reportConstants.imageDescription,
            personName: this.props.clientInfo.businessName,
          },
        ],
      };
      this.props.updateReport(imageObj);
    }
    if (
      this.props.reportUpdatedData.isSuccess &&
      prevProps.reportUpdatedData.isSuccess !==
        this.props.reportUpdatedData.isSuccess
    ) {
      const { updateReportData } = this.props.reportUpdatedData;
      this.props.clearSuccess();
      if (updateReportData && updateReportData.status === 'Completed') {
        this.props.updateReportClear();
        this.props.clearJobDetails();
        displayToast(toasterMessages.finishReport, 'success');
        this.props.history.push('reports/new');
      } else {
        displayToast(toasterMessages.success, 'success');
        this.setState({ showLoader: false, isImageModalVisible: false });
        this.resetState();
      }
    }
  }

  resetState = () => {
    this.setState({
      imageUrl: null,
      selectedImage: null,
      commentValue: null,
      idOfImage: null,
      validationErrs: null,
    });
  };

  onSave = () => {
    const { selectedImage, isImageModalVisible } = this.state;
    const validationErrs = { ...this.state.validationErrs };
    if (isImageModalVisible) {
      if (selectedImage) {
        this.setState({
          showLoader: true,
        });
        const image = new FormData();
        image.append('image', selectedImage);
        this.props.uploadImageAction(image);
      } else {
        validationErrs.image = validationMessage.imageName;
        this.setState({ validationErrs });
      }
    }
  };

  componentDidMount() {
    if (this.props.reportCat.customerValue) {
      this.props.getClientOrOwnerDetailsAction(
        this.props.reportCat.customerValue
      );
    }
    if (
      this.props.history.location &&
      this.props.history.location.state &&
      this.props.history.location.state.isModalVisible &&
      this.props.reportCat.typeValue === reportConstants.inspection
    ) {
      const validFrequencies = FREQUENCIES.map(({ label }) => label);
      this.props.getReportTemplateList(1, 18);
      this.setState({
        isFrequencyModalVisible: true,
        validFrequencies,
      });
    }
    if (this.props.offlineMode.isOffline) {
      const reportTemplateList = this.getReportTempList(
        this.props.reportCat.catValue
      );
      this.setState({
        reportTemplateList,
      });
    }
    this.props.setReportName(null);
    this.props.resetSelectedCustomer();
  }

  getReportTempList = (category) => {
    const { reportTemplateData } = this.props;
    const reportTemplates = reportTemplateData.find(
      (report) => report._id === category
    );
    if (reportTemplates) {
      return reportTemplates.reportDetails;
    }
    return [];
  };

  modelHide = () => {
    this.setState({
      visible: false,
    });
  };

  handelImage = (e) => {
    e.preventDefault();
    const { reportCat, clientInfo } = this.props;
    this.props.setSelectedCustomer(
      reportCat.customerValue,
      reportCat.typeValue,
      clientInfo.businessName
    );
    this.props.resetReportList();
    this.props.setReportName('reportClientDetail');
    this.setState({
      isBuildingInfo: false,
      isImages: true,
      isSystemInfo: false,
    });
  };

  handelSystemInfo = (e) => {
    e.preventDefault();
    const { reportCat, clientInfo } = this.props;
    this.props.setSelectedCustomer(
      reportCat.customerValue,
      reportCat.typeValue,
      clientInfo.businessName
    );
    this.props.resetReportList();
    this.props.setReportName('reportClientDetail');
    this.setState({
      isBuildingInfo: false,
      isImages: false,
      isSystemInfo: true,
    });
  };

  handelBuildingInfo = (e) => {
    e.preventDefault();
    const { reportCat, clientInfo } = this.props;
    this.props.setSelectedCustomer(
      reportCat.customerValue,
      reportCat.typeValue,
      clientInfo.businessName
    );
    this.props.resetReportList();
    this.props.setReportName('reportClientDetail');
    this.setState({
      isBuildingInfo: true,
      isImages: false,
      isSystemInfo: false,
    });
  };

  handelClick = () => {
    const { reportCat, clientInfo } = this.props;
    const reportCategory = reportCat.catValue || '';
    const createdFor = reportCat.customerValue || '';
    const createdForName = clientInfo.businessName || '';
    const reportType = reportCat.typeValue || '';
    let formData = { reportCategory, createdFor, createdForName, reportType };
    saveEvent(EVENTS.REPORTS_NEW_CLIENT_DETAIL_NEXT, formData);

    if (reportCat.typeValue !== reportConstants.inspection) {
      if (
        this.props.history.location &&
        this.props.history.location.state &&
        this.props.history.location.state.jobId
      ) {
        formData.jobId = this.props.history.location.state.jobId;
      }

      this.props.createReport(formData);
      this.props.clearJobDetails();
    } else {
      this.handleClickDefaultReport();
    }
  };

  handelReportCreate = () => {
    const { reportCat, clientInfo, offlineMode } = this.props;
    const { isOffline, questionJsonDefaultReports } = offlineMode;
    let formData = {};
    let reportEntryId = '';
    if (reportCat.typeValue !== reportConstants.inspection) {
      formData = {
        reportCategory: reportCat.catValue,
        createdFor: reportCat.customerValue,
        createdForName: clientInfo.businessName,
        reportType: reportCat.typeValue,
      };
    }
    if (reportCat.typeValue === reportConstants.inspection) {
      const frequencyObject = FREQUENCIES.find(
        (freq) => freq.label === this.state.frequency
      );
      formData = {
        reportCategory: reportCat.catValue,
        createdFor: reportCat.customerValue,
        createdForName: clientInfo.businessName,
        frequency:
          this.state.selectedReport === 'Default'
            ? reportCat.frequency
            : frequencyObject.value,
        reportType: reportCat.typeValue,
      };
      if (reportCat.price !== undefined || reportCat.price !== null) {
        formData.jobDetails = {
          price: reportCat.price,
        };
      }
      if (this.state.selectedReport === 'Dynamic') {
        formData.reportJson = this.state.reportTemplate.reportJson;
        formData.parsedJson = this.state.reportTemplate.parsedJson;
        formData.templateType = this.state.reportTemplate.type;
      }
    }
    if (
      this.props.history.location &&
      this.props.history.location.state &&
      this.props.history.location.state.jobId
    ) {
      formData.jobId = this.props.history.location.state.jobId;
    }
    if (
      this.props.history.location &&
      this.props.history.location.state &&
      this.props.history.location.state.reportEntry
    ) {
      reportEntryId = this.props.history.location.state.reportEntry;
    }
    if (isOffline) {
      if (
        reportCat.typeValue === reportConstants.inspection &&
        this.state.selectedReport === 'Default'
      ) {
        const questionJson =
          questionJsonDefaultReports.questionJsonDefaultReports[
            formData.reportCategory
          ];
        const correctStaticReportQuestions = questionJson && questionJson.find(((staticReport) => {
          return staticReport.createdFor === formData.createdFor;
        })) || null;
        formData.questionJson = correctStaticReportQuestions?.reportData.questionJson;
        formData.answers = correctStaticReportQuestions ? correctStaticReportQuestions.answers : {};
        formData.templateType = 'Static';
      }
      this.props.fillJobDetails(reportEntryId, formData);
      this.props.setUpdatedAnswersReportData({
        ...formData,
        _id: reportEntryId,
      });
      this.handleFormVisibility();
    } else if (
      reportCat.typeValue === reportConstants.inspection &&
      this.state.selectedReport === 'Default'
    ) {
      const isGeneric = GENERIC_CATEGORIES?.includes(formData.reportCategory);
      if(isGeneric && formData.reportType == 'Inspection') {
        newGenericReport(formData, this.props.history);
      } else {
        this.props.createReportInspection(formData);
      }
      this.props.clearJobDetails();
    } else {
      this.props.createReport(formData);
      this.props.clearJobDetails();
    }
  };

  openModal = () => {
    this.setState({ isImageModalVisible: true });
  };

  handleOnChange = (name, value) => {
    this.setState({
      [name]: value,
    });
  };

  handleRedirectionToMaps = () => {
    const { clientInfo } = this.props;
    const { formattedAddress } = clientInfo;
    window.open(googleMaps + formattedAddress.replace('/', '-'));
  };

  handleFormVisibility = () => {
    this.setState({
      visible: false,
      isShowDetails: true,
      reDirect: true,
    });
  };

  handleChange = (event) => {
    if (!event.target.files[0].type.includes('image')) {
      displayToast('Please select image files only', 'error');
      return;
    }
    if (event.target.files) {
      this.setState({ selectedImage: event.target.files[0] });
    }
  };

  CloseImageModal = () => {
    this.setState({ isImageModalVisible: false });
    this.resetState();
  };

  handleClickSave = () => {
    const { frequency, reportTemplate } = this.state;
    const { reportCat } = this.props;
    const formData = {
      catValue: reportCat.catValue,
      customerValue: reportCat.customerValue,
      reportTemplate,
      frequency: frequency || reportCat.frequency,
      typeValue: reportConstants.inspection,
      price: reportCat.price,
    };
    this.props.formReportDetails(formData);
    this.handelReportCreate();
  };

  handleClickCrossCopyModal = () => {
    this.setState({
      isCopyModal: false,
    });
  };

  handleClickNewReport = () => {
    const validFrequencies = FREQUENCIES.map(({ label }) => label);
    this.props.getReportTemplateList(1, 18);
    if (this.state.selectedReport === 'Default') {
      saveEvent(EVENTS.REPORTS_NEW_OR_COPY_NEW, {});
      this.handleClickSave();
    } else {
      this.setState({
        isCopyModal: false,
        isFrequencyModalVisible: true,
        validFrequencies,
      });
    }
  };

  handleClickDefaultReport = () => {
    this.setState({
      reportSelectionModal: false,
      isCopyModal: true,
      selectedReport: 'Default',
    });
  };

  handleClickDynamicReport = () => {
    this.setState({
      reportSelectionModal: false,
      isCopyModal: true,
      selectedReport: 'Dynamic',
    });
  };

  handleClickCopyReport = () => {
    if (this.props.offlineMode.isOffline) {
      displayToast('Cell or internet service required', 'error');
    } else if (
      this.props.history.location &&
      this.props.history.location.state &&
      this.props.history.location.state.jobId
    ) {
      this.props.history.push(reportConstants.completeReportsUrl, {
        jobId: this.props.history.location.state.jobId,
        selectedReport: this.state.selectedReport,
      });
    } else {
      this.props.history.push(reportConstants.completeReportsUrl, {
        selectedReport: this.state.selectedReport,
      });
    }
  };

  handleClickCross = () => {
    this.setState({
      isFrequencyModalVisible: false,
    });
  };

  componentWillUnmount() {
    this.props.setReportName(null);
    this.props.resetSelectedCustomer();
  }

  render() {
    const { reportCat, clientInfo, offlineMode } = this.props;
    const {
      selectedImage,
      validationErrs,
      reDirect,
      validFrequencies,
      frequency,
      reportTemplate,
      reportTemplateList,
      isBuildingInfo,
      isImages,
      isSystemInfo,
    } = this.state;
    if (reDirect) {
      if (
        offlineMode.isOffline &&
        this.props.history.location &&
        this.props.history.location.state &&
        this.props.history.location.state.reportEntry
      ) {
        this.props.history.push(
          `reportDetail/${this.props.history.location.state.reportEntry}`,
          {
            selectedReport: this.state.selectedReport,
          }
        );
      } else {
        this.props.history.push(
          `reportDetail/${this.props.reportDetails._id}`,
          {
            selectedReport: this.state.selectedReport,
          }
        );
      }
    }
    if (this.props.clientInfoFetch) {
      return (
        <div className="loader-container">
          <div className="loader"></div>
        </div>
      );
    }
    return (
      <div>
        {this.state.visible && (
          <div id="overlayModal">
            <div
              className="modal fade show"
              id="add-system"
              style={{ display: 'block', overflow: 'auto' }}
              tabIndex={-1}
              role="dialog"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div
                className="modal-dialog modal-dialog-centered"
                role="document"
              >
                <div className="modal-content">
                  <div className="modal-header">
                    <h5
                      className="modal-title"
                      id="exampleModalLabel"
                      style={{ color: 'white', fontWeight: 'normal' }}
                    >
                      <FormattedMessage
                        id="START REPORT"
                        defaultMessage="START REPORT"
                      />
                    </h5>
                    <button
                      type="button"
                      onClick={this.modelHide}
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span
                        style={{ color: 'white', fontWeight: 'normal' }}
                        aria-hidden="true"
                      >
                        ×
                      </span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <div>
                      {
                        <FormattedMessage
                          id="Do you want to open a new report ?"
                          defaultMessage="Do you want to open a new report ?"
                        />
                      }
                    </div>
                    <div className="buttonWraper">
                      <button
                        onClick={this.handelReportCreate}
                        className="btn btn_success btn_table"
                      >
                        <FormattedMessage id="Create" defaultMessage="Create" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {this.state.isFrequencyModalVisible &&
        this.state.selectedReport !== 'Default' ? (
          <Modal
            modalHeading={'ADD NEW REPORT'}
            ModalBody={AddNewReportModalBody}
            ModalFooter={AddNewReportModalFooter}
            handleClickCross={this.handleClickCross}
            ModalBodyProps={{
              validFrequencies,
              handleOnChange: this.handleOnChange,
              frequency,
              reportTemplate,
              reportTemplateList,
              selectedReport: this.state.selectedReport,
            }}
            ModalFooterProps={{
              isSaveVisible:
                this.state.selectedReport === 'Dynamic'
                  ? frequency && reportTemplate
                  : frequency,
              handleClickSave: this.handleClickSave,
              // selectedReport: this.state.selectedReport
            }}
          />
        ) : null}
        {this.state.isCopyModal ? (
          <Modal
            modalHeading={'ADD NEW REPORT'}
            ModalBody={ConfirmNewOrCopyBody}
            ModalFooter={ConfirmNewOrCopyFooter}
            handleClickCross={this.handleClickCrossCopyModal}
            ModalFooterProps={{
              handleClickNewReport: this.handleClickNewReport,
              handleClickCopyReport: this.handleClickCopyReport,
            }}
          />
        ) : null}
        {this.state.reportSelectionModal ? (
          <Modal
            modalHeading={'ADD NEW REPORT'}
            ModalBody={ReportSelectionModalBody}
            ModalFooter={ReportSelectionModalFooter}
            handleClickCross={() => {
              this.setState({
                reportSelectionModal: false,
              });
            }}
            ModalFooterProps={{
              handleClickDefaultReport: this.handleClickDefaultReport,
              handleClickDynamicReport: this.handleClickDynamicReport,
            }}
          />
        ) : null}
        {this.props.reportUpdatedData.isfetching ||
        this.props.uploadImage.isfetching ? (
          <Loader />
        ) : (
          this.state.isImageModalVisible && (
            <Modal
              modalHeading="ADD IMAGE"
              ModalBodyProps={{
                handleChange: this.handleChange,
                selectedImage,
                onSave: this.onSave,
                validationErrs,
              }}
              handleClickCross={this.CloseImageModal}
              ModalBody={ImageUploadModalBody}
            />
          )
        )}
        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center top-search-feild">
          <h1 className="flex space-x-4 justify-center mb-3 md:mb-0 inspection-title">
            <span>
              <FormattedMessage
                id={reportCat.catValue}
                defaultMessage={reportCat.catValue}
              />
            </span>{' '}
            <span className="seperate-line">|</span>
            <span>
              <FormattedMessage
                id={reportCat.typeValue}
                defaultMessage={reportCat.typeValue}
              />
            </span>
          </h1>
        </div>
        <div className="report-section">
          <div className="user-name-add-inn">
            <div className="user-name-fld">
              <div className="user-img">
                <img src="./img/img-placeholder.png" alt="" />
              </div>
              <div className="user-name">
                <p style={{ paddingRight: '54px', wordBreak: 'break-all' }}>
                  {clientInfo.businessName}
                </p>
                <div className="work-list-icon">
                  <div className="list-icon-wrap">
                    <div className="user-house sprite-icon-before1">
                      <div className="house-list-wrap">
                        <div className="house-list">
                          <ul>
                            <li>
                              <a href="/" onClick={this.handelBuildingInfo}>
                                <FormattedMessage
                                  id="Building Information"
                                  defaultMessage="Building Information"
                                />
                              </a>
                            </li>
                            <li>
                              <a href="/" onClick={this.handelSystemInfo}>
                                <FormattedMessage
                                  id="System Information"
                                  defaultMessage="System Information"
                                />
                              </a>
                            </li>
                            <li>
                              <a href="/" onClick={this.handelImage}>
                                <FormattedMessage
                                  id="Images"
                                  defaultMessage="Images"
                                />
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    {this.state.isShowDetails ? (
                      <div
                        className="imag-upload sprite-icon1"
                        onClick={this.openModal}
                        data-toggle="modal"
                        data-target="#img-upload"
                      ></div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
            <div className="user-address-fld">
              <div className="user-address-fld-inn">
                <div className="ofc-no sprite-icon-before">
                  <p>
                    <FormattedMessage
                      id="Office Number:"
                      defaultMessage="Office Number:"
                    />{' '}
                    <span>
                      {clientInfo.officePhone ? clientInfo.officePhone : 'NA'}
                    </span>
                  </p>
                </div>
                <div className="ofc-cell-no ofc-no sprite-icon-before">
                  <p>
                    <FormattedMessage
                      id="Cell Number:"
                      defaultMessage="Cell Number:"
                    />
                    <span>
                      {clientInfo.cellPhone ? clientInfo.cellPhone : 'NA'}
                    </span>
                  </p>
                </div>
                <div className="ofc-addr sprite-icon-before ">
                  <p style={{ display: 'flex' }}>
                    <FormattedMessage id="Address:" defaultMessage="Address:" />{' '}
                    <span className="pad-rt-12">
                      <div
                        style={{
                          display: 'inline-block',
                          wordBreak: 'break-word',
                        }}
                      >
                        { `${clientInfo.businessName}, ${clientInfo.formattedAddress}` }
                      </div>
                    </span>
                  </p>
                </div>
              </div>
              <div
                className="address-icon sprite-icon1"
                onClick={this.handleRedirectionToMaps}
              />
            </div>
          </div>
        </div>
        {isBuildingInfo || isImages || isSystemInfo ? (
          <div className="mr-top-24">
            <h1 className="head-back" style={{ cursor: 'pointer' }}>
              <span
                className="back-arrow sprite-icon"
                onClick={() => {
                  this.setState({
                    isBuildingInfo: false,
                    isImages: false,
                    isSystemInfo: false,
                  });
                  this.props.setReportName(null);
                  this.props.resetSelectedCustomer();
                }}
              />{' '}
            </h1>
            {isBuildingInfo && <BuildingInfo isRenderedInReports />}
            {isImages && <ImageUploadBuildingInfo isRenderedInReports />}
            {isSystemInfo && <SystemInfo isRenderedInReports />}
          </div>
        ) : (
          !this.state.isShowDetails && (
            <button
              onClick={this.handelClick}
              className="btn btn_success btn_table mt-4 float-rt"
            >
              <FormattedMessage id="Next" defaultMessage="Next" />
            </button>
          )
        )}
        <div className='end-space'></div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { offlineMode, addReportCat } = state;
  const { isOffline, customersList, reportTemplateList } = offlineMode;
  const reportId = window.location.pathname.split('/')[2];

  const jobId = getJobIdForIncompleteReport(
    {
      ...state.offlineMode.reportListByJobIds.reportListByJobIds,
      ...state.offlineMode.reportListByCustomer.reportListByCustomer,
    },
    reportId
  );

  return {
    messageReport: state.reportCreate.reportMessage,
    isSuccessReport: state.reportCreate.isSuccess,
    isErrorReport: state.reportCreate.error,
    reportCat: addReportCat.reportCatData,
    clientInfo: isOffline
      ? customersList[addReportCat.reportCatData.customerValue]
      : state.getClientOrOwnerDetails.clientOrOwnerData,
    clientInfoFetch: isOffline
      ? false
      : state.getClientOrOwnerDetails.isfetching,
    uploadImage: state.uploadImage,
    reportDetails: state.reportCreate.createReportData,
    reportUpdatedData: state.offlineMode.isOffline
      ? jobId[0] && jobId[0].jobId
        ? { updateReportData: state.offlineMode.reportEntries[jobId[0].jobId] }
        : { updateReportData: state.offlineMode.reportEntries[reportId] }
      : state.reportUpdate,
    addImageDetails: state.addImageDetails,
    getReportTemplateData: state.getReportTemplate,
    reportTemplateData: isOffline
      ? Object.values(reportTemplateList.reportTemplateList)
      : state.getReportTemplate.reportTemplateData,
    offlineMode: state.offlineMode,
  };
};

ClientDetailsReport.propTypes = {
  clientInfo: PropTypes.object,
  typeValue: PropTypes.string,
  catValue: PropTypes.string,
  customerValue: PropTypes.string,
  setSelectedCustomer: PropTypes.func,
  history: PropTypes.object.isRequired,
  createReport: PropTypes.func,
  clearJobDetails: PropTypes.func,
  messageReport: PropTypes.string,
  isError: PropTypes.string,
  reportCat: PropTypes.object,
  getClientOrOwnerDetailsAction: PropTypes.func,
  resetReportList: PropTypes.func,
  clientInfoFetch: PropTypes.bool,
  isSuccess: PropTypes.bool,
  createReportClear: PropTypes.func,
  updateReportClear: PropTypes.func,
  uploadImageAction: PropTypes.func,
  clearStateAction: PropTypes.func,
  uploadImage: PropTypes.object,
  isSuccessReport: PropTypes.bool,
  isErrorReport: PropTypes.string,
  reportDetails: PropTypes.object,
  updateReport: PropTypes.func,
  reportUpdatedData: PropTypes.object,
  clearSuccess: PropTypes.func,
  createReportInspection: PropTypes.func,
  getReportTemplateList: PropTypes.func,
  clearReportTemplateSuccess: PropTypes.func,
  getReportTemplateData: PropTypes.object,
  reportTemplateData: PropTypes.array,
  formReportDetails: PropTypes.func,
  offlineMode: PropTypes.object,
  fillJobDetails: PropTypes.func,
  setUpdatedAnswersReportData: PropTypes.func,
  setReportName: PropTypes.func,
  resetSelectedCustomer: PropTypes.func,
};

export default connect(mapStateToProps, {
  setSelectedCustomer,
  createReport,
  createReportInspection,
  uploadImageAction,
  clearStateAction,
  getClientOrOwnerDetailsAction,
  resetReportList,
  clearJobDetails,
  createReportClear,
  updateReportClear,
  updateReport,
  clearSuccess,
  getReportTemplateList,
  formReportDetails,
  clearReportTemplateSuccess,
  fillJobDetails,
  setUpdatedAnswersReportData,
  setReportName,
  resetSelectedCustomer,
})(ClientDetailsReport);
