import { useIndexedDB } from 'react-indexed-db';

export default (arrayList, keyNameValue, valueNameValue, initialData) => {
  let objectList = {};
  if (initialData) {
    objectList = {
      ...initialData,
    };
  }
  arrayList.forEach((listItem) => {
    if (keyNameValue && !valueNameValue) {
      objectList[listItem[keyNameValue]] = objectList[listItem[keyNameValue]]
        ? [...objectList[listItem[keyNameValue]], listItem]
        : [listItem];
    } else if (valueNameValue && keyNameValue) {
      objectList[listItem[keyNameValue]] = objectList[listItem[keyNameValue]]
        ? {
            ...objectList[listItem[keyNameValue]],
            [listItem._id]: objectList[listItem[keyNameValue]][listItem._id]
              ? [...objectList[listItem[keyNameValue]][listItem._id], listItem]
              : [listItem],
          }
        : {
            [listItem._id]: [listItem],
          };
      objectList[listItem[keyNameValue]][listItem._id] = Array.from(
        new Set(objectList[listItem[keyNameValue]][listItem._id])
      );
    } else {
      objectList[listItem._id] = { ...listItem };
    }
  });
  return objectList;
};

export const getJobIds = (jobList) =>
  Object.values(jobList).map((job) => job.createdFor._id);

export const getJobIdForIncompleteReport = (jobList, reportId) =>
  Object.values(jobList).filter((job) => job._id === reportId);

// key reportId will be generated only for incomplete reports.
// key frequency will be generated for recurring inspections.
const report = {
  reportCategory: '',
  createdFor: '',
  createdForName: '',
  reportType: '',
  // jobId: '',
  isFinished: false,
  jobDetails: {},
  parts: [],
  labours: [],
  // finishReport: {},
  // notes: {
  //   inspectorNotes: [],
  //   customerNotes: [],
  // },
  // answers: {},
  // missedItems: {},
  reportJson: [],
  createdAt: new Date().getTime(),
};

const createReportEntryForJob = (jobDetail) => {
  let reportEntry = {
    ...report,
    reportCategory: jobDetail.reportCategory,
    createdFor: jobDetail.createdFor._id,
    createdForName: jobDetail.createdFor.businessName,
    reportType: jobDetail.type,
    jobId: jobDetail._id,
  };
  if (jobDetail.type !== 'Inspection') {
    const jobDetails = {};
    if (jobDetail.invoiceNo) jobDetails.invoiceNo = jobDetail.invoiceNo;
    if (jobDetail.description) jobDetails.description = jobDetail.description;
    if (jobDetail.poNumber) jobDetails.poNumber = jobDetail.poNumber;
    if (jobDetail.noteId) reportEntry.noteId = jobDetail.noteId;
    if (jobDetail.images) reportEntry.images = jobDetail.images;

    jobDetails.terms = 'Fixed Price';
    reportEntry = {
      ...reportEntry,
      jobDetails,
    };
  }
  if (jobDetail.type === 'Inspection') reportEntry.parsedJson = [];
  if (jobDetail.quoteApprovalData) {
    if (jobDetail.quoteApprovalData.jobDetails) {
      reportEntry = {
        ...reportEntry,
        jobDetails: { ...jobDetail.quoteApprovalData.jobDetails },
      };
    }
    reportEntry = {
      ...reportEntry,
      parts: jobDetail.quoteApprovalData.parts || [],
      labours: jobDetail.quoteApprovalData.labours || [],
    };
  }

  return reportEntry;
};

const createReportEntryForIncompleteReport = (reportDetail) => {
  const reportEntry = {
    ...report,
    reportCategory: reportDetail.reportCategory,
    createdFor: reportDetail.createdFor,
    createdForName: reportDetail.createdForName,
    reportType: reportDetail.reportType,
    labours: reportDetail.labours,
    parts: reportDetail.parts,
    reportId: reportDetail._id,
  };
  if (reportDetail.reportType === 'Inspection') reportEntry.parsedJson = [];
  if (reportDetail.jobId) reportEntry.jobId = reportDetail.jobId;
  if (reportDetail.jobDetails) reportEntry.jobDetails = reportDetail.jobDetails;
  if (reportDetail.finishReport)
    reportEntry.finishReport = reportDetail.finishReport;
  if (reportDetail.frequency) reportEntry.frequency = reportDetail.frequency;
  if (reportDetail.missedItems)
    reportEntry.missedItems = reportDetail.missedItems;
  if (reportDetail.answers) reportEntry.answers = reportDetail.answers;
  if (reportDetail.parsedJson) reportEntry.parsedJson = reportDetail.parsedJson;
  if (reportDetail.reportJson) reportEntry.reportJson = reportDetail.reportJson;
  if (reportDetail.questionJson)
    reportEntry.questionJson = reportDetail.questionJson;
  if (reportDetail.templateType)
    reportEntry.templateType = reportDetail.templateType;
  if (reportDetail.startDate) reportEntry.startDate = reportDetail.startDate;
  if (reportDetail.noteId) reportEntry.noteId = reportDetail.noteId;
  if (reportDetail.images) reportEntry.images = reportDetail.images;

  return reportEntry;
};

export const createReportEntries = (
  jobList,
  reportListByCustomer,
  reportListByJobId
) => {
  const reportEntries = {};
  const allReports = [
    ...Object.values(jobList),
    ...Object.values(reportListByCustomer),
    ...Object.values(reportListByJobId),
  ];
  allReports.forEach((reportDetail) => {
    if (
      Object.prototype.hasOwnProperty.call(reportDetail, 'quickBookDetails')
    ) {
      // Checks if it is an incomplete report
      if (Object.prototype.hasOwnProperty.call(reportDetail, 'jobId')) {
        // For an incomplete report created using job
        reportEntries[reportDetail.jobId] =
          createReportEntryForIncompleteReport(reportDetail);
      } else {
        // For a direct incomplete report
        reportEntries[reportDetail._id] =
          createReportEntryForIncompleteReport(reportDetail);
      }
    } else {
      // for new report
      reportEntries[reportDetail._id] = createReportEntryForJob(reportDetail);
    }
  });
  return reportEntries;
};

export const checkIfLocalImage = (url) => {
  let isLocal = false;
  if (url) {
    const _url = url.split(':');
    if (_url[0] === 'blob') {
      isLocal = true;
    }
  }
  return isLocal;
};

const isUrl = (url) => {
  // eslint-disable-next-line no-useless-escape
  const regexp =
    /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;
  return regexp.test(url);
};

const checkLocalImagesForDynamicInsp = (missedItems) => {
  let localItem = false;
  if (missedItems) {
    Object.keys(missedItems).forEach((item) => {
      const _url = missedItems[item].split(':');
      if (_url[0] === 'blob' && isUrl(missedItems[item]) && !localItem) {
        localItem = item;
      }
    });
  }
  return localItem;
};

const checkLocalImagesForDefaultInsp = (panelDetails) => {
  let localItem = false;
  if (panelDetails && panelDetails.finish) {
    Object.keys(panelDetails.finish).forEach((item) => {
      const _url =
        panelDetails.finish[item] &&
        panelDetails.finish[item].ans &&
        panelDetails.finish[item].ans.split(':');
      if (
        _url &&
        _url[0] === 'blob' &&
        isUrl(panelDetails.finish[item].ans) &&
        !localItem
      ) {
        localItem = item;
      }
    });
  }
  return localItem;
};

export const checkLocalImagesInInspections = (
  reportDetail,
  isDefaultReport = false
) => {
  let isLocal = false;
  if (isDefaultReport) {
    isLocal = checkLocalImagesForDefaultInsp(reportDetail.answers);
  } else {
    isLocal = checkLocalImagesForDynamicInsp(reportDetail.missedItems);
  }
  return isLocal;
};

export const getBlobImagesFromDB = async (reportEntryId) => {
  let blobImages = {};
  const db = useIndexedDB('Signatures');
  await db
    .getByIndex('reportId', reportEntryId.toString())
    .then((e) => {
      blobImages = e.signatures;
    })
    .catch(() => {});
  return blobImages;
};

export const deleteReportDetailsFromDB = (reportEntryId) => {
  const db = useIndexedDB('Signatures');
  if (reportEntryId) db.deleteRecord(reportEntryId.toString());
};
