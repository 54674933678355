import React, { useEffect, useState, useRef, useMemo } from 'react';
import Button from '../../components_2/Button';
import { GetApp } from '@material-ui/icons';
import { notSafariSteps, safariSteps } from './iosSteps';

const isStandalone = 'standalone' in window.navigator && window.navigator.standalone;
const userAgent = window.navigator.userAgent.toLowerCase();
const isIos = /iphone|ipad|ipod/.test(userAgent);
const isChromeOnIos = isIos && /CriOS/i.test(navigator.userAgent);
const isAndroid = /(android)/i.test(navigator.userAgent);

const InstructionsPWA = () => {
  const [showPwaInstallButton, setShowPwaInstallButton] = useState(false);
  const [showIosInstructions, setIosInstructions] = useState(isIos && !isStandalone);
  const deferredPrompt = useRef(null);

  const close = () => {
    setShowPwaInstallButton(false);
    setIosInstructions(false);
  };

  const handleInstallPwa = async () => {
    // Hide the app provided install promotion
    setShowPwaInstallButton(false);
    // Show the install prompt
    deferredPrompt.current.prompt();
    // Wait for the user to respond to the prompt
    const { outcome } = await deferredPrompt.current.userChoice;
    // Optionally, send analytics event with outcome of user choice
    console.log(`User response to the install prompt: ${outcome}`);
    // We've used the prompt, and can't use it again, throw it away
    deferredPrompt.current = null;
  };

  useEffect(() => {
    window.addEventListener('beforeinstallprompt', (e) => {
      // Prevent the mini-infobar from appearing on mobile
      e.preventDefault();
      // Stash the event so it can be triggered later.
      deferredPrompt.current = e;
      // Update UI notify the user they can install the PWA
      setShowPwaInstallButton(true);
    });
  }, []);

  const showModal = (showPwaInstallButton && isAndroid) || showIosInstructions;
  if(!showModal) return null;

  const autoInstallInstructions = showPwaInstallButton ? (
    <div className="text-center">
      <Button
        className="mt-4"
        type="primary"
        size="large"
        onClick={handleInstallPwa}
        icon={<GetApp/>}
      >
        Install
      </Button>
    </div>
  ) : null;

  const steps = isChromeOnIos ? notSafariSteps : safariSteps;

  const iosInstructions = showIosInstructions ? (
    <div className="px-6">
      {steps.map((step, i) => (
        <div className="mb-12">
          {step(i + 1)}
        </div>
      ))}
    </div>
  ) : null;

  return (
    <div
      className="w-screen h-screen fixed top-0 left-0 bg-gray-100 overflow-auto"
      style={{ zIndex: 9999999999 }}
    >
      <img
        src="/img/favicon.png"
        className="w-20 mt-12 mx-auto"
      />
      <h3 className="mt-3 mb-7 text-center">
        Get Aries on your phone or tablet!
      </h3>
      {autoInstallInstructions}
      {iosInstructions}
      <div className="text-center pb-40">
        <div
          className="w-7/12 mx-auto my-8 border-b-2 border-dashed border-gray-300"
        />
        <Button
          onClick={close}
        >
          No, continue on the browser
        </Button>
      </div>
    </div>
  );
};

export default InstructionsPWA;
