import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Modal from '../Modal';
import showTeamListAction from '../../redux/actions/showTeamList';
import getClientOrOwnerDetailsAction from '../../redux/actions/getClientOrOwnerDetails';
import { getTeams } from '../../_helpers/commonFunctions';
import coachMarks from '../../libs/constants/coachMarks';
import EditJobModalBodys from './EditJobModalBody';

export class EditJobModal extends Component {
  componentDidUpdate(prevProps) {
    const { jobDetails } = this.props;
    const { jobDetail } = jobDetails;
    if (prevProps.jobDetails.jobDetail.createdFor !== jobDetail.createdFor) {
      this.props.getClientOrOwnerDetailsAction(jobDetail.createdFor);
    }
  }

  componentDidMount() {
    const { jobDetails } = this.props;
    const { jobDetail } = jobDetails;
    if (jobDetail.createdFor) {
      this.props.getClientOrOwnerDetailsAction(jobDetail.createdFor);
    }
    this.props.showTeamListAction(true, 1, 100);
  }

  render() {
    const {
      onCloseModal,
      jobDetails,
      clientDetails,
      currentEvent,
      teamListDetails,
      teamListDetailsWithoutFilter,
      isTeamListSuccess,
    } = this.props;
    const { jobDetail, isFetching } = jobDetails;
    const isCustomerDetailFetching = clientDetails.isfetching;

    return (
      <div>
        {this.props.currentCoachmarkTarget ===
          coachMarks.inspectionReminders.editJob.target && (
          <div
            id="test-tooltip"
            className={`info-timecard-info-show-descr coachmark-tooltips ${coachMarks.inspectionReminders.editJob.toolTipClass}`}
            style={coachMarks.inspectionReminders.editJob.style}
          >
            {coachMarks.inspectionReminders.editJob.content}
          </div>
        )}
        <Modal
          isDetailFetching={isFetching || isCustomerDetailFetching}
          modalHeading={
            jobDetail.reportCategory && jobDetail.type
              ? `${jobDetail.reportCategory} ${jobDetail.type}`
              : ' '
          }
          modalId="job-link"
          modalContentClass="quarterly-wrap-modal-coachmark"
          handleClickCross={() => onCloseModal(false)}
          ModalBody={EditJobModalBodys}
          ModalBodyProps={{
            jobId: jobDetail.jobId,
            originalJobId: jobDetail._id,
            clientData: clientDetails.clientOrOwnerData,
            currentEvent,
            teams: getTeams(teamListDetails.teamList, false),
            techniciansAssigned: getTeams(teamListDetailsWithoutFilter),
            jobDetail,
            closeModal: onCloseModal,
            jobDetailIsSuccess: isTeamListSuccess,
          }}
        />
      </div>
    );
  }
}

EditJobModal.propTypes = {
  onCloseModal: PropTypes.func,
  jobDetails: PropTypes.object,
  getClientOrOwnerDetailsAction: PropTypes.func,
  clientDetails: PropTypes.object,
  currentEvent: PropTypes.object,
  showTeamListAction: PropTypes.func,
  teamListDetails: PropTypes.object,
  teamListDetailsWithoutFilter: PropTypes.array,
  isTeamListSuccess: PropTypes.bool,
  currentCoachmarkTarget: PropTypes.string,
};

const mapStateToProps = (store) => ({
  jobDetails: store.jobListDetails.jobDetails,
  clientDetails: store.getClientOrOwnerDetails,
  teamListDetails: store.jobListDetails.teamListWithFilters,
  teamListDetailsWithoutFilter: store.showTeamList.showTeamListData,
  isTeamListSuccess: store.showTeamList.isfetching,
  currentCoachmarkTarget: store.signUpStatus.currentTarget,
});

export default connect(mapStateToProps, {
  getClientOrOwnerDetailsAction,
  showTeamListAction,
})(EditJobModal);
