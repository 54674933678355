import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Table from '../../components/ReusableTable';

const header = [
  {
    title: 'Employee',
    isSortable: false,
  },
  {
    title: 'Modified Date',
    isSortable: false,
  },
];

export const ProfileRow = ({ row }) => (
  <tr>
    <td>{row.name}</td>
    <td>{moment(row.updatedAt).format('LLL')}</td>
  </tr>
);

const ProfileModalBody = ({ profileDetails }) => {
  return (
    <div>
      <div style={{display: 'flex', backgroundColor:'#b71b1b', color: 'white', padding: 10}}>
        <div style={{flex: 1}}>{header[0].title}</div>
        <div style={{flex: 1}}>{header[1].title}</div>
      </div>
      {profileDetails.map((profileDetail => (
        <div style={{display: 'flex', padding: 10}}>
          <div style={{flex: 1}}>{profileDetail.name}</div>
          <div style={{flex: 1}}>{moment(profileDetail.updatedAt).format('LLL')}</div>
        </div>
      )))}
    </div>
)};

ProfileModalBody.propTypes = {
  profileDetails: PropTypes.object,
};

ProfileRow.propTypes = {
  row: PropTypes.object,
};

export default ProfileModalBody;
