export const numberToStringQuestions = [
  'assemblyMeterNumber',
  'panelSoftwareFirmwareRevisionNumber',
];

export const numberToNumberSpecialQuestions = [
  'companyPhoneNumber',
  'controlPanelBatteryVoltageWithPowerOn',
  'controlPanelBatteryVoltageWithPowerOff',
  'monitoringCompanyPhone',
  'assemblySize',
  'panelBatteryVoltageWithPowerOn',
  'panelBatteryVoltageWithPowerOff',
  'dieselEngineSystemsWhatISEngineRunningTimeFromMeter',
  'panelWhatWasTheBatteryVoltageWithPowerOn',
  'panelWhatWasTheBatteryVoltageWithPowerOffAndUnderLoad',
  'devicesSensitivityReadingsSensitivityRangeFrom',
  'devicesSensitivityReadingsSensitivityRangeTo',
  'devicesSensitivityReadingsTodaysReading',
  'devicesSize',
  'alarmValvesRiserCheckValvesAntifreezeLoopIsProtectedToWhatDegree',
  'waterStorageTankWhatIsWaterTemperatureInFahreinheit',
  'highPressureWaterStorageCylindeWhatIsWaterTemperatureFahreinheit',
  'systemSize',
  'cylinderWeight',
];