import actionConstants from '../constants/ActionConstants';

const initialState = {
  error: '',
  isfetching: false,
  supplierListData: [],
  isSuccess: false,
  totalCount: 0,
  currentpage: 1,
  totalPages: 1,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionConstants.INVENTORY_GET_SUPPLIER_LIST_REQUEST:
      return {
        ...state,
        isfetching: true,
        isSuccess: false,
      };
    case actionConstants.INVENTORY_GET_SUPPLIER_LIST_SUCCESS:
      return {
        ...state,
        supplierListData:
          action.payload.page === 1
            ? [...action.payload.docs]
            : [...state.supplierListData, ...action.payload.docs],
        isfetching: false,
        totalCount: action.payload.total,
        currentpage: action.payload.page,
        totalPages: action.payload.pages,
        isSuccess: true,
      };

    case actionConstants.INVENTORY_GET_SUPPLIER_LIST_FAILURE:
      return {
        ...state,
        error: action.message,
        isfetching: false,
      };
    case actionConstants.INVENTORY_GET_SUPPLIER_LIST_RESET:
      return {
        ...initialState,
      };
    case actionConstants.INVENTORY_GET_SUPPLIER_LIST_UPDATE:
      return {
        ...state,
        supplierListData: action.newState,
      };
    default:
      return state;
  }
};
