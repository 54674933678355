import React from 'react';
import SelectContext from './context';
import Option from './Option';

export default function Select({
  children,
  onChange = () => {},
  multiple = false,
  value
}) {

  const updateValue = payloadValue => {
    let newValue = multiple ? [...value] : {...value};
    if(multiple) {
      const index = value.indexOf(payloadValue);
      if(index != -1) {
        newValue.splice(index, 1);
      } else {
        newValue.push(payloadValue);
      }
    } else {
      newValue = payloadValue;
    }
    onChange({ target: { value: newValue }});
  };

  return (
    <SelectContext.Provider value={{ updateValue, value }}>
      {children}
    </SelectContext.Provider>
  );
};

Select.Option = Option;
