import reportCategory from '../../api/apiCallReportCategory';
import actionConstants from '../constants/ActionConstants';
import actionDispatcher from '../../api/ActionDispatcher';

const reportCat = () =>
  actionDispatcher(
    reportCategory.bind(null),
    actionConstants.FETCH_REPORT_CATEGORY_SUCCESS,
    actionConstants.FETCH_REPORT_CATEGORY_FAILURE,
    actionConstants.FETCH_REPORT_CATEGORY_REQUEST
  );

const reportCategoryClear = () => async (dispatch) => {
  dispatch({ type: actionConstants.FETCH_REPORT_CATEGORY_CLEAR });
};

export { reportCat, reportCategoryClear };
