import reportCreate, { reportCreateInspection } from '../../api/apiCallReport';
import actionConstants from '../constants/ActionConstants';
import actionDispatcher from '../../api/ActionDispatcher';

const createReport = (data) =>
  actionDispatcher(
    reportCreate.bind(null, data),
    actionConstants.CREATE_REPORT_SUCCESS,
    actionConstants.CREATE_REPORT_FAILURE,
    actionConstants.CREATE_REPORT_REQUEST
  );

const createReportInspection = (data) =>
  actionDispatcher(
    reportCreateInspection.bind(null, data),
    actionConstants.CREATE_REPORT_SUCCESS,
    actionConstants.CREATE_REPORT_FAILURE,
    actionConstants.CREATE_REPORT_REQUEST
  );

const saveReport = (data) =>
  actionDispatcher(
    reportCreateInspection.bind(null, data),
    actionConstants.UPDATE_REPORT_SUCCESS,
    actionConstants.UPDATE_REPORT_FAILURE,
    actionConstants.UPDATE_REPORT_REQUEST
  );

const createReportClear = () => async (dispatch) => {
  dispatch({ type: actionConstants.CREATE_REPORT_CLEAR });
};

export { createReport, createReportClear, createReportInspection, saveReport };
