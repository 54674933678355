import React from 'react';
import { format, isSameDay, addDays } from 'date-fns';
import clsx from 'clsx';
import IconButton from '../IconButton';
import { ChevronLeft, ChevronRight } from '@material-ui/icons';

const DayCircle = ({
  date,
  selected = false,
  onClick,
}) => {

  const className = clsx(
    'rounded-full text-white flex items-center justify-center uppercase text-center cursor-pointer select-none',
    selected
      ? 'w-16 h-16 bg-red-700 text-sm'
      : 'w-11 h-11 sm:12 sm:12 bg-gray-400 text-xs'
  );

  return (
    <div
      className={className}
      onClick={onClick}
    >
      <div>
        <div className="font-medium">
          {format(date, selected ? 'eee d' : 'd')}
        </div>
        <div className="opacity-70 -mt-0.5" style={{ fontSize: 12 }}>
          {format(date, 'MMM')}
        </div>
      </div>
    </div>
  );
};

const DaySelector = ({
  value = new Date(),
  onChange = () => {}
}) => {

  const items = 5;
  const days = Array.from(new Array(items), (a, i) => {
    const middle = (items - 1) / 2;
    return addDays(value, i - middle);
  });

  const arrowLeft = (
    <IconButton
      className="absolute left-0 hidden sm:block"
      onClick={() => onChange(addDays(value, -1))}
      icon={<ChevronLeft />}
    />
  );

  const arrowRight = (
    <IconButton
      className="absolute right-0 hidden sm:block"
      onClick={() => onChange(addDays(value, 1))}
      icon={<ChevronRight />}
    />
  );

  return (
    <div className="flex items-center px-4 relative justify-center">
      {arrowLeft}
      {days.map(date => (
        <div
          key={date.toLocaleDateString()}
          className="flex justify-center mx-1"
        >
          <DayCircle
            date={date}
            selected={isSameDay(date, value)}
            onClick={() => {
              onChange(date)
            }}
          />
        </div>
      ))}
      {arrowRight}
    </div>
  );
};

export default DaySelector;