const API = {
  BASE_URL: 'http://localhost:3000/firelab/api/v1',
  CLIENT_URL: 'https://dev.client.aries4.firelabinc.com/login',
  DOWNLOAD_FILE_URL: 'http://localhost:3000',
  IPIFY: 'https://api.ipify.org/?format=json',
  IP_STACK: 'https://api.ipstack.com',
  GEO_CODE_API: 'https://maps.googleapis.com/maps/api/geocode/json',
  TIME_ZONE_API: 'https://maps.googleapis.com/maps/api/timezone/json',
  WAY_POINT_API: 'https://maps.googleapis.com/maps/api/directions/json',
  DISTANCE_MATRIX_API:
    'https://maps.googleapis.com/maps/api/distancematrix/json',
  MAP_KEY: 'AIzaSyBvnzovCy-dw338jC6I-tnT_4jKOX54ePg',
  GEO_LOCATION_KEY: 'AIzaSyAzBUrghYgZZBXT0ibg9JGKTW05PzSq5Bs',
  IPSTACK_KEY: 'eb09c597a778f259af2515ca6989dfdd',
  CHAT_SERVER_URL: 'http://localhost:8754/api/v1',
  CHAT_SOCKET_URL: 'http://localhost:8754',
  BASE_SOCKET_URL: 'http://localhost:8752',
};

const DEV_TOOLS = {
  enableReduxDevTools: true,
  logError: true,
  SENTRY_DSN:
  'https://a52aad61d0924e08bd5fb1ddae880c60@o52241.ingest.sentry.io/5822044',
  SENTRY_ENV: 'testing',
};

module.exports = {
  API,
  DEV_TOOLS,
};
