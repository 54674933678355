import actionConstants from '../constants/ActionConstants';

const initialState = {
  error: '',
  isfetching: false,
  updateReportData: {},
  reportMessage: '',
  isSuccess: false,
  isFinished: false,
  imageDetails: {
    isDeleted: false,
    _id: '',
    description: '',
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionConstants.UPDATE_REPORT_REQUEST:
      return {
        ...state,
        isfetching: true,
        isSuccess: false,
        error: '',
      };
    case actionConstants.UPDATE_REALTIME_REQUEST:
      return {
        ...state,
        isSuccess: false,
        error: '',
      };
    case actionConstants.UPDATE_REALTIME_SUCCESS:
      return {
        ...state,
        reportMessage: action.message,
        isfetching: false,
        isSuccess: true,
      };
    case actionConstants.UPDATE_REALTIME_FAILURE:
      return {
        ...state,
        isfetching: false,
        isSuccess: false,
        error: action.message,
      };
    case actionConstants.UPDATE_REPORT_SUCCESS:
      return {
        ...state,
        updateReportData: action.payload,
        reportMessage: action.message,
        isfetching: false,
        isSuccess: true,
      };
    case actionConstants.UPDATE_REPORT_FINISH_REPORT:
      return {
        ...state,
        isFinished: true,
      };
    case actionConstants.UPDATE_REPORT_FAILURE:
      return {
        ...state,
        error: action.message,
        isfetching: false,
      };
    case actionConstants.UPDATE_REPORT_CLEAR:
      return {
        ...initialState,
      };
    case actionConstants.CLEAR_SUCCESS_REPORT:
      return {
        ...state,
        error: '',
        isSuccess: false,
        isFinished: false,
        imageDetails: {
          isDeleted: false,
          _id: '',
          description: '',
        },
      };
    case actionConstants.UPDATE_IMAGE_DETAILS_FOR_SOCKET:
      return {
        ...state,
        imageDetails: {
          ...state.imageDetails,
          ...action.payload,
        },
      };
    case actionConstants.CLEAR_IMAGE_DETAILS_FOR_SOCKET:
      return {
        ...state,
        imageDetails: {
          _id: '',
          isDeleted: false,
          description: '',
        },
      };
    case actionConstants.UPDATE_SOCKET_ANSWER_INSPECTION:
      if (action.payload.image && action.payload.image._id) {
        const imageIndex = state.updateReportData.images.findIndex(
          (x) => x._id === action.payload.image._id
        );
        if (imageIndex > -1) {
          if (action.payload.image.isDeleted) {
            return {
              ...state,
              updateReportData: {
                ...state.updateReportData,
                images: [
                  ...state.updateReportData.images.slice(0, imageIndex),
                  ...state.updateReportData.images.slice(imageIndex + 1),
                ],
              },
            };
          }
          return {
            ...state,
            updateReportData: {
              ...state.updateReportData,
              images: [
                ...state.updateReportData.images.slice(0, imageIndex),
                {
                  ...state.updateReportData.images[imageIndex],
                  ...action.payload.image,
                },
                ...state.updateReportData.images.slice(imageIndex + 1),
              ],
            },
          };
        }
      }
      return {
        ...state,
        // updateReportData: {
        //   ...state.updateReportData,
        //   images: [...state.updateReportData.images, action.payload.image]
        // },
      };
    case actionConstants.FETCH_REPORT_DETAIL_SUCCESS:
      return {
        ...state,
        updateReportData: action.payload,
      };
    default:
      return state;
  }
};
