import actionConstants from '../constants/ActionConstants';

const formJobDetails = (payload) => (dispatch) => {
  dispatch({ type: actionConstants.SET_JOB_DETAILS, payload });
};

const clearJobDetails = () => (dispatch) => {
  dispatch({ type: actionConstants.CLEAR_JOB_DETAILS });
};

// export default formJobDetails;
export { clearJobDetails, formJobDetails };
