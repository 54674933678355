import React from 'react';
import PropTypes from 'prop-types';
import DefaultFilterDropDownComponent from './DefaultFilterDropDownComponent';
import DefaultFilterDropDownContainer from './DefaultFilterDropDownContainer';

class ExpandableDropdown extends React.PureComponent {
  state = {
    isOpened: false,
  };

  node = React.createRef();

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClick);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClick);
  }

  handleClick = (e) => {
    if (this.node.current.contains(e.target)) {
      return;
    }
    this.setState(
      {
        isOpened: false,
      },
      () => {
        this.props.onToggleOpen(this.state.isOpened);
      }
    );
  };

  render() {
    const {
      headLabels,
      childLabels,
      expandedIndex,
      onApply,
      onReset,
      onHeadPress,
      onChildPress,
      DropDownComponent,
      DropDownContainerComponent,
      className,
    } = this.props;
    return (
      <div className={className} ref={this.node}>
        <DropDownContainerComponent
          dataFor={this.props.dataFor}
          dataTip={this.props.dataTip}
          place={this.props.place}
          isOpened={this.state.isOpened}
          toggleOpen={() => this.setState({ isOpened: !this.state.isOpened })}
        >
          <DropDownComponent
            headLabels={headLabels}
            childLabels={childLabels}
            expandedIndex={expandedIndex}
            onHeadPress={onHeadPress}
            onChildPress={onChildPress}
            onApply={() => {
              this.setState({ isOpened: false });
              onApply();
            }}
            onReset={onReset}
          />
        </DropDownContainerComponent>
      </div>
    );
  }
}

ExpandableDropdown.propTypes = {
  headLabels: PropTypes.array.isRequired,
  childLabels: PropTypes.array.isRequired,
  expandedIndex: PropTypes.number.isRequired,
  onApply: PropTypes.func,
  onReset: PropTypes.func,
  onHeadPress: PropTypes.func,
  onChildPress: PropTypes.func,
  DropDownComponent: PropTypes.func.isRequired,
  DropDownContainerComponent: PropTypes.func.isRequired,
  className: PropTypes.string.isRequired,
  onToggleOpen: PropTypes.func,
  dataFor: PropTypes.string,
  dataTip: PropTypes.string,
  place: PropTypes.string,
};

ExpandableDropdown.defaultProps = {
  headLabels: [],
  childLabels: {},
  expandedIndex: -1,
  onApply: undefined,
  onReset: undefined,
  onHeadPress: undefined,
  onChildPress: undefined,
  DropDownComponent: DefaultFilterDropDownComponent,
  DropDownContainerComponent: DefaultFilterDropDownContainer,
  className: '',
  onToggleOpen: undefined,
};

export default ExpandableDropdown;
