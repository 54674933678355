import actionConstants from '../constants/ActionConstants';

const setIsPrompt = (isPrompt, extradata) => (dispatch) =>
  dispatch({
    type: actionConstants.SET_IS_PROMPT,
    payload: isPrompt,
    extradata,
  });

export default setIsPrompt;
