import actionConstants from '../constants/ActionConstants';

const initialState = {
  error: '',
  isfetching: false,
  success: '',
  uploadedImageDetails: null,
  isSuccess: false,
};

const updateSuccess = (state = initialState, action) => {
  switch (action.type) {
    case actionConstants.UPLOAD_IMAGE_REQUEST:
      return {
        ...state,
        isfetching: true,
        isSuccess: false,
      };
    case actionConstants.UPLOAD_IMAGE_SUCCESS:
      return {
        ...state,
        uploadedImageDetails: action.payload,
        isfetching: false,
        isSuccess: true,
      };
    case actionConstants.UPLOAD_IMAGE_FAILURE:
      return {
        ...state,
        error: action.error,
        isSuccess: false,
      };
    case actionConstants.CLEAR_STATE:
      return {
        ...state,
        isfetching: false,
        isSuccess: false,
        error: '',
      };
    case actionConstants.UPLOAD_IMAGE_RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export default updateSuccess;
