import { useContext } from 'react';
import SelectContext from './context';

function getChecked(optionValue, contextValue, multiple) {
  if(multiple) {
    return contextValue.includes(optionValue);
  } else {
    return optionValue == contextValue;
  }
}

export default function Option({children, value: optionValue}) {

  const { updateValue, value } = useContext(SelectContext)

  if(typeof children != 'function') {
    console.error('The child of `<Option>` must be a function', children);
    return null;
  }

  const props = {
    onClick: () => updateValue(optionValue),
    checked: getChecked(optionValue, value, Array.isArray(value))
  };

  return children(props);
}