import { QUARTERLY_CONFIGS, SEMI_ANNUAL_CONFIGS, mappedMonths } from "./taskModalBodyConfigs";

export const getCorrectedTasks = (originalTasks) => {
  const correctedTasks = originalTasks.map((task) => {
    const fixed = getCorrectedTask(task);
    return { ...task, ...fixed };
  });
  return correctedTasks;
}

const getPrevMonth = (date) => {
  const [ month, year ] = [date.getMonth(), date.getFullYear()];
  const isPrevYear = month === 0;
  return isPrevYear ? new Date(year - 1, 11, date.getDate()) : new Date(year, month - 1, date.getDate());
}

const getNextMonth = (date) => {
  const [ month, year ] = [date.getMonth(), date.getFullYear()];
  const isNextYear = month === 11;
  return isNextYear ? new Date(year + 1, 0, date.getDate()) : new Date(year, month + 1, date.getDate());
}

const getMonthFromNumber = (monthNumber) => Object.keys(mappedMonths).find((key) => mappedMonths[key] === monthNumber);
const currentBiMonthlyStrategy = ({ taskMonth, currentYear, currentMonth, nextMonthYear, nextMonthMonth }) => { // for bi-monthly
  const isTaskEven = mappedMonths[taskMonth] % 2 === 0;
  const isCurrentEven = currentMonth % 2 === 0;
  const fixedMonth = isTaskEven == isCurrentEven ? currentMonth : nextMonthMonth;
  const fixedYear = isTaskEven == isCurrentEven ? currentYear : nextMonthYear;
  return { months: [fixedMonth], year: fixedYear };
}
const nextBiMonthlyStrategy = ({ taskMonth, currentYear, currentMonth, nextMonthMonth, nextMonthYear }) => { // for bi-monthly
  const isTaskEven = mappedMonths[taskMonth] % 2 === 0;
  const isCurrentEven = currentMonth % 2 === 0;
  const fixedMonth = isTaskEven != isCurrentEven ? currentMonth : nextMonthMonth;
  const fixedYear = isTaskEven != isCurrentEven ? currentYear : nextMonthYear;
  return { months: [fixedMonth], year: fixedYear };
}
const currentMonthlyStrategy = ({ taskMonth, currentYear, currentMonth }) => { // for weekly, bi-weekly, monthly
  return { months: [currentMonth], year: currentYear };
};
const nextMonthlyStrategy = ({ taskMonth, nextMonthMonth, nextMonthYear }) => { // for monthly
  return { months: [nextMonthMonth], year: nextMonthYear };
}
const currentQuarterlyStrategy = ({ taskMonth, currentYear, currentMonth }) => { // for quarterly
  const config = QUARTERLY_CONFIGS[taskMonth];
  const currentMonthConst = getMonthFromNumber(currentMonth);
  const rule = config[currentMonthConst];
  return {
    months: [mappedMonths[rule.month]],
    year: currentYear + rule.year,
  };
}
const currentSemiAnnualStrategy = ({ taskMonth, currentYear, currentMonth }) => { // for semi annual
  const config = SEMI_ANNUAL_CONFIGS[taskMonth];
  const currentMonthConst = getMonthFromNumber(currentMonth);
  const rule = config[currentMonthConst];
  return {
    months: [mappedMonths[rule.month]],
    year: currentYear + rule.year,
  };
}
const currentAnnualStrategy = ({ taskMonth, currentYear, currentMonth }) => { // for annual
  const taskMonthNumber = mappedMonths[taskMonth];
  const isCurrentBeforeMonth = currentMonth <= taskMonthNumber;
  return {
    months: [taskMonthNumber],
    year: currentYear + (isCurrentBeforeMonth ? 0 : 1),
  };
}
const currentBiAnnualStrategy = ({ taskMonth, taskYear, currentYear, currentMonth }) => { // for bi annual
  const taskMonthNumber = mappedMonths[taskMonth];
  const isTaskYearEven = taskYear % 2 === 0;
  const isCurrentYearEven = currentYear % 2 === 0;
  const isCurrentBeforeMonth = currentMonth <= taskMonthNumber;
  let fixedYear = currentYear;
  if (isTaskYearEven == isCurrentYearEven) {
    if (isCurrentBeforeMonth) {
      fixedYear = currentYear;
    } else {
      fixedYear = currentYear + 2;
    }
  } else {
    fixedYear = currentYear + 1;
  }
  return {
    months: [taskMonthNumber],
    year: fixedYear,
  };
}
const currentThreeYearStrategy = ({ taskMonth, taskYear, currentYear, currentMonth }) => { // for 3 year
  const taskMonthNumber = mappedMonths[taskMonth];
  const CONFIG_YEARS = 3;
  const DAY = 2;
  let fixedYear = taskYear;
  const currentDate = new Date(currentYear, currentMonth - 1, 1);
  for (let i = 0; i < 10; i++) {
    const iterationDate = new Date(fixedYear, taskMonthNumber - 1, DAY);
    if (iterationDate <= currentDate) fixedYear += CONFIG_YEARS;
  }
  return {
    months: [taskMonthNumber],
    year: fixedYear,
  };
}
const currentFiveYearStrategy = ({ taskMonth, taskYear, currentYear, currentMonth }) => { // for 5 year
  const taskMonthNumber = mappedMonths[taskMonth];
  const CONFIG_YEARS = 5;
  const DAY = 2;
  let fixedYear = taskYear;
  const currentDate = new Date(currentYear, currentMonth - 1, 1);
  for (let i = 0; i < 10; i++) {
    const iterationDate = new Date(fixedYear, taskMonthNumber - 1, DAY);
    if (iterationDate <= currentDate) fixedYear += CONFIG_YEARS;
  }
  return {
    months: [taskMonthNumber],
    year: fixedYear,
  };
}

const strategyMapper = {
  'WEEKLY': currentMonthlyStrategy,
  'BI_WEEKLY': currentMonthlyStrategy,
  'MONTHLY': currentMonthlyStrategy,
  'BI_MONTHLY': currentBiMonthlyStrategy,
  'QUARTELY': currentQuarterlyStrategy,
  'SEMI_ANNUAL': currentSemiAnnualStrategy,
  'ANNUAL': currentAnnualStrategy,
  'BI_ANNUAL': currentBiAnnualStrategy,
  'THREE_YEAR': currentThreeYearStrategy,
  'FIVE_YEAR': currentFiveYearStrategy,
}

const dateNotPassed = (month, year) => {
  const today = new Date();
  const currentYear = today.getFullYear();
  const currentMonth = today.getMonth() + 1;
  const monthNumber = mappedMonths[month];
  return year > currentYear || (year === currentYear && monthNumber >= currentMonth);
}

const getCorrectedTask = (task) => {
  if (dateNotPassed(task.months[0], task.year)) return task;
  const taskFrequency = task.frequency;
  const strategy = strategyMapper[taskFrequency];
  const taskYear = task.year;
  const taskMonth = task.months[0];
  const today = new Date();
  const currentYear = today.getFullYear();
  const currentMonth = today.getMonth() + 1;
  const prevMonth = getPrevMonth(today);
  const prevMonthYear = prevMonth.getFullYear();
  const prevMonthMonth = prevMonth.getMonth() + 1;
  const nextMonth = getNextMonth(today);
  const nextMonthYear = nextMonth.getFullYear();
  const nextMonthMonth = nextMonth.getMonth() + 1;
  const properties = { taskMonth, taskYear, currentYear, currentMonth, prevMonthMonth, prevMonthYear, nextMonthMonth, nextMonthYear};
  let newTask = strategy(properties);

  newTask.months[0] = Object.keys(mappedMonths).find((key) => mappedMonths[key] === newTask.months[0]);
  return newTask;
}
