import React from 'react';
import clsx from 'clsx';

const IconButton = ({ icon, className, onClick = () => {}, disabled = false }) => {
  const finalClassName = clsx(
    'rounded-full hover:bg-black hover:bg-opacity-10 cursor-pointer w-9 h-9 inline-flex items-center justify-center',
    disabled ? 'pointer-events-none opacity-40' : null,
    className
  );

  return (
    <div className={finalClassName} onClick={onClick} style={{ fontSize: 22 }}>
      {icon}
    </div>
  );
};

export default IconButton;