import actionConstants from '../constants/ActionConstants';
import { deleteSystemInfoApiCall } from '../../api/apiCallsCustomer';
import ActionDispatcher from '../../api/ActionDispatcher';

const deleteSystemOrDevice = (buildingInfoId, systemInfoId) =>
  ActionDispatcher(
    deleteSystemInfoApiCall.bind(null, buildingInfoId, systemInfoId),
    actionConstants.DELETE_SYSTEM_OR_DEVICE_SUCCESS,
    actionConstants.DELETE_SYSTEM_OR_DEVICE_FAILURE,
    actionConstants.DELETE_SYSTEM_OR_DEVICE_REQUEST
  );

export default deleteSystemOrDevice;
