const EVENT = {
  CONNECTION: 'connection',
  CONNECTED: 'connected',
  DISCONNECTED: 'disconnect',
  MESSAGE: 'message',
  UPDATE_ANSWER: 'updateAnswer',
  REPORT_UPDATED: 'reportUpdated',
  REPORT_START: 'reportStart',
  REPORT_END: 'reportEnd',
};

export default EVENT;
