import { getToken } from '../_helpers/commonFunctions';
import call from './apiRequest';
import apiEndpoints from './apiEndpoints';

/**
 * Api call for adding tool
 * @param {object} data
 */
const AddTool = (data) =>
  call({
    method: 'post',
    endpoint: apiEndpoints.inventoryTools,
    payload: data,
    authKey: getToken(),
  });

export default AddTool;
