import actionConstants from '../constants/ActionConstants';

const initialState = {
  error: '',
  isSuccess: false,
  isError: false,
  isfetching: false,
  techSuccess: false,
  assignedPartsList: [],
  assignedToolsList: [],
  totalCount: 0,
  currentpage: 1,
  assignedTechnician: {},
  totalPages: 1,
  apiMessage: '',
  isfetchingDelete: false,
  isSuccessDelete: false,
  successDelete: '',
  errorDelete: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionConstants.INVENTORY_VEHICLE_ASSIGNED_LIST_REQUEST:
      return {
        ...state,
        isfetching: true,
      };
    case actionConstants.INVENTORY_VEHICLE_ASSIGNED_LIST_SUCCESS:
      return {
        ...state,
        isfetching: false,
        techSuccess: true,
        assignedTechnician: action.payload.assignedTechnician,
        assignedPartsList: [...action.payload.parts],
        assignedToolsList: [...action.payload.tools],
        totalCount: action.payload.total,
        currentpage: action.payload.page,
        totalPages: action.payload.pages,
      };
    case actionConstants.INVENTORY_VEHICLE_ASSIGNED_LIST_FAILURE:
      return {
        ...state,
        isfetching: false,
        apiMessage: action.message,
      };

    case actionConstants.INVENTORY_VEHICLE_ASSIGNED_LIST_RESET:
      return {
        ...initialState,
      };

    case actionConstants.INVENTORY_VEHICLE_UNASSIGNED_ITEM_REQUEST:
      return {
        ...state,
        apiMessage: '',
        isfetching: true,
      };

    case actionConstants.INVENTORY_VEHICLE_UNASSIGNED_ITEM_SUCCESS:
      return {
        ...state,
        isfetching: false,
        isSuccess: true,
        apiMessage: action.message,
      };

    case actionConstants.INVENTORY_VEHICLE_UNASSIGNED_ITEM_FAILURE:
      return {
        ...state,
        isfetching: false,
        isError: true,
        apiMessage: action.message,
      };

    case actionConstants.INVENTORY_VEHICLE_UNASSIGNED_ITEM_RESET:
      return {
        ...state,
        isfetching: false,
        apiMessage: '',
        isSuccess: false,
        isError: false,
      };

    /** Delete Vehicle Details Info */

    case actionConstants.INVENTORY_DELETE_VEHICLE_DETAILS_REQUEST:
      return {
        ...state,
        isfetchingDelete: true,
        isSuccessDelete: false,
      };
    case actionConstants.INVENTORY_DELETE_VEHICLE_DETAILS_FAILURE:
      return {
        ...state,
        errorDelete: action.message,
        isfetchingDelete: false,
      };
    case actionConstants.INVENTORY_DELETE_VEHICLE_DETAILS_SUCCESS:
      return {
        ...state,
        isSuccessDelete: true,
        successDelete: action.message,
        assignedPartsList: [],
        assignedToolsList: [],
      };

    case actionConstants.INVENTORY_DELETE_VEHICLE_DETAILS_RESET:
      return {
        ...state,
        apiMessage: '',
        isfetchingDelete: false,
        isSuccessDelete: false,
        techSuccess: false,
        successDelete: '',
        errorDelete: '',
        error: '',
        isSuccess: false,
        isError: false,
        isfetching: false,
      };
    default:
      return state;
  }
};
