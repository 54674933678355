import actionConstants from '../constants/ActionConstants';
import { genericPermissions } from '../../api';
import ActionDispatcher from '../../api/ActionDispatcher';

const getGenericAccessPermissions = () =>
  ActionDispatcher(
    genericPermissions.bind(null),
    actionConstants.GET_GENERIC_ACCESS_PERMISSIONS_SUCCESS,
    actionConstants.GET_GENERIC_ACCESS_PERMISSIONS_FAILURE,
    actionConstants.GET_GENERIC_ACCESS_PERMISSIONS_REQUEST
  );

export default getGenericAccessPermissions;
