import actionConstants from '../constants/ActionConstants';

const jobsCompletedManually = {
  error: '',
  success: '',
  isFetching: false,
  jobDetail: {},
};

const initialState = {
  error: '',
  isfetching: false,
  success: '',
  pages: 0,
  searchText: '',
  jobData: [],
  myJobData: [],
  jobsCompletedManually,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionConstants.PRINT_JOB_LIST_REQUEST:
    case actionConstants.GET_JOB_LIST_REQUEST:
      return {
        ...state,
        isfetching: true,
        success: '',
        printData: '',
      };

    case actionConstants.PRINT_JOB_LIST_SUCCESS:
      return {
        ...state,
        printData: action.payload,
        isfetching: false,
        isFetching: false,
        isSuccess: true,
        success: 'download success',
      };

    case actionConstants.PRINT_JOB_LIST_FAILURE:
      return {
        ...state,
        printData: action.payload,
        isfetching: false,
        success: '',
      };
    case actionConstants.GET_JOB_LIST_SUCCESS:
      return {
        ...state,
        jobData:
          action.payload.page === 1
            ? action.payload.docs
            : [...state.jobData, ...action.payload.docs],
        isfetching: false,
        success: action.message,
        pages: action.payload.pages,
      };
    case actionConstants.RESET_PASSWORD_FAILURE:
    case actionConstants.GET_JOB_LIST_FAILURE:
      return {
        ...state,
        error: action.message,
        isfetching: false,
        success: '',
      };
    case actionConstants.GET_MY_JOB_LIST_REQUEST:
      return {
        ...state,
        isfetching: true,
        success: '',
        error: '',
      };

    case actionConstants.GET_MY_JOB_LIST_FAILURE:
      return {
        ...state,
        isfetching: false,
        error: action.message,
      };
    case actionConstants.GET_MY_JOB_LIST_SUCCESS:
      return {
        ...state,
        myJobData:
          action.payload.page === 1
            ? action.payload.docs
            : [...state.myJobData, ...action.payload.docs],
        isfetching: false,
        success: action.message,
        pages: action.payload.pages,
      };
    case actionConstants.COMPLETE_JOB_MANUALLY_REQUEST:
      return {
        ...state,
        jobsCompletedManually: {
          isFetching: true,
          success: '',
          error: '',
          jobDetail: {},
        },
      };
    case actionConstants.COMPLETE_JOB_MANUALLY_SUCCESS:
      return {
        ...state,
        jobsCompletedManually: {
          ...state.jobsCompletedManually,
          success: action.message,
          jobDetail: action.payload,
          isFetching: false,
        },
      };
    case actionConstants.COMPLETE_JOB_MANUALLY_FAILURE:
      return {
        ...state,
        jobsCompletedManually: {
          ...state.jobsCompletedManually,
          isFetching: false,
          error: action.message,
        },
      };
    case actionConstants.COMPLETE_JOB_MANUALLY_CLEAR:
      return {
        ...state,
        jobsCompletedManually: {
          ...jobsCompletedManually,
        },
      };
    case actionConstants.CLEAR_STATE:
      return {
        ...state,
        error: '',
        success: '',
        isfetching: false,
        jobData: [],
      };
    case actionConstants.RESET_ALL_REDUCERS:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};
