import actionConstants from '../constants/ActionConstants';

const initialState = {
  error: '',
  priceLevel: [],
  isFetching: false,
  isSuccess: false,
  success: '',
  firstLogin: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionConstants.INVENTORY_GET_PRICE_LEVEL_SUCCESS:
      return {
        ...state,
        isFetching: false,
        priceLevel: action.payload,
        success: action.message,
        firstLogin: true,
      };

    case actionConstants.INVENTORY_GET_PRICE_LEVEL_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.message,
      };

    case actionConstants.INVENTORY_GET_PRICE_LEVEL_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case actionConstants.INVENTORY_GET_PRICE_LEVEL_RESET:
      return {
        ...initialState,
        isSuccess: false,
      };

    case actionConstants.INVENTORY_ADD_PRICE_LEVEL_SUCCESS:
      return {
        ...state,
        isFetching: false,
        success: action.message,
      };

    case actionConstants.INVENTORY_ADD_PRICE_LEVEL_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.message,
      };

    case actionConstants.INVENTORY_ADD_PRICE_LEVEL_REQUEST:
      return {
        ...state,
        isFetching: true,
      };

    case actionConstants.INVENTORY_ADD_PRICE_LEVEL_RESET:
      return {
        ...initialState,
      };

    case actionConstants.INVENTORY_EDIT_PRICE_LEVEL_SUCCESS:
      return {
        ...state,
        isFetching: false,
        success: action.message,
        isSuccess: true,
      };

    case actionConstants.INVENTORY_EDIT_PRICE_LEVEL_FAILURE:
      return {
        ...state,
        isFetching: false,
        success: action.message,
      };

    case actionConstants.INVENTORY_EDIT_PRICE_LEVEL_REQUEST:
      return {
        ...state,
        isFetching: true,
      };

    case actionConstants.INVENTORY_EDIT_PRICE_LEVEL_RESET:
      return {
        ...initialState,
      };

    default:
      return state;
  }
};
