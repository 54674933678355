import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import PropTypes from 'prop-types';
import TransactionLayout from '../../layouts/InventoryViewLayout/InventoryViewLayout';
import {
  getTransactionlist,
  clearTransactionList,
} from '../../redux/actions/getTransactionList';
import {
  endMessageForTransactionTable,
  endMessageTransaction,
} from '../../utils/Constants';

export class Transactionview extends React.Component {
  constructor(props) {
    super(props);
    this.page = 1;
    this.LIMIT = 10;
  }

  state = {
    searchText: '',
    filter: undefined,
    assets: [],
    transactions: [],
    startDate: '',
    endDate: '',
    isClassVisible: {},
    isCrossVisible: false,
    endMessage: endMessageTransaction,
  };

  componentWillUnmount() {
    this.props.clearTransactionList();
  }

  reloadList = () => {
    this.props.getTransactionlist(this.page, this.LIMIT, {
      search: this.state.searchText,
      transactionTypes: this.state.transactions,
      assetTypes: this.state.assets,
      startDate: this.state.startDate,
      endDate: this.state.endDate,
    });
  };

  componentDidMount() {
    this.page = 1;
    this.reloadList();
  }

  setSearchText = (text) => {
    this.setState({ searchText: text, isCrossVisible: true });
  };

  handleOnSearch = (text) => {
    this.page = 1;
    if (text === '') {
      this.setState({ searchText: '', isCrossVisible: false }, () =>
        this.reloadList()
      );
    }
    this.reloadList();
  };

  setDatePeriod = (startDate, endDate) => {
    this.page = 1;
    if (startDate === null) {
      this.setState(
        {
          startDate: null,
          endDate: null,
        },
        () => this.reloadList()
      );
    } else {
      this.setState(
        {
          startDate: moment(startDate).valueOf(),
          endDate: moment(endDate).valueOf(),
        },
        () => this.reloadList()
      );
    }
  };

  applyFilter = (filter) => {
    this.page = 1;
    const assets = this.appliedFilters(filter.statusFilters);
    const transactions = this.appliedFilters(filter.reportFilters);
    this.setState(
      {
        assets,
        transactions,
      },
      () => this.reloadList()
    );
  };

  appliedFilters = (array) => {
    const newArray = Object.keys(array).filter((item) => array[item]);
    return newArray;
  };

  setFilters = (filters) => {
    this.setState(
      {
        filter: filters,
      },
      () => this.applyFilter(this.state.filter)
    );
  };

  loadMore = () => {
    this.page += 1;
    this.reloadList();
  };

  // Handle Mobile Table View
  setClassVisibility = (jobId) => {
    const isClassVisible = {};
    if (Object.keys(this.state.isClassVisible[0] === jobId)) {
      isClassVisible[`${jobId}`] = !this.state.isClassVisible[`${jobId}`];
    } else {
      isClassVisible[`${jobId}`] = true;
    }
    this.setState({ isClassVisible });
  };

  isEndMessageVisible = () =>
    this.page > 1 ||
    (this.props.transactionList && this.props.transactionList.length) === 0;

  render() {
    return (
      <TransactionLayout
        isCrossVisible={this.state.isCrossVisible}
        searchText={this.state.searchText}
        transactionList={this.props.transactionList}
        assets={this.state.assets}
        transactions={this.state.transactions}
        setFilters={this.setFilters}
        setSearchText={this.setSearchText}
        handleSearch={this.handleOnSearch}
        detail={this.props.getTransactionDetail}
        filter={this.state.filter}
        loadMore={this.loadMore}
        isfetching={this.props.isfetching}
        setDatePeriod={this.setDatePeriod}
        hasMoreItems={this.page < this.props.pages}
        isClassVisible={this.state.isClassVisible}
        setClassVisibility={this.setClassVisibility}
        endMessage={
          this.props.transactionList && this.props.transactionList.length > 0
            ? this.state.endMessage
            : endMessageForTransactionTable
        }
        isEndMessageVisible={this.isEndMessageVisible()}
      />
    );
  }
}
const mapStateToProps = (state) => ({
  transactionList: state.getTransactionsList.transactionList,
  pages: state.getTransactionsList.pages,
  isfetching: state.getTransactionsList.isfetching,
});

Transactionview.propTypes = {
  transactionList: PropTypes.array,
  isfetching: PropTypes.bool,
  pages: PropTypes.number,
  getTransactionlist: PropTypes.func,
  reportCategories: PropTypes.object,
  reportCat: PropTypes.func,
  clearTransactionList: PropTypes.func,
  getTransactionDetail: PropTypes.func,
};

export default connect(mapStateToProps, {
  getTransactionlist,
  clearTransactionList,
})(Transactionview);
