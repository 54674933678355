import actionConstants from '../constants/ActionConstants';

const initialState = {
  error: '',
  isfetching: false,
  editDetailsOfMember: null,
  isSuccess: false,
  success: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionConstants.EDIT_MEMBER_REQUEST:
      return {
        ...state,
        isfetching: true,
      };
    case actionConstants.EDIT_MEMBER_SUCCESS:
      return {
        ...state,
        editDetailsOfMember: action.payload,
        isfetching: false,
        success: action.message,
        isSuccess: true,
      };
    case actionConstants.EDIT_MEMBER_FAILURE:
      return {
        ...state,
        error: action.error,
        isfetching: false,
      };
    case actionConstants.CLEAR_STATE:
      return { ...initialState };
    default:
      return state;
  }
};
