const transactionTableHeading = [
  {
    title: 'Id',
    isSortable: false,
  },
  {
    title: 'Username',
    isSortable: false,
  },
  {
    title: 'Date',
    isSortable: false,
  },
  {
    title: 'Asset Type',
    isSortable: false,
  },
  {
    title: 'Transaction Type',
    isSortable: false,
  },
];

const TaskTableHeading = [
  {
    title: 'Job Id',
    isSortable: false,
    width: '9%',
  },
  {
    title: 'Name',
    isSortable: true,
    width: '14%',
  },
  {
    title: 'Report Category',
    isSortable: false,
    width: '19%',
  },
  {
    title: 'Frequency',
    isSortable: false,
    width: '16%',
  },
  {
    title: 'Date',
    isSortable: true,
    width: '16%',
  },
  {
    title: 'Job Type',
    isSortable: false,
    width: '12%',
  },
  {
    title: 'Action',
    isSortable: false,
    width: '14%',
  },
];

const ReportsTableHeading = [
  {
    title: ' ',
    isSortable: false,
    width: '1%',
  },
  {
    title: 'Category',
    isSortable: false,
  },
  {
    title: 'Frequency',
    isSortable: false,
  },
  {
    title: 'Created By',
    isSortable: false,
  },
  {
    title: 'Created At',
    isSortable: false,
  },
];

export default {
  transactionTableHeading,
  TaskTableHeading,
  ReportsTableHeading,
};
