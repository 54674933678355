import actionConstants from '../constants/ActionConstants';

const initialState = {
  selectedJobsForOffline: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionConstants.SET_OFFLINE_SELECTED_JOB_LIST:
      return {
        ...state,
        selectedJobsForOffline: action.payload.selectedJobList,
      };
    case actionConstants.RESET_OFFLINE_SELECTED_JOB_LIST:
      return {
        ...state,
        selectedJobsForOffline: [],
      };
    case actionConstants.RESET_ALL_REDUCERS:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};
