import React from 'react';

export const safariSteps = [
  (stepN) => (
    <>
      <h4>{stepN}. Click share</h4>
      <img
        src="/img/safari-share-icon.png"
        className="mx-auto mt-4 rounded-md"
      />
    </>
  ),
  (stepN) => (
    <>
      <h4>{stepN}. Click "Add to Home Screen"</h4>
      <img
        src="/img/safari-add-to-home-screen.jpg"
        className="mx-auto mt-4 rounded-md"
      />
    </>
  )
];

export const notSafariSteps = [
  (stepN) => (
    <>
      <h4>{stepN}. Open safari</h4>
      <img
        src="/img/iphone-safari.jpg"
        className="mx-auto mt-4 rounded-md"
      />
    </>
  ),
  (stepN) => (
    <>
      <h4>{stepN}. Go to
        <span className="bg-red-200 rounded ml-2 px-1">
          aries4.firelabinc.com
        </span>
      </h4>
      <img
        src="/img/safari-url.jpg"
        className="mx-auto mt-4 rounded-md"
      />
    </>
  ),
  ...safariSteps
];