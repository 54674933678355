import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';

const PwaSnackbarUpdate = () => {

  const [open, setOpen] = useState(false);
  const handlePWAUpdate = () => setOpen(true);
  useEffect(() => {
    window.addEventListener('pwa-update', handlePWAUpdate);
    return () => {
      window.removeEventListener('pwa-update', handlePWAUpdate);
    }
  }, []);

  const action = (
    <Button
      color="secondary"
      size="small"
      onClick={() => location.reload()}
    >
      Reload
    </Button>
  );

  return (
    <Snackbar
      style={{ zIndex: 9999999999 }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      open={open}
      message="New content available"
      action={action}
    />
  );
};

export default PwaSnackbarUpdate;
