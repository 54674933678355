import React from 'react';
import ReactTooltip from 'react-tooltip';
import './ToolTip.css';
import ProtoType from 'prop-types';

const ToolTip = (props) => {
  const hideTooltip = () => ReactTooltip.hide();
  return (
  <ReactTooltip
    html={props.html}
    afterShow={() => {
      document.addEventListener('wheel', hideTooltip);
      setTimeout(hideTooltip, 3000)
    }}
    afterHide={() => {
      document.removeEventListener('wheel', hideTooltip);
    }}
    clickable={true}
    place={props.place}
    effect="solid"
    id={props.id}
    type="info"
    className="__react_component_tooltip"
  />
);
}

ToolTip.defaultProps = {
  id: '',
  html: true,
  place: 'bottom',
};

ToolTip.propTypes = {
  id: ProtoType.any, // id of tooltip
  html: ProtoType.bool, // html to render in tooltip
  place: ProtoType.string, // direction of tooltip(allowed values:- top, bottom, left right)
};
export default ToolTip;
