import React from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import endpoints from '../../routes';

/**
 * Redirects to customer
 */
const RedirectToCustomer = ({ endpoint }) => (
  <Redirect to={endpoint || endpoints.customer} />
);

RedirectToCustomer.propTypes = {
  endpoint: PropTypes.string,
};

export default RedirectToCustomer;
