import actionConstants from '../constants/ActionConstants';
import { getSystemInfoApiCall } from '../../api/apiCallsCustomer';
import ActionDispatcher from '../../api/ActionDispatcher';

const getSystemInfo = (id) =>
  ActionDispatcher(
    getSystemInfoApiCall.bind(null, id),
    actionConstants.GET_SYSTEM_INFO_SUCCESS,
    actionConstants.GET_SYSTEM_INFO_FAILURE,
    actionConstants.GET_SYSTEM_INFO_REQUEST
  );

export default getSystemInfo;
