import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import './JobLinkPopUp.scss';

const checkoutStatus = ['CLOCKED OUT', 'CHECKOUT'];

const JobLinkPopUp = (props) => {
  const { bodyItems, onClickItem, onClickCross, selectedStatus } = props;

  const popupRef = useRef();

  return (
    <div id="change-status-modal" ref={popupRef}>
      <div className="status-list-header">
        <FormattedMessage id="Change Status" defaultMessage="Change Status" />
        <span className="sprite-icon close-arrow" onClick={onClickCross}></span>
      </div>
      <ul>
        {bodyItems.map((bodyItem) => (
          <li
            key={JSON.stringify(bodyItem)}
            className={bodyItem.wrapperClass}
            onClick={() => onClickItem(bodyItem.name)}
            id={
              selectedStatus.toUpperCase() === bodyItem.name.toUpperCase() ||
              (selectedStatus.toUpperCase() === checkoutStatus[1] &&
                bodyItem.name.toUpperCase() === checkoutStatus[0])
                ? 'selected-team-status'
                : null
            }
          >
            <p>
              <FormattedMessage
                id={bodyItem.name || ' '}
                defaultMessage={bodyItem.name || ' '}
              />
            </p>
            <span className={bodyItem.spanClass}></span>
          </li>
        ))}
      </ul>
    </div>
  );
};

JobLinkPopUp.propTypes = {
  heading: PropTypes.string,
  bodyItems: PropTypes.array,
  onClickItem: PropTypes.func,
  onClickCross: PropTypes.func,
  selectedStatus: PropTypes.string,
};

JobLinkPopUp.defaultProps = {
  selectedStatus: 'Checkout',
};

export default JobLinkPopUp;
