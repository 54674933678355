import actionConstants from '../constants/ActionConstants';

const initialState = {
  error: '',
  isFetching: false,
  invoiceNo: '',
  isSuccess: false,
  success: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionConstants.GET_AUTO_GENERATED_INVOICE_REQUEST:
      return {
        ...state,
        isFetching: true,
        isSuccess: false,
        error: '',
        success: '',
      };
    case actionConstants.GET_AUTO_GENERATED_INVOICE_SUCCESS:
      return {
        ...state,
        invoiceNo: action.payload,
        isFetching: false,
        isSuccess: true,
        success: action.message,
      };
    case actionConstants.GET_AUTO_GENERATED_INVOICE_FAILURE:
      return {
        ...state,
        error: action.message,
        isFetching: false,
      };
    case actionConstants.GET_AUTO_GENERATED_INVOICE_CLEAR:
      return {
        ...initialState,
      };
    case actionConstants.CLEAR_STATE:
      return {
        ...initialState,
        invoiceNo: state.reportPdf,
      };
    case actionConstants.RESET_ALL_REDUCERS:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};
